import {DocumentTag, Metadata, UploadAzureFiles} from '../types.domain';
import {GroupedDocument} from './document-details-component.domain';
import {Application} from '../application-service.domain';
import { GetUserFn } from '../auth-service.domain';

export interface UploadFileDialogData {
  title: string;
  tags?: string[];
  metadata?: Metadata;
  allTags?: DocumentTag[];
  enableCustomTag?: boolean;
  enableNonStandardCondition?: boolean;
}

export interface UploadFileDialogResult {
  valid: boolean;
  files: File[];
}

export interface FileWithTags {
  fileName: string | null;
  fileTags: DocumentTag[];
}
  
export interface UploadFilesMultiTagsDialogResult {
  valid: boolean;
  files: UploadAzureFiles;
  selectedTags: DocumentTag[];
}

export interface CustomDocumentTagDialogData {
  nonStandardCondition: boolean;
}

export interface CustomDocumentTagDialogResult {
  tag: DocumentTag;
  readyForSubmission: boolean;
}

export interface DocumentNotificationDialogData {
  docs: GroupedDocument[];
  application: Application;
  getUserFn: GetUserFn;
}

export interface DocumentNotificationDialogResult {
  readyForSubmission: boolean;
  additionalInformation: string;
  removeFromList: boolean;
  emailList: string[];
}

export interface DocumentReviewDialogData {
  title: string;
  groupNames:GroupedDocument[];
  application: Application;
  isShowRequestReviewSection: Boolean;
}

export interface DocumentReviewDialogResult {
  valid: boolean;
  groupNames:GroupedDocument[];
  documentNotes: string;
  isInternalUser: boolean;
  isSalesSelect: boolean;
  isCreditSelect: boolean;
  isSubmitterSelect: boolean;
  isSettlementSelect:boolean;
  submitterEmailList: string[];
  otherEmailList: string[];
  isAssignWorklist:boolean;
}