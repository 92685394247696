import {RepaymentFrequencyType} from '../app-calculator';
import { constants } from '../const';
import {ApplicationTypes, IndividualAdditionalRole} from './application-service.domain';
import {Address2ComponentValue} from './component/address2-component.domain';
import {PpsrAsset} from './ppsr-service.domain';

export type ExchangeDocusignTokenPayload = ExchangeDocusignTokenPayloadOk | ExchangeDocusignTokenPayloadBad;

export interface ExchangeDocusignTokenPayloadOk {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  error?: string;
}

export interface ExchangeDocusignTokenPayloadBad {
  error: string;
}

export interface GenerateContractBody {
  signers: DocusignSigner[];
  ccs: DocusignCarbonCopy[];
  templateData: ContractTemplateData;
  applicationType: ApplicationTypes;
  financeType: string;
  applicationId: number;
}

export interface ContractPricingDetails {
  InvoiceAmount?: number;
  Deposit?: number;
  LoanAmount?: number;
  AmountFinance?: number;
  BalloonPaymentPercentage?: number;
  Repayment?: number;
  BrokerageAmount?: number;
  Brokerage?: number;
  BrokerOriginationFee?: number;
  DocFeeFinanced?: "Yes" | "No";
  APR?: number;
  BrokerageDollar?: number;
  Rate?: number;
  Margin?: number;
  FacilityEstablishmentFee?: number;
  RateDiscount?: number
  OversCommission?: number;
}

export interface ContractEmailBody {
  signers: DocusignSigner[];
  ccs: DocusignCarbonCopy[];
  contractName: string;
  applicationId: number;
  emailSubject: string;
  customerName: string;
  assets?: PpsrAsset[];
  loanAmount?: number;
  pricingDetails: ContractPricingDetails;
  userId: number;
}

export interface AssetValuationDetails {
  index: number;
  category: string;
  year: string;
  description: string;
  serialNumberType: string;
  serialNumber: string;
  invoiceNumber: string;
  invoiceAmount: string;
  supplier: string;
  supplierType: string;
  status: string;
  ppsrStatus: string;

}
export interface DeleteContractBody {
  fileName: string;
}

export interface DeclineContractBody {
  envelopeId: string;
}

export interface DocusignSigner {
  email: string;
  name: string;
  mobile: string;
  role: string;
  address: string;
  isPrimaryContact: boolean;
  thirdPartyEntity: string;
  additionalRoles?: IndividualAdditionalRole[];
}

export interface DocusignCarbonCopy {
  email: string;
}

export interface ContractTemplateData {
  company?: {
    name: string;
    abn: string;
    address: string;
    email: string;
    acn: string;
    phone: string;
  };
  customer?: {
    name: string;
    address: string;
    mobile: string;
    email: string;
  };
  expense?: {
    carLoans: number;
    creditCardLimit: number;
    education: number;
    electricity: number;
    groceries: number;
    homeLoans: number;
    insurance: number;
    otherLoans: number;
    otherUtilities: number;
    personalLoans: number;
    shareOfExpense: number;
  } | null;
  invoicePrice: number;
  deposit: number;
  docFeeFinanced: boolean;
  loanAmount: number;
  totalInterest: number;
  totalInterestInclBrokerage: number;
  amountFinanced: number;
  totalAmount: number;
  docFee: number;
  numberOfInstallments: number;
  repaymentFrequency: RepaymentFrequencyType;
  repaymentFrequencyTypeName: string;
  residual: number;
  repayment: number;
  repaymentGST: number;
  brokerOriginationFee: number;
  rate: number;
  margin: number;
  term: number;
  date?: string;
  assets: {
      description: string;
      invoicePrice: number;
      invoiceNumber: string;
      supplier: string;
      serialNumber: string;
  }[];
  brokerName: string;
  brokerage: number;
  facilityLimit: number;
  facilityEstablishmentFee: number;
  paymentInAdvanceOrArrears: string;
  additionalSecurity: string;
  transactionType: string;
  specialConditions: string;
  monthlyAmountKeepingFee: number;
}

export interface SendContractResponse {
  envelopeId: string;
  success: boolean;
  statusCode: number;
  error: string;
}

export interface GenerateContractResponse {
  fileName: string | null;
  base64: string | null;
  watermarkedFileName: string | null;
}

export interface OverdraftStatementTemplateData {
  contact: {
    name: string;
  };
  company: {
    name: string;
    abn: string;
    acn: string;
    address: string;
  };
  customer: {
    address: string
  };
  pismo: {
    account: number; // account number
  };
  dd: {
    bsb: string,
    acct: string,
  },
  stmt: {
    opening: string; // opening balance
    debits: string;
    credits: string;
    interest: string;
    monthlyFacilityFee: string;
    closing: string; // closing balance
    amtDue: string;
    dueDate: string;
    period: string;
    minPymt: string;
    avaliableCredit: string; // available credit
    creditLimit: string; // credit limit
    rate: string;
    cycleOpeningDate: string;
    cycleClosingDate: string;
  };
  transactions: {
    date: string;
    cardLast4Digits: string; // card Last 4 digits
    description: string;
    credit: boolean;
    amt: string;
  }[];
}

export interface OverdraftStatementTemplateDataForCSV {
  stmt: {
    cycleClosingDate: string;
  };
  transactions: {
    Date: string;
    CardID: string; // card Last 4 digits
    Description: string;
    Payee: string;
    Reference: string;
    Amount: string;
  }[];
}

export interface OverdraftStatementTemplateDataForOFX {
  stmt: {
    cycleOpeningDate: string;
    cycleClosingDate: string;
  };
  transactions: {
    date: string,
    amount: string,
    payee: string,
    type: string,
    reference: string,
    name: string
  }[];
}

// created: The recipient is in a draft state. This is only associated with draft envelopes (envelopes with a created status).
// sent: The recipient has been sent an email notification that it is their turn to sign an envelope.
// delivered: The recipient has viewed the documents in an envelope through the DocuSign signing web site. This is not an email delivery of the documents in an envelope.
// signed; The recipient has completed (performed all required interactions, such as signing or entering data) all required tags in an envelope. This is a temporary state during processing, after which the recipient is automatically moved to completed.
// declined: The recipient declined to sign the documents in the envelope.
// completed: The recipient has completed their actions (signing or other required actions if not a signer) for an envelope.
// faxpending: The recipient has finished signing and the system is waiting a fax attachment by the recipient before completing their signing step.
// autoresponded: The recipient's email system auto-responded to the email from DocuSign. This status is used by the DocuSign webapp (also known as the DocuSign console) to inform senders about the auto-responded email.

export interface SignerStatus {
  completedCount: number;
  status: "created" | "sent" | "delivered" | "signed" | "declined" | "completed" | "faxpending" | "autoresponded";
  email: string;
  name: string;
  sentDateTime: string;
  deliveredDateTime: string;
  signedDateTime: string;
  declinedDateTime: string;
  routingOrder: number;
}
export type DocusignStatusKey = keyof typeof constants.docusignStatus;

export interface ContractStatusResponse {
  signers: SignerStatus[];
  success: boolean;
  statusCode: number;
  error: string;
  envelopeId: string;
  userName: string;
  createdTime: string;
}

export interface ContractDetails {
  contractId: number;
  envelopeId: string;
  status: string;
  applicationId: number;
  createdTime: string;
  lastUpdatedTime: string;
  pricingDetails?: ContractPricingDetails;
  userName?: string;
}

export interface DocusignTab {
  stampType?: string;
  value?: string;
  name: string;
  tabLabel: string;
  scaleValue:string;
  optional: string;
  documentId: string;
  recipientId: string;
  pageNumber: string;
  xPosition: string;
  yPosition: string;
  anchorString: string;
  anchorXOffset: string;
  anchorYOffset: string;
  anchorUnits: string;
  anchorCaseSensitive: string;
  anchorMatchWholeWord: string;
  anchorHorizontalAlignment: string;
  anchorTabProcessorVersion: string;
  tabId: string;
  status: string;
  tabType: string;
}

export interface DocusignConnectResponse {
  event: string;
  apiVersion: string;
  uri: string,
  retryCount: number;
  configurationId: number;
  generatedDateTime: string;
  data: {
    accountId: string;
    userId: string;
    envelopeId: string;
    envelopeSummary: {
      status: string;
      emailSubject: string;
      sender: {
        userName: string;
        accountId: string;
        userId: string;
        email: string;
      },
      recipients: {
        signers: {
          tabs: {
            signHereTabs?: DocusignTab[];
            dateSignedTabs?: DocusignTab[];
            textTabs?: DocusignTab[];
          }
        }[]
      },
      envelopeDocuments: {
        documentId: string;
        documentIdGuid: string;
        name: string;
        type: string;
        order: string;
        pages: {
          pageId: string;
          sequence: string;
          height: string;
          width: string;
          dpi: string;
        }[];
        display: string;
        includeInDownload: string;
        signerMustAcknowledge: string;
        templateRequired: string;
        authoritative: string;
        PDFBytes: string;
      }[],
    }
  }
}

export interface EditAddressDialogData {
  address: Address2ComponentValue;
}

export interface EditAddressDialogResult {
  readyForSubmission: boolean;
  address: Address2ComponentValue;
}

export interface SyncContractDetailsBody {
  brokerageAmount: number;
  brokerageDollar?: number;
  brokerOriginationFee: number;
  invoiceAmount: number;
  docFeeFinanced?: boolean;
  rate?: number; // only for consumer asset finance
  legalName?: string;
  address?: string;
  abn?: string;
  acn?: string;
  salesforceId: string;
  facilityEstablishmentFee?: number;
}

export interface UpdateApplicationStageBody {
  salesforceId: string;
  stageName: string;
}

export interface ConfirmationOfAcceptanceData {
  fileId: string;
  fileName: string;
  customerName: string;
  email: string;
  sentTime: string;
  acceptedTime: string;
  ip: string;
}

export interface DocumentReviewEmailBody {
  emailList: string[];
  pendingTag:string[];
  inProgressTag:string[];
  applicationId:number;
  brokerAppId:string;
  applicationType:string;
  isSubmitterSelect:boolean;
  documentNotes:string;
  isFromDocumentReview: boolean;
}
