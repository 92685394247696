import {Component, Input, ViewChild} from '@angular/core';
import {
  AssetSettlementGetApplicationAssetFn,
  BusinessLoansApplication,
  ConfirmationDialogResult,
  CreateApplicationNoteFn, DeleteApprovalNoticeFileFn, GenerateApprovalNoticeFn,
  GetApplicationByIdFn, GetApplicationOwnerFn, GetDisbursementByApplicationIdFn,
  GetUserFn,
  InsurancePremiumApplication,
  RemoveApplicationNoteByNoteIdFn, SendApprovalNoticeEmailFn, SettleLoanFn,
  UpdateApplicationStageInSfFn
} from '@portal-workspace/grow-shared-library';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {
  ApproveApplicationDocumentFn, CompleteDocumentWorklistFn,
  CreateDocumentWorklistFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DownloadAllApplicationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn, GetNotesByApplicationIdFn,
  ListApplicationDocumentFn, PortalHotToastService,
  SendApplicationDocumentNotificationFn,
  setupUntilDestroy,
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  UndoApplicationDocumentFn,
  ApplicationDialogService,
} from '@portal-workspace/grow-ui-library';
import { DocumentDetailsComponentEvent, DocumentDetailsComponent } from '../document-details.component';
import {tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import { AssetNotesComponent } from '../../asset-listing-component/asset-notes.component';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'insurance-documents',
    templateUrl: './insurance-documents.component.html',
    styleUrls: ['./insurance-documents.component.scss'],
    standalone: true,
    imports: [DocumentDetailsComponent, AssetNotesComponent]
})
export class InsuranceDocumentsComponent {

  @ViewChild('assetNotesComponent') assetNotesComponent!: AssetNotesComponent;

  subscriptions: Subscription[] = [];

  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) application!: InsurancePremiumApplication;
  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  @Input({required: true}) sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;
  totalNotesCount: number = 0;

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  constructor(private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService,) {}

  async onDocumentDetailsEvent($event: DocumentDetailsComponentEvent) {
    const files = $event.files;
    const removeFiles = $event.removefileNames;
    const isNonStandardConditionDocument = $event.isNonStandardConditionDocument;
    this.subscriptions.push((await this.uploadApplicationDocumentFn(this.application.ApplicationId, files, removeFiles))
      .pipe(
        this.toastService.spinnerObservable(),
        tap(app => {
          this.application = app as InsurancePremiumApplication;
        })
      ).subscribe(() => {
        if(isNonStandardConditionDocument){
          this.subscriptions.push(
            this.dialogService.openConfirmationDialog({
              message: 'Please confirm',
              subMessage: 'Have you uploaded all documents for non-standard conditions<br/> and wish to submit the application to the credit team for review?'
            }).afterClosed().pipe(
              tap(async (r: ConfirmationDialogResult | undefined) => {
                if (r && r.readyForSubmission) {
                  this.onSubmitForReview();
                }
            })
            ).subscribe()
          );
        }

        if (files.length) {
          if ((files[0] as any).tags.includes('bankstatements')) {
            this.subscriptions.push(
              this.dialogService.openConfirmationDialog({
                message: 'Please confirm',
                subMessage: 'Have you attached all the bank statements and would like to send the application to our Credit team for review?'
              }).afterClosed().pipe(
                tap(async (r: ConfirmationDialogResult | undefined) => {
                  if (r && r.readyForSubmission) {
                    this.onSubmitForCreditReview();
                  }
              })
              ).subscribe()
            );
          }
        }
        // this.toastService.snackbar(
        //   "Click 'Submit for Review' to get our team to review the new documents you have uploaded",
        //   'Close'
        // )
      }));
  }

  scrollToChildTwo(){
    const targetElement = document.getElementById('notes-component-section');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  totalNotes($event: number){
    this.totalNotesCount = $event;
  }

  onSaveNote(){
    this.assetNotesComponent.reload();
  }

  onSubmitForReview() {
    this.subscriptions.push(
      this.createDocumentWorklistFn({
        applicationId: this.application.ApplicationId,
        createdTime: new Date(),
        userId: null,
        status: 'pending',
        entityName: this.application.entityName,
        brokerAppId: this.application.BrokerAppId
      }).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Submission success',
          errorMessage: 'Failed to submit for review',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.createDocumentWorklistFn({
              applicationId: this.application.ApplicationId,
              createdTime: new Date(),
              userId: null,
              status: 'pending',
              entityName: this.application.entityName,
              brokerAppId: this.application.BrokerAppId
            })
          }
        })
      ).subscribe(r => {
        if (r) {
          if (!r.payload) {
            this.dialogService.openAlertDialog({
              message: "Warning",
              subMessage: "You have already submitted this application for review"
            })
          }
        }
      })
    )
  }

  onSubmitForCreditReview() {
    this.subscriptions.push(
      this.updateApplicationStageFn({
        salesforceId: this.application.AppInfoSalesforceID ?? '',
        stageName: "Submitted to Credit"
      }).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Submission success',
          errorMessage: 'Failed to submit for credit review',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.updateApplicationStageFn({
              salesforceId: this.application.AppInfoSalesforceID ?? '',
              stageName: "Submitted to Credit"
            })
          }
        })
      ).subscribe()
    )
  }

}
