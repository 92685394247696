import {AfterContentChecked, ChangeDetectorRef, Component, forwardRef, Input, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy , formControlErrorKeys, formControlErrorMessage, noSpaceValidator, PortalHotToastService, ApplicationDialogService } from '@portal-workspace/grow-ui-library';
import { tap, delay, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MARK, Mark } from '@portal-workspace/grow-ui-library/mark';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {compareMatch, CreditRuleComponentValue, CreditRuleItem, CreditRuleItemSelections, CreditRuleItemType, CreditRuleObject, CreditRuleOperator, GetCreditRuleItemsFn, groupCreditRuleItems, parseJSON, processCreditRule, removeUnprintableChar, stringifyJSON} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatFormFieldModule } from '@angular/material/form-field';
import {DisableControlDirective} from '../../directives/disable-control.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { validateCreditRule } from '@portal-workspace/grow-shared-library';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@UntilDestroy()
@Component({
    selector: 'credit-rule',
    templateUrl: './credit-rule.component.html',
    styleUrls: ['./credit-rule.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CreditRuleComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CreditRuleComponent) },
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatMenuModule, MatButtonModule, FlexModule, MatFormFieldModule, MatInputModule, InputMaskModule, DisableControlDirective, MatChipsModule]
})
export class CreditRuleComponent extends AbstractControlValueAccessor<CreditRuleComponentValue> implements OnInit, AfterContentChecked, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  @Input({required: false}) placeholder = 'Enter credit rule...';
  @Input({required: false}) title = 'Rule Formula';
  @Input({required: false}) creditRuleType: CreditRuleItemType = 'Formula';
  @Input({required: true}) getCreditRuleItemsFn!: GetCreditRuleItemsFn;

  formControl!: FormControl<CreditRuleComponentValue>;
  creditRuleObjects: string[] = [];
  actualCreditRuleObjects: string[] = [];
  readonly separatorKeysCodes = [ENTER] as const;
  addOnBlur = true;
  invalidFormulaError = true;

  @Input({required: false}) mandatory = true;
  formGroup!: FormGroup<{
    name: FormControl<CreditRuleComponentValue>
  }>;
  subscription?: Subscription;

  constructor(
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,) {
    super();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.formControl = this.formBuilder.control('', this.mandatory ? [Validators.required,noSpaceValidator()] : []);
    this.formGroup = this.formBuilder.group({
      name: this.formControl,
    });
    this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap((v: any) => {
        if (this.creditRuleType === 'Database' || this.creditRuleType === 'JSON' || this.creditRuleType === 'Inteflow Database') {
          if (this.formGroup.valid || this.formGroup.disabled) {
            // remove unprintable characters and remove front & end spaces
            this.propagateChange(removeUnprintableChar(v))
          } else {
            this.propagateChange(null)
          }
        }
      })
    ).subscribe();

   
    // this.getCreditRuleItemsFn().pipe(
    //   this.toastService.spinnerObservable()
    // ).subscribe((creditRuleItems: CreditRuleItem[]) => {
    //   this.creditRuleItemSelections = groupCreditRuleItems(creditRuleItems);
    //   console.log(this.creditRuleItemSelections)
    // })
  }

  // setDisabledState(isDisabled: boolean) {
  //   super.setDisabledState(isDisabled);
  //   if (isDisabled) {
  //     this.formControl.disable();
  //   } else {
  //     this.formControl.enable();
  //   }
  // }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  doWriteValue(v: CreditRuleComponentValue) {
    if (!v) {
      this.formControl.setValue(v);
      return;
    }
    
    const list = parseJSON(v);
    if (list.length && typeof list !== 'string') {
      this.actualCreditRuleObjects = list.map((a: any) => {
        if (a?.type === 'variable') {
          return `{${a?.value}}`
        } else if (a?.type === 'operator' || (a?.type === 'value' && typeof a.value !== 'string')) {
          return a?.value ?? ''
        } else if (a?.type === 'value' && typeof a.value === 'string') {
          return `'${a?.value}'` ?? ''
        } else {
          return a;
        }
      });
      this.creditRuleObjects = list.map((a: any) => {
        if (a?.type === 'variable') {
          const variableSplits = a?.value?.split('(ID:');
          if (variableSplits.length) {
            return `{${variableSplits[0].trim()}}`
          } else {
            return `{${a?.value}}`
          }
        } else if (a?.type === 'operator' || (a?.type === 'value' && typeof a.value !== 'string')) {
          return a?.value ?? ''
        } else if (a?.type === 'value' && typeof a.value === 'string') {
          return `'${a?.value}'` ?? ''
        } else {
          return a;
        }
      });
      console.log(this.actualCreditRuleObjects)
      if (this.creditRuleType === 'Formula') {
        this.invalidFormulaError = !validateCreditRule(this.actualCreditRuleObjects);
      }
    }
     else {
      this.formControl.setValue(v);
    }
  }

  mark() {
    this.formControl.markAllAsTouched();
  }

  addOperator(operator: CreditRuleOperator) {
    // const value = this.formControl.value ?? '';
    // this.formControl.setValue(`${value} ${operator}`)
    this.creditRuleObjects.push(operator);
    this.actualCreditRuleObjects.push(operator);
  }

  onClickItem() {
    this.dialogService.openSelectCreditRuleItemDialog({
      getCreditRuleItemsFn: this.getCreditRuleItemsFn
    }).afterClosed().subscribe(result => {
      if (result && result.readyForSubmission) {
        this.addItem(result.creditRuleItem);
      }
    })
  }

  addItem(item: CreditRuleItem) {
    if (this.creditRuleType === 'Formula') {
      this.creditRuleObjects.push(`{Item: ${item.name}}`);
      this.actualCreditRuleObjects.push(`{Item: ${item.name} (ID: ${item.id})}`);

      this.invalidFormulaError = !validateCreditRule(this.actualCreditRuleObjects);
      if (this.invalidFormulaError) {
        this.propagateChange(null)
      } else {
        this.propagateChange(stringifyJSON(processCreditRule(this.actualCreditRuleObjects)));
      }
    } else if (this.creditRuleType === 'Database' || this.creditRuleType === 'Inteflow Database') {
      const value = this.formControl.value ?? '';
      this.formControl.setValue(`${value}{Item: ${item.name} (ID: ${item.id})}`)
    }
  }

  validateRule() {
    // const rule = this.formControl.value ?? '';
    const result = validateCreditRule(this.actualCreditRuleObjects);
    if (result) {
      this.dialogService.successDialog({
        message: "Valid",
        subMessage: "The formula is valid"
      }).afterClosed().subscribe()
    } else {
      this.dialogService.openAlertDialog({
        message: "Error",
        subMessage: "The formula is invalid. Please double check."
      }).afterClosed().subscribe()
    }
  }

  add(event: MatChipInputEvent): void {
    if (this.creditRuleType === 'Formula') {
      const value = (event.value || '').trim();
      if (value) {
        this.creditRuleObjects.push(value);
        this.actualCreditRuleObjects.push(value)
        event.chipInput!.clear();

        this.invalidFormulaError = !validateCreditRule(this.actualCreditRuleObjects);
        console.log(this.invalidFormulaError)
        if (this.invalidFormulaError) {
          this.propagateChange(null)
        } else {
          this.propagateChange(stringifyJSON(processCreditRule(this.actualCreditRuleObjects)));
        }
      }
    }
  }

  remove(index: number): void {
    if (this.creditRuleType === 'Formula') {
      if (index >= 0) {
        this.creditRuleObjects.splice(index, 1);
        this.actualCreditRuleObjects.splice(index, 1);
      }
    
      this.invalidFormulaError = !validateCreditRule(this.actualCreditRuleObjects);
      console.log(this.invalidFormulaError)

      if (this.invalidFormulaError) {
        this.propagateChange(null)
      } else {
        this.propagateChange(stringifyJSON(processCreditRule(this.actualCreditRuleObjects)));
      }
    }
  }
}
