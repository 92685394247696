<div class="more-information-dialog">
  <mat-dialog-content class="max-height-100-vh">
    <div class="row">
      <div class="col-12">
        <h3 class="header">Request More Information</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 email-list">
        <mat-form-field class="cc-email-chip-list">
          <mat-label>Send the notification to*</mat-label>
          <mat-chip-grid #chipList aria-label="email list">
            @for (email of emailList; track email) {
              <mat-chip-row (removed)="remove(email)">
                {{email}}
                <button matChipRemove>
                  <span class="mdi mdi-close"></span>
                </button>
              </mat-chip-row>
            }
            <input [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              [formControl]="formControlEmail"
              (matChipInputTokenEnd)="add($event)">
            </mat-chip-grid>
          </mat-form-field>
          @if (emailError) {
            <span class="error">
              Please enter a valid email
            </span>
          }
        </div>
      </div>

      <div class="separator"></div>

      <div class="row">
        <div class="col">
        <custom-angular-editor [formControl]="formControlAdditionalInformation"
        [placeholder]="placeholder" [height]="250"></custom-angular-editor>

      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
        @if (emailList) {
          <button mat-flat-button color="primary"
            [disabled]="formGroup.invalid || emailError || !(emailList.length) || !formControlAdditionalInformation.value"
          (click)="onSubmit()">Submit</button>
        }
      </div>
    </div>
  </mat-dialog-content>
</div>
