import { DigitalIdResult, DigitalIdResultError, KycStatus } from '../domain/digital-id-service.domain';
import { MaritialStatusSelectionName } from '../domain/component/maritial-status-selection-component.domain'
import { Address2ComponentValue } from '../domain/component/address2-component.domain';
import { TrusteeValue } from '../domain/component/trustee-component.domain';
import { MemberValue } from '../domain/component/member-component.domain';
import { PartnerValue } from '../domain/component/partner-component.domain';
import { SoleTraderValue } from '../domain/component/sole-trader-component.domain';
import { DirectorValue } from '../domain/component/director-component.domain';
import { RoleAccess } from './auth-service.domain';
import { ClientResponse } from '@sendgrid/mail';
import { PismoCustomerMapping } from './pismo.domain';

export type ApplicationApplicant =
  | TrusteeValue
  | MemberValue
  | PartnerValue
  | SoleTraderValue
  | DirectorValue;
export type ApplicationApplicantVal =
  | Exclude<TrusteeValue, null>[number]
  | Exclude<MemberValue, null>[number]
  | Exclude<PartnerValue, null>[number]
  | Exclude<SoleTraderValue, null>
  | Exclude<DirectorValue, null>[number];

export type EncodingTypes =
  | 'ascii'
  | 'utf8'
  | 'utf-8'
  | 'utf16le'
  | 'ucs2'
  | 'ucs-2'
  | 'base64'
  | 'latin1'
  | 'binary'
  | 'hex';

export type BrokerPriviledge =
  | 'lg'
  | 'broker'
  | 'vendor'
  | 'dealer'
  | 'aggregator'
  | 'trade'
  | 'overdraft';
export type CustomerAccessLevelValue = CustomerAccessLevel | null;
export type CustomerAccessLevel = 'card-admin' | 'card-member' | 'card-disabled';
export type CustomerPriviledge = ''; // todo:
export type RoleAccessType = 'allowThirdPartyPayment' | 'allowBPay' | 'allowAllTransactions';
export type Priviledge = BrokerPriviledge | AccessLevel /* Broker Access Level */ | CustomerPriviledge /*| CustomerAccessLevel | RoleAccessType */;
export type Priviledges = Priviledge[];

export type AccessLevel = // broker access level
  | 'admin'
  | 'analyst'
  | 'companyadmin'
  | 'customer'
  | 'externalbroker'
  | 'internalbroker'
  | 'companyoperator'
  | 'operations'
  | 'operations24'
  | 'credit'        // analyst minus manage user, minus manage ratecard
  | 'settlement'    //
  | 'salesBDM'      // cannot manage users
  | 'salesAM'       // can manage users
;
export type OnOff = 0 | 1;
export type JsonString = string;
export type CustomerType = 'consumer' | 'commercial';
export type Nullable<T> = T | undefined;
export type NotNullable<T> = Exclude<T, null>;
export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;
export type PartialOrNull<T> = { [P in keyof T]?: T[P] | undefined | null };

export type PaymentPeriodType = 'Weekly' | 'Monthly' | 'Fortnightly' | 'Quarterly';

export type MobilePlatformType = 'mobile' | 'mobile-web';
export type PortalPlatformType = 'web';
export type PlatformType = MobilePlatformType | PortalPlatformType;

export interface BrowserInfo {
  browserName: string;
  fullVersion: string;
  majorVersion: string;
  appName: string;
  userAgent: string;
}

export interface PaywayAccountCustomer {
  customerNumber?: string;
  name: string;
  email: string;
  mobileNumber?: string;
  address?: {
    street?: string;
    city?: string;
    state?: string;
    postCode?: string;
  };
}

export class ClientError extends Error {
  constructor(
    public msg: string,
    public popupDialog: boolean = false,
    public redirectType: null | 'Applications' = null
  ) {
    super(msg);
  }
}

export type SendEmailResponse = [ClientResponse, {}]

// export interface SendEmailResponse {
//   accepted: string[]; // email address
//   rejected: string[]; // email address
//   envelopeTime: number;
//   messageTime: number;
//   messageSize: number;
//   response: string;
//   envelope: {
//     from: string;
//     to: string[];
//     messageId: string;
//     originalMessage: {
//       from: string;
//       to: string;
//       cc: string;
//       attachments: any[];
//       subject: string;
//       html: string;
//       text: string;
//     };
//   };
// }

export interface Doc {
  name: string;
  size: number;
  type: string;
  date?: string;
  base64?: string;
}
// export interface SupportDoc {
//   id: string,  // requestId
//   name: string,
//   size: number,
//   type: string,
//   date?: string,
//   invoiceNo: string;
// }
// export interface RightSignDoc {
//   name: string,
//   invoiceNo: string,
//   rightSignUrl: string,
//   date?: string,
// }

export interface Pagination {
  page?: { limit: number; offset: number };
  sort?: { columnName: string; order: 'DESC' | 'ASC' }[];
}

export interface Base64File {
  name: string;
  type: string;
  base64: string;
  size: number;
  // path?: string, // path in azure storage
  tags?: string[]; // tags in azure storage
  metadata?: Metadata; // metadata in azure storage
}

export interface Metadata {
  [propertyName: string]: string;
}

export type UploadAzureFilesValue = UploadAzureFiles | null;
export type UploadAzureFile = File & AzureFileTagable & AzureFileMetadatable;
export type UploadAzureFiles = UploadAzureFile[];
export type UploadAzureFileLike = { name: string } & AzureFileTagable &
  AzureFileMetadatable;
export type AzureFileTagable = { tags?: string[] };
export type AzureFileMetadatable = { metadata?: Metadata };

export interface PaginationInfo {
  limit: number;
  offset: number;
  start: number;
  filter: string | null;
  sorts: SortTypes;
}

export interface SortType<T extends string = string> {
  dir: 'asc' | 'desc' | 'ASC' | 'DESC';
  prop: T;
}
export type SortTypes<T extends string = string> = SortType<T>[] | null;

export interface ApiResponse {
  status: boolean;
  message: string;
}

export interface PayloadApiResponse<P> extends ApiResponse {
  payload: P;
}

export interface PaginablePayloadApiResponse<P> extends ApiResponse {
  limit: number;
  offset: number;
  total: number;
  payload: P[];
}

export interface AuthMiddlewarePayload {
  messages: string[];
}

export type PaywayVirtualAccountType =
  | {
      accountNumber: string;
      bsb: string;
      virtualAccountStatus: 'open' | 'close';
      yourBankAccount: {
        accountName: string;
        bankAccountId: string;
        remitterName: string;
        settlementAccountNumber: string;
        settlementBsb: string;
      };
    }
  | undefined;

export type XeroContactDetailsType =
  | {
      Contacts: {
        Address: {
          AddressType: string;
          City: string;
          Region: string;
          PostalCode: string;
          Country: string;
        }[];
        BankAccountDetails: string;
        ContactGroups: any[];
        ContactID: string;
        ContactPerson: any[];
        ContactStatus: string;
        EmailAddress: string;
        HasValidationErrors: boolean;
        IsCustomer: boolean;
        IsSupplier: boolean;
        Name: string;
        Phones: any[];
        PurchasesTrackingCategories: any[];
        SalesTrackingCategories: any[];
        UpdatedDateUTC: string;
      }[];
      DateTimeUTC: string;
      Id: string;
      ProviderName: string;
      Status: string;
    }
  | undefined;

export type UserPublicStatus = {
  approval: boolean,
  rejected: boolean,
  isLock: boolean,
  verified: boolean,
  mobile_verified: boolean,
}

export type UserOmitPriviledges = Omit<User, 'priviledges'>;

export type UserandCustomerPayway = UserandPriviledges & {
  BrokerSalesforceId?: string | null;
  SalesforceId: string | null;
};
export type UserandPismo = UserandPriviledges & {
  pismoCustomerNumber: number;
  pismoCardNumber: number;
  pismoAccountNumber: number;
};
export type LoginStatus = 'invalid-username-password' | 'locked' | 'other-login-issues' | 'user-disabled' | 'not-overdraft-user' | 'account-not-linked-to-pismo' | 'not-broker-login' |  'success';
export type PortalLoginUser = UserandCustomerPayway & {
  token: string;
  refreshToken: string;
};
export type UserandPriviledges = User & {
  priviledges: Priviledges,
  pismo: {
    pismoAccountNumber: number,
    pismoCustomerNumber: number,
    customerAccessLevel: CustomerAccessLevel;
    RoleAccess: RoleAccess,
    customerId: number,
    customerName: string,
    isPismoCustomerDisabled: boolean,
  }[];
};

export type UserType = 'broker-user' | 'customer-user';
export interface UserWithOriginatorBusinessAndCustomerEntityNames extends User {
  BusinessName: string | null, // originator business name
  customerEntityNames: string | null, // customer entity names (comma separaterd)
}

export interface User {
  //CustomerId: number;
  Email: string;
  FamilyName?: string;
  dob?: string; //yyyy-mm-dd
  GivenName?: string;
  MiddleName?: string;
  IsBrokerCompany?: string;
  IsCustomer?: boolean;
  Name?: string;
  OriginatorBusinessId?: number;
  // PhoneNumber?: string;
  MobileNumber?: string;
  UserId: number;
  approval?: 0 | 1;
  forgot_code?: string;
  mobile_otp?: any;
  mobile_verified?: OnOff;
  otp?: any;
  rejected?: OnOff;
  verification_code?: string;
  verified?: OnOff;
  AccessLevel?: AccessLevel;
  isDisabled: boolean;
  is2FA: boolean | null;
  SecretKey: string | null;
  customerAccessLevel?: CustomerAccessLevel;
  RoleAccess:RoleAccess| null,
  kycStatus?: KycStatus;
  kycVerificationResult?: DigitalIdResult;
  kycVerificationError?: DigitalIdResultError | null;
  kycCounter?: number | null;
  // PismoPhoneId?: number | null;
  ResidentialAddress?: Address2ComponentValue;
  PostalAddress?: Address2ComponentValue;
  firstResetPasswordDate?: string | null; // YYYY-MM-DD
  firstLoginDate?: string | null; // YYYY-MM-DD
  latestLoginDate?: string | null; // YYYY-MM-DD
  isLock: boolean;
  driverLicenceNumber?: string,
  driverCardNumber?: string,
  driverCardExpiryDate?: string,
  sfContactId?: string,
}

export interface Customer {
  CustomerId: number;
  SalesforceId?: string;
  CustomerType?: CustomerType;
  ABN?: string;
  ACN?: string;
  EntityName?: string;
  EntityType?: string;
  FiscalCode?: string;
  Address?: {
    RawAddress: string;
    UnitNumber?: string;
    StreetNumber?: string;
    StreetName?: string;
    StreetType?: string;
    Suburb?: string;
    State?: string;
    Postcode?: string;
    Country?: string;
    IsManualInput?: boolean;
    Latitude?: string;
    Longitude?: string;
  };
  CreateTime: string;
  Document?: { name: string; size: number; type: string }[];
  RecordTypeId?: string;
  AccountBSB?: string;
  AccountName?: string;
  AccountNumber?: string;
  Broker?: string;
  InsurancePremiumFinance?: boolean;
  TradeFinance?: boolean;
  AssetFinance?: boolean;
  BusinessLoans?: boolean;
  IsActive?: boolean;
}

export type CustomerWithUserContactAndPaywayDetails = Customer & {
  // PhoneNumber: string;
  MobileNumber: string;
  Email: string;
};

export interface OriginatorBusiness {
  OriginatorBusinessId: number;
  SalesforceId: string;
  SourceId: string;
  Relationship: 'undisclosed' | 'disclosed' | null;
  Type: 'broker' | 'vendor' | 'dealer' | 'aggregator';
  AggregatorSalesforceId?: string;
  EntityName: string;
  BusinessName: string;
  EntityType:
    | 'Incorporated'
    | 'Partnership'
    | 'Company'
    | 'Unknown'
    | 'Trust'
    | 'Proprietary'
    | 'Limited'
    | 'Sole Trader'
    | 'Proprietary Limited'
    | 'Corporation';
  ABN: string;
  ACN: string;
  Website: string;
  Email: string;
  MobileNumber: string;
  TelephoneNumber: string;
  Accredited: boolean;
  IsPartner: boolean;
  // Aggregator: number | null,
  IsBrokerCompany: boolean;
  FiscalCode: string;
  RatecardId: number;
  IsRemovedFromSF: boolean;
  PercentagePaid: number;
  sfOwnerId: string | null;
}

export interface SfUserUpdate {
  sfOwnerId: string,
  firstName: string,
  lastName: string,
  mobileNumber: string,
  email: string,
  isActive: boolean,
}

export interface RiskRatingInterface {
  growRiskRating: number;
  abnAge: number;
  gstAge: number;
  adverse: string;
  propertyOwnership: string;
  exceptionToPolicy: string;
  companyEquifaxScore: number;
  lowestIndividualEquifaxScore: number;
  highestIndividualEquifaxScore: number;
  lossReasonCategory: string;
  lossReasonDetails: string;
  creditDecisionFlag: string;
  closedLostDatetime: string;
  additionalSecurity: string;
  creditorWatchScore:number;
}

export interface DocumentTag {
  value: string;
  type: string;
  required?: boolean;
  description?: string;
  subDescription?: string;
  nonStandardCondition?: boolean;
  nonStandardConditionStatus?:
    | 'Pending'
    | 'Accepted'
    | 'Rejected'
    | 'In Progress';
  standardCondition?: boolean;
  standardConditionStatus?: 'Pending' | 'Accepted' | 'Rejected' | 'In Progress';
  disableDocumentUpload?: boolean; // only when true, then disable upload, in all other conditions allow it
}
export type AzureStorageContainer =
  | 'app-documents'
  | 'accreditation'
  | 'grow-banking'
  | 'grow-user';
export interface AzureStorageDocument {
  OrMetadata: string;
  metadata?: Metadata;
  name: string;
  blobTags?: {
    blobTagSet: {
      key: string;
      value: string;
    }[];
  };
  properties: {
    'Cache-Control': string;
    'Content-CRC64': string;
    'Content-Disposition': string;
    'Content-Encoding': string;
    'Content-Language': string;
    accessTier: string;
    accessTierInferred: boolean;
    blobType: string;
    contentLength: number;
    contentMD5: {
      type: boolean;
      data: number[];
    };
    contentType: string;
    createdOn: string;
    etag: string;
    lastModified: string;
    leaseState: string;
    leaseStatus: string;
    serverEncrypted: boolean;
  };
  tags?: Metadata;
}
export type EntityType = 'P/L' | 'PTNR' | 'SLTR' | 'PTY' | 'TRST' | null;

export interface SalesforceAggregatorListResult {
  totalSize: number;
  done: boolean;
  records: {
    attributes: {
      type: string;
      url: string;
    };
    Id: string;
    ec1_AccFiscalCode__c: string;
    ec1_RelationshipType__c: string;
    Name: string;
    Interflow_Entity_Type__c: string;
    ec1_ABN__c: string;
    ec1_ACN__c: string;
    Website: string;
    ec1_Email__c: string;
    Phone: string;
    RecordTypeId: string;
  }[];
}

export interface SalesforceOriginatorListResult {
  totalSize: number;
  done: boolean;
  records: {
    attributes: {
      type: string;
      url: string;
    };
    Id: string;
    ec1_AccFiscalCode__c: string;
    ec1_RelationshipType__c: string;
    Name: string;
    Interflow_Entity_Type__c: string;
    ec1_ABN__c: string;
    ec1_ACN__c: string;
    Aggregator__c: string;
    Website: string;
    ec1_Email__c: string;
    Phone: string;
    RecordTypeId: string;
    ec1_Accredited__c: boolean;
  }[];
}

export interface SalesforceAssetSupplierListResult {
  totalSize: number;
  done: boolean;
  records: {
    attributes: {
      type: string;
      url: string;
    };
    Id: string;
    ec1_AccFiscalCode__c: string;
    ec1_RelationshipType__c: string;
    Name: string;
    Interflow_Entity_Type__c: string;
    ec1_ABN__c: string;
    ec1_ACN__c: string;
    Aggregator__c: string;
    Website: string;
    ec1_Email__c: string;
    Phone: string;
    RecordTypeId: string;
    ec1_Accredited__c: boolean;
    Private_Seller__c: boolean;
    Financial_Institution_Name__c: string;
    Bank_Account_Name__c: string;
    Bank_Account_BSB__c: string;
    Bank_Account_Number__c: string;
  }[];
}

// DEPRECATED: same results as GetSalesforceApplicationStatusResult
// export interface RiskRatingResult {
//   totalSize: number,
//   done: boolean,
//   records: {
//     Id: string,
//     Company_Veda__c: number,
//     Min_Director_Equifax__c: number,
//     Max_Director_Equifax__c: number,
//     ABN_Age__c: string,
//     GST_Age__c: string,
//     Adverse__c: string,
//     Property_Ownership__c: string,
//     Exception_to_Policy__c: string,
//     LossReason__c: string,
//     Decline_Details__c: string,
//     Credit_Decision_Flag__c: string,
//     CloseDate: string,
//     Approval_Conditions__c: string,
//   }[]
// }

export interface GetSalesforceApplicationStatusResult {
  totalSize: number;
  done: boolean;
  records: InteflowStatusRecord[];
}

export interface InteflowStatusRecord {
  ABN_24_months__c?: string;
  ABN_Age__c?: number;
  Additional_Security__c?: string;
  Adverse__c?: string;
  Amount_Financed__c?: number;
  Approval_Conditions__c?: string;
  Broker_Origination_Fee__c?: number;
  Brokerage__c?: number;
  Calculated_Doc_Fee__c?: number;
  CloseDate?: string;
  Company_Veda__c?: number;
  Credit_Decision_Flag__c?: string;
  Decline_Details__c?: string;
  Deposit_where_applicable__c?: number;
  Doc_Fee_Financed__c?: string; // number
  Documentation_Fee__c?: number;
  Exception_to_Policy__c?: string;
  GST_Age__c?: number;
  Id: string;
  Invoice_Price__c?: number;
  LossReason__c?: string;
  Max_Director_Equifax__c?: number;
  Min_Director_Equifax__c?: number;
  Number_of_Installments__c?: number;
  Other_recovery_amounts__c?: string;
  Payment_Type__c?: string;
  Property_Ownership__c?: string;
  Rate__c?: number;
  Repayment_Frequency__c?: number;
  Residual_Value__c?: number;
  StageName?: string;
  Term_Charges_interest__c?: number;
  Total_Amount_Payable__c?: number;
  attributes?: {
    type: string;
    url: string;
  };
  ec1_CreditReasoning__c?: string;
  ec1_Credit_Approval_DateTime__c?: string;
  ec1_Credit_Approval_Date__c?: string;
  ec1_Term__c?: number;
  CreditorWatch_Score__c:number;
  growrisk?: number;
  user_id?: number;
}

export interface ServiceabilityCalculationInput {
  repayment: number;
  marital: MaritialStatusSelectionName;
  numberOfDependents: number;
  principalRegularIncome: number;
  spouseRegularIncome: number;
  postcode: string;
  homeLoanExpenses: number;
  electricityExpenses: number;
  carLoanExpenses: number;
  otherUtilitiesExpenses: number;
  personalLoansExpenses: number;
  educationExpenses: number;
  totalCreditCardLimitsExpenses: number;
  groceriesExpenses: number;
  otherLoansExpenses: number;
  insuranceExpenses: number;
  shareOfMonthlyLivingExpenses: number;
}
export interface ServiceabilityCalculationResult {
  repayment: number;
  totalNetIncome: number;
  totalExpense: number;
  netSurplus: number;
  dscr: number;
}

export interface ConsumerFinanceDscrServiceabilityCalculationInput {
  marital: MaritialStatusSelectionName;
  numberOfDependents: number;
  // employmentStatus
  principalRegularIncome: number;
  // hasSpouseOrPartner
  spouseRegularIncome: number;
  homeLoanExpenses: number;
  carLoanExpenses: number;
  totalCreditCardLimitsExpenses: number;
  electricityExpenses: number;
  otherUtilitiesExpenses: number;
  educationExpenses: number;
  monthlyRepayment: number; // this needs to be monthlyRepayment
  postcode: number;
  personalLoansExpenses: number;
  groceriesExpenses: number;
  otherLoansExpenses: number;
  insuranceExpenses: number;
  shareOfMonthlyLivingExpenses: number;
}

export interface ConsumerFinanceDscrServiceabilityCalculationResult {
  householdGrossIncome: number,
  hemInconeBand: string,
  region: string,
  maritialStatus: string,
  numberOfDependents: number,
  hemMonthlyExpense: number,
  applicantAfterTaxMonthlyIncome: number,
  spouseAfterTaxMonthlyIncome: number,
  totalLivingExpenses: number,
  loanAndCreditCardRepayments: number,
  insuranceAndEducation: number,
  higherOfHemAndDeclaredLivingExpense: number,
  totalMonthlyExpense: number,
  maximumShareFromSpouse: number,
  applicantActualShareOfExpense: number,
  monthlyRepayment: number,
  proposedMonthlyExpense: number,
  surplusOrDeficit: number,
  dscr: number,
}

export type UserAddressForGpay = {

  /**
   * Address line 1.
   */
  address1?: string;

  /**
   * Address line 2.
   */
  address2?: string;

  /**
   * Administrative area.
   */
  administrativeArea?: string;

  /**
   * Country code.
   */
  countryCode?: string;

  /**
   * Locality.
   */
  locality?: string;

  /**
   * Name.
   */
  name?: string;

  /**
   * Phone number.
   */
  mobileNumber?: string;

  /**
   * Postal code.
   */
  postalCode?: string;
}

export interface FilterObject {
  date: { label: string, value?: {startDate: string | null, endDate: string | null}},
  amount: { label: string, value?: {min: number | null, max: number | null}},
  type: string,
}

export interface TransactionsParams {
  accountId: number, customerId: number, limit?: number, offset?: number, user: User | undefined, filterObject?: string | undefined, customerAccessLevel?: CustomerAccessLevel | undefined, search?: string
}
