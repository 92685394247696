import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {combineLatest, Subscription} from 'rxjs';
import {
  AzureStorageDocument,
  DocumentTag,
  AggregatorSearchComponentValue,
  BalloonPaymentValueOptions,
  CurrencyInputValue,
  DatepickerValue,
  EmailComponentValue,
  FinanceTypeValueOptions,
  fromIncomeSelectionValueMonthlyIncome,
  GenderValue,
  minDepositForConsumerAssetFinance,
  MobileValue,
  NameComponentValue,
  OriginatorBusiness,
  ServiceabilityCalculationResult,
  UserSelectionValue,
  YesNoValue,
  ConsumerFinanceApplication,
  UploadAzureFiles,
  UploadFileValue,
  RoleTypes,
  SignerRoleTypes,
  TransactionValue,
  PrivateSaleType,
  SaveApplicationPricingDetailsData,
  GetUserSameCompanyFunc,
  PaymentFrequencyValueOptions,
  CustomerApplicantTypeValue,
  yesNoToBoolean,
  GetSalesforceContactSameAccountFunc,
  SalesforceContactSameAccountSelectionValue,
  applicantsPrivacyConsent,
  SliderComponentValue,
  NumberInputValue,
  isExternalUser,
  IdentificationComponentValue,
  RepaymentTypeValue,
  isInternalUser,
  RepaymentTypeOptions,
  IncomePeriodOptions,
  EmploymentStatusSelectionValueOptions,
  normaliseToMonthlyAmount,
  PaymentFrequencyType,
  LoanTermsSelectionWithInputValue,
  BrokerageSelectionType,
  calculateConsumerAssetFinanceEstimation,
  CalculateAssetFinanceEstmationResult,
  TermRateForConsumerFinance,
  CalculateConsumerAssetFinanceEstimationResult,
} from '@portal-workspace/grow-shared-library';
import {
  AggregatorSearchFn,
  applicationToAdditionalBrokerCorrespondent,
  applicationToApplicantType,
  applicationToHasAdditionalBrokerCorrespondent,
  applicationToHybrid,
  formControlErrorKeys,
  formControlErrorMessage,
  ServiceabilityCalculationFn,
  ServiceabilityRatioComponent,
  toInteflowAddress,
  toInteflowPropertyOwnerAddress, UserSameCompanySelectionComponent,
  setStepper2StepConfig, SalesforceContactSameAccountSelectionComponent,
  applicationToBrokerContact, requiredToBeTrueValidator,
  UploadedFilesListComponentEvent,
  duplicateFileNameValidator,
  maxFileUploadValidator,
  applicationToLoanTermsValue,
  applicationToRateDiscount,
  IdentificationModule,
  applicationToApplicantIdentification,
  applicationToRepaymentType
} from '@portal-workspace/grow-ui-library';
import {
  applicationToAdverseOnFile,
  applicationToApplicantCarLoansAmount,
  applicationToApplicantDob,
  applicationToApplicantEducationAmount,
  applicationToApplicantElectricityAmount,
  applicationToApplicantEmail,
  applicationToApplicantEmployer,
  applicationToApplicantEmploymentStatus,
  applicationToApplicantFirstName,
  applicationToApplicantGender,
  applicationToApplicantGroceriesAmount,
  applicationToApplicantHomeLoansAmount,
  applicationToApplicantInsuranceAmount,
  applicationToApplicantLastName,
  applicationToApplicantMaritalStatus,
  applicationToApplicantMobile,
  applicationToApplicantNumberOfDependeants,
  applicationToApplicantOtherLoansAmount,
  applicationToApplicantOtherUtilitiesAmount,
  applicationToApplicantPersonalLoansAmount,
  applicationToApplicantPrivacyConsent,
  applicationToApplicantPropertyOwnerWithAddress,
  applicationToApplicantRegularIncome,
  applicationToApplicantResidentialAddress,
  applicationToApplicantShareOfMonthlyLivingExpanses,
  applicationToApplicantTimeInAddress,
  applicationToApplicantTitle,
  applicationToApplicantTotalCreditCardLimit,
  applicationToApplicationNotes,
  applicationToAsset,
  applicationToAssetCondition,
  applicationToBalloonPaymentPrecentage,
  applicationToBrokerOriginationFee,
  applicationToBrokerSearchValue,
  applicationToDepositAmount,
  applicationToDocFeeFinanced,
  applicationToEquifaxScoreAboveThreshold,
  applicationToFinanceType,
  applicationToInvoiceAmount,
  applicationToLegalName,
  applicationToLoanAmount,
  applicationToPaymentFrequency,
  applicationToPropertyOwner,
  applicationToReferences,
  applicationToSpouseDob,
  applicationToSpouseEmail,
  applicationToSpouseEmployerContact,
  applicationToSpouseEmployerName,
  applicationToSpouseEmploymentStatus,
  applicationToSpouseFirstName,
  applicationToSpouseGender,
  applicationToSpouseGuarantor,
  applicationToSpouseIncome,
  applicationToSpouseLastName,
  applicationToSpouseMobile,
  applicationToSpousePrivacyConsent,
  applicationToSpouseTitle,
  fromReferenceToInteflowReferences,
  getUser,
  toInteflowGender,
  toInteflowPreviousAddress,
  toInteflowTimeAtAddress,
  toInteflowTimeAtCurrentEmployer,setupUntilDestroy, getAddress2ComponentValueFormControlValueFn,
  applicationToTransactionValue,
  applicationToPreviousLoan,
} from '@portal-workspace/grow-ui-library';
import {
  filesToBase64Files,
  booleanToYesNo,
} from '@portal-workspace/grow-shared-library';
import { AssetSelectionComponentSearchFn, } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponentValue } from '@portal-workspace/grow-shared-library';
import { RedbookSearch } from '@portal-workspace/grow-shared-library';
import {map, tap} from 'rxjs/operators';
import {RedbookService} from '../../service/redbook.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import moment from 'moment';
import {Moment} from 'moment';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {MaritialStatusSelectionValue} from '@portal-workspace/grow-shared-library';
import {ConsumerFinanceSummary} from '@portal-workspace/grow-shared-library';
import {TitleSelectionValue} from '@portal-workspace/grow-shared-library';
import {TimeInAddressValue} from '@portal-workspace/grow-shared-library';
import {PropertyOwnerWithAddressValue} from '@portal-workspace/grow-shared-library';
import {EmploymentStatusSelectionValue} from '@portal-workspace/grow-shared-library';
import {IncomeSelectionValue} from '@portal-workspace/grow-shared-library';
import {EmployerValue} from '@portal-workspace/grow-shared-library';
import {ReferenceValue} from '@portal-workspace/grow-shared-library';
import {
  FinanceTypeValue,
  FinanceTypeValueOptions as FinanceTypeOptions
} from '@portal-workspace/grow-shared-library';
import {
  AssetConditionValue,
  AssetConditionValueOptions as AssetConditionOptions
} from '@portal-workspace/grow-shared-library';
import {BrokerageSelectionValue, BrokerageSelectionValueOptions as BrokerageOptions} from '@portal-workspace/grow-shared-library';
import {
  PaymentFrequencyValueOptions as PaymentFrequencyOptions,
  PaymentFrequencyValue
} from '@portal-workspace/grow-shared-library';
import { ApplicationService } from '../../service/application.service';
import {
  BrokerOfUser,
  GeoLocation,
  RatecardDetails,
  UpdateApplicationData
} from '@portal-workspace/grow-shared-library';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Base64File, User} from '@portal-workspace/grow-shared-library';
import {TotalPaymentBreakupDialogData} from '@portal-workspace/grow-shared-library';
import {BalloonPaymentValue} from '@portal-workspace/grow-shared-library';
import {AppCalculator, TermRate} from '@portal-workspace/grow-shared-library';
import numeral from 'numeral';
import {constants, isAssetNewCars, isAssetVehicles, defaultDocuments} from '@portal-workspace/grow-shared-library';
import _ from 'lodash';
import {UploadAzureFilesValue} from '@portal-workspace/grow-shared-library';
import {Address2ComponentValue} from '@portal-workspace/grow-shared-library';
import {
  navigationUrlForApplications,
  navigationUrlForApplicationsWithQueryParams,
  navigationUrlForNewApplication
} from '../../service/navigation-urls';
import { requiredAllowEmptyValidator } from '@portal-workspace/grow-ui-library';
import {BalloonPaymentComponent} from '@portal-workspace/grow-ui-library'
import {environment} from '../../../environments/environment';
import { AdminService } from '../../service/admin.service';
import { AuthService } from '../../service/auth.service';
import { v4 } from 'uuid';
import { ConsumerFinanceSummaryComponent } from '@portal-workspace/grow-ui-library';
import { UploadFilesMultiTagsComponent } from '@portal-workspace/grow-ui-library';
import { UploadFileComponent } from '@portal-workspace/grow-ui-library';
import { UploadedFilesListComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { ReferenceComponent } from '@portal-workspace/grow-ui-library';
import { ServiceabilityRatioComponent as ServiceabilityRatioComponent_1 } from '@portal-workspace/grow-ui-library';
import { EmployerComponent } from '@portal-workspace/grow-ui-library';
import { IncomeComponent } from '@portal-workspace/grow-ui-library';
import { EmploymentStatusSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaritialStatusSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { EmailComponent } from '@portal-workspace/grow-ui-library';
import { MatDividerModule } from '@angular/material/divider';
import { PropertyOwnerWithAddressComponent } from '@portal-workspace/grow-ui-library';
import { TimeInAddressComponent } from '@portal-workspace/grow-ui-library';
import { CustomAddressComponent } from '@portal-workspace/grow-ui-library';
import { GenderComponent } from '@portal-workspace/grow-ui-library';
import { DatepickerComponent } from '@portal-workspace/grow-ui-library';
import { TitleSelectionComponent } from '@portal-workspace/grow-ui-library';
import { ApplicationHeaderSegmentComponent } from '@portal-workspace/grow-ui-library';
import { BrokerageSelectionComponent } from '@portal-workspace/grow-ui-library';
import { BalloonPaymentComponent as BalloonPaymentComponent_1 } from '@portal-workspace/grow-ui-library';
import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import { PaymentFrequencySelectionComponent } from '@portal-workspace/grow-ui-library';
import { ConsumerLoanTermsSelectionComponent } from '@portal-workspace/grow-ui-library';
import { YesNoComponent } from '@portal-workspace/grow-ui-library';
import { AssetSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CurrencyInputComponent } from '@portal-workspace/grow-ui-library';
import { MarkDirective } from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponent } from '@portal-workspace/grow-ui-library';
import { UserSelectionComponent } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NameComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';
import { ApplicationStepper2Component } from '@portal-workspace/grow-ui-library';
import { TransactionTypeSelectionComponent } from '@portal-workspace/grow-ui-library';
import { CustomerApplicantComponent } from '@portal-workspace/grow-ui-library';
import { SliderComponent, NumberInputComponent } from '@portal-workspace/grow-ui-library';
import { LooseCurrencyPipe } from '@portal-workspace/grow-ui-library';
import { CommissionChartComponent } from '@portal-workspace/grow-ui-library';
import { LoanTermsSelectionWithInputComponent } from '@portal-workspace/grow-ui-library';
import { RepaymentTypeModule } from 'libs/grow-ui-library/src/lib/components/repayment-type-component/repayment-type.module';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
// NOTE: Page to land when you are in DRAFT (new unsubmitted) consumer finance application
@UntilDestroy({arrayName: 'subscription'})
@Component({
    templateUrl: './consumer-asset-finance.page.html',
    styleUrls: ['./consumer-asset-finance.page.scss'],
    standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    ApplicationStepper2Component,
    CdkStepperModule,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    NameComponent,
    MatCheckboxModule,
    MatTooltipModule,
    UserSelectionComponent,
    AggregatorSearchComponent,
    MarkDirective,
    CurrencyInputComponent,
    AssetSelectionComponent,
    YesNoComponent,
    ConsumerLoanTermsSelectionComponent,
    PaymentFrequencySelectionComponent,
    DisableControlDirective,
    BalloonPaymentComponent_1,
    BrokerageSelectionComponent,
    ApplicationHeaderSegmentComponent,
    TitleSelectionComponent,
    DatepickerComponent,
    GenderComponent,
    CustomAddressComponent,
    TimeInAddressComponent,
    PropertyOwnerWithAddressComponent,
    MatDividerModule,
    EmailComponent,
    MobileComponent,
    MaritialStatusSelectionComponent,
    MatFormFieldModule,
    MatInputModule,
    EmploymentStatusSelectionComponent,
    IncomeComponent,
    EmployerComponent,
    ServiceabilityRatioComponent_1,
    ReferenceComponent,
    MessageBoxComponent,
    UploadedFilesListComponent,
    UploadFileComponent,
    UploadFilesMultiTagsComponent,
    ConsumerFinanceSummaryComponent,
    TransactionTypeSelectionComponent,
    UserSameCompanySelectionComponent,
    CustomerApplicantComponent,
    SalesforceContactSameAccountSelectionComponent,
    SliderComponent,
    NumberInputComponent,
    LooseCurrencyPipe,
    CommissionChartComponent,
    LoanTermsSelectionWithInputComponent,
    IdentificationModule,
    RepaymentTypeModule
  ],
})
export class ConsumerAssetFinancePage implements OnInit, AfterViewInit {

  @ViewChild('uploadFilesMultiTagsComponent') uploadFilesMultiTagsComponent!: UploadFilesMultiTagsComponent;

  // CONSUMER_ASSET_FINANCE_OPTIONS_FILTER = CONSUMER_ASSET_FINANCE_OPTIONS_FILTER;

  MIN_LOAN_AMOUNT = 0.01;
  MIN_ORIGINATION_FEE = 0;
  MAX_ORIGINATION_FEE = 1200;
  step2MinLimit = 5000;
  existingApplication: ConsumerFinanceApplication | null = null; // will exists if there is a draft
  rateCard: RatecardDetails | null = null;

  subscriptions: Subscription[] = [];
  AmountFinance! : number
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  // global:
  ip: string = '';
  geoLocation: GeoLocation = {};
  user: User = getUser()!;
  isExternalUser : boolean = isExternalUser(this.user);
  brokerOfUser: BrokerOfUser | null = null;
  acceptedQuotation = false;
  brokerApplicationId: string | null = null;
  applicationId: number | null = null;

  // global: step 3 onwards
  primaryApplicant: string='';      // applicant
  organisationName: string = '';
  terms: TermRateForConsumerFinance | null = null;          // available after chart popups
  calculator: AppCalculator | null = null;   // available after chart popups

  // after popup confirmation
  businessUseOnly = false;
  selfServiceability = false;
  submitThroughNewCreditFlow = false;
  // privacyConfirmation = false;
  // isDisable = true;
  maxRateDiscount = 2;
  originatorBusiness!: OriginatorBusiness;
  rst: CalculateConsumerAssetFinanceEstimationResult | null = null;

  queryParams: Params = {};

  defaultDocuments = defaultDocuments;

  getFormControlValueFn = getAddress2ComponentValueFormControlValueFn;

  @ViewChild('balloonPaymentComponent') balloonPaymentComponent!: BalloonPaymentComponent;

  // STEP 1
  step1GetUserSameCompanyFn!: GetUserSameCompanyFunc;
  step1GetSalesforceContactSameAccountFn!: GetSalesforceContactSameAccountFunc;
  step1BrokerSearchFn!: AggregatorSearchFn;
  step1OrgSalesforceId: string | null = null;
  step1NoOtherUsersInCompany = true;
  formControlStep1FirstName!: FormControl<NameComponentValue>;
  formControlStep1LastName!: FormControl<NameComponentValue>;
  formControlStep1Identification!: FormControl<IdentificationComponentValue>;
  formControlStep1Broker!: FormControl<AggregatorSearchComponentValue>;
  formControlStep1ShowCorrespondent!: FormControl<boolean | null>;
  formControlStep1Correspondent!: FormControl<UserSelectionValue>;
  formControlStep1BrokerContact!: FormControl<SalesforceContactSameAccountSelectionValue>;
  formGroupStep1!: FormGroup<{
    broker: FormControl<AggregatorSearchComponentValue>,
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    identification: FormControl<IdentificationComponentValue>,
    showCorrespondent: FormControl<boolean | null>,
    correspondent: FormControl<UserSelectionValue>,
    brokerContact:FormControl<SalesforceContactSameAccountSelectionValue>
  }>;

  // STEP 1_b:
  formGroupStep1_b!: FormGroup<{
    customerApplicantType: FormControl<CustomerApplicantTypeValue>;
    privacyStatementCheck: FormControl<boolean | null>;
  }>;
  formControlStep1_bCustomerApplicantType!: FormControl<CustomerApplicantTypeValue>;
  formControlStep1_bPrivacyStatementCheck!: FormControl<boolean | null>;

  // STEP 2
  step2Repayment = 0;
  step2EstimatedDrawdownDate: Moment = moment();
  step2AssetCategoryIndexFilter = ['139'];//['137', '106'];
  step2MinOriginationFee = this.MIN_ORIGINATION_FEE;
  step2MaxOriginationFee = this.MAX_ORIGINATION_FEE;
  step2DepositMin: number | null = null;
  step2DepositMax: number | null = null;
  step2BrokerSearchFn!: AggregatorSearchFn;
  step2AssetSearchFn!: AssetSelectionComponentSearchFn;
  step2ShowHybridField = false;
  formControlStep2InvoiceAmount!: FormControl<CurrencyInputValue>;
  formControlStep2DepositAmount!: FormControl<CurrencyInputValue>;
  formControlStep2LoanAmount!: FormControl<CurrencyInputValue>;
  formControlStep2Asset!: FormControl<AssetSelectionComponentValue>;
  formControlStep2LoanTerms!: FormControl<NumberInputValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2DocFeeFinanced!: FormControl<YesNoValue>;
  formControlStep2FinanceType!: FormControl<FinanceTypeValue>;
  formControlStep2BalloonPayment!: FormControl<BalloonPaymentValue>;
  formControlStep2BrokerOriginationFee!: FormControl<CurrencyInputValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formControlStep2AdverseOnFile!: FormControl<YesNoValue>;
  formControlStep2EquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlStep2AssetCondition!: FormControl<AssetConditionValue>;
  formControlStep2TransactionType!: FormControl<TransactionValue>;
  formControlStep2Hybrid!: FormControl<YesNoValue>;
  formControlStep2PreviousLoan!: FormControl<YesNoValue>;
  formControlStep2RateDiscount!: FormControl<SliderComponentValue>;
  formControlStep2PpsrFee!: FormControl<CurrencyInputValue>;
  formControlStep2RepaymentType !: FormControl<RepaymentTypeValue>;
  formGroupStep2!: FormGroup<{
    invoiceAmount: FormControl<CurrencyInputValue>,
    depositAmount: FormControl<CurrencyInputValue>,
    loanAmount: FormControl<CurrencyInputValue>,
    asset: FormControl<AssetSelectionComponentValue>,
    loanTerms: FormControl<NumberInputValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    docFeeFinanced: FormControl<YesNoValue>,
    financeType: FormControl<FinanceTypeValue>,
    balloonPayment: FormControl<BalloonPaymentValue>,
    originationFee: FormControl<CurrencyInputValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    assetCondition: FormControl<AssetConditionValue>,
    equifaxScoreAboveThreshold: FormControl<YesNoValue>,
    hybrid: FormControl<YesNoValue>,
    transactionType: FormControl<TransactionValue>,
    previousLoan: FormControl<YesNoValue>,
    rateDiscount: FormControl<SliderComponentValue>,
    repaymentType: FormControl<RepaymentTypeValue>;
  }>;

  // STEP 3
  formControlStep3Title!: FormControl<TitleSelectionValue>;
  formControlStep3FirstName!: FormControl<NameComponentValue>;
  formControlStep3LastName!: FormControl<NameComponentValue>;
  formControlStep3Dob!: FormControl<DatepickerValue>;
  formControlStep3Gender!: FormControl<GenderValue>;
  formControlStep3ResidentialAddress!: FormControl<Address2ComponentValue>;
  formControlStep3TimeInAddress!: FormControl<TimeInAddressValue>;
  formControlStep3PrivacyConsent!: FormControl<YesNoValue>;
  formControlStep3PropertyOwnerWithAddress!: FormControl<PropertyOwnerWithAddressValue>;
  formControlStep3Email!: FormControl<EmailComponentValue>;
  formControlStep3Mobile!: FormControl<MobileValue>;
  formControlStep3MaritalStatus!: FormControl<MaritialStatusSelectionValue>;
  formControlStep3NumberOfDependants!: FormControl<number | null>;
  formControlStep3EmploymentStatus!: FormControl<EmploymentStatusSelectionValue>;
  formControlStep3RegularIncome!: FormControl<IncomeSelectionValue>;
  formControlStep3Employer!: FormControl<EmployerValue>;
  formGroupStep3!: FormGroup<{
    title: FormControl<TitleSelectionValue>,
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    dob: FormControl<DatepickerValue>,
    gender: FormControl<GenderValue>,
    residentialAddress: FormControl<Address2ComponentValue>,
    timeAtAddress: FormControl<TimeInAddressValue>,
    privacyConsent: FormControl<YesNoValue>,
    propertyOwner: FormControl<PropertyOwnerWithAddressValue>,
    email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    maritalStatus: FormControl<MaritialStatusSelectionValue>,
    numberOfDependants: FormControl<number | null>,
    employmentStatus: FormControl<EmploymentStatusSelectionValue>,
    regularIncome: FormControl<IncomeSelectionValue>,
    employer: FormControl<EmployerValue>,
  }>;

  // STEP 4
  formControlStep4Title!: FormControl<TitleSelectionValue>;
  formControlStep4FirstName!: FormControl<NameComponentValue>;
  formControlStep4LastName!: FormControl<NameComponentValue>;
  formControlStep4Dob!: FormControl<DatepickerValue>;
  formControlStep4Gender!: FormControl<GenderValue>;
  formControlStep4Email!: FormControl<EmailComponentValue>;
  formControlStep4Mobile!: FormControl<MobileValue>;
  formControlStep4EmploymentStatus!: FormControl<EmploymentStatusSelectionValue>;
  formControlStep4RegularIncome!: FormControl<IncomeSelectionValue>;
  formControlStep4EmployerName!: FormControl<string | null>;
  formControlStep4EmployerContact!: FormControl<MobileValue>;
  formControlStep4Guarantor!: FormControl<YesNoValue>;
  formControlStep4PrivacyConsent!: FormControl<YesNoValue>;
  step4ShowPrivacyConsent = false;
  formGroupStep4!: FormGroup<{
    title: FormControl<TitleSelectionValue>,
    firstName: FormControl<NameComponentValue>,
    lastName: FormControl<NameComponentValue>,
    dob: FormControl<DatepickerValue>,
    gender: FormControl<GenderValue>,
    email: FormControl<EmailComponentValue>,
    mobile: FormControl<MobileValue>,
    employmentStatus: FormControl<EmploymentStatusSelectionValue>,
    regularIncome: FormControl<IncomeSelectionValue>,
    employerName: FormControl<string | null>,
    employerContact: FormControl<MobileValue>,
    guarantor: FormControl<YesNoValue>,
    privacyConsent: FormControl<YesNoValue>,
  }>;

  // STEP 5
  step5ServiceabilityCalculationFn!: ServiceabilityCalculationFn;
  step5ServiceabilityCalculationResult: ServiceabilityCalculationResult | null = null;
  @ViewChild('step5ServiceabilityRatioComponent') step5ServiceabilityRatioComponent?: ServiceabilityRatioComponent;
  formControlStep5HomeLoans!: FormControl<CurrencyInputValue>;
  formControlStep5Electricity!: FormControl<CurrencyInputValue>;
  formControlStep5CarLoans!: FormControl<CurrencyInputValue>;
  formControlStep5OtherUtilities!: FormControl<CurrencyInputValue>;
  formControlStep5PersonalLoans!: FormControl<CurrencyInputValue>;
  formControlStep5Education!: FormControl<CurrencyInputValue>;
  formControlStep5TotalCreditCardLimits!: FormControl<CurrencyInputValue>;
  formControlStep5Groceries!: FormControl<CurrencyInputValue>;
  formControlStep5OtherLoans!: FormControl<CurrencyInputValue>;
  formControlStep5Insurance!: FormControl<CurrencyInputValue>;
  formControlStep5ShareOfMonthlyLivingExpanses!: FormControl<number | null>;
  formGroupStep5!: FormGroup<{
    homeLoans: FormControl<CurrencyInputValue>,
    electricity: FormControl<CurrencyInputValue>,
    carLoans: FormControl<CurrencyInputValue>,
    otherUtilities: FormControl<CurrencyInputValue>,
    personalLoans: FormControl<CurrencyInputValue>,
    education: FormControl<CurrencyInputValue>,
    totalCreditCardLimits: FormControl<CurrencyInputValue>,
    groceries: FormControl<CurrencyInputValue>,
    otherLoans: FormControl<CurrencyInputValue>,
    insurance: FormControl<CurrencyInputValue>,
    shareOfMonthlyLivingExpanses: FormControl<number | null>,
  }>;

  // STEP 6
  formControlStep6References!: FormControl<ReferenceValue>;
  formGroupStep6!: FormGroup<{
    references: FormControl<ReferenceValue>,
  }>;

  // STEP 7
  formControlStep7ApplicantDriverLicenceFront!: FormControl<UploadAzureFilesValue>;
  formControlStep7ApplicantSpouseDriverLicenceFront!: FormControl<UploadAzureFilesValue>;
  formControlStep7OtherSupportingDocuments!: FormControl<UploadAzureFilesValue>;
  step7UploadedDocs: AzureStorageDocument[] = [];
  step7UploadedDrDocs: AzureStorageDocument[] = [];
  step7DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];

  step8UploadedDocs: AzureStorageDocument[] = [];
  step8DeletedUploadedDocs: Exclude<AzureStorageDocument[], null> = [];

  formGroupStep7!: FormGroup<{
    'Applicant Driver Licence': FormControl<UploadAzureFilesValue>,
    'Applicant Partner Driver Licence': FormControl<UploadAzureFilesValue>,
    otherSupportingDocuments: FormControl<UploadAzureFilesValue>,
  }>;
  step7And8FilesUploadedToAzure: Base64File[] = [];

  // STEP 8
  formControlStep8OtherSupportingDocuments!: FormControl<UploadAzureFilesValue>;
  formGroupStep8!: FormGroup<{
    otherSupportingDocuments: FormControl<UploadAzureFilesValue>,
  }>;

  // STEP 9
  formControlStep9ApplicationNotes!: FormControl<string | null>;
  formGroupStep9!: FormGroup<{
    applicationNotes: FormControl<string | null>,
  }>;

  // STEP 10
  step10Summary!: ConsumerFinanceSummary;
  formGroupStep10!: FormGroup<{}>;

  documentTypes: DocumentTag[] = [];

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private redbookService: RedbookService,
              private toastService: PortalHotToastService,
              private applicationService: ApplicationService,
              private router: Router,
              private adminService: AdminService,
              private applicationDialogService: ApplicationDialogService,
              private authService: AuthService) {
  }

  async ngOnInit() {
    setupUntilDestroy(this);
    this.queryParams = this.route.snapshot.queryParams ?? {};
    this.existingApplication = (this.route.snapshot.data as any).application;
    this.rateCard = (this.route.snapshot.data as any).ratecard;
    this.maxRateDiscount = this.rateCard?.MaxBrokerRateDiscount ?? 2;
    console.log('Resolved [ConsumerAssetFinance] application', this.existingApplication);
    if (this.existingApplication) {
      if (this.existingApplication.ApplicationType != 'Consumer') {
        this.toastService.error(`This application ${this.existingApplication.ApplicationId} is not an Consumer Asset Finance application`, `Invalid application type`);
      }
    }

    this.step5ServiceabilityCalculationFn = () => {
      const paymentFrequencyType: PaymentFrequencyType = this.formControlStep2PaymentFrequency.value?.type ?? 'Monthly';
      const repayment: number = this.step2Repayment ?? 0;
      const maritalStatus: MaritialStatusSelectionValue = this.formControlStep3MaritalStatus.value;
      const principalRegularIncome: IncomeSelectionValue = this.formControlStep3RegularIncome.value;
      const spouseRegularIncome: IncomeSelectionValue = this.formControlStep4RegularIncome.value;
      const address: Address2ComponentValue = this.formControlStep3ResidentialAddress.value;
      const postcode = address?.Postcode ?? '';
      const homeLoanExpenses: CurrencyInputValue = this.formControlStep5HomeLoans.value;
      const electricityExpenses: CurrencyInputValue = this.formControlStep5Electricity.value;
      const carLoanExpenses: CurrencyInputValue = this.formControlStep5CarLoans.value;
      const otherUtilitiesExpenses: CurrencyInputValue = this.formControlStep5OtherUtilities.value;
      const personalLoansExpenses: CurrencyInputValue = this.formControlStep5PersonalLoans.value;
      const educationExpenses: CurrencyInputValue = this.formControlStep5Education.value;
      const totalCreditCardLimitsExpenses: CurrencyInputValue = this.formControlStep5TotalCreditCardLimits.value;
      const groceriesExpenses: CurrencyInputValue = this.formControlStep5Groceries.value;
      const otherLoansExpenses: CurrencyInputValue = this.formControlStep5OtherLoans.value;
      const insuranceExpenses: CurrencyInputValue = this.formControlStep5Insurance.value;
      const shareOfMonthlyLivingExpenses: number = this.formControlStep5ShareOfMonthlyLivingExpanses.value ?? 0;
      const numberOfDependents: number = this.formControlStep3NumberOfDependants.value ?? 0;

      const effectiveRepayment = normaliseToMonthlyAmount(repayment, paymentFrequencyType);

      return this.applicationService.serviceabilityCalculationFn({
        repayment: effectiveRepayment,
        marital: maritalStatus?.name ?? 'Single',
        numberOfDependents,
        principalRegularIncome: fromIncomeSelectionValueMonthlyIncome(principalRegularIncome),
        spouseRegularIncome: fromIncomeSelectionValueMonthlyIncome(spouseRegularIncome),
        postcode,
        homeLoanExpenses: homeLoanExpenses ?? 0,
        electricityExpenses: electricityExpenses ?? 0,
        carLoanExpenses: carLoanExpenses ?? 0,
        otherUtilitiesExpenses: otherUtilitiesExpenses ?? 0,
        personalLoansExpenses: personalLoansExpenses ?? 0,
        educationExpenses: educationExpenses ?? 0,
        totalCreditCardLimitsExpenses: totalCreditCardLimitsExpenses ?? 0,
        groceriesExpenses: groceriesExpenses ?? 0,
        otherLoansExpenses: otherLoansExpenses ?? 0,
        insuranceExpenses: insuranceExpenses ?? 0,
        shareOfMonthlyLivingExpenses: shareOfMonthlyLivingExpenses ?? 0,
      }).pipe(
        tap(r => {
          this.step5ServiceabilityCalculationResult = r;
        })
      );
    }

    this.initStep1();
    this.initStep1_b();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.initStep5();
    this.initStep6();
    this.initStep7();
    this.initStep8();
    this.initStep9();
    this.initStep10();

    this.populate();

    // get ip
    const sub1 = this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub1);

    // get geolocation
    try {
      const geoLocation = await this.applicationService.getGeoLocation();
      if (geoLocation) {
        this.geoLocation = geoLocation;
      }
    } catch(err) {
      console.log(err);
    }

    // get broker of user
    const sub2 = this.applicationService.getBrokerOfUser(this.user.UserId).pipe(
      tap(r => {
        if (r.status) {
          this.brokerOfUser = r.payload;
        }
      })
    ).subscribe();
    this.subscriptions.push(sub2);

  }

  ngAfterViewInit(): void {
    const sub2= combineLatest([
      this.formControlStep2Asset.valueChanges,
      this.formControlStep2LoanTerms.valueChanges
    ]).pipe(
      tap((r: [AssetSelectionComponentValue, NumberInputValue]) => {
        const assetSelectionValue = r[0];
        const loanTermValue = r[1];
        this.step2UpdateBalloonPaymentValidation(assetSelectionValue, loanTermValue);
      })
    ).subscribe();
    this.subscriptions.push(sub2);
    if (this.existingApplication) {
      setTimeout(()=> {
        this.step2UpdateBalloonPaymentValidation(this.formControlStep2Asset.value, this.formControlStep2LoanTerms.value);
      }, 2000);
    }

    this.subscriptions.push(
      this.formGroupStep2.valueChanges.pipe(
        tap(r => {
          if (this.formGroupStep2.valid) {
            this.calculateEstimation(moment());
          }
        })
      ).subscribe()
    )
  }

  // comment for development
  step2UpdateBalloonPaymentValidation(assetSelectionValue: AssetSelectionComponentValue, loanTermValue: NumberInputValue) {
    if (assetSelectionValue && loanTermValue) {
      const assetCatgory = assetSelectionValue.category.index;
      const assetType = assetSelectionValue.type.index;
      const loanTerm = loanTermValue;
      const assetYear = parseInt(assetSelectionValue.year);
      const maxRv = new AppCalculator().getMaxRV(assetCatgory, loanTerm, assetYear, assetType);
      this.balloonPaymentComponent.setMaxRv(maxRv);
    }
  }

  step2UpdateDepositValidation() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    if (asset) {
      const invoiceAmount = this.formControlStep2InvoiceAmount.value ?? 0;
      const assetTypeIndex = asset.type.index;
      const isPropetyOwner = this.formControlStep2PropertyOwner.value ?? false;

      const minDeposit = minDepositForConsumerAssetFinance(invoiceAmount, assetTypeIndex, isPropetyOwner);
      this.step2DepositMin = minDeposit;
      this.step2DepositMax = _.round(invoiceAmount * 0.8, 2); // 80% of invoiceAmount
      // note: as long as it is within min & max range allows it, this way we will still keep the saved value from populate()
      const existingDeposit = this.formControlStep2DepositAmount.value ?? 0;
      if (this.step2DepositMax && existingDeposit > this.step2DepositMax) {
        this.formControlStep2DepositAmount.setValue(this.step2DepositMax);
      } else if (this.step2DepositMin && existingDeposit < this.step2DepositMin) {
        this.formControlStep2DepositAmount.setValue(this.step2DepositMin);
      }
    }
  }

  step2UpdateHybridField() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    if (asset && asset?.type?.index === '1') {
      this.step2ShowHybridField = true;
    } else {
      this.step2ShowHybridField = false;
    }
  }

  step2UpdateAssetCondition() {
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const assetYear = parseInt(asset?.year ? asset.year : '0');
    const difference = moment().year() - assetYear - 1;
    if (difference > 0) {
      this.formControlStep2AssetCondition.setValue({type: 'Used', name: 'Used'});
    } else {
      this.formControlStep2AssetCondition.setValue({type: 'New', name: 'New / Demo' });
    }
  }

  step2UpdatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlStep2PropertyOwner.value;
    if (propertyOwner) {
      this.formControlStep2PreviousLoan.clearValidators();
    } else {
      this.formControlStep2PreviousLoan.setValidators([Validators.required]);
    }
    this.formControlStep2PreviousLoan.updateValueAndValidity();
  }

  populate() {
    if (this.existingApplication) {
      // 1
      this.formControlStep1FirstName.setValue(applicationToApplicantFirstName(this.existingApplication));
      this.formControlStep1LastName.setValue(applicationToApplicantLastName(this.existingApplication));
      this.formControlStep1Identification.setValue(applicationToApplicantIdentification(this.existingApplication));
      this.formControlStep1ShowCorrespondent.setValue(applicationToHasAdditionalBrokerCorrespondent(this.existingApplication));
      this.formControlStep1Correspondent.setValue(applicationToAdditionalBrokerCorrespondent(this.existingApplication));
      this.brokerApplicationId = this.existingApplication.BrokerAppId;
      this.applicationId = this.existingApplication.ApplicationId;
      this.acceptedQuotation = this.existingApplication.AcceptQuotation;
      this.organisationName = applicationToLegalName(this.existingApplication) ?? '';
      this.primaryApplicant = this.formControlStep1FirstName.value + ' ' + this.formControlStep1LastName.value
      const brokerSearchValue = applicationToBrokerSearchValue(this.existingApplication);
      this.formControlStep1Broker.setValue(brokerSearchValue);
      this.step1OrgSalesforceId = brokerSearchValue?.salesforceId ?? null;
      this.formControlStep1BrokerContact.setValue(applicationToBrokerContact(this.existingApplication))

      // 2
      this.formControlStep2InvoiceAmount.setValue(applicationToInvoiceAmount(this.existingApplication));
      this.formControlStep2DepositAmount.setValue(applicationToDepositAmount(this.existingApplication));
      this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.existingApplication));
      this.formControlStep2Asset.setValue(applicationToAsset(this.existingApplication));
      this.formControlStep2LoanTerms.setValue(applicationToLoanTermsValue(this.existingApplication));
      this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.existingApplication) ??
      PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
      this.formControlStep2DocFeeFinanced.setValue(applicationToDocFeeFinanced(this.existingApplication));
      this.formControlStep2FinanceType.setValue(applicationToFinanceType(this.existingApplication));
      this.formControlStep2BalloonPayment.setValue(applicationToBalloonPaymentPrecentage(this.existingApplication));
      this.formControlStep2BrokerOriginationFee.setValue(applicationToBrokerOriginationFee(this.existingApplication));
      this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.existingApplication));
      this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.existingApplication));
      this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.existingApplication));
      this.formControlStep2TransactionType.setValue(applicationToTransactionValue(this.existingApplication));
      this.formControlStep2AssetCondition.setValue(applicationToAssetCondition(this.existingApplication));
      this.formControlStep2PreviousLoan.setValue(applicationToPreviousLoan(this.existingApplication));
      this.formControlStep2Hybrid.setValue(applicationToHybrid(this.existingApplication));
      this.formControlStep2RateDiscount.setValue(applicationToRateDiscount(this.existingApplication));
      this.formControlStep2RepaymentType.setValue(applicationToRepaymentType(this.existingApplication));

      // 3
      this.formControlStep3Title.setValue(applicationToApplicantTitle(this.existingApplication));
      this.formControlStep3FirstName.setValue(applicationToApplicantFirstName(this.existingApplication));
      this.formControlStep3LastName.setValue(applicationToApplicantLastName(this.existingApplication));
      this.formControlStep3Dob.setValue(applicationToApplicantDob(this.existingApplication));
      this.formControlStep3Gender.setValue(applicationToApplicantGender(this.existingApplication));
      this.formControlStep3ResidentialAddress.setValue(applicationToApplicantResidentialAddress(this.existingApplication));
      this.formControlStep3TimeInAddress.setValue(applicationToApplicantTimeInAddress(this.existingApplication));
      this.formControlStep3PrivacyConsent.setValue(applicationToApplicantPrivacyConsent(this.existingApplication));
      this.formControlStep3PropertyOwnerWithAddress.setValue(applicationToApplicantPropertyOwnerWithAddress(this.existingApplication));
      this.formControlStep3Email.setValue(applicationToApplicantEmail(this.existingApplication));
      this.formControlStep3Mobile.setValue(applicationToApplicantMobile(this.existingApplication));
      this.formControlStep3MaritalStatus.setValue(applicationToApplicantMaritalStatus(this.existingApplication));
      this.formControlStep3NumberOfDependants.setValue(applicationToApplicantNumberOfDependeants(this.existingApplication));
      this.formControlStep3EmploymentStatus.setValue(applicationToApplicantEmploymentStatus(this.existingApplication));
      this.formControlStep3RegularIncome.setValue(applicationToApplicantRegularIncome(this.existingApplication));
      this.formControlStep3Employer.setValue(applicationToApplicantEmployer(this.existingApplication));

      // 4
      this.formControlStep4Title.setValue(applicationToSpouseTitle(this.existingApplication));
      this.formControlStep4FirstName.setValue(applicationToSpouseFirstName(this.existingApplication));
      this.formControlStep4LastName.setValue(applicationToSpouseLastName(this.existingApplication));
      this.formControlStep4Dob.setValue(applicationToSpouseDob(this.existingApplication));
      this.formControlStep4Gender.setValue(applicationToSpouseGender(this.existingApplication));
      this.formControlStep4Email.setValue(applicationToSpouseEmail(this.existingApplication));
      this.formControlStep4Mobile.setValue(applicationToSpouseMobile(this.existingApplication));
      this.formControlStep4EmploymentStatus.setValue(applicationToSpouseEmploymentStatus(this.existingApplication));
      this.formControlStep4RegularIncome.setValue(applicationToSpouseIncome(this.existingApplication));
      this.formControlStep4EmployerName.setValue(applicationToSpouseEmployerName(this.existingApplication));
      this.formControlStep4EmployerContact.setValue(applicationToSpouseEmployerContact(this.existingApplication));
      this.formControlStep4Guarantor.setValue(applicationToSpouseGuarantor(this.existingApplication));
      this.formControlStep4PrivacyConsent.setValue(applicationToSpousePrivacyConsent(this.existingApplication));
      if(!this.formControlStep4Guarantor.value){
        this.formControlStep4PrivacyConsent.setValue(null)
      }
      // 5
      this.formControlStep5HomeLoans.setValue(applicationToApplicantHomeLoansAmount(this.existingApplication));
      this.formControlStep5Electricity.setValue(applicationToApplicantElectricityAmount(this.existingApplication));
      this.formControlStep5CarLoans.setValue(applicationToApplicantCarLoansAmount(this.existingApplication));
      this.formControlStep5OtherUtilities.setValue(applicationToApplicantOtherUtilitiesAmount(this.existingApplication));
      this.formControlStep5PersonalLoans.setValue(applicationToApplicantPersonalLoansAmount(this.existingApplication));
      this.formControlStep5Education.setValue(applicationToApplicantEducationAmount(this.existingApplication));
      this.formControlStep5TotalCreditCardLimits.setValue(applicationToApplicantTotalCreditCardLimit(this.existingApplication));
      this.formControlStep5Groceries.setValue(applicationToApplicantGroceriesAmount(this.existingApplication));
      this.formControlStep5OtherLoans.setValue(applicationToApplicantOtherLoansAmount(this.existingApplication));
      this.formControlStep5Insurance.setValue(applicationToApplicantInsuranceAmount(this.existingApplication));
      this.formControlStep5ShareOfMonthlyLivingExpanses.setValue(applicationToApplicantShareOfMonthlyLivingExpanses(this.existingApplication));

      // 6
      this.formControlStep6References.setValue(applicationToReferences(this.existingApplication));

      // 7-9
      this.formControlStep9ApplicationNotes.setValue(applicationToApplicationNotes(this.existingApplication));
      this.listUploadedDocuments();
      this.documentTypes = this.existingApplication.DocumentTypes ?? [];

    } else { // not prepopulating ie. creating new application
      this.documentTypes = this.applicationDefaultDocuments(false);
    }

    // auto populate originator business if we have one
    const orignatorBusinessId = this.user?.OriginatorBusinessId;
    if (orignatorBusinessId) {
      this.subscriptions.push(this.applicationService.getOriginatorBusinessById(orignatorBusinessId).pipe(
        tap(r => {
          if (r.payload) {
            console.log('===originator business: ', r)
            this.originatorBusiness = r.payload;
            if (!this.existingApplication) {
              const v: AggregatorSearchComponentValue = {
                abn: this.originatorBusiness.ABN,
                entityName: this.originatorBusiness.EntityName,
                salesforceId: this.originatorBusiness.SalesforceId,
              }
              this.formControlStep1Broker.setValue(v);
            }
          }
        })
      ).subscribe());
    }
  }


  initStep1() {
    this.formControlStep1FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Broker = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1LastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Identification = this.formBuilder.control(null, [Validators.required])
    this.formControlStep1ShowCorrespondent = this.formBuilder.control(false)
    this.formControlStep1Correspondent = this.formBuilder.control(null);
    this.formControlStep1BrokerContact = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      broker: this.formControlStep1Broker,
      firstName: this.formControlStep1FirstName,
      lastName: this.formControlStep1LastName,
      identification: this.formControlStep1Identification,
      showCorrespondent: this.formControlStep1ShowCorrespondent,
      brokerContact:this.formControlStep1BrokerContact,
      correspondent: this.formControlStep1Correspondent,
    });
    this.step1BrokerSearchFn = (term)=> {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    this.formControlStep1FirstName.valueChanges.pipe(tap(r => {
      const givenName= this.existingApplication?.Individuals.find(i => i.GivenName)?.GivenName
      if (r && this.formControlStep3FirstName) {
        this.formControlStep3FirstName.setValue(r);
        this.primaryApplicant = this.formControlStep1FirstName.value + ' ' + this.formControlStep1LastName.value
      }
      if(r && this.existingApplication){
        if(r !== givenName){
          this.formControlStep1_bCustomerApplicantType.setValue(null)
          this.formControlStep1_bPrivacyStatementCheck.setValue(null)
        }
        else{
          const userApplicantDetails = this.existingApplication.AppInfo;
          if (userApplicantDetails) {
            if (userApplicantDetails.MetApplicant !== undefined) {
              this.formControlStep1_bCustomerApplicantType.setValue(applicationToApplicantType(this.existingApplication));
            }
            if (userApplicantDetails.PrivacyConfirmation !== undefined) {
              this.formControlStep1_bPrivacyStatementCheck.setValue(yesNoToBoolean(userApplicantDetails.PrivacyConfirmation));
            }
          }
        }
      }
    })).subscribe();
    this.formControlStep1LastName.valueChanges.pipe(tap(r => {
      const surName= this.existingApplication?.Individuals.find(i => i.SurName)?.SurName
      if (r && this.formControlStep3LastName) {
        this.formControlStep3LastName.setValue(r);
        this.primaryApplicant = this.formControlStep1FirstName.value + ' ' + this.formControlStep1LastName.value
      }
      if(r && this.existingApplication){
        if(r !== surName){
          this.formControlStep1_bCustomerApplicantType.setValue(null)
          this.formControlStep1_bPrivacyStatementCheck.setValue(null)
        }
        else{
          const userApplicantDetails = this.existingApplication.AppInfo;
          if (userApplicantDetails) {
            if (userApplicantDetails.MetApplicant !== undefined) {
              this.formControlStep1_bCustomerApplicantType.setValue(applicationToApplicantType(this.existingApplication));
            }
            if (userApplicantDetails.PrivacyConfirmation !== undefined) {
              this.formControlStep1_bPrivacyStatementCheck.setValue(yesNoToBoolean(userApplicantDetails.PrivacyConfirmation));
            }
          }
        }
      }
    })).subscribe();
    this.step1GetUserSameCompanyFn = (orgSalesforceId: string) => {
      return this.authService.getOtherUsersInCompanyBySalesforceIdFn(orgSalesforceId).pipe(
        map(r => {
          // exclude this user
          const filteredCorrespndentUserList =  r.filter(u => u.UserId != this.user.UserId);
          this.step1NoOtherUsersInCompany = filteredCorrespndentUserList.length == 0;
          return filteredCorrespndentUserList;
        })
      );
    }
    this.step1GetSalesforceContactSameAccountFn = this.authService.getSalesforceContactSameAccountFn;
    this.subscriptions.push(this.formControlStep1Broker.valueChanges.pipe(
      tap(broker => {
        if (broker) {
          this.step1OrgSalesforceId = broker.salesforceId;
          this.step1GetUserSameCompanyFn(this.step1OrgSalesforceId).pipe().subscribe();
        }
      })
    ).subscribe());
    this.subscriptions.push(this.formControlStep1ShowCorrespondent.valueChanges.pipe(
      tap((r) => {
        if (!r) {
          this.formControlStep1Correspondent.setValue(null)
        }
      })
    ).subscribe())
    this.primaryApplicant = this.formControlStep1FirstName.value + ' ' + this.formControlStep1LastName.value

    setStepper2StepConfig(this.formGroupStep1, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepClickedFn: (stepper) => {
        stepper.next();
      },
      previousStepClickedFn: async (stepper) => {
        if(this.applicationId){
          await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, this.queryParams));
        }else {
          await this.router.navigate(navigationUrlForNewApplication());
        }
      }
    });
  }

  initStep1_b(){
    this.formControlStep1_bCustomerApplicantType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1_bPrivacyStatementCheck = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1_b = this.formBuilder.group({
      customerApplicantType: this.formControlStep1_bCustomerApplicantType,
      privacyStatementCheck: this.formControlStep1_bPrivacyStatementCheck,
    })

    const sub = this.formControlStep1_bPrivacyStatementCheck.valueChanges.pipe(
      tap((r) =>{
          if (r === false) {
            this.formControlStep1_bPrivacyStatementCheck.setValue(null);
          }
      })
    ).subscribe()
    this.subscriptions.push(sub);
    setStepper2StepConfig(this.formGroupStep1_b, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepButtonText:'Next',
      nextStepClickedFn: async stepper => {
        if (this.formGroupStep2.valid) {
          this.calculateEstimation(moment());
        }
        stepper.next();
      }
    });
  }

  initStep2() {
    this.step2BrokerSearchFn = (term)=> {
      return this.applicationService.searchBroker2(term).pipe(map(r => r.payload));
    }
    this.step2AssetSearchFn = <T extends RedbookSearch>(s: T)=> {
      return this.redbookService.search(s).pipe(
        // this.toastService.toastObserver(),
        map( r => {
          return r.payload;
        })
      );
    }
    this.formControlStep2InvoiceAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DepositAmount = this.formBuilder.control(0, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Asset = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control( { type: 'Monthly', name: 'Monthly'}, [Validators.required]);
    this.formControlStep2DocFeeFinanced = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2FinanceType = this.formBuilder.control(FinanceTypeValueOptions[0]);
    this.formControlStep2BalloonPayment = this.formBuilder.control( BalloonPaymentValueOptions[0], [Validators.required]);
    this.formControlStep2BrokerOriginationFee = this.formBuilder.control(this.rateCard?.CreditAssitanceFee ?? 0, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2EquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AssetCondition = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2TransactionType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Hybrid = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2PreviousLoan = this.formBuilder.control(null);
    this.formControlStep2RateDiscount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PpsrFee = this.formBuilder.control(this.rateCard?.PpsrFee ?? 0);
    this.formControlStep2RepaymentType =  this.formBuilder.control(RepaymentTypeOptions[1], [Validators.required]);
    this.formGroupStep2 = this.formBuilder.group({
      invoiceAmount: this.formControlStep2InvoiceAmount,
      depositAmount: this.formControlStep2DepositAmount,
      loanAmount: this.formControlStep2LoanAmount,
      asset: this.formControlStep2Asset,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      docFeeFinanced: this.formControlStep2DocFeeFinanced,
      financeType: this.formControlStep2FinanceType,
      balloonPayment: this.formControlStep2BalloonPayment,
      originationFee: this.formControlStep2BrokerOriginationFee,
      propertyOwner: this.formControlStep2PropertyOwner,
      adverseOnFile: this.formControlStep2AdverseOnFile,
      transactionType: this.formControlStep2TransactionType,
      assetCondition: this.formControlStep2AssetCondition,
      equifaxScoreAboveThreshold: this.formControlStep2EquifaxScoreAboveThreshold,
      hybrid: this.formControlStep2Hybrid,
      previousLoan: this.formControlStep2PreviousLoan,
      rateDiscount: this.formControlStep2RateDiscount,
      repaymentType : this.formControlStep2RepaymentType
    });

    setStepper2StepConfig(this.formGroupStep2, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepButtonText:'Accept Quotation',
      nextStepClickedFn: async stepper => {
          await this.step2ShowQuotation(stepper);
      }
    });

    const sub = combineLatest([
      this.formControlStep2InvoiceAmount.valueChanges,
      this.formControlStep2DepositAmount.valueChanges,
    ]).pipe(
      tap((r) => {
        const invoiceAmount = r[0];
        const depositAmount = r[1];
        const loanAmount = (invoiceAmount ?? 0) - (depositAmount ?? 0);
        this.formControlStep2LoanAmount.setValue(loanAmount);
      })
    ).subscribe();
    this.subscriptions.push(sub);

       // deposit calculation trigger
       this.subscriptions.push(this.formControlStep2InvoiceAmount.valueChanges.pipe(
        tap(r => {
          this.step2UpdateDepositValidation();
        })
      ).subscribe());
      this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
        tap(r => {
          this.step2UpdateDepositValidation();
        })
      ).subscribe());
      this.subscriptions.push(this.formControlStep2Asset.valueChanges.pipe(
        tap(r => {
          this.step2UpdateDepositValidation();
          this.step2UpdateHybridField();
          this.step2UpdateAssetCondition();
        })
      ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdatePreviousLoanValidation();
      })
    ).subscribe())
  }

  initStep3() {
    this.formControlStep3Title = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3LastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Dob = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Gender = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3ResidentialAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3TimeInAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PrivacyConsent = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3PropertyOwnerWithAddress = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Email  =this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Mobile = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3MaritalStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3NumberOfDependants = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3EmploymentStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3RegularIncome = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3Employer = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep3 = this.formBuilder.group({
      title: this.formControlStep3Title,
      firstName: this.formControlStep3FirstName,
      lastName: this.formControlStep3LastName,
      dob: this.formControlStep3Dob,
      gender: this.formControlStep3Gender,
      residentialAddress: this.formControlStep3ResidentialAddress,
      timeAtAddress: this.formControlStep3TimeInAddress,
      privacyConsent: this.formControlStep3PrivacyConsent,
      propertyOwner: this.formControlStep3PropertyOwnerWithAddress,
      email: this.formControlStep3Email,
      mobile: this.formControlStep3Mobile,
      maritalStatus: this.formControlStep3MaritalStatus,
      numberOfDependants: this.formControlStep3NumberOfDependants,
      employmentStatus: this.formControlStep3EmploymentStatus,
      regularIncome: this.formControlStep3RegularIncome,
      employer: this.formControlStep3Employer,
    });

    setStepper2StepConfig(this.formGroupStep3, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepClickedFn: async stepper => {
          await this.saveApplication(stepper, 3);
      }
    });
  }

  initStep4() {
    this.formControlStep4Title = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4FirstName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4LastName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Dob = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Gender = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Email = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Mobile = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4EmploymentStatus = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4RegularIncome = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4EmployerName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4EmployerContact = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4Guarantor = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4PrivacyConsent = this.formBuilder.control(null);


    this.subscriptions.push(this.formControlStep4Guarantor.valueChanges.pipe(
      // delay(0),
      tap(r => {
        if (r === true) {
          this.formControlStep4PrivacyConsent.setValidators([Validators.required, requiredToBeTrueValidator()]);
          this.formControlStep4PrivacyConsent.updateValueAndValidity();
          this.step4ShowPrivacyConsent = true;
        } else {
          this.formControlStep4PrivacyConsent.setValue(null);
          this.formControlStep4PrivacyConsent.clearValidators();
          this.formControlStep4PrivacyConsent.updateValueAndValidity();
          this.step4ShowPrivacyConsent = false;
        }
      })
    ).subscribe());

    this.formGroupStep4 = this.formBuilder.group({
      title: this.formControlStep4Title,
      firstName: this.formControlStep4FirstName,
      lastName: this.formControlStep4LastName,
      dob: this.formControlStep4Dob,
      gender: this.formControlStep4Gender,
      email: this.formControlStep4Email,
      mobile: this.formControlStep4Mobile,
      employmentStatus: this.formControlStep4EmploymentStatus,
      regularIncome: this.formControlStep4RegularIncome,
      employerName: this.formControlStep4EmployerName,
      employerContact: this.formControlStep4EmployerContact,
      guarantor: this.formControlStep4Guarantor,
      privacyConsent: this.formControlStep4PrivacyConsent,
    });

    this.subscriptions.push(this.formControlStep4EmploymentStatus.valueChanges.pipe(
      tap((v) => {
        if (v && v.type === 'unemployed') {
          this.formControlStep4RegularIncome.setValue({income: 0, period: IncomePeriodOptions[0]});
          this.formControlStep4EmployerName.setValue(null);
          this.formControlStep4EmployerName.clearValidators();
          this.formControlStep4EmployerContact.setValue(null);
          this.formControlStep4EmployerContact.clearValidators();
          this.formControlStep4RegularIncome.disable();
          this.formControlStep4EmployerName.disable();
          this.formControlStep4EmployerContact.disable();
        } else {
          this.formControlStep4EmployerName.addValidators([Validators.required]);
          this.formControlStep4EmployerContact.addValidators([Validators.required]);
          this.formControlStep4RegularIncome.enable();
          this.formControlStep4EmployerName.enable();
          this.formControlStep4EmployerContact.enable();
        }
      })
    ).subscribe());



    setStepper2StepConfig(this.formGroupStep4, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepClickedFn: async stepper => {

          if (this.formControlStep4PrivacyConsent.value === false) {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: 'If the partner is a guarantor then the privacy consent must be obtained'
            })
          }
          else {
            const sub = this.applicationDialogService.openCreditInfoExchangeAlertDialog().afterClosed().pipe(
              tap(async r => {
                if (r && r.proceed) {
                  await this.saveApplication(stepper, 4);
                }
              })
            ).subscribe();
            this.subscriptions.push(sub);
          }
      }
    });
  }

  initStep5() {
    this.formControlStep5HomeLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Electricity = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5CarLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5OtherUtilities = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5PersonalLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Education = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5TotalCreditCardLimits = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Groceries = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5OtherLoans = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5Insurance = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep5ShareOfMonthlyLivingExpanses = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep5 = this.formBuilder.group({
      homeLoans: this.formControlStep5HomeLoans,
      electricity: this.formControlStep5Electricity,
      carLoans: this.formControlStep5CarLoans,
      otherUtilities: this.formControlStep5OtherUtilities,
      personalLoans: this.formControlStep5PersonalLoans,
      education: this.formControlStep5Education,
      totalCreditCardLimits: this.formControlStep5TotalCreditCardLimits,
      groceries: this.formControlStep5Groceries,
      otherLoans: this.formControlStep5OtherLoans,
      insurance: this.formControlStep5Insurance,
      shareOfMonthlyLivingExpanses: this.formControlStep5ShareOfMonthlyLivingExpanses,
    });

    setStepper2StepConfig(this.formGroupStep5, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      stepSelectionEventFn: e => {
        if (!this.step3OnWardsHasSpouse()) {
          this.formControlStep5ShareOfMonthlyLivingExpanses.setValue(100);
          this.formControlStep5ShareOfMonthlyLivingExpanses.updateValueAndValidity();
        }
        if (this.step5ServiceabilityRatioComponent) {
          this.step5ServiceabilityRatioComponent.reloadIfLoadedBefore();
        }
      },
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 5);
      }
    });
  }

  initStep6() {
    this.formControlStep6References = this.formBuilder.control(null, [requiredAllowEmptyValidator]);
    this.formGroupStep6 = this.formBuilder.group({
      references: this.formControlStep6References,
    });

    setStepper2StepConfig(this.formGroupStep6, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepClickedFn: async stepper => {
        await this.saveApplication(stepper, 6);
      }
    });
  }

  initStep7() {
    this.formControlStep7ApplicantDriverLicenceFront = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep7ApplicantSpouseDriverLicenceFront = this.formBuilder.control(null);
    this.formGroupStep7 = this.formBuilder.group({
      'Applicant Driver Licence': this.formControlStep7ApplicantDriverLicenceFront,
      'Applicant Partner Driver Licence': this.formControlStep7ApplicantSpouseDriverLicenceFront,
      otherSupportingDocuments: this.formControlStep7OtherSupportingDocuments,
    });

    setStepper2StepConfig(this.formGroupStep7, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      stepSelectionEventFn: e => {
        const skipValidation = (this.step7UploadedDocs && this.step7UploadedDocs.length);
        if (this.step3OnWardsHasSpouse()) {
          this.formControlStep7ApplicantSpouseDriverLicenceFront.setValidators(skipValidation ? [] : [Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
          this.formControlStep7ApplicantSpouseDriverLicenceFront.updateValueAndValidity();
        }
        this.formControlStep7ApplicantDriverLicenceFront.setValidators(skipValidation ? [] : [Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
        this.formControlStep7ApplicantDriverLicenceFront.updateValueAndValidity();
      },
      nextStepClickedFn: async stepper => {
        this.documentTypes = this.applicationDefaultDocuments(true);
        let hasError = true
        if(this.formControlStep7ApplicantDriverLicenceFront.value && this.formControlStep7ApplicantDriverLicenceFront.value.length > 0){
          const existingEntry  = this.step7UploadedDrDocs.filter(arg =>  arg.metadata && arg.metadata.isSpouseDriverLicence && arg.metadata.isSpouseDriverLicence === "false")
          if (existingEntry && (existingEntry.length +   this.formControlStep7ApplicantDriverLicenceFront.value.length) > 1) {
            this.applicationDialogService.openAlertDialog({
              message: `Validation Error`,
              subMessage: "Please check the following validation issue: Applicant driver licence file have been uploaded more than one file."
            });
          }else {
            hasError = false
          }
        }else if(this.formControlStep7ApplicantSpouseDriverLicenceFront.value && this.formControlStep7ApplicantSpouseDriverLicenceFront.value.length > 0){
          const existingEntry  = this.step7UploadedDrDocs.filter(arg =>  arg.metadata && arg.metadata.isSpouseDriverLicence && arg.metadata.isSpouseDriverLicence === "true")
          if (existingEntry && (existingEntry.length +   this.formControlStep7ApplicantSpouseDriverLicenceFront.value.length) > 1) {
            this.applicationDialogService.openAlertDialog({
              message: `Validation Error`,
              subMessage: "Please check the following validation issue: Partner driver licence file have been uploaded more than one file."
            });
          }else {
            hasError = false
          }
        } else {
          hasError = false
        }
        if(!hasError){
          await this.saveApplication(stepper, 7);
        }


        //this.step7UploadedDocs[0].metadata
         //
      }
    });

  }

  initStep8() {
    this.formControlStep8OtherSupportingDocuments = this.formBuilder.control(null,[duplicateFileNameValidator()]);
    this.formGroupStep8 = this.formBuilder.group({
      otherSupportingDocuments: this.formControlStep8OtherSupportingDocuments,
    });

    setStepper2StepConfig(this.formGroupStep8, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepButtonText:'Next',
      nextStepClickedFn: async stepper => {
        const isMissingTagsCheck = this.formGroupStep8.value.otherSupportingDocuments?.filter(file => file.tags && file.tags.length === 0) ;
        if(isMissingTagsCheck?.length){
          const fileNames = isMissingTagsCheck.map(file => file.name).join(', ');
          this.applicationDialogService.openAlertDialog({
            message: `Validation Error`,
            subMessage: `The following files are missing tags: ${fileNames}`
          });

        }else {
          await this.saveApplication(stepper, 8);
        }
      }
    });
  }

  initStep9() {
    this.formControlStep9ApplicationNotes = this.formBuilder.control(null);
    this.formGroupStep9 = this.formBuilder.group({
      applicationNotes: this.formControlStep9ApplicationNotes,
    })


    setStepper2StepConfig(this.formGroupStep9, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepButtonText:'Next to Review',
      nextStepClickedFn: async stepper => {
         await this.saveApplication(stepper, 9);
      }
    });
  }

  initStep10() {
    this.formGroupStep10 = this.formBuilder.group({});

    setStepper2StepConfig(this.formGroupStep10, {
      scrollToParentElementSelector: '.customer-asset-finance-page',
      nextStepButtonText:'Submit',
      stepSelectionEventFn: e => {
        this.step10Summary = {
            repayment: String(this.step2Repayment),
            estimatedDrawDownDate: this.step2EstimatedDrawdownDate,
            applicationId: String(this.applicationId),
            repaymentFrequency: this.formControlStep2PaymentFrequency.value?.type ?? 'Monthly',
            title: this.formControlStep3Title.value,
            firstName: this.formControlStep3FirstName.value ?? '',
            lastName: this.formControlStep3LastName.value ?? '',
            dob: this.formControlStep3Dob.value ?? moment(),
            gender: this.formControlStep3Gender.value,
            privacyConsent: this.formControlStep3PrivacyConsent.value,
            timeInAddress: this.formControlStep3TimeInAddress.value,
            propertyOwnerWithAddress: this.formControlStep3PropertyOwnerWithAddress.value,
            email: this.formControlStep3Email.value ?? '',
            mobileNumber: this.formControlStep3Mobile.value ?? '',
            maritalStatus: this.formControlStep3MaritalStatus.value,
            numberOfDependants: String(this.formControlStep3NumberOfDependants.value ?? 0),
            employmentStatus: this.formControlStep3EmploymentStatus.value,
            regularIncome: this.formControlStep3RegularIncome.value,
            employer: this.formControlStep3Employer.value,
            addtionalBrokerCorrespondent: this.formControlStep1Correspondent.value,
            brokerContact:this.formControlStep1BrokerContact.value!,
            spouse: this.step3OnWardsHasSpouse() ? {
              title: this.formControlStep4Title.value,
              firstName: this.formControlStep4FirstName.value ?? '',
              lastName: this.formControlStep4LastName.value ?? '',
              dob: this.formControlStep4Dob.value ?? moment(),
              gender: this.formControlStep4Gender.value,
              email: this.formControlStep4Email.value ?? '',
              mobileNumber: this.formControlStep4Mobile.value ?? '',
              employmentStatus: this.formControlStep4EmploymentStatus.value,
              regularIncome: this.formControlStep4RegularIncome.value,
              employer: this.formControlStep4EmployerName.value ?? '',
              employerContact: this.formControlStep4EmployerContact.value ?? '',
              guarantor: this.formControlStep4Guarantor.value,
              privacyConsent: this.formControlStep4PrivacyConsent.value,
            } : undefined,

            expanses: {
              homeLoans: String(this.formControlStep5HomeLoans.value ?? 0),
              electricity: String(this.formControlStep5Electricity.value ?? 0),
              carLoans: String(this.formControlStep5CarLoans.value ?? 0),
              otherUtilities: String(this.formControlStep5OtherUtilities.value ?? 0),
              personalLoans: String(this.formControlStep5PersonalLoans.value ?? 0),
              education: String(this.formControlStep5Education.value ?? 0),
              totalCreditCardLimits: String(this.formControlStep5TotalCreditCardLimits.value ?? 0),
              groceries: String(this.formControlStep5Groceries.value ?? 0),
              otherLoans: String(this.formControlStep5OtherLoans.value ?? 0),
              insurance: String(this.formControlStep5Insurance.value ?? 0),
              shareMonthlyLivingExpanses: String(this.formControlStep5ShareOfMonthlyLivingExpanses.value ?? 0),
            },

            references: this.formControlStep6References.value,

            applicationNotes: this.formControlStep9ApplicationNotes.value ?? '',
          }
      },
      nextStepClickedFn: async stepper => {
        await this.finalSubmission(stepper);
      },
    });
  }

  async finalSubmission(stepper: CdkStepper) {
    const applicant = {
      firstName: this.formControlStep3FirstName.value ?? '',
      lastName: this.formControlStep3LastName.value ?? '',
      privacyConsentObtained: this.formControlStep3PrivacyConsent.value ?? false,
    };
    const {
      atLeastOneWithPrivacyConsent,
      allHasPrivacyConsent,
      withoutPrivacyConsent
    } = applicantsPrivacyConsent([applicant]);
    if (!allHasPrivacyConsent && !atLeastOneWithPrivacyConsent) {
      this.subscriptions.push(this.applicationDialogService.openApplicantsPrivacyConsentDialog({
        atLeastOneWithPrivacyConsent,
        applicants: withoutPrivacyConsent,
      }).afterClosed().pipe(
        tap(async (r) => {
          if (r && r.type === 'save') {
            const privacyConsented = r.applicants[0].privacyConsentObtained;
            this.formControlStep3PrivacyConsent.setValue(privacyConsented);
            // console.log(await this.mapToInteflowData());
            await this.updateDraftInBg(10);
            await this.agreeToTermsOfSubmission(stepper);
          }
        })
      ).subscribe())
    } else {
      await this.agreeToTermsOfSubmission(stepper);
    }
  }

  async agreeToTermsOfSubmission(stepper: CdkStepper) {
    this.applicationDialogService.openConsumerFinanceApplicationConfirmationDialog()
      .afterClosed().pipe(
      tap(async r => {
        if (r && r.readyForSubmission) {
          this.businessUseOnly = false;
          this.selfServiceability = true;
          // if (isInternalUser(this.user)) {
          //   this.applicationDialogService.openYesNoDialog({
          //     message: "New Credit Flow",
          //     subMessage: "Do you want to submit this application to the new credit flow?"
          //   }).afterClosed().subscribe(async (result) => {
          //     if (result && result.readyForSubmission) {
          //       this.submitThroughNewCreditFlow = true;
          //     }
          //     await this.sendFinalSubmission(stepper);
          //   })
          // } else {
            await this.sendFinalSubmission(stepper);
          // }
        }
      })
    ).subscribe();
  }

  async sendFinalSubmission(stepper: CdkStepper) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData(true);
      console.log('*** consumer asset finance data', data);

      // update driver licence doc metadata - as the individualId will be changed
      if (this.applicationId) {
        this.subscriptions.push(
          this.applicationService.listApplicationDocumentFn(this.applicationId).subscribe(
            (r: AzureStorageDocument[]) => {
              const driverLicenceDocs = r.filter(obj => Object.values(obj?.tags ?? {}).includes('driverslicence'));
              for (const doc of driverLicenceDocs) {
                const individual = data.Individuals.find(obj => (doc.metadata?.driverslicencename ?? '') == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
                if (individual && individual.id) {
                  this.applicationService.updateDocumentMetadataFn(doc.name, "", {
                    ...doc.metadata,
                    individualid: individual.id ?? ''
                  }).subscribe()
                }
              }
            }
          )
        )
      }

      const sub = this.applicationService.submitConsumerFinance(this.applicationId, {...data, SubmitThroughNewCreditFlow: this.submitThroughNewCreditFlow}).pipe(
        // this.toastService.toastObserver('submit application'),
        this.toastService.spinnerObservable(),
        tap(r => {
          console.log('********* final Submission', r);
          if (r && r.status) {
            this.applicationDialogService.openApplicationSubmittedDialog().afterClosed().pipe(
              tap(async r => {
                // stepper.reset();
                // stepper.next();
                // await this.router.navigate(navigationUrlForApplications({ reload: true }), {queryParams: {reload:true}});
                await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, {reload: true}));
              })
            ).subscribe();
          } else {
            this.applicationDialogService.openAlertDialog({
              message: `Error`,
              subMessage: r.message
            });
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const assetConditionValue: AssetConditionValue = this.formControlStep2AssetCondition.value;
    const assetSelectionValue: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const loanTermValue: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const financeTypeValue: FinanceTypeValue = this.formControlStep2FinanceType.value;
    const invoiceAmount: CurrencyInputValue = this.formControlStep2InvoiceAmount.value ?? 0;
    const balloonPaymentValue : BalloonPaymentValue = this.formControlStep2BalloonPayment.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    const brokerageAmount = null;
    const brokeragePercentage = Math.round((brokerageAmount ?? 0) * 10000 / loanAmount) / 100;
    const brokerageValue: BrokerageSelectionValue = {type: `${brokeragePercentage}` as BrokerageSelectionType, name: `${brokeragePercentage}%`};
    const balloonPaymentPercentage: number = balloonPaymentValue ? Number(balloonPaymentValue?.type) : 0;
    const hybrid = this.step2ShowHybridField ? !!this.formControlStep2Hybrid.value : false;
    const rateDiscount: number = this.formControlStep2RateDiscount.value ?? 0;
    const ppsrFee: number = this.formControlStep2PpsrFee.value ?? 0;

    this.rst = calculateConsumerAssetFinanceEstimation(date, {
      type: 'Consumer',
      paymentFrequencyType: paymentFrequencyValue?.type ?? null,
      assetConditionType: assetConditionValue?.type ?? null,
      assetSelectionValue: assetSelectionValue!,
      assetYear: parseFloat(assetSelectionValue?.year ?? '0'),
      loanTerm: numeral(loanTermValue).value() ?? 0,
      financeType: financeTypeValue?.type ?? null,
      //balloonPaymentType: (`${balloonPaymentPercentage}` as BalloonPaymentType) ?? null,
      loanAmount: numeral(this.formControlStep2LoanAmount.value).value() ?? 0,
      propertyOwnership: this.formControlStep2PropertyOwner.value ?? false,
      docFeeFinanced: this.formControlStep2DocFeeFinanced.value ?? false,
      brokerOriginationFee: this.formControlStep2BrokerOriginationFee.value ?? 0,
      brokerage: Number(brokerageValue?.type ?? 0),
      brokerageAmount: brokerageAmount ?? 0,
      adverseOnFile: this.formControlStep2AdverseOnFile.value ?? false,
      equifaxScoreAbove600: this.formControlStep2EquifaxScoreAboveThreshold.value ?? false,
      privateSaleOrLeaseback: this.formControlStep2TransactionType.value?.type !== 'Dealer Sale',
      balloonPayment: balloonPaymentPercentage,
      rateCard: this.rateCard,
      invoiceAmount: this.formControlStep2InvoiceAmount.value ?? 0,
      deposit: this.formControlStep2DepositAmount.value ?? 0,
      businessSearchValue: null,
      monthlyAccountKeepingFee: this.rateCard?.MonthlyAccountKeepingFee ?? 0,
      hybrid: hybrid,
      rateDiscount: rateDiscount,
      ppsrFee: ppsrFee,
      percentagePaidToDealerOrBroker: this.originatorBusiness ? this.originatorBusiness.PercentagePaid : 100,
      repaymentType: this.formControlStep2RepaymentType.value?.type ?? '',
      existingApplicationBureauReport: null
    });

    console.log('=========rst', this.rst)
    this.calculator = this.rst.calculator;
    this.terms = this.rst.terms;
    this.step2Repayment = this.rst.repayment;
    this.AmountFinance = this.rst.calculator.principalAmt;// + this.rst.calculator.brokerageAmount);

    console.log('****** calc result', this.rst);

    this.step2Repayment = this.calculator.emiAmtPlusMonthlyAccountKeepingFee;
    const rst1 = this.calculator.getDonutChartData();
    const rst2 = this.calculator.getBarChartYearlyData();
    const rst3 = this.calculator.getBarChartQuaterlyData();
    const rst4 = this.calculator.getRepaymentEstimationData2(
      this.terms,
      paymentFrequencyValue?.type ?? 'Monthly',
      'advance',
    );
    const rst5 = this.calculator.getAmortisationData();
    const x: TotalPaymentBreakupDialogData = {
      repaymentEstimationData: rst4.map((rst ) =>({
        amountFinance: String(rst.amountFinance),
        month24: String(rst.data.find((d ) => d.loanTerm == 24)?.repayment ?? 0),
        month36: String(rst.data.find((d ) => d.loanTerm == 36)?.repayment ?? 0),
        month48: String(rst.data.find((d ) => d.loanTerm == 48)?.repayment ?? 0),
        month60: String(rst.data.find((d ) => d.loanTerm == 60)?.repayment ?? 0),
      })),
      amortizationScheduleData: rst5.map(rst => ({
        year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
        details: rst.childRows.map(d =>({
          monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
          principal: String(d.pricipalPaid), balance: String(d.endingBalance)
        }))
      })),
      paymentChartData: {
        amountFinanced: rst1.pricipalAmt,
        totalInterest: this.terms.totalInterest,
        emiAmt: this.calculator.emiAmt,
        paymentFrequency: this.calculator.repaymentFrequency,
        principalAmt:this.calculator.principalAmt,
        interestAmt: this.calculator.interestAmt,
        totalAmt: this.calculator.totalAmt,
        loanTerm: this.calculator.loanValue, // loanValue from calculator
        lvr: this.terms.LVR,
        rv: this.terms.RV,
        brokerageAmount: this.terms.brokerageAmount,
        docFee: this.terms.docFee,
        brokerOriginationFee: this.terms.brokerOriginationFee,
        applicationType:'Consumer',
        invoiceAmount: this.formControlStep2InvoiceAmount.value!,
        deposit: this.formControlStep2DepositAmount.value!,
        displayedInterest: this.calculator.displayedInterest
      },
      amortizationChartData: {
        estimatedDrawdownDate: moment(this.calculator.dateValue),
        annualData: rst2.map(rst => ({
          year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
        })),
        quarterlyData: rst3.map(rst => ({
          quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
        }))
      },
      // calculationLog: this.terms.calculationLog,
    };
    this.AmountFinance= this.calculator.principalAmt; // rst1.pricipalAmt;
    return x;
  }

  private async step2ShowQuotation(stepper: CdkStepper) {
    this.step2EstimatedDrawdownDate = moment().add(1, 'day');
    this.acceptedQuotation = true;
    this.saveApplication(stepper, 2);
  }

  step3OnWardsHasSpouse() {
    return this.formControlStep3MaritalStatus.value &&
      (this.formControlStep3MaritalStatus.value.type  === 'married' ||
       this.formControlStep3MaritalStatus.value.type  === 'defacto');
  }


  private async saveApplication(stepper: CdkStepper, step: number) {
    if (!this.applicationId) { // application was not saved before (new draft)
      const inteflowData = await this.mapToInteflowData();
      console.log('save application (asset) inteflowData', inteflowData);
      this.applicationService.saveApplication(inteflowData).pipe(
        // this.toastService.toastObserver('save draft'),
        this.toastService.spinnerObservable(),
        tap(r => {
          const applicationId = r.payload.ApplicationId;
          const brokerApplicationId = r.payload.BrokerApplicationId;
          this.brokerApplicationId = brokerApplicationId;
          this.applicationId = applicationId;
          stepper.next();
        })
      ).subscribe();
    } else {  // existing draft
      await this.updateDraftInBg(step);
      stepper.next();
    }
  }


  private async updateDraftInBg(step: number, fn?: ()=>void) {
    if (this.applicationId) {
      const data = await this.mapToInteflowData();
      console.log('====data: ', data);
      await this.uploadFileToAzure(step, data);
      const sub = this.applicationService.updateApplication(this.applicationId, data).pipe(
        fn ? this.toastService.loadingWithMessage(`Saving, please be patient`) : tap(()=>{}),
        tap(r => {
          if (fn) {
            fn();
          }
        })
      ).subscribe();
      this.subscriptions.push(sub);
    }
  }

  async uploadFileToAzure(step: number, data: UpdateApplicationData) {
    let base64Files: Base64File[] = [];
    let allToBeDeleted: string[] = [];
    let allFiles: File[] = [];
    if (step === 7) { // driver's licence
      allToBeDeleted = [...this.step7DeletedUploadedDocs].map(file => file.name);
      // primary applicant's front & back driver licence
      if (this.formControlStep7ApplicantDriverLicenceFront.value) {
        const files: File[] = this.formControlStep7ApplicantDriverLicenceFront.value;
        if (files && files.length) {
          allFiles = files.map((f) => {
            const individual = data.Individuals.find(obj => `${this.formControlStep3FirstName.value} ${this.formControlStep3LastName.value}` == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
            (f as any).tags = ['driverslicence'];
            (f as any).metadata =  individual?.id ? {
              ...this.step8OtherDocMetadata,
              individualid: individual.id,
              driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
              isSpouseDriverLicence:"false"
            } : this.step8OtherDocMetadata;

            return f;
          })
        }
      }

      // spouse applicant's front and back driver licence
      if (this.formControlStep7ApplicantSpouseDriverLicenceFront.value) {
        const files: File[] = this.formControlStep7ApplicantSpouseDriverLicenceFront.value;
        if (files && files.length) {
          allFiles = [...allFiles, ...files.map((f) => {
            const individual = data.Individuals.find(obj => `${this.formControlStep4FirstName.value} ${this.formControlStep4LastName.value}` == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
            (f as any).tags = ['driverslicence'];
            (f as any).metadata = individual?.id ? {
              ...this.step8OtherDocMetadata,
              individualid: individual.id,
              driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
              isSpouseDriverLicence:"true"
            } : this.step8OtherDocMetadata;
            return f;
          })]
        }
      }

    } else if (step === 8) { // other supporting doc
      const otherSupportingDocs: UploadFileValue = this.formControlStep8OtherSupportingDocuments.value;
      allToBeDeleted = [...this.step8DeletedUploadedDocs].map(file => file.name);
      if (otherSupportingDocs && otherSupportingDocs.length) {
        allFiles = [...otherSupportingDocs];
      }
    } else {
      return;
    }
    base64Files = [...await filesToBase64Files(allFiles)];
    // double check if any changes with existing files
    // remove file that has been uploaded
    base64Files = base64Files.map(file => {
      file.metadata =  file.tags?.includes('driverslicence') ? file.metadata : this.step8OtherDocMetadata
      return file;
    }).filter(f => {
      const fileIdentifiers = this.step7And8FilesUploadedToAzure.map(m => `${m.name}${m.tags?.join(' ')}`);
      return !fileIdentifiers.includes(`${f.name}${f.tags?.join(' ')}`);
    })

    this.step7And8FilesUploadedToAzure = [...this.step7And8FilesUploadedToAzure, ...base64Files];

    if (this.applicationId) {
      this.subscriptions.push(
        (await this.applicationService.uploadApplicationDocumentToAzureStorage(
            this.applicationId,
            [...base64Files],
            allToBeDeleted)
        ).subscribe(arg =>{
          if(step === 7){
            this.listUploadedDocuments()
            this.formControlStep7ApplicantDriverLicenceFront.setValue(null)
            this.formControlStep7ApplicantSpouseDriverLicenceFront.setValue(null)

            this.formControlStep7ApplicantDriverLicenceFront.removeValidators([Validators.required])
            this.formControlStep7ApplicantDriverLicenceFront.updateValueAndValidity();

            this.formControlStep7ApplicantSpouseDriverLicenceFront.removeValidators([Validators.required])
            this.formControlStep7ApplicantSpouseDriverLicenceFront.updateValueAndValidity();
          } else if(step === 8){
            this.uploadFilesMultiTagsComponent.files = []
            this.uploadFilesMultiTagsComponent.filesWithTags = []
            this.uploadFilesMultiTagsComponent.formControl.setValue(null)
            this.listUploadedDocuments()
            this.formControlStep8OtherSupportingDocuments.setValue(null)
          }
        })
      );
    }
  }

  async mapToInteflowData(finalSubmission = false) {

    const user  = getUser();
    const financeType: FinanceTypeValue = this.formControlStep2FinanceType.value;
    const applicationNotes = this.formControlStep9ApplicationNotes.value ?? '';
    const broker: AggregatorSearchComponentValue = this.formControlStep1Broker.value;
    const asset: AssetSelectionComponentValue = this.formControlStep2Asset.value;
    const refValue: ReferenceValue = this.formControlStep6References.value;
    const correspondentDetails: UserSelectionValue = this.formControlStep1Correspondent.value;
    const userApplicant: CustomerApplicantTypeValue = this.formControlStep1_bCustomerApplicantType.value;
    // workout broker salesforce id
    let brokerSalesforceId = broker!.salesforceId;
    let brokerAbn = broker!.abn;
    let brokerEntityName = broker!.entityName;
    if (user!.BrokerSalesforceId === environment.GrowFinanceGroupSalesforceId) {
      if (broker && broker.salesforceId) {
        brokerSalesforceId = broker.salesforceId;
        brokerAbn = broker.abn;
        brokerEntityName = broker.entityName;
      } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId) {
        brokerSalesforceId = this.brokerOfUser.SalesforceId;
        brokerAbn = this.brokerOfUser.ABN;
        brokerEntityName = this.brokerOfUser.EntityName;
      }
    } else {
      if (broker) { // broker dropdown selection is being populated
        // use the declared brokerSalesforceId, brokerAbn and brokerEntityName
      } else if (this.brokerOfUser && this.brokerOfUser.SalesforceId !== undefined) {
        // broker drop down selection is not being populated, we populate it with the current user's broker company details
        brokerSalesforceId = this.brokerOfUser.SalesforceId;
        brokerAbn = this.brokerOfUser.ABN;
        brokerEntityName = this.brokerOfUser.EntityName;
      }
    }

    // workout disclosure
    let disclosed = true;
    let introducerGroup = 'ECLIPXCOMMERCIAL';
    if (this.brokerOfUser) {
      if (this.brokerOfUser.Relationship !== 'disclosed') {
        disclosed = false;
        introducerGroup = 'UNDISCLOSED_ECLIPXCOMMERCIAL';
      }
    }




    // work out vehicle asset spec.
    let assetLvr = undefined;
    let vehSpec = {};
    if (asset) {
      if (isAssetVehicles(asset.category.index)) {
        if (isAssetNewCars(asset.category.index, asset.type.index)) {
          if(this.terms) {
            assetLvr = this.terms.LVR;
            vehSpec = asset.vehicle ?? {};
          }
        }
      }
    }


    // individuals (primary applicant)
    const otherIndividuals: any[] = [];
    {
      const title: TitleSelectionValue = this.formControlStep3Title.value;
      const firstName = this.formControlStep3FirstName.value ?? undefined;
      const lastName = this.formControlStep3LastName.value ?? undefined;
      const identification = this.formControlStep1Identification.value ?? undefined;
      const dob = this.formControlStep3Dob.value?.format('YYYY-MM-DD') ?? undefined;
      const gender = toInteflowGender(this.formControlStep3Gender.value);
      const privacyConsented = this.formControlStep3PrivacyConsent.value ?? undefined;
      const propertyOwner: PropertyOwnerWithAddressValue = this.formControlStep3PropertyOwnerWithAddress.value;
      const guarantor = true;
      const email = this.formControlStep3Email.value ?? undefined;
      const mobile = this.formControlStep3Mobile.value ?? undefined;
      const address: Address2ComponentValue = this.formControlStep3ResidentialAddress.value;
      const role : RoleTypes = 'Applicant' as const;
      const timeAtAddress: TimeInAddressValue = (this.formControlStep3TimeInAddress.value);
      const maritalStatus: MaritialStatusSelectionValue = this.formControlStep3MaritalStatus.value;
      const numberOfDependants = this.formControlStep3NumberOfDependants.value;
      const employmentStatus: EmploymentStatusSelectionValue = this.formControlStep3EmploymentStatus.value;
      const income: IncomeSelectionValue = this.formControlStep3RegularIncome.value;
      const employer: EmployerValue = this.formControlStep3Employer.value;

      const individual = {
        id: v4(),
        Title: title?.type ?? undefined,
        GivenName: firstName,
        MiddleName: '',
        SurName: lastName,
        Identification: identification,
        DoB: dob,
        Gender: gender,
        PrivacyConsent: privacyConsented,
        PropertyOwnerFlag: propertyOwner?.propertyOwner ?? false,
        PropertyValue: propertyOwner?.propertyValue ?? undefined,
        MortgageBalance: propertyOwner?.mortgageValue ?? undefined,
        AddressofPropertyOwned: toInteflowPropertyOwnerAddress(propertyOwner),
        GuarantorFlag: guarantor,
        Email: email,
        MobileNumber:  mobile,
        HomeAddress: toInteflowAddress(address),
        Role: role,
        SignerRole: "GuarantorSigner" as SignerRoleTypes,
        DriverLicenseExpiryDt: undefined,
        DriverLicenseState: undefined,
        TimeatAddress: toInteflowTimeAtAddress(timeAtAddress),
        PreviousAddress: toInteflowPreviousAddress(timeAtAddress),
        MaritalStatus: maritalStatus?.name ?? undefined,
        NumberofDependants: Number(numberOfDependants),
        EmploymentStatus: employmentStatus?.name ?? undefined,
        Income: income?.income ?? undefined,
        IncomeFrequency: income?.period.type ?? undefined,
        Employer: employer?.currentEmployer ?? undefined,
        EmployerContact: employer?.currentEmployerContact ?? undefined,
        TimeatCurrentEmployment: toInteflowTimeAtCurrentEmployer(employer),
        PreviousEmployer: employer?.previousEmployer ?? undefined,
        PreviousEmployerContact: employer?.previousEmployerContact ?? undefined,
      }
      otherIndividuals.push(individual);
    }

    // individuals (spouse of applicant)
    if (this.step3OnWardsHasSpouse()) {

      const title: TitleSelectionValue = this.formControlStep4Title.value;
      const firstName = this.formControlStep4FirstName.value ?? undefined;
      const lastName = this.formControlStep4LastName.value ?? undefined;
      const dob = this.formControlStep4Dob.value?.format('YYYY-MM-DD') ?? undefined;
      const gender = toInteflowGender(this.formControlStep4Gender.value) ?? undefined;
      const privacyConsented = this.formControlStep4PrivacyConsent.value ?? undefined;
      const guarantor = this.formControlStep4Guarantor.value ?? undefined;
      const email = this.formControlStep4Email.value ?? undefined;
      const mobile = this.formControlStep4Mobile.value ?? undefined;
      const role = 'Spouse';
      const employmentStatus: EmploymentStatusSelectionValue = this.formControlStep4EmploymentStatus.value;
      const income: IncomeSelectionValue = this.formControlStep4RegularIncome.value;
      const employerName = this.formControlStep4EmployerName.value;
      const employerContact = this.formControlStep4EmployerContact.value;
      const address: Address2ComponentValue = this.formControlStep3ResidentialAddress.value;
      const propertyOwner: PropertyOwnerWithAddressValue = this.formControlStep3PropertyOwnerWithAddress.value;
      let _employmentStatusName = employmentStatus?.name;
      let _incomeAmount = income?.income ?? 0;
      let _incomeFrequency = income?.period?.type ?? IncomePeriodOptions[0].type;
      let _employerName = employerName ?? undefined;
      let _employerContact = employerContact ?? undefined;

      // patch: when employmentStatus is unemployed we need to patch this so it can be accepted in SF
      if(employmentStatus?.type === 'unemployed') {
        _employmentStatusName = EmploymentStatusSelectionValueOptions.find(opt => opt.type === 'casual')?.name;
        _incomeAmount = 0;
        _incomeFrequency = IncomePeriodOptions[0].type;
        _employerName = 'N/A';
        _employerContact = '0411111111';
      }

      const individual = {
        id: v4(),
        Title: title?.type ?? undefined,
        GivenName: firstName,
        MiddleName: '',
        SurName: lastName,
        DoB: dob,
        Gender: gender,
        PrivacyConsent: privacyConsented,
        PropertyValue: undefined,
        MortgageBalance: undefined,
        AddressofPropertyOwned: undefined,
        GuarantorFlag: guarantor,
        Email: email,
        MobileNumber:  mobile,
        PropertyOwnerFlag: propertyOwner?.propertyOwner ?? false,
        HomeAddress: toInteflowAddress(address),
        Role: role,
        SignerRole: !!guarantor ? 'Guarantor' : 'Others',
        DriverLicenseExpiryDt: undefined,
        DriverLicenseState: undefined,
        TimeatAddress: undefined,
        MaritalStatus: undefined,
        NumberofDependants: undefined,
        EmploymentStatus: _employmentStatusName,
        Income: _incomeAmount,
        IncomeFrequency: _incomeFrequency,
        Employer: _employerName,
        EmployerContact: _employerContact,
        TimeatCurrentEmployment: undefined,
        PreviousEmployer: undefined,
        PreviousEmployerContact: undefined,
      }
      otherIndividuals.push(individual);
    }

    // reference
    const references =  fromReferenceToInteflowReferences(refValue);

    // pricing details
    const loanTerm: NumberInputValue = this.formControlStep2LoanTerms.value;
    const invoiceAmount = this.formControlStep2InvoiceAmount.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value!, 2);
    const deposit = this.formControlStep2DepositAmount.value;
    const paymentPeriod: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const docFeeFinanced = this.formControlStep2DocFeeFinanced.value;
    const docFee = this.terms?.docFee ?? undefined;
    const brokerOriginationFee = this.formControlStep2BrokerOriginationFee.value;
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value;
    const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
    const balloonPercentage: BalloonPaymentValue = this.formControlStep2BalloonPayment.value;
    const balloonPayment = this.terms?.RV ?? undefined;
    const transactionType: TransactionValue = this.formControlStep2TransactionType.value;
    const privateSales: PrivateSaleType = transactionType ? (
      transactionType.type === 'Dealer Sale' ? 'No' :
      (transactionType.type === 'Private Sale' ? 'Yes' : transactionType.type)
      ) : 'No';
    const assetCondition: AssetConditionValue = this.formControlStep2AssetCondition.value;
    const repayment = this.calculator?.emiAmt ?? undefined;
    const apr = this.calculator?.displayedInterest ?? undefined;
    const previousLoan = this.formControlStep2PreviousLoan.value;
    const hybrid = this.step2ShowHybridField ? !!this.formControlStep2Hybrid.value : false;
    const rateDiscount = this.formControlStep2RateDiscount.value ?? 0;
    const rate = this.terms?.totalInterest ? (this.terms?.totalInterest + rateDiscount - this.maxRateDiscount) : undefined;
    const brokerage = loanAmount ? (this.calculator?.commission ?? 0) * 100 / loanAmount : 0; // percentage paid to the broker
    const oversCommission = this.calculator?.amountToUs ?? 0; // percentage paid to dynamoney
    const repaymentType : RepaymentTypeValue = this.formControlStep2RepaymentType.value

    // file uploads
    const otherSupportingDocs: UploadAzureFilesValue = this.formControlStep8OtherSupportingDocuments.value;

    const allToBeDeleted = [...this.step8DeletedUploadedDocs,...this.step7DeletedUploadedDocs].map(file => file.name);
    let allFiles: File[] = [];

    // primary applicant's front & back driver licence
    if (this.formControlStep7ApplicantDriverLicenceFront.value) {
      const files: File[] = this.formControlStep7ApplicantDriverLicenceFront.value;
      if (files && files.length) {
        allFiles = files.map((f) => {
          const individual = otherIndividuals.find(obj => `${this.formControlStep3FirstName.value} ${this.formControlStep3LastName.value}` == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
          (f as any).tags = ['driverslicence'];
          (f as any).metadata = individual?.id ? {
            individualid: individual?.id,
            driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
            ...this.step8OtherDocMetadata
          } : this.step8OtherDocMetadata;
          return f;
        })
      }
    }

    // spouse applicant's front and back driver licence
    if (this.formControlStep7ApplicantSpouseDriverLicenceFront.value) {
      const files: File[] = this.formControlStep7ApplicantSpouseDriverLicenceFront.value;
      if (files && files.length) {
        allFiles = [...allFiles, ...files.map((f) => {
          const individual = otherIndividuals.find(obj => `${this.formControlStep4FirstName.value} ${this.formControlStep4LastName.value}` == `${obj.GivenName ?? ''} ${obj.SurName ?? ''}`);
          (f as any).tags = ['driverslicence'];
          (f as any).metadata = individual?.id ? {
            individualid: individual?.id,
            driverslicencename: `${individual?.GivenName ?? ''} ${individual?.SurName ?? ''}`,
            ...this.step8OtherDocMetadata
          } : this.step8OtherDocMetadata;
          return f;
        })]
      }
    }

    if (otherSupportingDocs && otherSupportingDocs.length) {
      allFiles = [...allFiles, ...otherSupportingDocs];
    }
    allFiles = [...allFiles].filter(file => !allToBeDeleted.includes(file?.name));

    // get doc types
    let uploadedTags = allFiles.reduce((tags: string[], file) => [...tags, ...((file as any).tags ?? [])], []);
    uploadedTags = [...uploadedTags, ...this.step7UploadedDocs.reduce((tags: string[], file: AzureStorageDocument) => [...tags, ...Object.values(file?.tags ?? {})], [])];
    uploadedTags = [...new Set(uploadedTags)];
    console.log('=====uploadedTags: ', uploadedTags);
    for (const tag of uploadedTags) {
      if (!this.documentTypes.map(t => t.value).includes(tag)) {
        const tagObj = (Object.values(constants.documentTypes) as DocumentTag[]).find(obj => obj.value === tag);
        if (tagObj) {
          this.documentTypes.push(tagObj);
        }
      }
    }
    console.log('====updated defaultTags: ', this.documentTypes);

    const newCarAssetType = constants.NewCarsAssetTypeIndex;

    const expensesHomeLoan = this.formControlStep5HomeLoans.value ?? 0;
    const expensesCarLoan = this.formControlStep5CarLoans.value ?? 0;
    const expensesPersonalLoan = this.formControlStep5PersonalLoans.value ?? 0;
    const expensesCreditCardLimit = this.formControlStep5TotalCreditCardLimits.value ?? 0;
    const expensesOtherLoan = this.formControlStep5OtherLoans.value ?? 0;
    const expensesElectricity = this.formControlStep5Electricity.value ?? 0;
    const expensesOtherUtilities = this.formControlStep5OtherUtilities.value ?? 0;
    const expensesEducation = this.formControlStep5Education.value ?? 0;
    const expensesGroceries = this.formControlStep5Groceries.value ?? 0;
    const expensesInsurance = this.formControlStep5Insurance.value ?? 0;
    const expensesShare = this.formControlStep5ShareOfMonthlyLivingExpanses.value ?? 0;

    const apiBodyExpense = {
      HomeLoans: expensesHomeLoan,
      CarLoans: expensesCarLoan,
      PersonalLoans: expensesPersonalLoan,
      CreditCardLimit: expensesCreditCardLimit,
      OtherLoans: expensesOtherLoan,
      Electricity: expensesElectricity,
      OtherUtilities: expensesOtherUtilities,
      Education: expensesEducation,
      Groceries: expensesGroceries,
      Insurance: expensesInsurance,
      ShareofExpense: expensesShare,
    };

    let apiBodyPricingDetails: SaveApplicationPricingDetailsData = {
      LoanTerm: loanTerm ?? undefined,
      InvoiceAmount: (invoiceAmount != null ? invoiceAmount : undefined),
      Deposit: (deposit != null ? deposit : undefined),
      Brokerage: brokerage,
      LoanAmount: loanAmount,
      AmountFinance:this.AmountFinance,
      PaymentPeriod: paymentPeriod ? paymentPeriod.name : undefined,
      DocFee: (docFee != null ? Number(docFee) : undefined),
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      BrokerOriginationFee: brokerOriginationFee ?? undefined,
      PropertyOwner: booleanToYesNo(propertyOwner),
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      InterestRate: rate,
      BalloonPaymentPercentage: balloonPercentage?.type ? Number(balloonPercentage.type) : undefined,
      BalloonPayment: balloonPayment,
      Repayment: repayment ?? 0,
      BankStatementSubmitted: 'No' as const,
      PrivateSale: privateSales,
      AssetCondition: assetCondition?.type ?? undefined,
      APR: apr,
      TransactionType: transactionType ? transactionType.type : undefined,
      Hybrid: booleanToYesNo(hybrid),
      RateDiscount: rateDiscount,
      OversCommission: oversCommission,
      RepaymentType : repaymentType?.type,
      MonthlyAccountKeepingFee: this.rateCard?.MonthlyAccountKeepingFee ?? 0,
    }
    if (!propertyOwner) {
      apiBodyPricingDetails = {
        ...apiBodyPricingDetails,
        PreviousLoan: booleanToYesNo(previousLoan)
      }
    }

    const apiBodyReferences = [
      ...references
    ]
    const apiBodyIndividuals  = [
      ...otherIndividuals
    ];
    const apiBodyAppInfo = {
      BrokerAppID: this.brokerApplicationId ?? undefined,
      IntroducerGroup: introducerGroup,
      Disclosed: disclosed,
      FinanceType: financeType?.name ?? undefined,
      UserId: user!.UserId,
      CompanyId: user!.OriginatorBusinessId,
      UserEmail: user!.Email,
      UserFirstName: user!.GivenName,
      UserLastName: user!.FamilyName,
      BrokerSalesforceID: brokerSalesforceId,
      BrokerAbn: brokerAbn,
      BrokerEntityName: brokerEntityName,
      Notes: applicationNotes,
      BusinessUse: booleanToYesNo(this.businessUseOnly),
      SelfServiceability:  booleanToYesNo(this.selfServiceability),
      PrivacyConfirmation: booleanToYesNo(this.formControlStep1_bPrivacyStatementCheck.value ?? null),
      BrokerflowDocumentID: undefined,
      AssetCategory: asset?.category.index ?? undefined,
      AssetType: asset?.type.index ?? undefined,
      ContactId: undefined,
      CustomerId: undefined,
      SalesforceId: undefined,
      StageName: 'Draft',
      MetApplicant: userApplicant ? userApplicant.type : undefined,
    };

    let apiBodyAssetSpec: UpdateApplicationData['AssetSpec'] = {
      make: asset?.make ?? undefined,
      family: asset?.family ?? undefined,
      year: asset?.year ?? undefined,
      vehicle: asset?.model ?? undefined,
      description: asset?.description ?? undefined,
      OtherCar: asset?.OtherCar ?? false,
    };

    if (newCarAssetType === asset?.type?.index) {
      if(this.terms) {
        assetLvr = this.terms.LVR;
        vehSpec = asset.vehicle ?? {};
      }
      apiBodyAssetSpec = {
        ...apiBodyAssetSpec,
        ...vehSpec,
        LVR: assetLvr ?? undefined,
      };
    }

    // broker contact
    const brokerContact: SalesforceContactSameAccountSelectionValue = this.formControlStep1BrokerContact.value;

    const apiBody: UpdateApplicationData = {
      ApplicationType: 'Consumer',
      IP: this.ip,
      ApplicantLocation: this.geoLocation,
      UserId: this.applicationService.getApplicationUserId(this.existingApplication,this.user),
      CompanyId: this.applicationService.getApplicationCompanyId(this.existingApplication,this.user),
      AppInfo: apiBodyAppInfo,
      AssetSpec: apiBodyAssetSpec,
      Contacts: undefined,
      CommercialEntities: undefined,
      Individuals: apiBodyIndividuals,
      Reference: apiBodyReferences,
      PricingDetails: apiBodyPricingDetails,
      AcceptQuotation: this.acceptedQuotation,
      ApplicationNotes: applicationNotes,
      CompanyDetails: undefined,
      //Documents: apiBodyDocuments,
      Expense: apiBodyExpense,
      DocumentTypes: this.documentTypes,
      AdditionalBrokerCorrespondent: correspondentDetails ?? null,
      BrokerContact: brokerContact,
    }
    return apiBody;
  }


  onStep8DeleteUploadedDoc($event: UploadedFilesListComponentEvent) {
    this.step8DeletedUploadedDocs = [...$event.deleted];
  }

  onStep7DeleteUploadedDoc($event: any) { // UploadedFilesListComponentEvent
    this.step7UploadedDrDocs = this.step7UploadedDrDocs.filter(file => {
      // Check if the file should be kept in the array
      return !(file.metadata &&
               file.metadata.driverslicencename &&
               $event.deletedFile.metadata &&
               $event.deletedFile.metadata.driverslicencename &&
               $event.deletedFile.metadata.driverslicencename === file.metadata.driverslicencename);
  });

    this.step7DeletedUploadedDocs = [...$event.deleted];
    if($event.deletedFile.metadata && $event.deletedFile.metadata.isSpouseDriverLicence && $event.deletedFile.metadata.isSpouseDriverLicence === "false"){
      const existingEntry  = this.step7UploadedDrDocs.filter(arg =>  arg.metadata && arg.metadata.isSpouseDriverLicence && arg.metadata.isSpouseDriverLicence === "false")
      if(existingEntry && existingEntry.length < 0){
        this.formControlStep7ApplicantDriverLicenceFront.addValidators([Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
        this.formControlStep7ApplicantDriverLicenceFront.updateValueAndValidity();
      }

    }else if($event.deletedFile.metadata &&  $event.deletedFile.metadata.isSpouseDriverLicence && $event.deletedFile.metadata.isSpouseDriverLicence === "true" && this.step3OnWardsHasSpouse()){
      const existingEntry  = this.step7UploadedDrDocs.filter(arg =>  arg.metadata && arg.metadata.isSpouseDriverLicence && arg.metadata.isSpouseDriverLicence === "true")
      if(existingEntry && existingEntry.length < 0){
        this.formControlStep7ApplicantSpouseDriverLicenceFront.addValidators([Validators.required, duplicateFileNameValidator(),maxFileUploadValidator(2)]);
        this.formControlStep7ApplicantSpouseDriverLicenceFront.updateValueAndValidity();
      }
    }
  }

  get step8OtherDocMetadata() {
    const firstName = this.formControlStep3FirstName.value ?? '';
    const lastName = this.formControlStep3LastName.value ?? '';
    const dob = this.formControlStep3Dob.value?.format('YYYY-MM-DD') ?? '';

    return {
      consumername: firstName + ' ' + lastName,
      dob: dob
    };
  }

  onStep8DocEvent(files: UploadAzureFiles) {
    if(files && files.length > 0) {
      this.formControlStep8OtherSupportingDocuments.setValue(files)
    } else {
      this.formControlStep8OtherSupportingDocuments.setValue(null)
    }
  }
  
  applicationDefaultDocuments(includeOptions = false): DocumentTag[] {
    return this.defaultDocuments(
      'Consumer',
      [this.formControlStep4FirstName.value + ' ' + this.formControlStep4LastName.value],
      this.formControlStep1FirstName.value + ' ' + this.formControlStep1LastName.value,
      String((this.formControlStep2Asset.value as AssetSelectionComponentValue)?.category?.index),
      String((this.formControlStep2Asset.value as AssetSelectionComponentValue)?.type?.index),
      this.formControlStep2InvoiceAmount.value ?? undefined,
      this.formControlStep2DepositAmount.value ?? undefined,
      this.formControlStep2TransactionType.value?.type !== 'Dealer Sale',
      false,
      false,
      includeOptions
    );
  }

  skipFileUploadValidation() {
    return (this.step7UploadedDocs && this.step7UploadedDocs.length > 0);
  }

  listUploadedDocuments() {
    if (this.applicationId) {
      this.subscriptions.push(
        this.applicationService.listApplicationDocumentFn(this.applicationId).subscribe(
          (r: AzureStorageDocument[]) => {
            const driverslicenceDoc  = r.filter(arg =>  arg.metadata && arg.metadata.driverslicencename)
            const supportDoc  = r.filter(arg =>  arg.metadata && !arg.metadata.driverslicencename)
            this.step7UploadedDocs = driverslicenceDoc;
            this.step8UploadedDocs = supportDoc;
            this.step7UploadedDrDocs  = driverslicenceDoc
            console.log("=====uploaded driverslicenceDoc docs: ", driverslicenceDoc);
            console.log("=====uploaded supportDoc docs: ", supportDoc);
          }
        )
      )
    }
  }

  downloadQuotation() {
    this.subscriptions.push(
      this.applicationService.generateConsumerQuotationFn({
        data: {
          monthlyPayment: this.calculator?.emiAmtPlusMonthlyAccountKeepingFee ?? 0,
          monthlyInstallment: this.calculator?.emiAmt ?? 0,
          monthlyAccountKeepingFee: this.calculator?.monthlyAccountKeepingFee ?? 0,
          interestRate: this.calculator?.displayedInterest ?? 0,
          comparisonRate: this.calculator?.comparisonRate ?? 0,
          amountFinanced: this.calculator?.principalAmt ?? 0,
          totalInterest: this.calculator?.interestAmt ?? 0,
          totalPayment: this.calculator?.totalAmt ?? 0,
          invoiceAmount: this.formControlStep2InvoiceAmount.value ?? 0,
          deposit: this.formControlStep2DepositAmount.value ?? 0,
          residual: this.calculator?.residualAmount ?? 0,
          docFee: this.terms?.docFee ?? 0,
          loanTerms: this.calculator?.loanValue ?? 0,
          paymentFrequency: this.calculator?.repaymentFrequency ?? 'Monthly',
        },
        ip: this.ip,
        generatedTime: moment().format('DD/MM/YYYY hh:mm:ss'),
        consumerFirstName: this.formControlStep1FirstName.value ?? '',
        consumerLastName: this.formControlStep1LastName.value ?? '',
      }).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(id => {
        const url = `${environment.api2Host}/api2/download-consumer-quotation/${id}`;
        const res = window.open(url, '_blank');
        if (!res || res.closed || typeof res.closed === 'undefined') {
          this.applicationDialogService.openAlertDialog({
            message: 'Warn',
            subMessage: 'Please disable Popup blocker and try again',
          });
          return;
        }
      })
    )
  }

  sendQuotation() {
    this.subscriptions.push(
      this.applicationDialogService.openQuotationNotificationDialog({})
        .afterClosed().pipe(
          tap(result => {
            if (result && result.readyForSubmission) {
              this.applicationService.sendConsumerQuotationFn({
                data: {
                  monthlyPayment: this.calculator?.emiAmtPlusMonthlyAccountKeepingFee ?? 0,
                  monthlyInstallment: this.calculator?.emiAmt ?? 0,
                  monthlyAccountKeepingFee: this.calculator?.monthlyAccountKeepingFee ?? 0,
                  interestRate: this.calculator?.displayedInterest ?? 0,
                  comparisonRate: this.calculator?.comparisonRate ?? 0,
                  amountFinanced: this.calculator?.principalAmt ?? 0,
                  totalInterest: this.calculator?.interestAmt ?? 0,
                  totalPayment: this.calculator?.totalAmt ?? 0,
                  invoiceAmount: this.formControlStep2InvoiceAmount.value ?? 0,
                  deposit: this.formControlStep2DepositAmount.value ?? 0,
                  residual: this.calculator?.residualAmount ?? 0,
                  docFee: this.terms?.docFee ?? 0,
                  loanTerms: this.calculator?.loanValue ?? 0,
                  paymentFrequency: this.calculator?.repaymentFrequency ?? 'Monthly',
                },
                emailList: result.emailList,
                consumerFirstName: this.formControlStep1FirstName.value ?? '',
                consumerLastName: this.formControlStep1LastName.value ?? '',
              }).pipe(
                this.toastService.snackBarObservable('Quotation sent successfully')
              ).subscribe()
            }
        })
      ).subscribe()
    )

  }
}
