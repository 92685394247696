import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, EventEmitter} from '@angular/core';
import {switchMap, tap, map, catchError, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { ApplicationService, } from '../../service/application.service';
import _ from 'lodash';
import {
  AllApplicationTypes,
  ApplicationSort,
  ApplicationTypes,
  DEFAULT_APPLICATION_TYPE_FILTER, PortalLoginUser,
  PromotionResponse,
  SimplifiedApplication,
  Application,
  CopyApplicationDialogResult,
  getApplicationStatus,
  ConfirmationDialogResult, compareMatch,
} from '@portal-workspace/grow-shared-library';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { PaginablePayloadApiResponse, User } from '@portal-workspace/grow-shared-library';
import {MessageBoxComponentEvent, PortalHotToastService, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import moment from 'moment';
import {
  getUser,ApplicationDialogService
} from '@portal-workspace/grow-ui-library';
import {
  displayApplicationDateTime,
  isInternalUser,
  isAssociatedWithAggregator,
  GetGeoLocationFn,
  CopyApplicationFn,
  SaveApplicationResult
} from '@portal-workspace/grow-shared-library';
import { PageEvent } from '@angular/material/paginator';
import { Sort, MatSortModule } from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {
  navigationUrlForApplication,
  navigationUrlForAssetFinance,
  navigationUrlForBusinessFinance,
  navigationUrlForBusinessOverdraft,
  navigationUrlForCommercialFinance,
  navigationUrlForConsumerAssetFinance,
  navigationUrlForConsumerFinance,
  navigationUrlForCorporateLoan,
  navigationUrlForInsurancePremium,
  navigationUrlForAllApplication,
  navigationUrlForAssetProductSelector,
  navigationUrlForApplicationWithQueryParams,
  navigationUrlForAssetProductSelectorWithQueryParams,
  navigationUrlForCorporateLoanWithQueryParams,
  navigationUrlForInsurancePremiumWithQueryParams,
  navigationUrlForConsumerAssetFinanceWithQueryParams,
  navigationUrlForBusinessOverdraftWithQueryParams,
  navigationUrlForBusinessFinanceWithQueryParams,
  navigationUrlForCommercialFinanceWithQueryParams,
} from '../../service/navigation-urls';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@portal-workspace/grow-shared-library';
import { createAsyncStore, loadingFor } from '@ngneat/loadoff';
import { LocalApplicationsDbService } from '../../service/local-applications-db.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AdminService } from '../../service/admin.service';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { TagBoxComponent } from '@portal-workspace/grow-ui-library';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApplicationTypeIconComponent } from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DataBoxComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { AsyncPipe, NgClass, JsonPipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';


export class InternalDataSource extends DataSource<SimplifiedApplication> {

  subject: BehaviorSubject<SimplifiedApplication[]> = new BehaviorSubject<SimplifiedApplication[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<SimplifiedApplication[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(applications: SimplifiedApplication[]) {
    this.subject.next(applications);
  }
}

export interface ApplicationTypeFilter {
  type: AllApplicationTypes,
  name: string;
}

export type ApplicationState = 'all' | 'under-review' | 'in-settlement' | 'closed-won';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './applications.page.html',
    styleUrls: ['./applications.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, MatMenuModule, FlexModule, NgClass, CustomContentLoaderComponent, DataBoxComponent, MatFormFieldModule, JsonPipe, MatInputModule, MatSelectModule, MatOptionModule, ApplicationTypeIconComponent, MatTooltipModule, MatTableModule, MatSortModule, TagBoxComponent, CustomPaginatorComponent, AsyncPipe]
})
export class ApplicationsPage implements OnInit, AfterViewInit {

  subscriptions: Subscription[] = [];
  loader = loadingFor('tableLoading');


  applicationStates: ApplicationState[] = ['all', 'in-settlement', 'closed-won', 'under-review'];

  applicationTypes: ApplicationTypeFilter[] = [
    DEFAULT_APPLICATION_TYPE_FILTER,
    { type: 'AssetFinance', name: 'Asset Finance' },
    { type: 'BusinessLoans', name: 'Business Term Loan' },
    { type: 'BusinessOverdraft', name: 'Business Overdraft' },
    { type: 'InsurancePremium', name: 'Insurance Premium' },
    { type: 'Consumer', name: 'Consumer Asset Finance' },
    { type: 'CorporateLoans', name: 'Corporate Loan' }
  ];

  displayColumns = ['appId', 'appName', 'status', 'stage', 'actions'];

  @ViewChild('searchText') searchTextRef!: ElementRef<HTMLInputElement>;

  totalApplicationsUnderReview = 0;
  totalApplicationsInSettlement = 0;
  totalApplicationsClosedWon = 0;

  selectedApplicationType: ApplicationTypeFilter = DEFAULT_APPLICATION_TYPE_FILTER;
  selectedApplicationState: ApplicationState = 'all';
  search = '';
  filterOpportunityOwner = ''
  limit: number = DEFAULT_LIMIT;
  offset: number = DEFAULT_OFFSET;
  total: number = 0;
  sorts?: ApplicationSort = undefined;

  application!: Application;
  getGeoLocationFn!: GetGeoLocationFn;
  copyApplicationFn!: CopyApplicationFn;
  isInternalUser = isInternalUser;

  loggedInUser: User | null = getUser();
  ip: string = '';


  user: PortalLoginUser | null = null;
  store = createAsyncStore();
  errorTitle = 'Error Occurred!'
  errorMessage = 'Please try again.'

  promo ?: PromotionResponse

  retry() {
    this.reload();
  }

  dataSource = new InternalDataSource();

  constructor(private applicationService: ApplicationService,
    private portalHotToastService: PortalHotToastService,
    private localApplicationDbService: LocalApplicationsDbService,
    private adminService :AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,) {
      this.getGeoLocationFn = this.applicationService.getGeoLocationFn;
      this.copyApplicationFn = this.applicationService.copyApplicationFn;
  }

  private resetPagination() {
    this.offset = DEFAULT_OFFSET;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    // this.subscriptions.push(this.activatedRoute.queryParams.pipe(
    //   debounceTime(1000),
    //   distinctUntilChanged(compareMatch),
    //   tap(queryParams => {
    //     const forceReload = queryParams.reload === 'true';
    //     const limit = isNaN(_.toNumber(queryParams.limit)) ? DEFAULT_LIMIT : _.toNumber(queryParams.limit);
    //     const offset = isNaN(_.toNumber(queryParams.offset)) ? DEFAULT_OFFSET : _.toNumber(queryParams.offset);
    //     const search = queryParams.search ?? '';
    //     const applicationType: AllApplicationTypes =
    //       this.applicationTypes.map(t => t.type).includes(queryParams.applicationType) ?
    //         queryParams.applicationType : DEFAULT_APPLICATION_TYPE_FILTER.type;
    //     const applicationState: ApplicationState = this.applicationStates.includes(queryParams.applicationState) ?
    //       queryParams.applicationState : 'all';

    //     console.log('********** query', queryParams);
    //     this.selectedApplicationState = applicationState;
    //     this.selectedApplicationType = this.applicationTypes.find(t => t.type == applicationType) ?? DEFAULT_APPLICATION_TYPE_FILTER;
    //     this.searchTextRef.nativeElement.value = search;
    //     this.reload(forceReload, offset, limit, search, applicationType, applicationState);

    //   })
    // ).subscribe());
    this.reloadPromos();
    this.user = getUser();

    this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
        }
      })
    ).subscribe();
    // this.resetPagination();
    // this.reload(true, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts);
  }

  ngAfterViewInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const forceReload = queryParams.reload === 'true';
    const limit = isNaN(_.toNumber(queryParams.limit)) ? DEFAULT_LIMIT : _.toNumber(queryParams.limit);
    const offset = isNaN(_.toNumber(queryParams.offset)) ? DEFAULT_OFFSET : _.toNumber(queryParams.offset);
    const search = queryParams.search ?? '';
    const applicationType: AllApplicationTypes =
      this.applicationTypes.map(t => t.type).includes(queryParams.applicationType) ? queryParams.applicationType : DEFAULT_APPLICATION_TYPE_FILTER.type;
    const applicationState: ApplicationState = this.applicationStates.includes(queryParams.applicationState) ? queryParams.applicationState : 'all';
    setTimeout(()=>{
      this.searchTextRef.nativeElement.value = search;
      this.selectedApplicationState = applicationState;
      this.selectedApplicationType = this.applicationTypes.find(t => t.type == applicationType) ?? DEFAULT_APPLICATION_TYPE_FILTER;
      this.reload(forceReload, offset, limit, search, applicationType, applicationState);
      // this.reload(forceReload);
    });
  }

  refresh() {
    this.resetPagination();
    // WEB-4792: this.clearSearch();
    this.reload(true, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts,this.filterOpportunityOwner);
  }

  private reloadPromos() {
    this.subscriptions.push(this.adminService.getPromotionByType('application').pipe(
      tap(r => {
        this.promo = r.payload
      })
    ).subscribe());
  }


  getApplicantName(app: SimplifiedApplication) {
    const entityLegalName = (app as any)['CompanyName'];
    if (entityLegalName) {
      return entityLegalName;
    } else if (app.ApplicationType === 'Consumer') {
      const givenName = (app as any)['IndividualGivenName'];
      const surName = (app as any)['IndividualSurName'];
      if (givenName || surName) {
        return `${givenName ?? ''} ${surName ?? ''}`.trim();
      }
    }
    return '';
  }

  getApplicationStatus(app: SimplifiedApplication) {
    const stage = (app as any).StageName;
    const salesforceId = (app as any).SalesforceId;
    return this.applicationService.getApplicationStatus2(stage, salesforceId);
  }

  getApplicationStage(app: SimplifiedApplication) {
    const stage = (app as any).StageName;
    return this.applicationService.getApplicationStage2(stage);
  }


  getApplicationCreateTime(app: SimplifiedApplication) {
    return displayApplicationDateTime(moment(app.CreateTime));
  }

  reload(
      forceReload: boolean = false,
      offset: number = DEFAULT_OFFSET,
      limit: number = DEFAULT_LIMIT,
      search: string = '',
      applicationType: AllApplicationTypes = 'All',
      applicationState: 'all' | 'under-review' | 'in-settlement' | 'closed-won' = 'all',
      sorts?: ApplicationSort,
      filterOpportunityOwner:string = ''
  ) {
    this.store = createAsyncStore();
    this.subscriptions.push(this._applicationService().reload({
      forceReload,
      page: {offset, limit},
      filter: search,
      applicationState,
      applicationType,
      sorts,
      filterOpportunityOwner
    }).pipe(
      this.loader.tableLoading.track(),
      this.store.track(),
      this.portalHotToastService.topMenuLoadingObservable(),
      this.portalHotToastService.publishErrorNotificationObservable({
        errorTitle: this.errorTitle, errorMessage: this.errorMessage, retryFn: this.retry.bind(this),
      }),
      tap(r => {
        this.totalApplicationsUnderReview = r.totalApplicationsUnderReview;
        this.totalApplicationsInSettlement = r.totalApplicationsInSettlement;
        this.totalApplicationsClosedWon = r.totalApplicationsClosedWon;
        this.limit = r.limit;
        this.offset = r.offset;
        this.total = r.total;
        this.dataSource.update(r.applications);
      })
    ).subscribe());
  }

  onPagination($event: PageEvent) {
    this.limit = $event.pageSize;
    const offset = $event.pageIndex;
    this.reload(false, offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts,this.filterOpportunityOwner);
  }

  toClass(applicationStatus: string | null) {
    return applicationStatus ? applicationStatus.replace(/\s/g, '') : '';
  }

  onSearch($event: Event) {
     
    this.search = ($event.target as HTMLInputElement).value;
    this.selectedApplicationState = 'all';
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts,this.filterOpportunityOwner);
  }

  onFilterOpportunityOwnerSearch($event: Event) {

    this.filterOpportunityOwner = ($event.target as HTMLInputElement).value;
    this.selectedApplicationState = 'all';
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts,this.filterOpportunityOwner);
  }

  onSort($event: Sort) {
    if ($event.direction) {
      const dir = $event.direction === 'asc' ? 'ASC' : 'DESC';
      switch ($event.active) {
        case 'appId':
          this.sorts = [{ prop: 'BrokerAppId', dir }];
          break;
        case 'appName':
          this.sorts = [{ prop: 'CompanyName', dir }];
          break;
        case 'status':
          this.sorts = [{ prop: 'Status', dir }];
          break;
        case 'stage':
          this.sorts = [{ prop: 'AppInfoStageName', dir }];
          break;
      }
    } else {
      this.sorts = undefined;
    }
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts);
  }

  clearSearch() {
    this.search = '';
    if (this.searchTextRef && this.searchTextRef.nativeElement) {
      this.searchTextRef.nativeElement.value = '';
    }
  }

  async onApplicationClick($event: MouseEvent, application: SimplifiedApplication) {
    const status = this.getApplicationStatus(application);
    const applicationId = application.ApplicationId;
    const queryParams = {
      reload: false,
      limit: this.limit,
      offset: this.offset,
      search: this.search,
      applicationType: this.selectedApplicationType.type,
      applicationState: this.selectedApplicationState,
    };
    if (status === "Draft") {
      switch (application.ApplicationType) {
        case 'AssetFinance':
          await this.router.navigateByUrl(navigationUrlForAssetProductSelectorWithQueryParams(this.router, applicationId, queryParams));
          break;
        case 'BusinessLoans':
          await this.router.navigateByUrl(navigationUrlForBusinessFinanceWithQueryParams(this.router, applicationId, queryParams));
          break;
        case 'BusinessOverdraft':
          await this.router.navigateByUrl(navigationUrlForBusinessOverdraftWithQueryParams(this.router, applicationId, queryParams));
          break;
        case 'Commercial':
          await this.router.navigateByUrl(navigationUrlForCommercialFinanceWithQueryParams(this.router, applicationId, queryParams));
          break;
        case 'Consumer':
          if (getUser()!.priviledges.includes('lg' as any)) {
            await this.router.navigate(navigationUrlForConsumerFinance(applicationId));
          } else {
            await this.router.navigateByUrl(navigationUrlForConsumerAssetFinanceWithQueryParams(this.router, applicationId, queryParams));
          }
          break;
        case 'InsurancePremium':
          await this.router.navigateByUrl(navigationUrlForInsurancePremiumWithQueryParams(this.router, applicationId, queryParams));
          break;
        case 'CorporateLoans':
          await this.router.navigateByUrl(navigationUrlForCorporateLoanWithQueryParams(this.router, applicationId, queryParams));
          break;

      }
    } else {
      await this.router.navigateByUrl(
        navigationUrlForApplicationWithQueryParams(
          this.router,
          String(application.ApplicationId), 'app',
          queryParams,
        )
      )
    }
  }

  onUnderReviewApplicationStateFilter() {
    this.selectedApplicationState = this.selectedApplicationState === 'under-review' ? 'all' : 'under-review';
    // WEB-4792: this.clearSearch();
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts);
  }

  onInSettlementApplicationStateFilter() {
    this.selectedApplicationState = this.selectedApplicationState === 'in-settlement' ? 'all' : 'in-settlement';
    // WEB-4792: this.clearSearch();
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts);
  }

  onClosedWonApplicationStateFilter() {
    this.selectedApplicationState = this.selectedApplicationState === 'closed-won' ? 'all' : 'closed-won';
    // WEB-4792: this.clearSearch();
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts);
  }

  onApplicationTypeSelected(value: ApplicationTypeFilter) {
    this.selectedApplicationType = value;
    // WEB-4792: this.clearSearch();
    this.resetPagination();
    this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts);
  }

  showBrokerName(): boolean {
    return isInternalUser(this.user) || isAssociatedWithAggregator(this.user);
  }

  closePromo($event: MessageBoxComponentEvent) {
    $event.close();
  }

  _applicationService(): LocalApplicationsDbService {
    return this.localApplicationDbService;
  }

  onDeleteByAppId(applicationId: number) {
    this.subscriptions.push(this.applicationService.deleteApplicationByAppId(applicationId).pipe(
      this.portalHotToastService.spinnerObservable(),
      this.portalHotToastService.snackBarObservable('Application deleted'),
      tap(r=>{
        if (r.status) { // deleted successfully
          this._applicationService().removeApplicationLocally(applicationId);
          this.reload(false, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts,this.filterOpportunityOwner);
        }
        // this.reloadApplications()
      }),
    ).subscribe());
  }

  onDelete(applicationId: number) {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Please confirm",
        subMessage: "Do you want to delete this application?"
      }).afterClosed().subscribe((r: ConfirmationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          if (applicationId) {
            this.onDeleteByAppId(applicationId)
            this.reload()
          }
        }
      })
    )
  }

  onCopyApplication(ApplicationId: number) {
    this.subscriptions.push(this.applicationService.getApplicationById(ApplicationId).pipe(
      tap(r => {
        this.application=r.payload
      },
      catchError((err) => {
        console.log(`error resolving application with id ${ApplicationId}`, err);
        return of(null);
      })),
    ).subscribe());
    this.subscriptions.push(
      this.applicationDialogService.openCopyApplicationDialog({
        application: this.application
      }).afterClosed().subscribe(async (r: CopyApplicationDialogResult | undefined) => {
        if (r && r.readyForSubmission) {
          const applicationType = r.applicationType as ApplicationTypes;
          let geoLocation = {};
          try {
            geoLocation = await this.getGeoLocationFn();
          } catch (error) {
            console.log(error)
          }
          this.copyApplicationFn({
            newApplicationType: applicationType,
            application: this.application,
            user: this.loggedInUser as User,
            ip: this.ip,
            geoLocation: geoLocation
          }).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((r: SaveApplicationResult) => {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: `Application ${r.BrokerApplicationId} has been created for you.`
            }).afterClosed().subscribe(() => {
              this.resetPagination();
              this.clearSearch();
              this.reload(true, this.offset, this.limit, this.search, this.selectedApplicationType.type, this.selectedApplicationState, this.sorts,this.filterOpportunityOwner);
            })
          })
        }
      })
    )
  }

  async onClickAllApplication($event: Event){
    await this.router.navigate(navigationUrlForAllApplication());
  }

}
