<div class="insurance-premium-application-details">

  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <div class="separator-bottom row">
      <div class="col-lg-8 col-md-12 mb-1" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-flat-button class="mat-flat-button-custom w-lt-md-100">
          <application-type-icon applicationType="InsurancePremium" />
          Insurance Premium
          @if (application.Source == 'external') {
            <span class="ml-2 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
          }
          @if (application.Source == 'direct') {
            <span class="ml-2 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
          }
        </button>
      </div>
      <div class="col-lg-4 col-md-12 mb-1">
        <application-stage-icon [stage]="getApplicationStage(application)"></application-stage-icon>
      </div>
    </div>
  </div>
  @if (application.SystemCreditStatus) {
    <div class="row">
      <div class="col-12">
        <message-box type="warn">This application was processed through the newly implemented credit evaluation system</message-box>
      </div>
    </div>
  }
  <mat-tab-group
    [mat-stretch-tabs]="false"
    mat-align-tabs="start"
    [(selectedIndex)]="currentSectionIndex"
    (selectedTabChange)="onSelectedTabChange($event)">
    @for (currentTab of tabs; track currentTab) {
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi {{currentTab.iconClass}}"></span>
          {{currentTab.name}}
        </ng-template>
      </mat-tab>
    }
    <!--
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mdi mdi-view-grid-outline"></span>
        APPLICATION
      </ng-template>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <span class="mdi mdi-bank-outline"></span>
        BANK STATEMENTS
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mdi mdi-check-decagram-outline"></span>
        KYC/AML
      </ng-template>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <span class="mdi mdi-folder-outline"></span>
        DOCUMENTS
      </ng-template>
    </mat-tab>
    -->
  </mat-tab-group>
  <div>
    @if (currentSection === 'app') {
      <insurance-app
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        [withdrawApplicationFn]="withdrawApplicationFn"
        (events)="events.emit($event)"
        [data]="data"
        (navigateToApplications)="navigateToApplications.emit()"
        [application]="application">
      </insurance-app>
    }
    @if (currentSection === 'bank-statement') {
      <bank
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [application]="application"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn">
      </bank>
    }
    @if (currentSection === 'kyc-aml') {
      <kyc-verification
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [idVerifyUrl]="idVerifyUrl"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [application]="application">
      </kyc-verification>
    }
    @if (currentSection === 'credit') {
      <insurance-credit
        [application]="application"
        [runCreditFlowFn]="runCreditFlowFn"
        [getCreditWebServicesFn]="getCreditWebServicesFn"
        [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
        [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
        [updateCreditStatusFn]="updateCreditStatusFn"
        [getCreditAuditLogFn]="getCreditAuditLogFn"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
        [deleteWebServicesFn]="deleteWebServicesFn"
        [apiUrl]="apiUrl"
        [requestMoreInformationFn]="requestMoreInformationFn"
        [getUserFn]="getUserFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        (viewReport)="onViewReport($event)"
        (clickApplicationEvent)="onClick($event)"
        (refreshCreditTabEvent)="refreshCreditTab($event)"
        />
    }
    @if (currentSection === 'credit-report') {
      <credit-report
        [getWebServiceReportFn]="getWebServiceReportFn"
        [apiUrl]="apiUrl"
        (leaveCreditReport)="onLeaveReport($event)"
      ></credit-report>
    }
    @if (currentSection === 'documents') {
      <insurance-documents
        [apiUrl]="apiUrl"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
        [sendApplicationDocumentNotificationFn]="sendApplicationDocumentNotificationFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [getUserFn]="getUserFn"
        [application]="application"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [settleLoanFn]="settleLoanFn"
      ></insurance-documents>
    }
  </div>

</div>
