import moment from 'moment';
import {RatecardDetails, SearchCompanyByABNResult} from './domain/application-service.domain';
import {
  AssetFinanceTier1,
  AssetFinanceTier1Range,
  AssetFinanceTier2,
  AssetFinanceTier2Range,
  AssetFinanceTier3, AssetFinanceTier4,
} from './domain/admin-service.domain';
import {parseJSON} from './utils/json';
import {
  constants,
  isAssetCars,
  isAssetNewCars,
  isAssetOldCars, isAssetTypeAnyTruck,
  isAssetTypeCars,
  isAssetTypeLightOrHeavyTruck,
  isAssetTypeTruck,
  isAssetVehicles,
  TIER1_ASSET_CATS,
  TIER2_ASSET_CATS,
  TIER3_ASSET_CATS,
  TIER4_ASSET_CATS,
} from './const';
import _ from 'lodash';
import {Moment} from 'moment/moment';
import {PaymentFrequencyType, PaymentFrequencyValue} from './domain/component/payment-frequency-selection-component.domain';
import {AssetConditionType, AssetConditionValue} from './domain/component/asset-condition-component.domain';
import {AssetSelectionComponentValue} from './domain/component/asset-selection-component.domain';
import {LoanTermType, LoanTermValue} from './domain/component/loan-terms-selection-component.domain';
import {FinanceType, FinanceTypeValue} from './domain/component/finance-type-component.domain';
import {BalloonPaymentType, BalloonPaymentValue} from './domain/component/balloon-payment-component.domain';
import {BrokerageSelectionType, BrokerageSelectionValue} from './domain/component/brokerage-selection-component.domain';
import {BusinessSearchValue} from './domain/component/business-search-component.domain';
import {TotalPaymentBreakupDialogData} from './domain/component/total-payment-breakup-dialog.domain';
import numeral from 'numeral';
import {AssetCategoryTypeSelectionValue} from './domain/component/asset-category-type-selection-component.domain';
import {TransactionType, TransactionValue} from './domain/component/transaction-type-component.domain';
import {TruckGrossVehicleWeight, TruckGrossVehicleWeightSelection} from './domain/redbook-service.domain';
import {
  AppCalculatorAssetFinanceData,
  AppCalculatorBusinessLoanData,
  AppCalculatorBusinessOverdraftData,
  AppCalculatorConsumerAssetFinanceData,
  AppCalculatorCorporateLoanData,
  CalculateAssetFinanceEstmationResult,
  CalculateBusinessLoanEstimationResult,
  CalculateBusinessOverdraftEstimationResult,
  CalculateConsumerAssetFinanceEstimationResult, CalculateCorporateLoanEstmationResult
} from "./domain/app-calculator.domain";
import {SecurityTypeSelectionType} from "./domain/component/security-type-selection-component.domain";
import {LtvSelectionType} from "./domain/component/ltv-selection-component.domain";
import {normaliseFromMonthyAmount, normaliseToMonthlyAmount} from "./utils/utils";

export type RepaymentFrequencyType = 'Weekly' | 'Monthly' | 'Fortnightly';
export type RepaymentType = 'arrears' | 'advance';
export type MonthType = 'Jan'| 'Feb'| 'Mar'| 'Apr'| 'May'| 'Jun'| 'Jul'| 'Aug'| 'Sept'| 'Oct'| 'Nov'| 'Dec';

export type Operator = '<' | '>=' | '-' | '>' | '<=';
export const parseAssetFinanceTier = <T extends {Threshold: string, Operator: Operator}>(d: number, arr: T[]) => {
  const r = (arr ?? []).reduce((acc: T | null, curr: T | null) => {
    const threshold = curr!.Threshold;
    const operator = curr!.Operator;
    switch (operator) {
      case '-': {
        const t: number[] = threshold.split('-').map(_t => parseInt(_t, 10));
        if (d >= t[0] && d <= t[1]) {
          return curr;
        }
        break;
      }
      case '<=': {
        const t = parseInt(threshold, 10);
        if (d <= t) {
          return curr;
        }
        break;
      }
      case '<': {
        const t = parseInt(threshold, 10);
        if (d < t) {
          return curr;
        }
        break;
      }
      case '>=': {
        const t = parseInt(threshold, 10);
        if (d >= t) {
          return curr;
        }
        break;
      }
      case '>': {
        const t = parseInt(threshold, 10);
        if (d > t) {
          return curr;
        }
        break;
      }
      default:
        throw Error(`unexpected operator ${operator} when parsing Asset Finance Tiers`);
    }
    return acc;
  }, null);
  return r;
};

export class AppCalculator {

  isAssetVehicles = isAssetVehicles;
  isAssetCars = isAssetCars;
  isAssetNewCars = isAssetNewCars;
  isAssetOldCars = isAssetOldCars;
  isAssetTypeCars = isAssetTypeCars;

  isAssetTypeLightOrHeavyTruck = isAssetTypeLightOrHeavyTruck;

  repaymentFrequency: RepaymentFrequencyType = 'Fortnightly';
  repaymentFrequencyPeriod: 26 | 52 | 12 = 26;
  principalValue = 15000;
  interestValue = 5;
  baseRate = 3; // for consumer
  percentagePaidToDealerOrBroker = 1; // for consumer
  monthlyAccountKeepingFee = 10; // for consumer
  loanValue = 24;
  dateValue: Date = new Date(moment().add(1, 'days').format('MM/DD/YYYY'));
  acceptQuotation = false;
  residualPercent = 0;
  residualAmount = 0;
  brokeragePercent = 0;
  brokerageAmount = 0;
  brokerOriginationFee = 0;
  repaymentType: RepaymentType = 'advance';
  emi = 0;
  amountForComparisonRate = 15000; // for consumer;
  emiOnBaseRate = 0; // for consumer;
  emiOnBaseRateAmt = 0; // for consumer;
  pvOfDifferenceOfRepayments = 0; // for consumer;
  commission = 0; // for consumer;
  totalEarned = 0; // consumer
  totalEarnedInclGst = 0; // consumer
  amountToUs = 0; // for consumer;
  emiAmtPlusMonthlyAccountKeepingFee = 0; // for consumer
  princ = 0;
  tent = 0;
  dataUnits: DataUnitType[] = [];
  yearWiseData: YearWiseData[] = [];
  quarterlyData: MonthWiseData[] = [];
  totalInterest = 0;
  totalAmount = 0;
  totalPrincipal = 0;
  totalPrincipalYear = 0;
  totalInterestYear = 0;
  emiAmt = 0;
  interestAmt = 0;
  totalAmt = 0;
  principalAmt = 0;
  dateObj: Date = new Date();
  endBalance = 0;
  yearTotal = 0;
  monthNames: MonthType[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  inter = 0;
  quarterlyTotal = 0;
  displayedInterest = 0;
  comparisonRate = 0;

  constructor() {
    this.setInitValues();
  }

  setPrinicipalValue(value: any): any {
    this.principalValue = value;
  }

  setAmountForComparisonRate(value: any): any {
    this.amountForComparisonRate = value;
  }

  setInterestValue(value: any): any {
    this.interestValue = value;
  }

  setBaseRate(value: any): any {
    this.baseRate = value
  }

  setloanValue(value: any): any {
    this.loanValue = value;
  }

  setRepaymentFrequency(value: RepaymentFrequencyType): any {
    this.repaymentFrequency = value;
  }

  setAcceptQuotation(value: any) {
    this.acceptQuotation = value;
  }

  setResidualPercent(value: any) {
    this.residualPercent = value;
  }

  setRepaymentType(value: any) {
    this.repaymentType = value;
  }

  setResidualAmount(value: any) {
    this.residualAmount = value;
  }

  setBrokeragePercent(value: any) {
    this.brokeragePercent = value;
  }

  setBrokerageAmount(value: any) {
    this.brokerageAmount = value;
  }

  setDateValue(value: string) {
    this.dateValue = moment(value, 'DD/MM/YYYY').toDate();
  }

  setBrokerOriginationFee(value: any) {
    this.brokerOriginationFee = value;
  }

  setPercentagePaidToDealerOrBroker(value: number) {
    this.percentagePaidToDealerOrBroker = (_.round((value == undefined ? 100 : value) / 100, 2));
  }

  setMonthlyAccountKeepingFee(value: any) {
    this.monthlyAccountKeepingFee = value;
  }

  setRepaymentFrequencyPeriod() {
    if (this.repaymentFrequency == 'Fortnightly') {
      this.repaymentFrequencyPeriod = 26;
    } else if (this.repaymentFrequency == 'Weekly') {
      this.repaymentFrequencyPeriod = 52;
    } else if (this.repaymentFrequency == 'Monthly') {
      this.repaymentFrequencyPeriod = 12;
    }
  }

  public setInitValues(): void {
    this.setRepaymentFrequencyPeriod();
    this.emi = this.calculateEMI();
    this.princ = this.principalValue + this.brokerageAmount; // emi and all should be count based on this
    this.tent = this.loanValue / 12 * this.repaymentFrequencyPeriod;
    this.dataUnits = [];
    this.yearWiseData = [];
    this.dateObj = new Date(this.dateValue.getTime());
    this.totalInterest = 0;
    this.totalAmount = 0;
    this.totalPrincipal = 0;
    this.totalPrincipalYear = 0;
    this.totalInterestYear = 0;
    this.emiAmt = this.getCurrencyVal(this.tent ? Math.round(this.emi * 100) / 100 : 0);
    this.totalAmt = Math.round(((this.emiAmt * this.tent)) * 100) / 100 + this.residualAmount;
    this.interestAmt = this.getCurrencyVal(this.tent ? Math.abs(this.residualAmount) + Math.round(((this.emiAmt * this.tent) - this.princ) * 100) / 100 : 0);
    /* princ to include principal value + brokerage amount */
    this.principalAmt = this.principalValue; // this value is just displayed in UI, no use in calculations
    //this.displayedInterest = Math.round(this.RATE(this.tent, - this.emiAmt, this.principalAmt, - this.residualAmount, 1) * this.repaymentFrequencyPeriod * 10000) / 100;

    // for consumer
    this.emiOnBaseRate = this.calculateEMIOnBaseRate();
    this.emiOnBaseRateAmt = _.round(this.emiOnBaseRate, 2)
    this.pvOfDifferenceOfRepayments = _.round(- this.PV(
      this.baseRate / ((this.tent / this.loanValue) * 1200),
      this.tent,
      this.PMT(
        this.interestValue / ((this.tent / this.loanValue) * 1200),
        this.tent,
        - this.principalValue,
        0,
        this.repaymentType == 'arrears' ? 0 : 1,
        ),
        0,
        this.repaymentType == 'arrears' ? 0 : 1,
      ) - this.principalValue, 2);
    this.commission = _.round(this.pvOfDifferenceOfRepayments * this.percentagePaidToDealerOrBroker, 2);
    console.log('this.pvOfDifferenceOfRepayments: ',this.pvOfDifferenceOfRepayments)
    console.log('this.percentagePaidToDealerOrBroker: ', this.percentagePaidToDealerOrBroker)
    this.totalEarned = _.round(this.commission + this.brokerOriginationFee, 2);
    this.totalEarnedInclGst = _.round(this.totalEarned * 1.1, 2);
    this.amountToUs = _.round(this.pvOfDifferenceOfRepayments - this.commission, 2);
    this.displayedInterest = this.interestValue;
    this.comparisonRate = _.round((this.RATE(this.tent, - this.emiAmt - this.monthlyAccountKeepingFee, this.amountForComparisonRate, - this.residualAmount, 1)) * (this.tent / this.loanValue) * 1200, 2);
    this.emiAmtPlusMonthlyAccountKeepingFee = _.round(this.emiAmt + this.getAccountKeepingFeeBasedOnPaymentFrequency(), 2);
  }

  getAccountKeepingFeeBasedOnPaymentFrequency() {
    // if (this.repaymentFrequency == 'Fortnightly') {
    //   return this.monthlyAccountKeepingFee / 2;
    // } else if (this.repaymentFrequency == 'Weekly') {
    //   return this.monthlyAccountKeepingFee / 4;
    // } else {
    //   return this.monthlyAccountKeepingFee;
    // }
    const monthlyAccountKeepingFee = normaliseFromMonthyAmount(this.monthlyAccountKeepingFee, this.repaymentFrequency);
    return monthlyAccountKeepingFee;
  }


  /* Ballon payment restriction -> max settings
    * User only eligible for RV when
    * EOT of asset < 10 years
    * asset is Car or Trucks
    * loan terms in month
    * loan terms : max RV
    * 5 years    : 30
    * 4 years    : 35
    * <= 3 years : 40
  */
  getMaxRV(assetCat: string, loanterms: number, assetYear: number, assetType: string = ''): number {
    const eot = (moment().year() - assetYear - 1) + (loanterms / 12);
    let isAssetEligibleforRV = false;
    let maxRV = 0;

    // consumer asset
    if (parseInt(assetCat) === 139) {
      if (!(eot > 10 || loanterms / 12 > 5 || parseInt(assetType) === 4)) {
        isAssetEligibleforRV = true;
      }
      if (isAssetEligibleforRV) {
        if (loanterms / 12 > 4) {
          maxRV = 35;
        } else if (loanterms / 12 > 3) {
          maxRV = 45;
        } else {
          maxRV = 50;
        }
      }
    } else { // other asset
      if (eot <= 10) {
        if ([130, 132, 137, 140].includes(parseInt(assetCat))) {
          isAssetEligibleforRV = true;
        }
      }
      if (isAssetEligibleforRV) {
        if ((loanterms / 12) == 2) {
          maxRV = Math.ceil((- (5 / 12) * loanterms + 60) / 5) * 5;
        } else {
          maxRV = Math.ceil((- (5 / 12) * loanterms + 55) / 5) * 5;
        }
      }
    }

    console.log(`*** maxRv for eligibleForRv ${isAssetEligibleforRV} eot ${eot} assetCat ${assetCat}, loanTerms ${loanterms}, assetYear ${assetYear} = ${maxRV}`);
    return maxRV;
  }


  public calculateEMI(): number {
    const interestValue: number = this.getInterest();
    const tent: number = this.loanValue / 12 * this.repaymentFrequencyPeriod;
    let frate: any = 0;
    let nper: any;
    const pv: any = this.principalValue + this.brokerageAmount;
    const fv: number = -this.residualAmount;
    const type: any = this.repaymentType == 'arrears' ? 0 : 1;
    nper = this.repaymentFrequencyPeriod * (this.loanValue / 12);
    frate = parseFloat((this.interestValue / (this.repaymentFrequencyPeriod * 100)).toString());
    // console.log(this.interestValue, frate, nper, pv, fv, type);
    let totalRepayment = this.PMT(frate, nper, pv, fv, type);
    totalRepayment = -totalRepayment.toFixed(2);
    return totalRepayment;
  }

  public calculateEMIOnBaseRate(): number {
    let frate: any = 0;
    let nper: any;
    const pv: any = this.principalValue + this.brokerageAmount;
    const fv: number = -this.residualAmount;
    const type: any = this.repaymentType == 'arrears' ? 0 : 1;
    nper = this.repaymentFrequencyPeriod * (this.loanValue / 12);
    frate = parseFloat((this.baseRate / (this.repaymentFrequencyPeriod * 100)).toString());
    // console.log(this.interestValue, frate, nper, pv, fv, type);
    let totalRepayment = this.PMT(frate, nper, pv, fv, type);
    totalRepayment = -totalRepayment.toFixed(2);
    return totalRepayment;
  }

  /*
    Count Rate given period payment
    nper: total no of repayments 2(Years) * Based on frequency period( weekly - 52, fortnightly-26, monthly-12)
    pmt: period payment
    pv: present value (loan amount + docfee + brokerage amount)
    fv: -(residual amount Or ballon payment)
    type: 0 for Business Term Loan, 1 for others
  */
  RATE(nper: number, pmt: number, pv: number, fv: number, type: number, guess: number = 0.01) {
    // Sets default values for missing parameters
    fv = typeof fv !== 'undefined' ? fv : 0;
    type = typeof type !== 'undefined' ? type : 0;
    guess = typeof guess !== 'undefined' ? guess : 0.1;
    // Sets the limits for possible guesses to any
    // number between 0% and 100%
    let lowLimit = 0;
    let highLimit = 1;
    // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
    // the solution as valid.
    let tolerance = Math.abs(0.00000005 * pmt);
    // Tries at most 40 times to find a solution within the tolerance.
    for (let i = 0; i < 40; i++) {
      // Resets the balance to the original pv.
      let balance = pv;
      // Calculates the balance at the end of the loan, based
      // on loan conditions.
      for (let j = 0; j < nper; j++ ) {
        if (type == 0) {
          // Interests applied before payment
          balance = balance * (1 + guess) + pmt;
        } else {
          // Payments applied before insterests
          balance = (balance + pmt) * (1 + guess);
        }
      }
      // Returns the guess if balance is within tolerance.  If not, adjusts
      // the limits and starts with a new guess.
      if (Math.abs(balance + fv) < tolerance) {
        return guess;
      } else if (balance + fv > 0) {
        // Sets a new highLimit knowing that
        // the current guess was too big.
        highLimit = guess;
      } else  {
        // Sets a new lowLimit knowing that
        // the current guess was too small.
        lowLimit = guess;
      }
      // Calculates the new guess.
      guess = (highLimit + lowLimit) / 2;
    }
    // Returns null if no acceptable result was found after 40 tries.
    return 0;
  }

  /*
      Calculations reference : https://github.com/sutoiku/formula.js/blob/master/lib/financial.js

      rate: (interest / 100) * Based on frequency period( weekly - 52, fortnightly-26, monthly-12)
      nper: total no of repayments 2(Years) * Based on frequency period( weekly - 52, fortnightly-26, monthly-12)
      pv: present value (loan amount + docfee + brokerage amount)
      fv: -(residual amount Or ballon payment)
      type: 0 for Business Term Loan, 1 for others
  */

  PMT(rate: number, nper: number, pv: number, fv: number, type: 0 | 1) {
    if (!fv) { fv = 0; }
    if (!type) { type = 0; }
    let pmt = 0;
    if (rate == 0) { return -(pv + fv) / nper; }

    const pvif = Math.pow(1 + rate, nper);
    pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type == 1) {
      pmt /= (1 + rate);
    }

    return pmt;
  }


  /*
      count interest given on particular payment
      period: no(index) of repayment to calculate interest for
      periods: total no.of periods
      present: pv
      future: rv
  */
  IPMT(rate: number, period: number, periods: number, present: number, future: number, type: 0 | 1) {
    // Credits: algorithm inspired by Apache OpenOffice
    future = future || 0;
    type = type || 0;
    // Compute payment
    const payment = this.PMT(rate, periods, present, future, type);

    // Compute interest
    let interest;
    if (period === 1) {
      if (type === 1) {
        interest = 0;
      } else {
        interest = -present;
      }
    } else {
      if (type === 1) {
        interest = this.FV(rate, period - 2, payment, present, 1) - payment;
      } else {
        interest = this.FV(rate, period - 1, payment, present, 0);
      }
    }

    // Return interest
    return interest * rate;
  }


  PPMT(rate: number, period: number, periods: number, present: number, future: number, type: 0 | 1) {
    future = future || 0;
    type = type || 0;
    return this.PMT(rate, periods, present, future, type) - this.IPMT(rate, period, periods, present, future, type);
  }

  FV(rate: number, periods: number, payment: number, value: number, type: number) {
    value = value || 0;
    type = type || 0;

    // Return future value
    let result;
    if (rate === 0) {
      result = value + payment * periods;
    } else {
      const term = Math.pow(1 + rate, periods);
      if (type === 1) {
        result = value * term + payment * (1 + rate) * (term - 1) / rate;
      } else {
        result = value * term + payment * (term - 1) / rate;
      }
    }
    return -result;
  }

  PV(rate: number, num: number, amount: number, remain: number, type: 0 | 1) {
    let factor;

    if (type == 0) {
      factor = (rate == 0 ? num : (1 - Math.pow(1+rate, -num))/rate);
    } else {
      factor = (rate == 0 ? num : (1 - Math.pow(1+rate, -num))*(1+rate)/rate);
    }

    return -amount*factor - remain * Math.pow(1+rate, -num);
  }


  public refreshUI(): void {
    this.setInitValues();
    this.calRangeValues();
    // this.calculateQuarterlyData();
  }

  // public refreshUI1(): void {
  //   this.setInitValues();
  //   // let interestPercent: number = Math.round(((this.emi * this.tent) - this.princ) * 100) / 100 /
  //   //   Math.round((this.emi * this.tent) * 100) / 100 * 100;
  //   // interestPercent = Math.round(interestPercent * 100) / 100;
  // }

  public calRangeValues(): void {
    const previousYear = null;
    const currentYear = null;
    const yearBasedData: any = {};
    let frate: any = 0;
    let nper: any;
    const pv: any = this.principalValue + this.brokerageAmount;
    const fv: number = -this.residualAmount;
    const type: any = this.repaymentType == 'arrears' ? 0 : 1;
    let princ: any = pv;
    nper = this.repaymentFrequencyPeriod * (this.loanValue / 12);
    frate = parseFloat((this.interestValue / (this.repaymentFrequencyPeriod * 100)).toString());
    for (let i = 0; i < this.tent; i++) {
      const ppmt = -this.PPMT(frate, i + 1, nper, pv, fv, type);
      this.inter = this.emi - ppmt;
      this.totalInterest += this.inter;
      this.totalAmount += this.emi;
      this.totalPrincipal += ppmt;
      this.endBalance = princ - ppmt;
      this.yearTotal += this.emi;
      this.totalPrincipalYear += ppmt;
      this.totalInterestYear += this.inter;
      this.quarterlyTotal += this.emi;
      const currentMonthNumber = this.dateObj.getMonth() + 1;
      const dataUnit = {
        month: this.monthNames[this.dateObj.getMonth()],
        index: (i + 1),
        totalInterest: Math.round(this.totalInterest * 100) / 100,
        totalAmount: this.totalAmount,
        // emi: (i == this.tent-1) ? (Math.round((this.emi+this.residualAmount)*100)/100) : (Math.round(this.emi*100)/100),
        emi: Math.round(this.emi * 100) / 100,
        year: this.dateObj.getFullYear(),
        beginningBalance: Math.round(princ * 100) / 100,
        interest: Math.round(this.inter * 100) / 100,
        pricipalPaid: Math.round(ppmt * 100) / 100,
        endingBalance: Math.round(this.endBalance * 100) / 100,
        date: this.dateObj.getFullYear() + '-' + currentMonthNumber + '-' + this.dateObj.getDate()
      };
      this.dataUnits.push(dataUnit);
      if (yearBasedData[this.dateObj.getFullYear()] == undefined) {
        yearBasedData[this.dateObj.getFullYear()] = [];
      }
      yearBasedData[this.dateObj.getFullYear()].push(dataUnit);

      princ = this.endBalance;
      if (i < this.tent - 1) {
        if (this.repaymentFrequency == 'Weekly') {
          this.dateObj.setDate(this.dateObj.getDate() + 7);
        } else if (this.repaymentFrequency == 'Monthly') {
          this.dateObj.setMonth(this.dateObj.getMonth() + 1);
        } else {
          this.dateObj.setDate(this.dateObj.getDate() + 2 * 7);
        }
      }
    }

    /* Add last payment for residual value (Balloon Payment) */
    /*
        rate: same as other cal
        nper: 1 - fixed
        per: 1 - fixed
        pv: last end balance got at the end of payments
        type: will always default to arrears when counting residual last payment
    */
    if (this.residualAmount > 0) {
      const endBalance = this.dataUnits[this.dataUnits.length - 1].endingBalance;

      const lastIPMT = -this.IPMT(frate, 1, 1, endBalance, 0, 0);
      const lastRepayment = -fv;
      const lastPPMT = lastRepayment - lastIPMT;
      if (this.repaymentFrequency == 'Weekly') {
        this.dateObj.setDate(this.dateObj.getDate() + 7);
      } else if (this.repaymentFrequency == 'Monthly') {
        this.dateObj.setMonth(this.dateObj.getMonth() + 1);
      } else {
        this.dateObj.setDate(this.dateObj.getDate() + 2 * 7);
      }
      const lastMonthNumber = this.dateObj.getMonth() + 1;
      const lastDataUnit = {
        month: this.monthNames[this.dateObj.getMonth() + 1],
        index: this.tent,
        totalInterest: Math.round((this.totalInterest + lastIPMT) * 100) / 100,
        totalAmount: this.totalAmount,
        emi: lastRepayment,
        year: this.dateObj.getFullYear(),
        beginningBalance: Math.round(princ * 100) / 100,
        interest: Math.round(lastIPMT * 100) / 100,
        pricipalPaid: Math.round(lastPPMT * 100) / 100,
        endingBalance: Math.round((endBalance - lastPPMT) * 100) / 100,
        date: this.dateObj.getFullYear() + '-' + lastMonthNumber + '-' + this.dateObj.getDate()
      };
      this.dataUnits.push(lastDataUnit);
      if (yearBasedData[this.dateObj.getFullYear()] == undefined) {
        yearBasedData[this.dateObj.getFullYear()] = [];
      }
      yearBasedData[this.dateObj.getFullYear()].push(lastDataUnit);
    }
    /* Add last payment for residual value (Balloon Payment) */

    /* prepare yearwise data */
    let beginBalance = 0;
    const yprinc = this.principalValue + this.brokerageAmount;
    let totalInterest = 0;
    let totalPrincipal = 0;
    let totalAmount = 0;
    let endingBalance = 0;
    let yearTotal = 0;
    let yearN: any = null;
    let year: any = null;
    let totalPrincipalYear = 0;
    let totalInterestYear = 0;
    for (const property in yearBasedData) {
      if (yearBasedData.hasOwnProperty(property)) {
        const yearData = yearBasedData[property];
        beginBalance = yearData[0].beginningBalance;
        for (let monthC = 0; monthC <= yearData.length - 1; monthC++) {
          if (monthC == 0) {
            beginBalance = yearData[monthC].beginningBalance;
          }
          totalPrincipal += Math.round((yearData[monthC].emi - yearData[monthC].interest) * 100) / 100;
          princ = yearData[monthC].endingBalance;
          totalInterest = yearData[monthC].totalInterest;
          totalAmount = yearData[monthC].totalAmount;
          yearTotal += yearData[monthC].emi;
          endingBalance = yearData[monthC].endingBalance;
          yearN = new Date(yearData[monthC].year, 0, 1),
            year = yearData[monthC].year;
          totalPrincipalYear += Math.round((yearData[monthC].emi - yearData[monthC].interest) * 100) / 100;
          totalInterestYear += yearData[monthC].interest;
        }
        this.yearWiseData.push({
          beginningBalance: beginBalance,
          totalInterest: Math.round(this.totalInterest * 100) / 100,
          totalPrincipal: totalPrincipal,
          totalAmount: Math.round(totalAmount * 100) / 100,
          yearTotal: Math.round(yearTotal * 100) / 100,
          endingBalance: Math.round(endingBalance * 100) / 100,
          yearN: yearN,
          year: year,
          yearPrincipal: totalPrincipalYear,
          yearInterest: totalInterestYear
        });
        yearTotal = 0;
        totalPrincipalYear = 0;
        totalInterestYear = 0;
      }
    }
  }


  /*
      prepare quarterly data based on dataUnits
      sum of prinicipalValue/interest/repayment etc of 3 months
  */
  public calculateQuarterlyData() {
    /* manipulate quarterly data */
    this.quarterlyData = [];
    let beginBalance = 0;
    let princ: number = this.principalValue + this.brokerageAmount;
    let totalInterest = 0;
    let totalPrincipal = 0;
    let totalAmount = 0;
    let endingBalance = 0;
    let yearTotal = 0;
    let yearN: any = null;
    let year: any = null;
    let totalPrincipalYear = 0;
    let totalInterestYear = 0;
    const quarterCount = 1;
    for (let monthC = 0; monthC <= this.dataUnits.length - 1; monthC++) {
      let prevQuarterOfTheYear: any = null;
      if (monthC != 0) {
        prevQuarterOfTheYear = this.quarterOfTheYear(new Date(this.dataUnits[monthC - 1].date));
      } else {
        prevQuarterOfTheYear = this.quarterOfTheYear(new Date(this.dataUnits[monthC].date));
      }
      const quarterOfTheYear = this.quarterOfTheYear(new Date(this.dataUnits[monthC].date));

      const nextQuarterOfTheYear = (monthC != this.dataUnits.length - 1) ? this.quarterOfTheYear(new Date(this.dataUnits[monthC + 1].date)) : quarterOfTheYear;

      if (monthC == 0 || quarterOfTheYear > prevQuarterOfTheYear) {
        beginBalance = this.dataUnits[monthC].beginningBalance;
      }
      totalPrincipal += Math.round((this.dataUnits[monthC].emi - this.dataUnits[monthC].interest) * 100) / 100;

      totalInterest = this.dataUnits[monthC].totalInterest;
      totalAmount = this.dataUnits[monthC].totalAmount;
      yearTotal += this.dataUnits[monthC].emi;
      endingBalance = this.dataUnits[monthC].endingBalance;
      yearN = new Date(this.dataUnits[monthC].year, 0, 1),
        year = this.dataUnits[monthC].year;
      totalPrincipalYear += Math.round((this.dataUnits[monthC].emi - this.dataUnits[monthC].interest) * 100) / 100;
      totalInterestYear += this.dataUnits[monthC].interest;

      if (quarterOfTheYear != nextQuarterOfTheYear || monthC == this.dataUnits.length - 1) {
        this.quarterlyData.push({
          month: this.dataUnits[monthC].month,
          beginningBalance: beginBalance,
          totalInterest: Math.round(this.totalInterest * 100) / 100,
          totalPrincipal: totalPrincipal,
          totalAmount: Math.round(totalAmount * 100) / 100,
          yearTotal: Math.round(yearTotal * 100) / 100,
          endingBalance: Math.round(endingBalance * 100) / 100,
          yearN: yearN,
          year: year,
          yearPrincipal: totalPrincipalYear,
          yearInterest: totalInterestYear,
          quarter: quarterOfTheYear
        });
        yearTotal = 0;
        totalPrincipalYear = 0;
        totalInterestYear = 0;
      }
      princ = this.dataUnits[monthC].endingBalance;
    }
  }


  calculateTableRepayment(rate: number, amountFinance: number,
                          repaymentFrequency: RepaymentFrequencyType,
                          brokerageAmount = 0,
                          residualAmount: number,
                          repaymentType: RepaymentType):{amountFinance: number, data: {repayment: number, loanTerm: number}[]}[] {
    let amountFinanced = []
    let loanTerms = [24, 36, 48, 60]
    let resultObject: any[] = []
    let repaymentFrequencyPeriod = 12;
    if (repaymentFrequency == 'Fortnightly') {
      repaymentFrequencyPeriod = 26;
    } else if (repaymentFrequency == 'Weekly') {
      repaymentFrequencyPeriod = 52;
    } else if (repaymentFrequency == 'Monthly') {
      repaymentFrequencyPeriod = 12;
    }
    let frate: any = 0;
    let nper: any;
    let roundpv = Math.ceil(amountFinance * 0.1 / 1000) * 1000
    amountFinance=Math.ceil(amountFinance/1000)*1000
    if (amountFinance <= roundpv) {
      for (let i = 0; i <= 4; i++) {
        let financeAmount = amountFinance
        amountFinanced.push(financeAmount)
        amountFinance = amountFinance + roundpv
      }
    } else {
      for (let i = 0; i <= 4; i++) {
        let amtFinance
        if (i < 2) {
          if (i === 0) {
            amtFinance = amountFinance - (roundpv + roundpv)
          }
          else {
            amtFinance = amountFinance - roundpv
          }
        } if (i === 2) {
          amtFinance = amountFinance
        } if (i > 2) {
          if (i === 3) {
            amtFinance = amountFinance + roundpv
          }
          else {
            amtFinance = amountFinance + (roundpv + roundpv)
          }
        }
        if (amtFinance !== undefined) {
          amountFinanced.push(amtFinance)
        }
      }
    }
    const fv: number = -residualAmount;
    const type: any = repaymentType == 'arrears' ? 0 : 1;
    amountFinanced.forEach(e => {
      let data: any[] = []
      loanTerms.forEach(element => {
        let obj = { repayment: 0, loanTerm: 0 }
        nper = repaymentFrequencyPeriod * (element / 12);
        frate = parseFloat((rate / (repaymentFrequencyPeriod * 100)).toString());
        let totalRepayment = this.PMT(frate, nper, e+brokerageAmount, fv, type);
        totalRepayment = -totalRepayment.toFixed(2);
        obj.loanTerm = element
        obj.repayment = totalRepayment
        data.push(obj)
      })
      resultObject.push({ amountFinance: e, data: data })
    });
    return resultObject
  }

  public getInterest(): number {
    return this.interestValue ? parseFloat('' + this.interestValue / this.repaymentFrequencyPeriod / 100) : 0;
  }

  public getCurrencyVal(value: number): number {
    return value;
  }

  isFloat(n: number) {
    return Number(n) === n && n % 1 !== 0;
  }

  /*
      Return quarter of the given date(Date javascript)
      Q1 Jan - March
      Q2 Apr - Jun
      Q3 July- Sept
      Q4 Oct - Dec
  */
  public quarterOfTheYear(date: Date) {
    const month = date.getMonth() + 1;
    return (Math.ceil(month / 3));
  }


  /*
      Calculate and return repayment based on params given
  */
  getRepayment(rate: number, loanTerms: number, principalValue: number, repaymentFrequency: RepaymentFrequencyType,
               brokerageAmount = 0, residualAmount = 0, repaymentType: RepaymentType = 'advance') {
    residualAmount = isNaN(residualAmount) == false ? residualAmount : 0;
    brokerageAmount = isNaN(brokerageAmount) == false ? brokerageAmount : 0;
    principalValue = isNaN(principalValue) == false ? principalValue : 0;
    let repaymentFrequencyPeriod = 12;
    if (repaymentFrequency == 'Fortnightly') {
      repaymentFrequencyPeriod = 26;
    } else if (repaymentFrequency == 'Weekly') {
      repaymentFrequencyPeriod = 52;
    } else if (repaymentFrequency == 'Monthly') {
      repaymentFrequencyPeriod = 12;
    }
    const tent: number = loanTerms / 12 * repaymentFrequencyPeriod;
    let frate: any = 0;
    let nper: any;
    const pv: any = principalValue + brokerageAmount;
    const fv: number = -residualAmount;
    const type: any = repaymentType == 'arrears' ? 0 : 1;
    nper = repaymentFrequencyPeriod * (loanTerms / 12);
    frate = parseFloat((rate / (repaymentFrequencyPeriod * 100)).toString());
    console.log(frate, nper, pv, fv, type);
    let totalRepayment = this.PMT(frate, nper, pv, fv, type);
        totalRepayment = -totalRepayment.toFixed(2);
    return totalRepayment;
  }


  getApplicableInterestRate(comparison: any, value: any) {
    let rate = 0;
    if (comparison && comparison.length > 0) {
      for (let i = 0; i <= comparison.length - 1; i++) {
        if (comparison[i].To === undefined && comparison[i].From === undefined) {
          rate = parseFloat(comparison[i].Rate);
        } else if (comparison[i].To === 'max') {
          rate = parseFloat(comparison[i].Rate);
        } else if (comparison[i].From === undefined && comparison[i].To !== undefined) {
          if (value <= parseFloat(comparison[i].To)) {
            rate = parseFloat(comparison[i].Rate);
          }
        } else if (comparison[i].To === undefined && comparison[i].From !== undefined) {
          if (value > parseFloat(comparison[i].From)) {
            rate = parseFloat(comparison[i].Rate);
          }
        } else {
          if (value <= parseFloat(comparison[i].To) && value > parseFloat(comparison[i].From)) {
            rate = parseFloat(comparison[i].Rate);
          }
        }
      }
    }
    return _.round(rate, 2);
  }




  calculateResidualValue(prinicipalAmount: number, balloonPaymentPercent: number) {
    const repayment = prinicipalAmount;
    let rv: any = 0;
    rv = Math.round((repayment * balloonPaymentPercent) / 100 * 100) / 100;
    return rv;
  }



  getTotalInterestRateForInsurancePremiumFinance(rateCard: RatecardDetails, form: {totalPremiumAmount: number}): TermRateForInsurancePremium {
    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Insurance Premium interest rate calculation`, rate: undefined});

    const rateCardBaseRate= rateCard.BaseInterestRate;
    let baseRate = rateCardBaseRate;
    const loanAmount = form.totalPremiumAmount;
    calculationLog.push({log: `BaseRate`, rate: rateCardBaseRate});

    //rate changes based on Loan Amount
    const loanAmountRate = this.getApplicableInterestRate(rateCard.LoanAmount, loanAmount);
    baseRate += (loanAmountRate != null) ? loanAmountRate : 0;
    calculationLog.push({log: `LoanAmount ${loanAmount} Rate `, rate: loanAmountRate});


    //rate changes based on doc fee
    let prinicipalAmount= loanAmount;
    let docfee = this.getApplicableInterestRate(rateCard.DocFee, loanAmount);
    docfee = (docfee != null) ? parseFloat(docfee as any) : 0;
    prinicipalAmount += docfee;
    calculationLog.push({log:`Doc Fee ${docfee} added to principalAmount ${loanAmount}`});

    const totalInterest = _.round(baseRate, 2);
    calculationLog.push({log: `Total`, rate: totalInterest});

    console.table(calculationLog);

    return {
      brokerage: 0,
      basicInterest: rateCard.BaseInterestRate,
      brokerageAmount: 0,
      docFee: docfee,
      totalInterest,
      RV: 0,
      LVR: 0,
      prinicipalAmount: prinicipalAmount,
      brokerOriginationFee: 0,
      calculationLog,
    }
  }


  getTotalInterestRateForBusinessOverdraft(rateCard: RatecardDetails, form: FormDataForBusinessOverdraft): TermRateForBusinessOverdraft {
    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Corporate Loan interest rate calculation`, rate: undefined});

    const brokerageAmountSf = form.brokerageAmount;
    //const docFee: number = docFeeSf != undefined ? docFeeSf : this.getApplicableInterestRate(rateCard.DocFee, form.facilityLimit);
    const facilityLimit = form.facilityLimit;
    const facilityEstablishmentFeePercent = form.facilityEstablishmentFeePercent;
    const faciltyEstablishmentFee = this.calculateFaciltyEstablishmentFee(facilityEstablishmentFeePercent, form.facilityLimit);
    const docFee: number = form.docFee != undefined ? form.docFee : this.getApplicableInterestRate(rateCard.DocFee, form.facilityLimit);
    const rateCardFacilityFeePercentage = rateCard.FacilityFee ?? 0;
    // const monthlyFacilityFee = ((form.facilityEstablishmentFeePercent / 100) * facilityLimit) / 12
    const monthlyFacilityFee = ((rateCardFacilityFeePercentage / 100) * facilityLimit) / 12
    const brokerage = (0.02+(rateCard.BrokerShareFacilityFee / 100) * (facilityEstablishmentFeePercent / 100))*100;
    const brokerageDollar  = (brokerage/100) * facilityLimit;
    const availableAmount: any = facilityLimit - faciltyEstablishmentFee - docFee
    const scoreBetween500And600 = form.directorScore;
    const scoreAbove600 = form.lowEquifaxScore;
    let interestRate: any = 0
    if (form.propertyOwner && scoreAbove600) {
      const rate = rateCard.PropertyOwner ?? 0;
      interestRate = rate;
      calculationLog.push({log: `PropertyOwner + DirectoryScoreAbove600`, rate});
    } else if (!form.propertyOwner && scoreAbove600) {
      const rate = rateCard.NoPropertyOwner ?? 0;
      interestRate = rate;
      calculationLog.push({log: `NotPropertyOwner + DirectoryScoreAbove600`, rate});
    } else if (form.propertyOwner && !scoreAbove600 && scoreBetween500And600) {
      const rate = rateCard.DirectorScoreRate ?? 0;
      interestRate = rate;
      calculationLog.push({log: `PropertyOwner + DirectoryScoreBetween500And600 + DirectorScoreNotAbove600`, rate});
    } else {
      const rate = rateCard.NonPropertyOwnerBetween500And600Rate ?? 0;
      interestRate = rate;
      calculationLog.push({log: `NonPropertyOwner + DirectoryScoreBetween500And600`, rate});
    }
    const rbaRate = rateCard.rbaRate;
    interestRate += rbaRate;
    calculationLog.push({log: `RbaRate`, rate: rbaRate});


    // removing this as now the portal rate will be taken into consideration
    // interestRate = rateSf != undefined ? rateSf : interestRate;

    // add credit rate adjustment if applicable
    if(form.creditRateAdjustment){
      const creditAdjustment = ((!_.isNil(form.creditRateAdjustment)) ? form.creditRateAdjustment : 0);
      interestRate += creditAdjustment;
      calculationLog.push({log: `CreditRateAdjustment`, rate: creditAdjustment});
    }
    calculationLog.push({log: `Total`, rate: interestRate});
    console.table(calculationLog);


    const termRate: TermRateForBusinessOverdraft = {
      facilitylimit: facilityLimit,
      facilityEstablishmentFee: parseFloat(faciltyEstablishmentFee.toFixed(2)),
      monthlyFacilityFee: parseFloat(monthlyFacilityFee.toFixed(2)),
      docFee: docFee,
      brokerageDollar: brokerageAmountSf != undefined ? brokerageAmountSf : parseFloat(brokerageDollar.toFixed(2)),
      brokerage: parseFloat(brokerage.toFixed(2)),
      amountAvailable: availableAmount,
      interestRate: _.round(interestRate, 2),
      rbaRate: rateCard.rbaRate,
      margin: form.margin ?? _.round(interestRate, 2) - rateCard.rbaRate,
      monthlyFacilityFeePercentage: rateCardFacilityFeePercentage,
      facilityEstablishmentFeePercentage: Math.round(10000 * parseFloat(faciltyEstablishmentFee.toFixed(2)) / facilityLimit) / 100,
      calculationLog,
    }
    return termRate;
  }


  getTotalInterestRateForCorporateLoan(rateCard: RatecardDetails, form: FormDataForCorporateLoan): TermRateForCorporateLoan {
    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Corporate Loan interest rate calculation`, rate: undefined});
    const brokerageAmountSf = form.brokerageAmount;
    //const docFee: number = docFeeSf != undefined ? docFeeSf : this.getApplicableInterestRate(rateCard.DocFee, form.facilityLimit);
    const facilityLimit = form.facilityLimit;
    const facilityEstablishmentFeePercent = form.facilityEstablishmentFeePercent;
    const faciltyEstablishmentFee = this.calculateFaciltyEstablishmentFee(facilityEstablishmentFeePercent, form.facilityLimit);
    const docFee: number = form.docFee != undefined ? form.docFee : this.getApplicableInterestRate(rateCard.DocFee, form.facilityLimit);
    const rateCardFacilityFeePercentage = rateCard.FacilityFee ?? 0;
    // const monthlyFacilityFee = ((form.facilityEstablishmentFeePercent / 100) * facilityLimit) / 12
    const monthlyFacilityFee = ((rateCardFacilityFeePercentage / 100) * facilityLimit) / 12
    const brokerage = (0.02+(rateCard.BrokerShareFacilityFee / 100) * (facilityEstablishmentFeePercent / 100))*100;
    const brokerageDollar  = (brokerage/100) * facilityLimit;
    const availableAmount: any = facilityLimit - faciltyEstablishmentFee - docFee
    const scoreBetween500And600 = form.directorScore;
    const scoreAbove600 = form.lowEquifaxScore;
    let interestRate: any = 0
    if (form.propertyOwner && scoreAbove600) {
      const rate = rateCard.PropertyOwner ?? 0;
      interestRate = rate;
      calculationLog.push({log: `PropertyOwner + DirectorScoreAbove600`, rate: rate});
    } else if (!form.propertyOwner && scoreAbove600) {
      const rate = rateCard.NoPropertyOwner ?? 0;
      interestRate = rate;
      calculationLog.push({log: `NotPropertyOwner + DirectorScoreAbove600`, rate: rate});
    } else if (form.propertyOwner && !scoreAbove600 && scoreBetween500And600) {
      const rate = rateCard.DirectorScoreRate ?? 0;
      interestRate = rate;
      calculationLog.push({log: `PropertyOwner + NoDirectorScoreAbove600 + DirectorScoreBetween500And600`, rate: rate});
    } else {
      const rate = rateCard.NonPropertyOwnerBetween500And600Rate ?? 0;
      interestRate = rate;
      calculationLog.push({log: `NotPropertyOwnerDirectorScoreBetween500And600`, rate: rate});
    }

    // RBA rate
    {
      const rate = rateCard.rbaRate;
      interestRate += rate;
      calculationLog.push({log: `RBA rate`, rate: rate});
    }
    // removing this as now the portal rate will be taken into consideration
    // interestRate = rateSf != undefined ? rateSf : interestRate;

    // security type adjustment
    if (form.securityType) {
      switch(form.securityType) {
        case 'first-mortgage': {
          switch (form.ltvType) {
            case 'low': {  // <70%
              const rate = numeral(rateCard.SecurityType?.["first-mortgage"]?.low).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: first-mortgage + ltv low <70%`, rate: rate});
              break;
            }
            case 'medium': { // 70%-80%
              const rate = numeral(rateCard.SecurityType?.["first-mortgage"].medium).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: first-mortgage + ltv medium 70%-80%`, rate: rate});
              break;
            }
            case 'high': { // >80%
              const rate = numeral(rateCard.SecurityType?.["first-mortgage"].high).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: first-mortgage + ltv high >80%`, rate: rate});
              break;
            }
          }
          break;
        }
        case 'second-mortgage': {
          switch (form.ltvType) {
            case 'low': {  // <70%
              const rate = numeral(rateCard.SecurityType?.["second-mortgage"].low).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: second-mortgage + ltv low <70%`, rate: rate});
              break;
            }
            case 'medium': { // 70%-80%
              const rate = numeral(rateCard.SecurityType?.["second-mortgage"].medium).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: second-mortgage + ltv medium 70%-80%`, rate: rate});
              break;
            }
            case 'high': { // >80%
              const rate = numeral(rateCard.SecurityType?.["second-mortgage"].medium).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: second-mortgage + ltv high >80%`, rate: rate});
              break;
            }
          }
          break;
        }
        case 'caveat': {
          switch (form.ltvType) {
            case 'low': {  // <70%
              const rate = numeral(rateCard.SecurityType?.["caveat"].low).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: Caveat + ltv low <70%`, rate: rate});
              break;
            }
            case 'medium': { // 70%-80%
              const rate = numeral(rateCard.SecurityType?.["caveat"].medium).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: Caveat + ltv medium 70%-80%`, rate: rate});
              break;
            }
            case 'high': { // >80%
              const rate = numeral(rateCard.SecurityType?.["caveat"].medium).value() ?? 0;
              interestRate += rate;
              calculationLog.push({log: `SecurityType: Caveat + ltv high >80%`, rate: rate});
              break;
            }
          }
        }
      }
    }

    // add credit rate adjustment if applicable
    if(form.creditRateAdjustment){
      const rate = ((!_.isNil(form.creditRateAdjustment)) ? form.creditRateAdjustment : 0);
      interestRate += rate;
      calculationLog.push({log: `creditRateAdjustment`, rate: rate});
    }

    calculationLog.push({log: `Total`, rate: interestRate});
    console.table(calculationLog);

    const termRate: TermRateForCorporateLoan = {
      facilitylimit: facilityLimit,
      facilityEstablishmentFee: parseFloat(faciltyEstablishmentFee.toFixed(2)),
      monthlyFacilityFee: parseFloat(monthlyFacilityFee.toFixed(2)),
      docFee: docFee,
      brokerageDollar: brokerageAmountSf != undefined ? brokerageAmountSf : parseFloat(brokerageDollar.toFixed(2)),
      brokerage: parseFloat(brokerage.toFixed(2)),
      amountAvailable: availableAmount,
      interestRate: _.round(interestRate, 2),
      rbaRate: rateCard.rbaRate,
      margin: form.margin ?? _.round(interestRate, 2) - rateCard.rbaRate,
      facilityEstablishmentFeePercentage: Math.round(10000 * parseFloat(faciltyEstablishmentFee.toFixed(2)) / facilityLimit) / 100,
      monthlyFacilityFeePercentage: rateCardFacilityFeePercentage,
      calculationLog,
    }
    return termRate;
  }

  getTotalInterestRateForBusinessFinance(rateCard: RatecardDetails, form: FormDataForBusinessFinance, companyBureauReport: any): TermRateForBusinessFinance {

    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Business Finance interest rate calculation`});

    const loanAmount = form.loanAmount;
    const loanTerms: any = form.loanTerms; // this.businessLoanForm.get('loanTerms').value; // in months
    // const docFeeFinanced = form.docFeeFinanced; // this.businessLoanForm.get('docFeeFinanced').value;
    const brokerage = form.brokerage; // this.businessLoanForm.get('brokerage').value;
    const adversOnFile = form.adverseOnFile; // this.businessLoanForm.get('adverseOnFile').value;
    const scoreBetween500And600 = form.directorScore;
    const scoreAbove600 = form.lowEquifaxScore;
    const brokerageAmountSF = form.brokerageAmount;
    const docFeeFinanced = !!form?.docFeeFinanced;
    const creditRateAdjustment = form.creditRateAdjustment;
    console.log('form=====================', form)

    let baseRate: number = 0;
    calculationLog.push({log: `base rate`, rate: baseRate});


    if (form.propertyOwner && scoreAbove600) {
      const rate = rateCard.PropertyOwner ?? 0;
      baseRate = rate;
      calculationLog.push({log: `PropertyOwner`, rate});
    } else if (!form.propertyOwner && scoreAbove600) {
      const rate = rateCard.NoPropertyOwner ?? 0;
      baseRate = rate;
      calculationLog.push({log: 'NoPropertyOwner', rate});
    } else if (form.propertyOwner && !scoreAbove600 && scoreBetween500And600) {
      const rate = rateCard.DirectorScoreRate ?? 0;
      baseRate = rate;
      calculationLog.push({log: 'DirectorScore', rate});
    } else {
      const rate = rateCard.NonPropertyOwnerBetween500And600Rate ?? 0;
      baseRate = rate;
      calculationLog.push({log: `NonPropertyOwnerBetween500And600Rate`, rate});
    }

    if (adversOnFile) {
      const rate = (rateCard.AdversOnFile != null) ? (rateCard.AdversOnFile) : 0;
      baseRate += rate;
      calculationLog.push({log: `AdverseOnFile`, rate});
    }

    // rate changes based on loan terms
    let loanTermsRate: any = this.getApplicableInterestRate(rateCard.LoanTerms, loanTerms);
    loanTermsRate = (loanTermsRate != null) ? parseFloat(loanTermsRate) : 0;
    baseRate += loanTermsRate;
    calculationLog.push({log: `LoanTerms ${loanTerms}`, rate: loanTermsRate});

    // rate changes based on Brokerage
    let brokerageRate: any = this.getApplicableInterestRate(rateCard.Brokerage, brokerage);
    brokerageRate = (brokerageRate != null) ? parseFloat(brokerageRate) : 0;
    baseRate += brokerageRate;
    calculationLog.push({log: `Brokerage ${brokerage}`, rate: brokerageRate});

    // rate changes based on Loan Amount
    let loanAmountRate: any = this.getApplicableInterestRate(rateCard.LoanAmount, loanAmount);
    loanAmountRate = (loanAmountRate != null) ? parseFloat(loanAmountRate) : 0;
    baseRate += loanAmountRate;
    calculationLog.push({log: `LoanAmount ${loanAmount}`, rate: loanAmountRate});


    // rate changes based on doc fee
    let prinicipalAmount: any = loanAmount;
    // get applicable doc fee just to display in repayment popup
    const docfee: any = this.getApplicableInterestRate(rateCard.DocFee, loanAmount);
    const abnAge=companyBureauReport?.ABNAgeMonths ?? 0;
    let abnAgeRate:any=this.getApplicableInterestRate(rateCard.ABNorGSTAge,abnAge)
    abnAgeRate = (abnAgeRate != null) ? parseFloat(abnAgeRate) : 0;
    baseRate += abnAgeRate;
    calculationLog.push({log: `AbnAge ${abnAge}`, rate: abnAgeRate});

    const brokerageAmount = brokerageAmountSF != undefined ? brokerageAmountSF : this.calculateBrokerageAmount(loanAmount, brokerage);
    // removing this as now the portal rate will be taken into consideration
    // baseRate = rateSf != undefined ? rateSf : baseRate;
    const docFee = form.docFee != undefined ? form.docFee : docfee ;
    // apply doc fee if Fee financed is Yes and add in principal amount
    if (docFeeFinanced) {
      prinicipalAmount += (docFee != null) ? Number(docFee) : 0;
      calculationLog.push({log: `DocFeeFinanced, add docFee ${docFee} to principal amount ${loanAmount}`});
    }
    // add credit rate adjustment if applicable
    if(creditRateAdjustment){
      const rate = ((creditRateAdjustment != null) ? creditRateAdjustment : 0);
      baseRate += rate;
      calculationLog.push({log: `CreditRateAdjustment`, rate});
    }
    const basicInterest = rateCard.BaseInterestRate;
    const totalInterest = _.round(baseRate, 2);

    calculationLog.push({log: `Total`, rate: totalInterest});
    console.table(calculationLog);

    const termRate: TermRateForBusinessFinance = {
      brokerage,
      basicInterest,
      brokerageAmount,
      docFee,
      totalInterest,
      RV: 0,
      LVR: 0,
      prinicipalAmount: prinicipalAmount,
      brokerOriginationFee: 0,
      calculationLog,
    }
    return termRate;
  }

  calculateBrokerageAmount(loanAmount: number, brokerage: number) {
    const LA = loanAmount;
    let brokerageAmount =  0;
    brokerageAmount = Math.round((LA * brokerage) / 100 * 100) / 100;
    return brokerageAmount;
  }

  calculateFaciltyEstablishmentFee(facilityPercent: number, facilityFee: number) {
    const FP = facilityPercent;
    // (facilityEstablishmentFeePercent/100)*(this.formControlStep2FacilityEstablishmentFee.value)
    let establishmentFee =  0;
    establishmentFee = (facilityPercent/100)*(facilityFee);
    return establishmentFee;
  }

  getTotalInterestRateForCommercialFinance(rateCard: RatecardDetails, form: CommercialFinanceFormData, companyBureauReport: any = null, fixedBalloonPayment: number | null = null): TermRateForCommercialFinance {

    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Commercial Finance interest rate calculation`});


    let baseRate: any = rateCard.BaseInterestRate;
    const assetCondition = form.assetCondition;                                // 'Used', 'New'
    const assetCat = form.assetCategory;                                       // config.assetCat[].cat.index (string)
    const assetType = form.assetType;                                          // config.assetCat[].type.index (string)
    const assetYear: any = form.assetSpec.year;                         // number
    const ageOfAssetToday: any = new Date().getFullYear() - parseInt(assetYear, 10);   // number
    const loanAmount = form.loanAmount;                                        // number
    const loanTerms: any = form.loanTerms;                                     // in months
    const propertyOwnership = form.propertyOwner;                              // true / false
    const docFeeFinanced = form.docFeeFinanced;                                // true / false
    const brokerOriginationFee = form.brokerOriginationFee;                        // number
    const brokerage = form.brokerage;                                          // number
    const adversOnFile = form.adverseOnFile;                                   // true / false
    const highEquifaxScore = form.equifaxScoreAbove600;                         // true / false
    const privateSale = form.privateSaleORLeaseback;                          // true / false
    // const vehicle = form.assetSpec.vehicle;                             // string
    const balloonPayment = form.balloonPaymentPercent;                         // number (percent)
    const financeType = form.financeType;                                      // "Rental", "Operating Lease",
                                                                                            // "Chattel Mortgage", "Finance Lease",
                                                                                            // "Software Agreement"
    // const exGSTProducts = ['Rental', 'Finance Lease', 'Operating Lease'];


    let additionalRate1 = 0;
    let additionalRate2 = 0;

    if (adversOnFile == true && additionalRate2 === 0) {   // addtionalRate2 option
      const rate = (rateCard.AdversOnFile != null) ? parseFloat(String(rateCard.AdversOnFile)) : 0;
      additionalRate2 += rate;
      calculationLog.push({log: `AdverseOnFile (additional rate 2)`, rate});
    }
    if (highEquifaxScore == false && additionalRate2 === 0) {  // additionalRate2 option
      const rate = (rateCard.LowEquifaxScore != null) ? parseFloat(String(rateCard.LowEquifaxScore)) : 0;
      additionalRate2 += rate;
      calculationLog.push({log: `LowEquifaxScore (additional rate 2)`, rate});
    }
    if (privateSale == true && additionalRate1 === 0) {  // additionalRate1 option
      const rate = (rateCard.PrivateSale != null) ? parseFloat(String(rateCard.PrivateSale)) : 0;
      additionalRate1 += rate;
      calculationLog.push({log: `Private Sale (addtional rate 1)`, rate});
    }


    // === START MATRIX CALCULATION ==========
    const isAssetBacked = propertyOwnership === true;
    let tier = null;
    if (assetCat && !isNaN(ageOfAssetToday) && loanTerms) {
      if (assetCat && TIER1_ASSET_CATS.includes(assetCat)) {
          tier = 1;
      } else if (TIER2_ASSET_CATS.includes(assetCat)) {
          tier = 2;
      } else if (TIER3_ASSET_CATS.includes(assetCat)) {
          tier = 3;
      } else if (TIER4_ASSET_CATS.includes(assetCat)) {
          tier = 4;
      }
    }

    if (tier !== null) {
      let gstAgeInMonths = 0;
      let abnOrGstRate = 0;
      if (companyBureauReport !== null) {
        if (companyBureauReport.GSTAgeMonths || companyBureauReport.ABNAgeMonths) {
          gstAgeInMonths = companyBureauReport!.GSTAgeMonths ? companyBureauReport.GSTAgeMonths : companyBureauReport.ABNAgeMonths;

          // rate changes based on ABNorGST (additionalRate2 option)
          if (additionalRate2 === 0) {
            abnOrGstRate = this.getApplicableInterestRate(rateCard.ABNorGSTAge, gstAgeInMonths);
            abnOrGstRate = (abnOrGstRate != null) ? parseFloat(abnOrGstRate as any) : 0;
            additionalRate2 += abnOrGstRate;
            calculationLog.push({log: `AbnOrGst (addtional rate 2)`, rate: abnOrGstRate});
          }

        } else {
          console.error('no companyBureauReport GSTAgeMonths or ABNAgeMonths');
        }
      } else {
        console.error('no companyBureauReport');
      }


      const eotAge: any = ageOfAssetToday + (loanTerms / 12);
      if (tier === 1 || tier === 2) {
        if (tier === 1) {
          const tier1AssetFinanceRate: AssetFinanceTier1 = parseJSON(rateCard.AssetFinanceTier1);
          const tier1AssetFinanceRateRange = tier1AssetFinanceRate.AssetBacked;

          // const gstAgeInMonthMatrix = parseAssetFinanceTier(gstAgeInMonths, monthsCard) as AssetFinanceTier1Range;
          // if (!gstAgeInMonthMatrix) {
          //   throw Error(`Tier 1: can't find GST Age matrix for ${gstAgeInMonths}`);
          // }

          const eotAgeMatrix =
            parseAssetFinanceTier(eotAge, tier1AssetFinanceRateRange.YearRanges) as AssetFinanceTier1Range['YearRanges'][number];
          if (!eotAgeMatrix) {
            throw Error(`Tier 1: can't find EOT Age Matrix for ${eotAgeMatrix}`);
          }
          const rate = parseFloat(eotAgeMatrix.Rate);
          baseRate += rate;
          calculationLog.push({log: `EatAge ${eotAge}`, rate});

          baseRate = baseRate + additionalRate1 + additionalRate2;
          const msg = (` TIER 1 rate=${rate}, additionalRate1=${additionalRate1} additionalRate2=${additionalRate2}  isAssetBacked=${isAssetBacked}, gstAgeInMonths=${gstAgeInMonths}, eotAge=${eotAge}`);
          calculationLog.push({log: msg});

        } else {
          const tier2AssetFinanceRate: AssetFinanceTier2 = parseJSON(rateCard.AssetFinanceTier2);
          const tier2AssetFinanceRateRange = tier2AssetFinanceRate.AssetBacked;

          // const gstAgeInMonthMatrix = parseAssetFinanceTier(gstAgeInMonths, tier2MonthsCard) as AssetFinanceTier2Range;
          // if (!gstAgeInMonthMatrix) {
          //   throw Error(`Tier 2: can't find GST Age matrix for ${gstAgeInMonths}`);
          // }
          const eotAgeMatrix =
            parseAssetFinanceTier(eotAge, tier2AssetFinanceRateRange.YearRanges) as AssetFinanceTier2Range['YearRanges'][number];
          if (!eotAgeMatrix) {
            throw Error(`Tier 2: can't find EOT Age Matrix for ${eotAgeMatrix}`);
          }
          const rate = parseFloat(eotAgeMatrix.Rate);
          baseRate += rate;
          calculationLog.push({log: `EatAge ${eotAge}`, rate});

          baseRate = baseRate + additionalRate1 + additionalRate2;
          const msg = (`TIER 2 rate rate=${rate}, additionalRate1=${additionalRate1} additionalRate2=${additionalRate2}  isAssetBacked=${isAssetBacked}, gstAgeInMonths=${gstAgeInMonths}, eotAge=${eotAge}`);
          calculationLog.push({log: msg});
        }
      } else if (tier === 3) {
        // const tier3AssetFinanceRate: AssetFinanceTier3 = parseJSON(rateCard.AssetFinanceTier3);
        // const monthsCard = isAssetBacked ? tier3AssetFinanceRate.AssetBacked : tier3AssetFinanceRate.NotAssetBacked;
        // const u  = monthsCard.find(m => m.Type === assetCondition);
        // if (!u) {
        //   throw Error(`Tier 3: can't find Used Matrix`);
        // }
        // const rate = parseFloat(u.Rate);
        // baseRate += rate;

        // baseRate = baseRate + additionalRate1 + additionalRate2;
        // console.log(` TIER 3 rate=${rate}, additionalRate1=${additionalRate1} additionalRate2=${additionalRate2}  isAssetBacked=${isAssetBacked}, assetCondition=${assetCondition}`);

        const tier3AssetFinanceRate: AssetFinanceTier1 = parseJSON(rateCard.AssetFinanceTier1);
        const tier3AssetFinanceRateRange = tier3AssetFinanceRate.AssetBacked;
        const eotAgeMatrix =
          parseAssetFinanceTier(eotAge, tier3AssetFinanceRateRange.YearRanges) as AssetFinanceTier1Range['YearRanges'][number];
        if (!eotAgeMatrix) {
          throw Error(`Tier 3: can't find EOT Age Matrix for ${eotAgeMatrix}`);
        }
        const rate = parseFloat(eotAgeMatrix.Rate);
        baseRate += rate;
        calculationLog.push({log: `EatAge ${eotAge}`, rate});

        baseRate = baseRate + additionalRate1 + additionalRate2;
        const msg = (` TIER 3 rate=${rate}, additionalRate1=${additionalRate1} additionalRate2=${additionalRate2}  isAssetBacked=${isAssetBacked}, gstAgeInMonths=${gstAgeInMonths}, eotAge=${eotAge}`);
        calculationLog.push({log: msg});
      }
    }
    // === END MATRIX CALCULATION ===============


    // rate for asset categories
    if (rateCard.AssetCategory.length > 0) {
      rateCard.AssetCategory.forEach((item: {Cat: string, Rate: number}) => {
        if (item.Cat === assetCat) {
          const rate = (item.Rate != null) ? parseFloat(String(item.Rate)) : 0;
          baseRate += rate;
          calculationLog.push({log: `asset category ${assetCat}`, rate});
        }
      });
    }

    // rate for asset types
    if (rateCard.AssetType.length > 0) {
      rateCard.AssetType.forEach((item: {Cat: string, Type: string, Rate: number}) => {
        if (item.Cat === assetCat && item.Rate != null) {
          console.log('***** asset type', item.Type);
          const type = item.Type; // item.Type.split('-')[1];
          if ((type) === assetType) {
            const rate = item.Rate != null ? item.Rate : 0;
            baseRate += rate;
            console.log(`add asset type ${assetCat}-${assetType} rate ${rate}%`);
            calculationLog.push({log: `asset type ${assetCat}-${assetType}`, rate});
          }
        }
      });
    }




    // rate changes based on Brokerage
    let brokerageRate: any = this.getApplicableInterestRate(rateCard.Brokerage, brokerage);
    brokerageRate = (brokerageRate != null) ? parseFloat(brokerageRate) : 0;
    baseRate += brokerageRate;
    calculationLog.push({log: `Brokerage ${brokerage}`, rate: brokerageRate});


    // rate changes based on doc fee
    let prinicipalAmount: any = loanAmount;
    let docfee: any = 0;
    // get applicable doc fee just to display in repayment popup
    docfee = this.getApplicableInterestRate(rateCard.DocFee, loanAmount);
    // apply doc fee if Fee financed is Yes and add in principal amount
    if (docFeeFinanced == true) {
      docfee = (docfee != null) ? parseFloat(docfee) : 0;
      prinicipalAmount += docfee;
      calculationLog.push({log: `DocFeeFinanced, docfee ${docfee} added to principalAmount ${loanAmount}`});
    }

    // if (exGSTProducts.includes(financeType)) {
    //   docfee = Math.round((docfee / 1.1) * 100) / 100;
    // }

    if (privateSale == true) {
      docfee = docfee + rateCard.PrivateSaleDocFee
      if (docFeeFinanced === true) {
        const privateSaleDocFee =  rateCard.PrivateSaleDocFee != null ? parseFloat(String(rateCard.PrivateSaleDocFee)) : 0;
        prinicipalAmount += privateSaleDocFee;
        calculationLog.push({log: `Private sale doc fee ${privateSaleDocFee} applied to principalAmount ${loanAmount} `});
      }
    }
    docfee = parseFloat(docfee).toFixed(2)


    // add broker origination fee
    if (brokerOriginationFee > 0) {
      const _brokerOriginationFee = parseFloat(String(brokerOriginationFee));
      prinicipalAmount += _brokerOriginationFee;
      calculationLog.push({log: `Broker Origination Fee ${_brokerOriginationFee} applied to principalAmount ${loanAmount}`});
    }

    // LVR
    let LVR: any = 0;


    const totalInterest = _.round(baseRate, 2);
    console.log('total interest', totalInterest);

    console.table(calculationLog);

    const brokerageAmount = this.calculateBrokerageAmount(loanAmount, brokerage);

    // const financeTerms: any = {};
    // financeTerms.brokerage = brokerage;
    // const brokerageAmount = this.calculateBrokerageAmount(loanAmount, brokerage);
    // financeTerms.brokerageAmount = brokerageAmount;
    // financeTerms.basicInterest = rateCard.BaseInterestRate;
    // financeTerms.totalInterest = _.round(baseRate, 2);
    // financeTerms.prinicipalAmount = prinicipalAmount;
    // financeTerms.LVR = LVR;
    // financeTerms.docFee = docfee;
    // financeTerms.brokerOriginationFee = brokerOriginationFee;
    // if (fixedBalloonPayment == null) {
    //   financeTerms.RV = this.calculateResidualValue(prinicipalAmount, balloonPayment); // clculated based on interest.prinicipalAmount
    // } else {
    //   financeTerms.RV = fixedBalloonPayment;
    // }
    // financeTerms.calculationLog = calculationLog;
    // return financeTerms;

    const RV = fixedBalloonPayment == null ? this.calculateResidualValue(prinicipalAmount, balloonPayment) : fixedBalloonPayment;

    const termRate: TermRateForCommercialFinance = {
      brokerage: brokerage,
      brokerageAmount: brokerageAmount,
      basicInterest: rateCard.BaseInterestRate,
      totalInterest: totalInterest,
      prinicipalAmount: prinicipalAmount,
      docFee: docfee,
      LVR: LVR,
      brokerOriginationFee: brokerOriginationFee,
      RV,
      calculationLog,
    };
    return termRate;
  }


  getTotalInterestRateForConsumerFinance(rateCard: RatecardDetails, form: ConsumerFinanceFormData, vehicles: any[] = [], fixedBalloonPayment = null): TermRateForConsumerFinance {
    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Consumer Asset Finance interest rate calculation`});

    let baseRate: number = 0, indicativeRate: number = 0;
    const assetCondition = form.assetCondition;
    const loanAmount = form.loanAmount;
    const loanTerms: any = form.loanTerms; // in months
    const propertyOwnership = form.propertyOwner;
    const docFeeFinanced = form.docFeeFinanced;
    let brokerOriginationFee = form.brokerOriginationFee;
    const adversOnFile = form.adverseOnFile;
    const lowEquifaxScore = form.equifaxScoreAbove600;
    const privateSale = form.privateSaleORLeaseback;
    const assetCat = form.assetCategory;
    const assetType = form.assetType;
    const vehicle = form.assetSpec.vehicle;
    const balloonPayment = form.balloonPaymentPercent;
    const financeType = form.financeType;
    const hybrid = form.hybrid;
    const balloonPaymentAmount = form.balloonPaymentAmount;
    const ppsrFee = form.ppsrFee;
    // const exGSTProducts = ['Rental', 'Finance Lease', 'Operating Lease'];
    // update brokerage
    console.log("===form: ", form);

    const formAssetYear:any= form.assetSpec.year
    const assetYear:any=new Date().getFullYear() - parseInt(formAssetYear) - 1;
    if (assetType == '1' || assetType == '2') {
      const rate = this.getApplicableInterestRate(parseJSON(rateCard.CarsAndCaravans), assetYear);
      baseRate += rate;
      calculationLog.push({log: `assetType ${assetType}`, rate});
    }
    if (assetType == '3') {
      const rate = this.getApplicableInterestRate(parseJSON(rateCard.Motorbikes), assetYear);
      baseRate += rate;
      calculationLog.push({log: `assetType ${assetType}`, rate});
    }
    if (assetType == '4') {
      const rate = this.getApplicableInterestRate(parseJSON(rateCard.SolarSystems), assetYear);
      baseRate += rate;
      calculationLog.push({log: `assetType ${assetType}`, rate});
    }

    if (adversOnFile) {
      const adverseOnFileRate = (rateCard.AdversOnFile != null) ? (rateCard.AdversOnFile) : 0;
      baseRate += adverseOnFileRate
      calculationLog.push({log: `adverse on file`, rate: adverseOnFileRate });
    }
    if (!lowEquifaxScore) {
      const lowEquifaxScoreRate = (rateCard.LowEquifaxScore != null) ? (rateCard.LowEquifaxScore) : 0;
      baseRate += lowEquifaxScoreRate;
      calculationLog.push({log: `Low Equifax Score`, rate: lowEquifaxScoreRate });
    }
    if (privateSale) {
      const privateSaleRate = (rateCard.PrivateSale != null) ? (rateCard.PrivateSale) : 0;
      baseRate += privateSaleRate
      calculationLog.push({log: `Private Sale`, rate: privateSaleRate});

    }
    if (!propertyOwnership) {
      const noPropertyOwnershipRate = (rateCard.NoPropertyOwnership != null) ? (rateCard.NoPropertyOwnership) : 0;
      baseRate += noPropertyOwnershipRate;
      calculationLog.push({log: `No Property Ownership`, rate: noPropertyOwnershipRate});

    }
    if (hybrid) {
      let hybridRate = (rateCard.Hybrid != null) ? Math.abs(rateCard.Hybrid) : 0;
      baseRate -= hybridRate;
      console.log(`---------hybrid, minus rate ${hybridRate}`);
      calculationLog.push({log: `Hybrid (minus)`, rate: -hybridRate});

    }
    // rate changes based on loan terms
    const loanTermsRate: any = this.getApplicableInterestRate(rateCard.LoanTerms, loanTerms);
    baseRate += (loanTermsRate != null) ? parseFloat(loanTermsRate) : 0;
    calculationLog.push({log: `Loan Terms ${loanTerms}`, rate: loanTermsRate});

    // asset type
    if (rateCard.AssetType.length > 0) {
      rateCard.AssetType.forEach(item => {
        if ((item.Cat) == assetCat && item.Rate != null) {
          const type = item.Type; // item.Type.split('-')[1];
          if ((type) == assetType || (type == constants.CarsAssetTypeIndex && this.isAssetTypeCars(assetType))) {
            const rate = item.Rate != null ? (item.Rate) : 0;
            baseRate += rate;
            calculationLog.push({log: `Asset Cat ${assetCat}, Asset Type ${assetType}`, rate})
          }
        }
      });
    }
    // GST age <= 24 months
    // Add rate based on rate card settings when GST Age is < 24 months
    // if (companyBureauReport != null) {
    //   // Add rate for GST/ABN Age rate settings
    //   if (companyBureauReport.GSTAgeMonths != undefined || companyBureauReport.ABNAgeMonths != undefined) {
    //     const ABNorGSTAgeRate: any = this.getApplicableInterestRate(rateCard.ABNorGSTAge, companyBureauReport.GSTAgeMonths != undefined ? companyBureauReport.GSTAgeMonths : companyBureauReport.ABNAgeMonths);
    //     baseRate += (ABNorGSTAgeRate != null) ? (ABNorGSTAgeRate) : 0;
    //   }
    // }
    // rate changes based on doc fee
    let prinicipalAmount: any = loanAmount;
    let amountForComparisonRate: number = loanAmount;
    let docfee: any = 0;
    // get applicable doc fee just to display in repayment popup
    docfee = form.docFee == undefined ? this.getApplicableInterestRate(rateCard.DocFee, loanAmount) : form.docFee;
    docfee += ppsrFee;
    // apply doc fee if Fee financed is Yes and add in principal amount
    calculationLog.push({log: `Principal Amount ${prinicipalAmount}`});
    if (docFeeFinanced) {
      prinicipalAmount += (docfee != null) ? Number(docfee) : 0;
      calculationLog.push({log: `docFeeFinanced, add docFee ${docfee} to principalAmount ${loanAmount}`});
    } else {
      amountForComparisonRate += (docfee != null) ? Number(docfee) : 0;
    }
    if (privateSale && form.docFee == undefined) {
      docfee = docfee + rateCard.PrivateSaleDocFee
      if (docFeeFinanced) {
        const privateSaleDocFee = rateCard.PrivateSaleDocFee != null ? (rateCard.PrivateSaleDocFee) : 0;
        prinicipalAmount += privateSaleDocFee;
        calculationLog.push({log: `privateSale, add private sale doc fee ${privateSaleDocFee} to principalAmount ${loanAmount}`});
      }
    }

    // if (exGSTProducts.includes(financeType)) {
    //   docfee = Math.round((docfee / 1.1) * 100) / 100;
    // }
    docfee = Number(docfee).toFixed(2)
    // add broker origination fee
    if (brokerOriginationFee > 0) {
      prinicipalAmount += (brokerOriginationFee);
      calculationLog.push({log: `add brokerageOriginationFee (credit assistance fee) ${brokerOriginationFee} to PrincipalAmount ${loanAmount}`});
    }

    // LVR
    let LVR: any = 0;
    if (this.isAssetVehicles(assetCat) && this.isAssetNewCars(assetCat, assetType)) {
      let assetPrice: any = form.assetSpec.goodretail;
      if (assetPrice == null || assetPrice == '') {
        assetPrice = form.assetSpec.newprice;
      }
      if (vehicle == 'actual_model_not_yet_determined') {
        let totalGoodPrice = 0;
        for (let i = 0; i <= vehicles.length - 1; i++) {
          totalGoodPrice += (vehicles[i].goodretail != '') ? vehicles[i].goodretail : vehicles[i].newprice;
        }
        const avgPrice = Math.round((totalGoodPrice / vehicles.length) * 100) / 100;
        LVR = Math.round(((loanAmount / avgPrice) * 100) * 100) / 100;
      } else {
        if (assetPrice != null && assetPrice != '') {
          LVR = Math.round(((loanAmount / assetPrice) * 100) * 100) / 100;
        }
      }
    }

    // add credit rate adjustment if applicable
    if(form.creditRateAdjustment){
      const creditRateAdjustment = ((!_.isNil(form.creditRateAdjustment)) ? form.creditRateAdjustment : 0);
      baseRate += creditRateAdjustment;
      calculationLog.push({log: `CreditRateAdjustment`, rate: creditRateAdjustment});
    }

    // apply rate discound if applicable
    indicativeRate = baseRate;
    if (form.rateDiscount) {
      const rateDiscount = ((!_.isNil(form.rateDiscount)) ? form.rateDiscount : 0);
      baseRate -= rateDiscount;
      calculationLog.push({log: `RateDiscount`, rate: -rateDiscount});
    }

    const totalInterest = _.round(baseRate, 2);
    console.log('total interest', totalInterest);
    calculationLog.push({log: `Total`, rate: totalInterest});
    console.table(calculationLog);

    // const financeTerms: any = {};
    // financeTerms.brokerage = brokerage;
    // financeTerms.brokerageAmount = brokerageAmount;
    // financeTerms.basicInterest = rateCard.BaseInterestRate;
    // financeTerms.totalInterest = totalInterest;
    // financeTerms.prinicipalAmount = prinicipalAmount;
    // financeTerms.LVR = LVR;
    // financeTerms.docFee = docfee;
    // financeTerms.brokerOriginationFee = brokerOriginationFee;
    // financeTerms.calculationLog = calculationLog;
    // if (fixedBalloonPayment == null) {
    //   financeTerms.RV = balloonPaymentAmount != undefined ? balloonPaymentAmount : this.calculateResidualValue(prinicipalAmount, balloonPayment); // clculated based on interest.prinicipalAmount
    // } else {
    //   financeTerms.RV = fixedBalloonPayment;
    // }
    // return financeTerms;

    const RV = fixedBalloonPayment == null ?
      (balloonPaymentAmount != undefined ? balloonPaymentAmount : this.calculateResidualValue(form.invoiceAmount, balloonPayment)) :
      fixedBalloonPayment;



    const termRate: TermRateForConsumerFinance = {
      brokerage: 0,
      brokerageAmount: 0,
      basicInterest: rateCard.BaseInterestRate,
      totalInterest: totalInterest,
      baseRate: indicativeRate - 2,
      prinicipalAmount: prinicipalAmount,
      docFee: docfee,
      brokerOriginationFee: brokerOriginationFee,
      LVR: LVR,
      RV: RV,
      calculationLog,
      monthlyAccountKeepingFee: rateCard.MonthlyAccountKeepingFee ?? 0,
      amountForComparisonRate: amountForComparisonRate
    };

    return termRate;
  }


  getTotalInterestRateForAssetFinance(rateCard: RatecardDetails,
                                      form: RepaymentEstimationFormData,
                                      vehicles: {goodretail?: number, newprice?: number}[] = [],
                                      companyBureauReport: {GSTAgeMonths?: number, ABNAgeMonths?: number} | null = null,
                                      fixedBalloonPayment: any = null): TermRateForAssetFinance {
    const calculationLog: {log: string, rate?: number }[] = [];
    calculationLog.push({log: `Asset Finance interest rate calculation`, rate: undefined});

    let baseRate: any = rateCard.BaseInterestRate;
    calculationLog.push({log: `baseRate`, rate: baseRate});


    const assetCondition = form.assetCondition;                                      // 'Used', 'New'
    const assetCat = form.assetCategory;                                                    // config.assetCat[].cat.index (string)
    const assetType = form.assetType;                                                       // config.assetCat[].type.index (string)
    const assetYear: any = form.assetSpec.year;                                                    // number
    const ageOfAssetToday: number =
      Math.max(0, new Date().getFullYear() - parseInt(assetYear, 10)-1) ;              // number
    const loanAmount = form.loanAmount;                                                     // number
    const loanTerms: number = form.loanTerms;                                                  // in months
    const propertyOwnership = form.propertyOwnership;                                       // boolean
    const docFeeFinanced = form.docFeeFinanced;                                             // boolean
    let brokerOriginationFee = form.brokerOriginationFee;                                 // number
    let brokerage = form.brokerage;                                                       // number
    const adverseOnFile = form.adverseOnFile;                                               // boolean
    const highEquifaxScore = form.equifaxScoreAbove600;                                     // boolean
    // const privateSaleOrLeaseback = form.privateSaleOrLeaseback;                                        // boolean
    const vehicle = form.assetSpec.vehicle;                                                 // string
    const balloonPayment = form.balloonPayment;                                             // number (percentage)
    const financeType = form.financeType;                                                   // 'rental' | 'operating-lease' | 'chattel-mortgage' | 'finance-lease' | 'software-agreement'
    // const exGSTProducts = ['Rental', 'Finance Lease', 'Operating Lease'];
    const isAssetBacked = propertyOwnership === true;                                       // boolean
    const eotAge: number = ageOfAssetToday + (loanTerms / 12);                                       // EOT Age (number)
    const deposit = form?.deposit ?? 0;                                                           // number
    const brokerageAmountSF = form?.brokerageAmount;                                     // number
    const balloonPaymentAmount = form.balloonPaymentAmount;                                 // number
    const transactionType = form.transactionType;                                                           // TransactionType
    const isTransactionTypeInRateCard = transactionType ? (rateCard.TransactionTypeRate?.transactionTypes ?? []).includes(transactionType) : false;
    const isTruckGrossWeigh12T = form.truckGrossWeight == TruckGrossVehicleWeightSelection.Massive;
    console.log("=======form ", form);

    let abnAgeInMonths = 0;                                                                 // ABN Age (in months)
    let hasCompanyAbnAge = false;                                                           // boolean: has abn age or not
    let tier = null;                                                                        // tier category
    let additionalRate = 0;                                                                 // all additional rates

    // update brokerage
    if (brokerageAmountSF != undefined) {
      console.log('=======here ', brokerageAmountSF);
      brokerage = Math.round(brokerageAmountSF * 10000 / loanAmount) / 100;
    }

    // initialize gst age in months
    if (companyBureauReport?.ABNAgeMonths) {
      hasCompanyAbnAge = true;
      abnAgeInMonths = companyBureauReport.ABNAgeMonths ?? 0;
    }

    // tier classifications
    if (assetCat && !isNaN(ageOfAssetToday) && loanTerms) {
      if (assetCat && TIER1_ASSET_CATS.includes(assetCat)) {
        tier = 1;
      } else if (TIER2_ASSET_CATS.includes(assetCat)) {
        tier = 2;
      } else if (TIER3_ASSET_CATS.includes(assetCat)) {
        tier = 3;
      } else if (TIER4_ASSET_CATS.includes(assetCat)) {
        tier = 4;
      }
    }

    // get base rate according to tiers
    // let gstAgeInMonthMatrix;
    let eotAgeMatrix;
    switch (tier) {
      case 1: {// tier 1
        const tier1AssetFinanceRate: AssetFinanceTier1 = parseJSON(rateCard.AssetFinanceTier1);
        const tier1AssetFinanceRateRange = tier1AssetFinanceRate.AssetBacked;
        const u = (tier1AssetFinanceRateRange.AssetCondition ?? []).find(m => m.Type == assetCondition);
        if (!u) {
          console.error(`Tier 1: can't find Used Matrix `);
        } else {
          const rate = parseFloat(u.Rate ?? '0');
          baseRate += rate;
          const log = `Tier 1: Asset condition (${assetCondition}) rate`;
          calculationLog.push({log, rate});
        }

        // gstAgeInMonthMatrix = parseAssetFinanceTier(abnAgeInMonths, tier1MonthsCard) as AssetFinanceTier1Range;
        // if (!gstAgeInMonthMatrix) {
        //   throw Error(`Tier 1: can't find GST Age matrix for ${abnAgeInMonths}`);
        // }
        eotAgeMatrix =
          parseAssetFinanceTier(eotAge, tier1AssetFinanceRateRange.YearRanges); //  as AssetFinanceTier1Range['YearRanges'][number];
        if (!eotAgeMatrix) {
          throw Error(`Tier 1: can't find EOT Age Matrix for ${eotAgeMatrix}`);
        }
        const rate = parseFloat(eotAgeMatrix.Rate);
        const log = `Tier1 Asset: rate: ${rate}, eotAgeYear: ${eotAge}, eotAgeYearRage: ${eotAgeMatrix.YearRange}, abnAgeMonth: ${abnAgeInMonths} `;
        baseRate += rate;
        calculationLog.push({log, rate});
        break;
      }
      case 2: {// tier 2
        const tier2AssetFinanceRate: AssetFinanceTier2 = parseJSON(rateCard.AssetFinanceTier2);
        const tier2AssetFinanceRateRange =  (tier2AssetFinanceRate?.AssetBacked ?? 0);
        const u = (tier2AssetFinanceRateRange.AssetCondition ?? []).find(m => m.Type == assetCondition);
        if (!u) {
          console.error(`Tier 2: can't find Used Matrix `);
        } else {
          const rate = parseFloat(u.Rate ?? '0');
          baseRate += rate;
          const log = `Tier 2: Asset condition (${assetCondition}) rate`;
          calculationLog.push({log, rate});
        }

        // gstAgeInMonthMatrix = parseAssetFinanceTier(abnAgeInMonths, tier2MonthsCard) as AssetFinanceTier2Range;
        // if (!gstAgeInMonthMatrix) {
        //   throw Error(`Tier 2: can't find GST Age matrix for ${abnAgeInMonths}`);
        // }
        eotAgeMatrix =
          parseAssetFinanceTier(eotAge, tier2AssetFinanceRateRange.YearRanges) as AssetFinanceTier2Range['YearRanges'][number];
        if (!eotAgeMatrix) {
          throw Error(`Tier 2: can't find EOT Age Matrix for ${eotAgeMatrix}`);
        }
        const rate = parseFloat(eotAgeMatrix.Rate);
        const log = (`Tier2 Asset: rate: ${rate}, eotAgeYear: ${eotAge}, eotAgeYearRage: ${eotAgeMatrix.YearRange}, abnAgeMonth: ${abnAgeInMonths} `);
        baseRate += rate;
        calculationLog.push({log, rate});
        break;
      }
      case 3: { // tier 3
        const tier3AssetFinanceRate: AssetFinanceTier3 = parseJSON(rateCard.AssetFinanceTier3);
        const tier3MonthsCard = tier3AssetFinanceRate.AssetBacked;
        const u = tier3MonthsCard.find(m => m.Type === assetCondition);
        if (!u) {
          console.error(`Tier 3: can't find Used Matrix`);
        } else {
          const rate = parseFloat(u.Rate);
          baseRate += rate;
          const log = (`Tier3 Asset: rate: ${rate}, assetCondition: ${assetCondition}, eotAgeYear: ${eotAge}, abnAgeMonth: ${abnAgeInMonths} `);
          calculationLog.push({log, rate});
        }
        // const tier3AssetFinanceRate: AssetFinanceTier1 = parseJSON(rateCard.AssetFinanceTier3);
        // const tier3AssetFinanceRateRange = isAssetBacked ? tier3AssetFinanceRate.AssetBacked : tier3AssetFinanceRate.NotAssetBacked;
        // eotAgeMatrix =
        //   parseAssetFinanceTier(eotAge, tier3AssetFinanceRateRange.YearRanges); //  as AssetFinanceTier1Range['YearRanges'][number];
        // if (!eotAgeMatrix) {
        //   throw Error(`Tier 1: can't find EOT Age Matrix for ${eotAgeMatrix}`);
        // }
        // const rate = parseFloat(eotAgeMatrix.Rate);
        // console.log(`Tier3 Asset: rate: ${rate}, eotAgeYear: ${eotAge}, eotAgeYearRage: ${eotAgeMatrix.YearRange}, gstAgeMonth: ${abnAgeInMonths} `);
        // baseRate += rate;
        break;
      }
      case 4: { // tier 4
        const tier4AssetFinanceRate: AssetFinanceTier4 = parseJSON(rateCard.AssetFinanceTier4);
        const tier4MonthsCard = tier4AssetFinanceRate.AssetBacked;
        const u = tier4MonthsCard.find(m => m.Type === assetCondition);
        if (!u) {
          console.error(`Tier 4: can't find Used Matrix`);
        } else {
          const rate = parseFloat(u.Rate);
          baseRate += rate
          const log = (`Tier4 Asset: rate: ${rate}, assetCondition: ${assetCondition}, eotAgeYear: ${eotAge}, abnAgeMonth: ${abnAgeInMonths} `);
          calculationLog.push({log, rate});
        }
        // if (tier4AssetFinanceRate) {
        //   const rate = isAssetBacked ? tier4AssetFinanceRate.AssetBacked : tier4AssetFinanceRate.NotAssetBacked;
        //   baseRate  += rate;
        //   console.log(`Tier 4 Asset rate: ${rate}`);
        // } else {
        //   console.log(`No Tier4 Asset finance rate found`);
        // }
        break;
      }
    }

    // private sale - NOTE: replaced by "transaction type rate"
    // if (privateSaleOrLeaseback) {
    //   const privateSaleRate = (rateCard.PrivateSale != null) ? parseFloat(String(rateCard.PrivateSale)) : 0;
    //   additionalRate += privateSaleRate;
    //   console.log(`================add interest ${privateSaleRate} for private sale`);
    // }

    // adverse on file
    if (adverseOnFile) {
      const adverseOnFileRate = (rateCard.AdversOnFile != null) ? parseFloat(String(rateCard.AdversOnFile)) : 0;
      additionalRate += adverseOnFileRate;
      calculationLog.push({log: `Adverse on File (additional rate)`, rate: adverseOnFileRate});
    }

    // low equifax score
    if (!highEquifaxScore) {
      const equifaxScore = (rateCard.LowEquifaxScore != null) ? parseFloat(String(rateCard.LowEquifaxScore)) : 0;
      additionalRate += equifaxScore;
      calculationLog.push({log: `LowEquifaxScore (additional rate)`, rate: equifaxScore});
    }

    // ABN age
    if (hasCompanyAbnAge) {
      const abnRate = this.getApplicableInterestRate(rateCard.ABNorGSTAge, abnAgeInMonths);
      additionalRate += (abnRate != null) ? parseFloat(abnRate as any) : 0;
      calculationLog.push({log: `ABN Age (${abnAgeInMonths} Months) (additional rate)`, rate: abnRate});
    }

    // rate changes based on Loan Amount
    let loanAmountRate: any = this.getApplicableInterestRate(rateCard.LoanAmount, loanAmount);
    loanAmountRate = (loanAmountRate != null) ? parseFloat(loanAmountRate) : 0;
    // baseRate += loanAmountRate;
    additionalRate += loanAmountRate;
    calculationLog.push({log: `LoanAmountRate (additional rate)`, rate: loanAmountRate});

    // loan terms
    let loanTermsRate = this.getApplicableInterestRate(rateCard.LoanTerms, loanTerms);
    loanTermsRate = (loanTermsRate != null) ? parseFloat(loanTermsRate as any) : 0;
    additionalRate += loanTermsRate;
    calculationLog.push({log: `LoanTerms ${loanTerms} (additional rate)`, rate: loanTermsRate});

    // asset category
    if (rateCard.AssetCategory.length > 0) {
      rateCard.AssetCategory.forEach((item: {Cat: string, Rate: number}) => {
        if (item.Cat === assetCat) {
          const assetCategoryRate = (item.Rate != null) ? parseFloat(String(item.Rate)) : 0;
          additionalRate += assetCategoryRate;
          calculationLog.push({log: `Asset Category ${assetCat} (additional rate)`, rate: assetCategoryRate});
        }
      });
    }

    // asset type
    if (rateCard.AssetType.length > 0) {
      rateCard.AssetType.forEach((item: {Cat: string, Type: string, Rate: number}) => {
        if (item.Cat === assetCat && item.Rate != null) {
          const type = item.Type;
          if ((type) === assetType) {
            const assetTypeRate = item.Rate !== null ? item.Rate : 0;
            additionalRate += assetTypeRate;
            calculationLog.push({log: `Asset Type ${assetType} (additional rate)`, rate: assetTypeRate})
          }
        }
      });
    }


    // Deposit uplift
    if (tier === 1 && !propertyOwnership && deposit < loanAmount * 0.2) {
      const lowDepositUplift = rateCard.LowDepositUplift ?? 1;
      additionalRate += lowDepositUplift;
      calculationLog.push({log: `Low Deposit Uplift (additional rate)`, rate: lowDepositUplift});
    }


    // no asset backed lift
    if (!isAssetBacked) {
      const noAssetBackedRate = rateCard.NoAssetBacked ?? 0;
      additionalRate += noAssetBackedRate;
      calculationLog.push({log: `NoAssetBacked (additional rate)`, rate: noAssetBackedRate});
    }

    // 12T truck uplift
    if (isAssetTypeAnyTruck(assetCat, assetType) && isTruckGrossWeigh12T) {
      const truck12tRate = rateCard.Truck12T ?? 0;
      additionalRate += truck12tRate;
      calculationLog.push({log: `12T truck uplift (additional rate)`, rate: truck12tRate});
    }

    // transaction type rate
    if (transactionType && rateCard.TransactionTypeRate) {
      if(isTransactionTypeInRateCard) {
        // transaction type is listed in rate card transaction type, apply rate
        const rate = rateCard.TransactionTypeRate.rate;
        additionalRate += rate;
        calculationLog.push({log: `TransactionType ${transactionType} Uplift`, rate: rate});
      }
    }

    // cap additional rate
    const capRate = rateCard.CapRate ?? 0;
    if (capRate > 0 && additionalRate > capRate) {
      additionalRate = capRate;
      calculationLog.push({log: `${additionalRate} (additional rate) > ${capRate} (cap rate), capping rate to cap rate`, rate: capRate});
    }

    // brokerage
    let brokerageRate = this.getApplicableInterestRate(rateCard.Brokerage, brokerage);
    brokerageRate = (brokerageRate != null) ? parseFloat(brokerageRate as any) : 0;
    additionalRate += brokerageRate;
    calculationLog.push({log: `Brokerage`, rate: brokerageRate});

    // update base rate
    baseRate += additionalRate;
    calculationLog.push({log: `total additionalRate`, rate: additionalRate});

    // rate changes based on doc fee
    let prinicipalAmount: any = loanAmount;
    let docfee: any = 0;
    // get applicable doc fee just to display in repayment popup
    docfee = form.docFee == undefined ? this.getApplicableInterestRate(rateCard.DocFee, loanAmount) : form.docFee;
    // apply doc fee if Fee financed is Yes and add in principal amount
    if (docFeeFinanced) {
      prinicipalAmount += (docfee != null) ? Number(docfee) : 0;
    }

    // if (exGSTProducts.includes(financeType) == true) {
    //   docfee = Math.round((docfee / 1.1) * 100) / 100;
    // }

    // NOTE: replaced wby transactionTypeRate.addionalDocFee;
    // if (privateSaleOrLeaseback && docFeeSf == undefined) {
    //   docfee = docfee + rateCard.PrivateSaleDocFee
    //   if (docFeeFinanced) {
    //     prinicipalAmount += rateCard.PrivateSaleDocFee != null ? parseFloat(String(rateCard.PrivateSaleDocFee)) : 0;
    //   }
    // }
    if (isTransactionTypeInRateCard && form.docFee == undefined) {
      const additionalDocFee = rateCard.TransactionTypeRate?.additionalDocFee ?? 0;
      const oldDocFee = docfee;
      docfee = docfee + additionalDocFee;
      const msg = (`additional doc fee applied, old doc fee ${oldDocFee}, additional doc fee ${additionalDocFee}, new doc fee ${docfee}`);
      calculationLog.push({log: msg, rate: 0});
      if (docFeeFinanced) {
        const oldPrincipalAmount = prinicipalAmount;
        prinicipalAmount += additionalDocFee;
        const msg = (`additional doc fee applied to principal amount, old principal amount ${oldPrincipalAmount}, additional doc fee ${additionalDocFee}, new principal amount ${prinicipalAmount}`);
        calculationLog.push({log: msg, rate: 0});
      }
    }
    docfee = parseFloat(docfee).toFixed(2)


    // add broker origination fee
    if (brokerOriginationFee > 0) {
      prinicipalAmount += parseFloat(String(brokerOriginationFee));
    }

    // LVR
    let LVR: any = 0;
    if (this.isAssetVehicles(assetCat) && (this.isAssetNewCars(assetCat, assetType) || this.isAssetTypeLightOrHeavyTruck(assetCat, assetType))){
       let assetPrice: any = form.assetSpec.goodretail;
       if (assetPrice == null || assetPrice == '') {
         assetPrice = form.assetSpec.newprice;
       }
       if (vehicle == 'actual_model_not_yet_determined') {
         let totalGoodPrice = 0;
         for (let i = 0; i <= vehicles.length - 1; i++) {
           totalGoodPrice += ((vehicles[i].goodretail) ? vehicles[i].goodretail : vehicles[i].newprice) ?? 0;
         }
         const avgPrice = Math.round((totalGoodPrice / vehicles.length) * 100) / 100;
         LVR = Math.round(((loanAmount / avgPrice) * 100) * 100) / 100;
       } else {
         if (assetPrice != null && assetPrice != '') {
           LVR = Math.round(((loanAmount / assetPrice) * 100) * 100) / 100;
         }
       }
    }
    // removing this as now the portal rate will be taken into consideration
    // baseRate = rateSf == undefined ? baseRate : rateSf;

    // add credit rate adjustment if applicable
    if(form.creditRateAdjustment){
      const creditRateAdjustment = ((!_.isNil(form.creditRateAdjustment)) ? form.creditRateAdjustment : 0);
      baseRate += creditRateAdjustment;
      calculationLog.push({log: `Credit Rate Adjustment`, rate: creditRateAdjustment});
    }

    const totalInterest = _.round(baseRate, 2);
    console.log('total interest', totalInterest);
    calculationLog.push({log: `Total`, rate: totalInterest});

    const brokerageAmount = brokerageAmountSF ? brokerageAmountSF : this.calculateBrokerageAmount(loanAmount, brokerage);

    // const financeTerms: any = {};
    // financeTerms.brokerage = brokerage;
    // financeTerms.brokerageAmount = brokerageAmount;
    // financeTerms.basicInterest = rateCard.BaseInterestRate;
    // financeTerms.totalInterest = totalInterest,
    // financeTerms.LVR = LVR;
    // financeTerms.docFee = docfee;
    // financeTerms.brokerOriginationFee = brokerOriginationFee;
    // financeTerms.prinicipalAmount = prinicipalAmount;
    // financeTerms.calculationLog = calculationLog;

    let RV = fixedBalloonPayment;
    if (fixedBalloonPayment == null) {
      let ballooninvoiceAmount = form.invoiceAmount ?? 0
      // if (docFeeFinanced) {
      //   ballooninvoiceAmount = ballooninvoiceAmount - Number(docfee)
      // }
      // if (brokerOriginationFee > 0) {
      //   ballooninvoiceAmount = ballooninvoiceAmount - brokerOriginationFee
      // }
      // financeTerms.RV = balloonPaymentAmount != undefined ? balloonPaymentAmount : this.calculateResidualValue(ballooninvoiceAmount, balloonPayment); // clculated based on interest.prinicipalAmount
      RV = balloonPaymentAmount != undefined ? balloonPaymentAmount : this.calculateResidualValue(ballooninvoiceAmount, balloonPayment); // clculated based on interest.prinicipalAmount
    } else {
      // financeTerms.RV = fixedBalloonPayment;
      RV = fixedBalloonPayment;
    }

    const termRate: TermRateForAssetFinance = {
      brokerage: brokerage,
      brokerageAmount: brokerageAmount,
      basicInterest: rateCard.BaseInterestRate,
      totalInterest: totalInterest,
      prinicipalAmount: prinicipalAmount,
      docFee: docfee,
      LVR: LVR,
      brokerOriginationFee: brokerOriginationFee,
      RV: RV,
      calculationLog,
    };

    console.log('getTotalInterestRate result:', termRate);
    console.table(calculationLog);

    return termRate;
  }



  // isAssetVehicles(cat: string) {
  //   const catInt = parseInt(cat, 10);
  //   if ([137, 132, 130].includes(catInt)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAssetCars(cat: string) {
  //   const catInt = parseInt(cat, 10);
  //   if (catInt === 137) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAssetNewCars(cat: string, type: string) {
  //   const catInt = parseInt(cat, 10);
  //   const typeInt = parseInt(type, 10);
  //   if (this.isAssetCars(String(catInt)) === false) {
  //     return false;
  //   }
  //   if (typeInt === parseInt(constants.NewCarsAssetTypeIndex,10)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // isAssetOldCars(cat: string, type: string) {
  //   const catInt = parseInt(cat, 10);
  //   const typeInt = parseInt(type, 10);
  //   if (this.isAssetCars(String(catInt)) === false) {
  //     return false;
  //   }
  //   if (typeInt === parseInt(constants.OldCarsAssetTypeIndex,10)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // setDateValue
  // refreshUI


  getDonutChartData(): { pricipalAmt: number, interestAmt: number } {
    return {
      pricipalAmt: this.principalAmt,
      interestAmt: this.interestAmt,
    }
  }

  getBarChartQuaterlyData():MonthWiseData[] {
    this.calculateQuarterlyData();
    return this.quarterlyData;
  }

  getBarChartYearlyData(): YearWiseData[] {
    return this.yearWiseData;
  }

  getRepaymentEstimationData2(terms: TermRate,
                              repaymentFrequency: RepaymentFrequencyType,
                              repaymentType: RepaymentType): {amountFinance: number, data: {repayment: number, loanTerm: number}[]}[] {
    const basicInterest = terms.basicInterest;
    const totalInterest = terms.totalInterest;
    const principalAmount = terms.prinicipalAmount;
    const brokerageAmount = terms.brokerageAmount;
    const RV = terms.RV;
    const LVR = terms.LVR;
    const docFee = terms.docFee;
    const brokerOriginationFee = terms.brokerOriginationFee;

    return this.calculateTableRepayment(
      totalInterest,
      principalAmount,
      repaymentFrequency,
      brokerageAmount,
      RV,
      repaymentType
    )
  }

  // getRepaymentEstimationData(rateCard: RatecardDetails, form: RepaymentEstimationFormData,
  //                            repaymentFrequency: RepaymentFrequencyType,
  //                            repaymentType: RepaymentType,
  //                            vehicles: any[] = [], companyBureauReport: any = null,
  //                            fixedBalloonPayment: number | null = null) {
  //   const terms: any = this.getTotalInterestRateForAssetFinance(
  //     rateCard, form, vehicles, companyBureauReport, fixedBalloonPayment);
  //   const basicInterest = terms.basicInterest;
  //   const totalInterest = terms.totalInterest;
  //   const principalAmount = terms.prinicipalAmount;
  //   const brokerageAmount = terms.brokerageAmount;
  //   const RV = terms.RV;
  //   const LVR = terms.LVR;
  //   const docFee = terms.docFee;
  //   const brokerOriginationFee = terms.brokerOriginationFee;

  //   return this.calculateTableRepayment(
  //     totalInterest,
  //     principalAmount,
  //     repaymentFrequency,
  //     brokerageAmount,
  //     RV,
  //     repaymentType
  //   )
  // }

  getAmortisationData(): YearWiseDataWithChild[] {
    const yearWiseData: YearWiseDataWithChild[] = this.yearWiseData.map(r => ({ ...r, childRows: []}));
    const dataUnitsGroupByYears: any = {};
    console.log('****** dataUnits', this.dataUnits);
    for (let i = 0; i <= this.dataUnits.length - 1; i++) {
      if (dataUnitsGroupByYears[this.dataUnits[i].year] != undefined) {
        dataUnitsGroupByYears[this.dataUnits[i].year].push(this.dataUnits[i]);
      } else {
        dataUnitsGroupByYears[this.dataUnits[i].year] = [];
        dataUnitsGroupByYears[this.dataUnits[i].year].push(this.dataUnits[i]);
      }

    }
    for (let cr = 0; cr <= yearWiseData.length - 1; cr++) {
      yearWiseData[cr].childRows = dataUnitsGroupByYears[yearWiseData[cr].year];
    }
    return yearWiseData;
  }
}


export interface FormDataForBusinessFinance {
  loanAmount: number,
  loanTerms: number,
  propertyOwner: boolean,
  // docFeeFinanced: boolean,
  brokerage: number,
  adverseOnFile: boolean,
  lowEquifaxScore: boolean,
  directorScore: boolean,
  docFeeFinanced?: boolean,
  brokerageAmount?: number,
  creditRateAdjustment?: number,
  docFee?: number,
}

export interface FormDataForBusinessOverdraft {
  facilityLimit: number,
  facilityEstablishmentFeePercent:number,
  loanTerms: number,
  propertyOwner: boolean,
  // docFeeFinanced: boolean,
  // brokerage: number,
  adverseOnFile: boolean,
  lowEquifaxScore: boolean,
  directorScore: boolean,
  brokerageAmount?: number,
  docFee?: number,
  margin?: number,
  creditRateAdjustment?: number
}

export interface FormDataForCorporateLoan {
  facilityLimit: number,
  facilityEstablishmentFeePercent:number,
  loanTerms: number,
  propertyOwner: boolean,
  // docFeeFinanced: boolean,
  // brokerage: number,
  adverseOnFile: boolean,
  lowEquifaxScore: boolean,
  directorScore: boolean,
  brokerageAmount?: number,
  docFee?: number,
  margin?: number,
  creditRateAdjustment?: number,
  securityType?: SecurityTypeSelectionType,
  ltvType?: LtvSelectionType,
}

export interface InsurancePremiumCalculatorData {
  monthlyInstallment: number;
  applicationFee: number;
  brokerage: number;
}

export interface CommercialFinanceFormData {
  assetCondition: 'Used' | 'New',
  loanAmount: number,
  loanTerms: number,  // in months
  propertyOwner: boolean,
  docFeeFinanced: boolean,
  brokerOriginationFee: number,
  brokerage: number,
  adverseOnFile: boolean,
  equifaxScoreAbove600: boolean,
  privateSaleORLeaseback: boolean,
  assetCategory: string,
  assetType: string,
  assetSpec: {
    year: number,
  },
  balloonPaymentPercent: number,
  financeType: string,
}

export interface ConsumerFinanceFormData {
  assetCondition: 'Used' | 'New',
  loanAmount: number,
  invoiceAmount: number,
  loanTerms: number,  // in months
  propertyOwner: boolean,
  docFeeFinanced: boolean,
  brokerOriginationFee: number,
  adverseOnFile: boolean,
  equifaxScoreAbove600: boolean,
  privateSaleORLeaseback: boolean,
  assetCategory: string,
  assetType: string,
  assetSpec: {
    vehicle: any,
    year: number,
    goodretail?: number,
    newprice?: number,
  },
  balloonPaymentPercent: number,
  financeType: string,
  hybrid?: boolean,
  paymentType?: string,
  balloonPaymentAmount?: number,
  docFee?: number,
  creditRateAdjustment?: number,
  rateDiscount?: number;
  ppsrFee?: number;
  repaymentType ?: string|null
}

export interface RepaymentEstimationFormData {
  assetCondition: 'Used' | 'New',
  assetCategory: string,
  assetType: string,
  assetSpec: {
    year: number,
    vehicle: string,
    goodretail?: number,
    newprice?: number,
  },
  truckGrossWeight?: TruckGrossVehicleWeight,
  ageOfAssetToday: number,
  loanAmount: number,
  loanTerms: number,
  propertyOwnership: boolean,
  docFeeFinanced: boolean,
  brokerOriginationFee: number,
  brokerage: number,
  adverseOnFile: boolean,
  equifaxScoreAbove600: boolean,
  privateSaleOrLeaseback: boolean,
  balloonPayment: number,
  financeType: FinanceType | null,
  invoiceAmount?: number,
  deposit?: number,
  brokerageAmount?: number,
  balloonPaymentAmount?: number,
  transactionType?: TransactionType,
  creditRateAdjustment?: number
  docFee?: number,
}

export interface YearWiseData {
  beginningBalance: number,
  endingBalance: number,
  totalAmount: number,
  totalInterest: number,
  totalPrincipal: number,
  year: number,
  yearN: Date;
  yearInterest: number,
  yearPrincipal: number,
  yearTotal: number,
}

export interface YearWiseDataWithChild extends YearWiseData {
  childRows: DataUnitType[];
}

export interface MonthWiseData {
  beginningBalance: number,
  endingBalance: number,
  month: string,
  quarter: number,
  totalAmount: number,
  totalInterest: number,
  totalPrincipal: number,
  year: number,
  yearInterest: number,
  yearN: Date,
  yearPrincipal: number,
  yearTotal: number,
}

export interface DataUnitType {
  month: MonthType,
  index: number,
  totalInterest: number,
  totalAmount: number,
  emi: number,
  year: number,
  beginningBalance: number,
  interest: number,
  pricipalPaid: number,
  endingBalance:  number,
  date: string,
}

export interface TermRate {
  brokerage: number,
  brokerageAmount: number,
  basicInterest: number,
  totalInterest: number,
  prinicipalAmount: number,
  docFee: number,
  LVR: number,
  brokerOriginationFee: number,
  RV: number
}

export interface TermRateForAssetFinance {
  brokerage: number,
  brokerageAmount: number,
  basicInterest: number,
  totalInterest: number,
  prinicipalAmount: number,
  docFee: number,
  LVR: number,
  brokerOriginationFee: number,
  RV: number,
  calculationLog: {log: string, rate?: number}[],
}

export interface TermRateForConsumerFinance {
  brokerage: number,
  brokerageAmount: number,
  basicInterest: number,
  totalInterest: number,
  baseRate: number,
  prinicipalAmount: number,
  docFee: number,
  LVR: number,
  brokerOriginationFee: number,
  RV: number,
  amountForComparisonRate: number,
  monthlyAccountKeepingFee: number,
  calculationLog: {log: string, rate?: number}[],
}

export interface TermRateForCommercialFinance {
  brokerage: number,
  brokerageAmount: number,
  basicInterest: number,
  totalInterest: number,
  prinicipalAmount: number,
  docFee: number,
  LVR: number,
  brokerOriginationFee: number,
  RV: number,
  calculationLog: {log: string, rate?: number}[],
}

export interface TermRateForInsurancePremium {
  brokerage: number,
  brokerageAmount: number,
  basicInterest: number,
  totalInterest: number,
  prinicipalAmount: number,
  docFee: number,
  LVR: number,
  brokerOriginationFee: number,
  RV: number,
  calculationLog: {log: string, rate?: number}[],
}

export interface TermRateForBusinessFinance {
  brokerage: number,
  brokerageAmount: number,
  basicInterest: number,
  totalInterest: number,
  prinicipalAmount: number,
  docFee: number,
  LVR: number,
  brokerOriginationFee: number,
  RV: number,
  calculationLog: {log: string, rate?: number}[],
}

export interface TermRateForBusinessOverdraft {
  facilitylimit:number
  facilityEstablishmentFee: number
  facilityEstablishmentFeePercentage: number,
  monthlyFacilityFee:number
  docFee: number
  brokerage:number
  brokerageDollar: number
  amountAvailable:number
  interestRate: number,
  rbaRate: number,
  margin: number,
  monthlyFacilityFeePercentage: number,
  calculationLog: {log: string, rate?: number}[],
}


export interface TermRateForCorporateLoan {
  facilitylimit:number
  facilityEstablishmentFee: number
  facilityEstablishmentFeePercentage: number,
  monthlyFacilityFee:number
  docFee: number
  brokerage:number
  brokerageDollar: number
  amountAvailable:number
  interestRate: number,
  rbaRate: number,
  margin: number,
  monthlyFacilityFeePercentage: number,
  calculationLog: {log: string, rate?: number}[],
}


// ============= app-calculator related functions
export const calculateAssetFinanceEstimation = (
    date: Moment, data: AppCalculatorAssetFinanceData): CalculateAssetFinanceEstmationResult => {
    const paymentFrequencyType: PaymentFrequencyType | null = data.paymentFrequencyType;
    const assetConditionType: AssetConditionType | null = data.assetConditionType;
    const assetSelectionValue = data.assetSelectionValue;
    const assetYear = parseFloat(assetSelectionValue?.year ?? "0");
    const ageOfAssetToday: any = new Date().getFullYear() - assetYear;
    const loanTerms: number  = data.loanTerm;
    const balloonPayment: number  = data.balloonPayment;
    const financeType: FinanceType | null = data.financeType;

    const brokerageType: number = data.brokerage;
    const calculator = new AppCalculator();
    const loanAmount = numeral(data.loanAmount).value() ?? 0;
    const businessSearchValue = data.businessSearchValue;
    const formData: RepaymentEstimationFormData = {
        assetCondition: assetConditionType ?? 'Used',
        assetCategory: assetSelectionValue?.category.index ?? '',
        assetType: assetSelectionValue?.type.index ?? '',
        assetSpec: {
            year: assetYear,
            vehicle: assetSelectionValue?.model ?? '',
            goodretail: (assetSelectionValue?.vehicle?.goodretail) ?? 0,
            newprice: (assetSelectionValue?.vehicle?.newprice) ?? 0,
        },
        truckGrossWeight: assetSelectionValue?.truckGrossVehicleWeight ?? undefined,
        ageOfAssetToday: ageOfAssetToday,
        loanAmount: loanAmount,
        loanTerms: loanTerms,
        propertyOwnership: data.propertyOwnership ?? false,
        docFeeFinanced: data.docFeeFinanced ?? false,
        docFee: data.docFee,
        brokerOriginationFee: data.brokerOriginationFee,
        brokerage: Number(brokerageType ?? 0),
        adverseOnFile: data.adverseOnFile ?? false,
        equifaxScoreAbove600: data.equifaxScoreAbove600 ?? false,
        privateSaleOrLeaseback: data.privateSaleOrLeaseback ?? false,
        balloonPayment: balloonPayment,
        financeType: financeType ?? null,
        deposit: data?.deposit ?? 0,
        brokerageAmount: data?.brokerageAmount,
        balloonPaymentAmount: data.balloonPaymentAmount,
        transactionType: data.transactionType,
        creditRateAdjustment: data.creditRateAdjustment,
        invoiceAmount: numeral(data.invoiceAmount).value() ?? 0
    }
    // NOTE: use result only when it is an ASIC/ABN search
    const bureauReport = (businessSearchValue?.type === 'search-result' && businessSearchValue?.result) ? businessSearchValue.result : data.existingApplicationBureauReport;
    console.log('===bureauReport: ', bureauReport);
    const terms = calculator.getTotalInterestRateForAssetFinance(
        data.rateCard!,
        formData,
        assetSelectionValue?.vehicles ?? [],
        bureauReport,
        // Number(balloonPaymentType?.type ?? '0'),
    );
    console.log("termsterms",terms);
    calculator.setRepaymentType(data.paymentType ? data.paymentType.toLowerCase() : "advance");
    calculator.setDateValue(date.format('DD/MM/YYYY'));
    calculator.setRepaymentFrequency(paymentFrequencyType ?? 'Monthly');
    calculator.setPrinicipalValue(terms.prinicipalAmount);
    calculator.setInterestValue(terms.totalInterest);
    calculator.setloanValue(loanTerms);
    calculator.setResidualAmount(terms.RV);
    calculator.setBrokerageAmount(terms.brokerageAmount);
    calculator.refreshUI();

    const repayment = calculator.emiAmt;
    const rst1 = calculator.getDonutChartData();
    const rst2 = calculator.getBarChartYearlyData();
    const rst3 = calculator.getBarChartQuaterlyData();
    const rst4 = calculator.getRepaymentEstimationData2(
        terms,
        paymentFrequencyType ?? 'Monthly',
        'advance',
    );
    const rst5 = calculator.getAmortisationData();
    const x: TotalPaymentBreakupDialogData = {
        repaymentEstimationData: rst4.map((rst) => ({
            amountFinance: String(rst.amountFinance),
            month24: String(rst.data.find((d) => d.loanTerm == 24)?.repayment ?? 0),
            month36: String(rst.data.find((d) => d.loanTerm == 36)?.repayment ?? 0),
            month48: String(rst.data.find((d) => d.loanTerm == 48)?.repayment ?? 0),
            month60: String(rst.data.find((d) => d.loanTerm == 60)?.repayment ?? 0),
        })),
        amortizationScheduleData: rst5.map(rst => ({
            year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
            details: rst.childRows.map(d => ({
                monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
                principal: String(d.pricipalPaid), balance: String(d.endingBalance)
            }))
        })),
        paymentChartData: {
            amountFinanced: rst1.pricipalAmt,
            totalInterest: terms.totalInterest,
            emiAmt: calculator.emiAmt,
            paymentFrequency: calculator.repaymentFrequency,
            principalAmt: calculator.principalAmt,
            interestAmt: calculator.interestAmt,
            totalAmt: calculator.totalAmt,
            loanTerm: calculator.loanValue, // loanValue from calculator
            lvr: terms.LVR,
            rv: terms.RV,
            brokerageAmount: calculator.brokerageAmount,
            docFee: terms.docFee,
            brokerOriginationFee: terms.brokerOriginationFee,
            applicationType: 'AssetFinance',
            invoiceAmount: data.invoiceAmount,
            deposit: data.deposit
        },
        amortizationChartData: {
            estimatedDrawdownDate: moment(calculator.dateValue),
            annualData: rst2.map(rst => ({
                year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
            })),
            quarterlyData: rst3.map(rst => ({
                quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
            }))
        },
        calculationLog: terms.calculationLog,
    };

    console.log('*************** getRepaymentEstimationData rateCard', data.rateCard);
    console.log('*************** getRepaymentEstimationData formData', formData);
    console.log('*************** getRepaymentEstimationData vehicles', assetSelectionValue?.vehicles);
    console.log('*************** getRepaymentEstimationData DonutChartData', rst1);
    console.log('*************** getRepaymentEstimationData BarChartYearlyData', rst2);
    console.log('*************** getRepaymentEstimationData BarChartQuaterlyData', rst3);
    console.log('*************** getRepaymentEstimationData RepaymentEstimationData', rst4);
    console.log('*************** getRepaymentEstimationData AmortisationData', rst5);

    return {
        repayment,
        calculator,
        terms,
        totalPaymentBreakupDialogData: x,
    };
}

export const calculateSolarAssetFinanceEstimation = (
    date: Moment, data: {
        paymentFrequencyValue: PaymentFrequencyValue,
        assetConditionValue: AssetConditionValue,
        assetSelectionValue: AssetCategoryTypeSelectionValue,
        assetCategory: { index: '106', value: '' },
        assetYear: number,
        loanTermValue: LoanTermValue,
        financeType: FinanceTypeValue,
        balloonPaymentType: BalloonPaymentValue,
        brokerageValue: BrokerageSelectionValue,
        loanAmount: number,
        businessSearchValue: BusinessSearchValue,
        existingApplicationBureauReport: SearchCompanyByABNResult | null,
        propertyOwnership: boolean,
        docFeeFinanced: boolean,
        brokerOriginationFee: number,
        brokerage: number,
        adverseOnFile: boolean,
        equifaxScoreAbove600: boolean,
        privateSaleOrLeaseback: boolean,
        balloonPayment: number,
        rateCard: RatecardDetails | null,
    }): {
    terms: TermRateForAssetFinance,
    calculator: AppCalculator,
    repayment: number,
    totalPaymentBreakupDialogData: TotalPaymentBreakupDialogData
} => {
    const paymentFrequencyValue: PaymentFrequencyValue = data.paymentFrequencyValue;
    const assetConditionValue: AssetConditionValue = data.assetConditionValue;
    const assetSelectionValue: AssetCategoryTypeSelectionValue = data.assetSelectionValue;
    const assetYear = parseFloat("0");
    const ageOfAssetToday: any = new Date().getFullYear() - assetYear;
    const loanTermValue: LoanTermValue = data.loanTermValue;
    const financeType: FinanceTypeValue = data.financeType;
    const balloonPaymentType: BalloonPaymentValue = data.balloonPaymentType;
    const brokerageValue: BrokerageSelectionValue = data.brokerageValue;
    const calculator = new AppCalculator();
    const loanAmount = numeral(data.loanAmount).value() ?? 0;
    const businessSearchValue: BusinessSearchValue = data.businessSearchValue;
    const formData: any = {
        assetCondition: assetConditionValue?.type ?? 'Used',
        assetCategory: data.assetCategory.index ?? '',
        assetType: assetSelectionValue?.index ?? '',
        assetSpec: {
            year: assetYear
        },
        ageOfAssetToday: ageOfAssetToday,
        loanAmount: loanAmount,
        loanTerms: parseFloat(loanTermValue?.type ?? '0'),
        propertyOwnership: data.propertyOwnership,
        docFeeFinanced: data.docFeeFinanced,
        brokerOriginationFee: data.brokerOriginationFee,
        brokerage: Number(brokerageValue?.type ?? 0),
        adverseOnFile: data.adverseOnFile,
        equifaxScoreAbove600: data.equifaxScoreAbove600,
        privateSaleOrLeaseback: data.privateSaleOrLeaseback,
        balloonPayment: parseFloat(balloonPaymentType?.type ?? '0'),
        financeType: financeType?.name ?? '',
    }
    // NOTE: use result only when it is an ASIC/ABN search
    const bureauReport = (businessSearchValue?.type === 'search-result' && businessSearchValue?.result) ? businessSearchValue.result : data.existingApplicationBureauReport;
    const terms = calculator.getTotalInterestRateForAssetFinance(
        data.rateCard!,
        formData,
        [],
        bureauReport,
        // Number(balloonPaymentType?.type ?? '0'),
    );
    calculator.setDateValue(date.format('DD/MM/YYYY'));
    calculator.setRepaymentFrequency(paymentFrequencyValue?.type ?? 'Monthly');
    calculator.setPrinicipalValue(terms.prinicipalAmount);
    calculator.setInterestValue(terms.totalInterest);
    calculator.setloanValue(Number(loanTermValue?.type ?? '0'));
    calculator.setResidualAmount(terms.RV);
    calculator.setBrokerageAmount(terms.brokerageAmount);
    calculator.refreshUI();

    const repayment = calculator.emiAmt;
    const rst1 = calculator.getDonutChartData();
    const rst2 = calculator.getBarChartYearlyData();
    const rst3 = calculator.getBarChartQuaterlyData();
    const rst4 = calculator.getRepaymentEstimationData2(
        terms,
        paymentFrequencyValue?.type ?? 'Monthly',
        'advance',
    );
    const rst5 = calculator.getAmortisationData();
    const x: TotalPaymentBreakupDialogData = {
        repaymentEstimationData: rst4.map((rst) => ({
            amountFinance: String(rst.amountFinance),
            month24: String(rst.data.find((d) => d.loanTerm == 24)?.repayment ?? 0),
            month36: String(rst.data.find((d) => d.loanTerm == 36)?.repayment ?? 0),
            month48: String(rst.data.find((d) => d.loanTerm == 48)?.repayment ?? 0),
            month60: String(rst.data.find((d) => d.loanTerm == 60)?.repayment ?? 0),
        })),
        amortizationScheduleData: rst5.map(rst => ({
            year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
            details: rst.childRows.map(d => ({
                monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
                principal: String(d.pricipalPaid), balance: String(d.endingBalance)
            }))
        })),
        paymentChartData: {
            amountFinanced: rst1.pricipalAmt,
            totalInterest: terms.totalInterest,
            emiAmt: calculator.emiAmt,
            paymentFrequency: calculator.repaymentFrequency,
            principalAmt: calculator.principalAmt,
            interestAmt: calculator.interestAmt,
            totalAmt: calculator.totalAmt,
            loanTerm: calculator.loanValue, // loanValue from calculator
            lvr: terms.LVR,
            rv: terms.RV,
            brokerageAmount: calculator.brokerageAmount,
            docFee: terms.docFee,
            brokerOriginationFee: terms.brokerOriginationFee,
            applicationType: 'AssetFinance'
        },
        amortizationChartData: {
            estimatedDrawdownDate: moment(calculator.dateValue),
            annualData: rst2.map(rst => ({
                year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
            })),
            quarterlyData: rst3.map(rst => ({
                quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
            }))
        },
        calculationLog: terms.calculationLog,
    };

    console.log('*************** getRepaymentEstimationData rateCard', data.rateCard);
    console.log('*************** getRepaymentEstimationData formData', formData);
    // console.log('*************** getRepaymentEstimationData vehicles', assetSelectionValue?.vehicles);
    console.log('*************** getRepaymentEstimationData DonutChartData', rst1);
    console.log('*************** getRepaymentEstimationData BarChartYearlyData', rst2);
    console.log('*************** getRepaymentEstimationData BarChartQuaterlyData', rst3);
    console.log('*************** getRepaymentEstimationData RepaymentEstimationData', rst4);
    console.log('*************** getRepaymentEstimationData ArmotisationData', rst5);

    return {
        repayment,
        calculator,
        terms,
        totalPaymentBreakupDialogData: x,
    };
}

export const calculateConsumerAssetFinanceEstimation = (
    date: Moment, data: AppCalculatorConsumerAssetFinanceData): CalculateConsumerAssetFinanceEstimationResult => {
    const paymentFrequencyType: PaymentFrequencyType | null = data.paymentFrequencyType ?? "Monthly";
    const assetConditionType: AssetConditionType | null = data.assetConditionType;
    const assetSelectionValue = data.assetSelectionValue;
    const assetYear = parseFloat(assetSelectionValue?.year ?? "0");
    const loanTerm: number = data.loanTerm;
    const financeType: FinanceType | null = data.financeType;
    const balloonPayment: number | null = data.balloonPayment;
    const calculator = new AppCalculator();
    const loanAmount = numeral(data.loanAmount).value() ?? 0;

    const formData: ConsumerFinanceFormData = {
        assetCondition: assetConditionType ?? 'Used',
        assetCategory: assetSelectionValue?.category.index ?? '',
        assetType: assetSelectionValue?.type.index ?? '',
        assetSpec: {
            year: assetYear,
            vehicle: assetSelectionValue?.model ?? '',
            goodretail: (assetSelectionValue?.vehicle?.goodretail) ?? 0,
            newprice: (assetSelectionValue?.vehicle?.newprice) ?? 0,
        },
        loanAmount: loanAmount,
        loanTerms: loanTerm,
        propertyOwner: data.propertyOwnership,
        docFeeFinanced: data.docFeeFinanced,
        brokerOriginationFee: data.brokerOriginationFee,
        //brokerage: Number(brokerageType ?? 0),
        adverseOnFile: data.adverseOnFile,
        equifaxScoreAbove600: data.equifaxScoreAbove600,
        privateSaleORLeaseback: data.privateSaleOrLeaseback,
        balloonPaymentPercent: balloonPayment,
        financeType: financeType ?? '',
        balloonPaymentAmount: data.balloonPaymentAmount,
        //brokerageAmount: data.brokerageAmount,
        docFee: data.docFee,
        paymentType: data.paymentType,
        hybrid: data.hybrid,
        creditRateAdjustment: data.creditRateAdjustment,
        ppsrFee: data.ppsrFee,
        rateDiscount: data.rateDiscount,
        invoiceAmount:data.invoiceAmount
    }
    console.log('====formData: ', formData);
    const terms = calculator.getTotalInterestRateForConsumerFinance(
        data.rateCard!,
        formData,
        [],
        // Number(balloonPaymentType?.type ?? '0'),
    );
    console.log('***************************** calculateInterest', terms);
    calculator.setRepaymentType(data.repaymentType ? data.repaymentType.toLowerCase() : 'arrears');
    calculator.setDateValue(date.format('DD/MM/YYYY'));
    calculator.setRepaymentFrequency(paymentFrequencyType);
    calculator.setAmountForComparisonRate(terms.amountForComparisonRate);
    calculator.setPrinicipalValue(terms.prinicipalAmount);
    calculator.setInterestValue(terms.totalInterest);
    calculator.setBaseRate(terms.baseRate);
    calculator.setloanValue(loanTerm);
    calculator.setResidualAmount(terms.RV);
    calculator.setBrokeragePercent(data.brokerage);
    calculator.setBrokerageAmount(terms.brokerageAmount);
    calculator.setBrokerOriginationFee(data.brokerOriginationFee ?? 0);
    calculator.setPercentagePaidToDealerOrBroker(data.percentagePaidToDealerOrBroker ?? 0)
    calculator.setMonthlyAccountKeepingFee(data.monthlyAccountKeepingFee ?? 0);
    calculator.refreshUI();

    const repayment = calculator.emiAmt;
    const rst1 = calculator.getDonutChartData();
    const rst2 = calculator.getBarChartYearlyData();
    const rst3 = calculator.getBarChartQuaterlyData();
    const rst4 = calculator.getRepaymentEstimationData2(
        terms,
        paymentFrequencyType,
        'advance',
    );
    const rst5 = calculator.getAmortisationData();

    const repaymentEstimationData = rst4.map((rst) => ({
        amountFinance: String(rst.amountFinance),
        month24: String(rst.data.find((d) => d.loanTerm == 24)?.repayment ?? 0),
        month36: String(rst.data.find((d) => d.loanTerm == 36)?.repayment ?? 0),
        month48: String(rst.data.find((d) => d.loanTerm == 48)?.repayment ?? 0),
        month60: String(rst.data.find((d) => d.loanTerm == 60)?.repayment ?? 0),
    }));
    const amortizationScheduleData = rst5.map(rst => ({
        year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
        details: rst.childRows.map(d => ({
            monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
            principal: String(d.pricipalPaid), balance: String(d.endingBalance)
        }))
    }));
    const paymentChartData = {
        amountFinanced: rst1.pricipalAmt,
        totalInterest: terms.totalInterest,
        emiAmt: calculator.emiAmt,
        paymentFrequency: calculator.repaymentFrequency,
        principalAmt: calculator.principalAmt,
        interestAmt: calculator.interestAmt,
        totalAmt: calculator.totalAmt,
        loanTerm: calculator.loanValue, // loanValue from calculator
        lvr: terms.LVR,
        rv: terms.RV,
        brokerageAmount: terms.brokerageAmount,
        docFee: terms.docFee,
        brokerOriginationFee: terms.brokerOriginationFee,
        applicationType: 'Consumer',
        invoiceAmount: data.invoiceAmount,
        deposit: data.deposit,
        displayedInterest: calculator.displayedInterest,
        commission: calculator.commission,
        comparisionRate: calculator.comparisonRate,
        totalEarned: calculator.totalEarned,
        totalEarnedInclGst: calculator.totalEarnedInclGst,
        pvOfDifferenceOfRepayments: calculator.pvOfDifferenceOfRepayments,
        amountToUs: calculator.amountToUs,
        monthlyAccountKeepingFee: calculator.monthlyAccountKeepingFee,
        repaymentPlusMonthlyAccountKeepingFee: calculator.emiAmtPlusMonthlyAccountKeepingFee,
    };
    const amortizationChartData = {
        estimatedDrawdownDate: moment(calculator.dateValue),
        annualData: rst2.map(rst => ({
            year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
        })),
        quarterlyData: rst3.map(rst => ({
            quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
        }))
    };

    return {
        repayment,
        calculator,
        terms,
        totalPaymentBreakupDialogData: {
            repaymentEstimationData,
            amortizationScheduleData,
            paymentChartData,
            amortizationChartData,
            calculationLog: terms.calculationLog,
        },
    }
}


export const calculateBusinessLoanEstimation = (date: Moment, data: AppCalculatorBusinessLoanData): CalculateBusinessLoanEstimationResult => {
    const paymentFrequencyValue: PaymentFrequencyType | null = data.paymentFrequencyType ?? "Monthly";
    const loanTermType: LoanTermType | null = data.loanTermType;
    const loanTerms = Number(loanTermType ?? 0);
    const brokerageType: BrokerageSelectionType | null = data.brokerageType;
    const brokerageValue: number = Number(brokerageType ?? 0);
    const loanAmount: number = Number(data.loanAmount ?? 0);
    const docFeeFinanced = !!data?.docFeeFinanced;
    const paymentType = (data?.paymentType ?? "arrears").toLowerCase();

    const calculator = new AppCalculator();
    const formData: FormDataForBusinessFinance = {
        loanAmount: loanAmount,
        lowEquifaxScore: data.lowEquifaxScore,
        adverseOnFile: data.adverseOnFile,
        brokerage: brokerageValue,
        propertyOwner: data.propertyOwner,
        loanTerms: loanTerms,
        directorScore: data.directorScore,
        brokerageAmount: data.brokerageAmount,
        docFeeFinanced,
        docFee: data.docFee,
        creditRateAdjustment: data.creditRateAdjustment ?? 0, // handle creditRateAdjustment
    }

    const terms = calculator.getTotalInterestRateForBusinessFinance(
        data.rateCard!,
        formData,
        data.bureauReport,
    );
    calculator.setRepaymentType(paymentType);
    calculator.setRepaymentFrequency(paymentFrequencyValue as any ?? 'Monthly');
    calculator.setPrinicipalValue(terms.prinicipalAmount);
    calculator.setInterestValue(terms.totalInterest);
    calculator.setloanValue(Number(loanTermType ?? '0'));
    calculator.setResidualAmount(terms.RV);
    calculator.setBrokeragePercent(Number(brokerageType ?? '0'));
    calculator.setBrokerageAmount(terms.brokerageAmount);
    calculator.setDateValue(date.format('DD/MM/YYYY'));
    calculator.refreshUI();

    const repayment = calculator.emiAmt;
    const rst1 = calculator.getDonutChartData();
    const rst2 = calculator.getBarChartYearlyData();
    const rst3 = calculator.getBarChartQuaterlyData();
    const rst4 = calculator.getRepaymentEstimationData2(
        terms,
        paymentFrequencyValue as any ?? 'Monthly',
        'advance',
    );
    const rst5 = calculator.getAmortisationData();

    const repaymentEstimationData = rst4.map((rst) => ({
        amountFinance: String(rst.amountFinance),
        month24: String(rst.data.find((d) => d.loanTerm == 24)?.repayment ?? 0),
        month36: String(rst.data.find((d) => d.loanTerm == 36)?.repayment ?? 0),
        month48: String(rst.data.find((d) => d.loanTerm == 48)?.repayment ?? 0),
        month60: String(rst.data.find((d) => d.loanTerm == 60)?.repayment ?? 0),
    }));
    const amortizationScheduleData = rst5.map(rst => ({
        year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
        details: rst.childRows.map(d => ({
            monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
            principal: String(d.pricipalPaid), balance: String(d.endingBalance)
        }))
    }));
    const paymentChartData = {
        amountFinanced: rst1.pricipalAmt,
        totalInterest: terms.totalInterest,
        emiAmt: calculator.emiAmt,
        paymentFrequency: calculator.repaymentFrequency,
        principalAmt: calculator.principalAmt,
        interestAmt: calculator.interestAmt,
        totalAmt: calculator.totalAmt,
        loanTerm: calculator.loanValue, // loanValue from calculator
        lvr: terms.LVR,
        rv: terms.RV,
        brokerageAmount: terms.brokerageAmount,
        docFee: terms.docFee,
        brokerOriginationFee: terms.brokerOriginationFee,
        applicationType: 'BusinessLoans'
    };
    const amortizationChartData = {
        estimatedDrawdownDate: moment(calculator.dateValue),
        annualData: rst2.map(rst => ({
            year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
        })),
        quarterlyData: rst3.map(rst => ({
            quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
        }))
    };

    return {
        repayment,
        calculator,
        terms,
        totalPaymentBreakupDialogData: {
            repaymentEstimationData,
            amortizationScheduleData,
            paymentChartData,
            amortizationChartData,
            calculationLog: terms.calculationLog,
            // isBusinessLoan: true,
            // hideBrokerage: !!data.hideBrokerage,
        },
    }
}

export const calculateBusinessOverdraftEstimation = (date: Moment, data: AppCalculatorBusinessOverdraftData): CalculateBusinessOverdraftEstimationResult => {
    const calculator = new AppCalculator();
    const formData: FormDataForBusinessOverdraft = {
        facilityLimit: data.facilityLimit,
        facilityEstablishmentFeePercent: data.facilityEstablishmentFeePercent,
        loanTerms: Number(data.loanTermType ?? 0),
        propertyOwner: data.propertyOwner,
        adverseOnFile: data.adverseOnFile,
        lowEquifaxScore: data.lowEquifaxScore,
        directorScore: data.directorScore,
        docFee: data.docFee,
        brokerageAmount: data.brokerageAmount,
        creditRateAdjustment: data.creditRateAdjustment ?? 0,
    };
    calculator.setRepaymentFrequency('Monthly');
    calculator.setloanValue(Number(data.loanTermType ?? 0));
    calculator.refreshUI();

    const term = calculator.getTotalInterestRateForBusinessOverdraft(data.rateCard!, formData)
    return {
      calculator,
      term,
    };
}

export const calculateCorporateLoanEstimation = (date: Moment, data: AppCalculatorCorporateLoanData): CalculateCorporateLoanEstmationResult => {
  const calculator = new AppCalculator();
  const formData: FormDataForCorporateLoan = {
    facilityLimit: data.facilityLimit,
    facilityEstablishmentFeePercent: data.facilityEstablishmentFeePercent,
    loanTerms: Number(data.loanTermType ?? 0),
    propertyOwner: data.propertyOwner,
    adverseOnFile: data.adverseOnFile,
    lowEquifaxScore: data.lowEquifaxScore,
    directorScore: data.directorScore,
    docFee: data.docFee,
    brokerageAmount: data.brokerageAmount,
    creditRateAdjustment: data.creditRateAdjustment ?? 0,
    securityType: data.securityType,
    ltvType: data.ltvType
  };
  calculator.setRepaymentFrequency('Monthly');
  calculator.setloanValue(Number(data.loanTermType ?? 0));
  calculator.refreshUI();

  const term = calculator.getTotalInterestRateForCorporateLoan(data.rateCard!, formData)
  return {
    calculator,
    term,
  };
}

export const isAddressEquals = (address1: any, address2: any) => {
    if (_.isEqual(address1, address2)) {
        return true;
    } else {
        return false;
    }
}

