import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  ApplicationWebService,
  MoreInformationDialogData, MoreInformationDialogResult, UserandPriviledges, WebServiceJsonDialogData, WebServiceJsonDialogResult, stringifyJSON
} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {PortalHotToastService, setupUntilDestroy, validateEmail} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatDividerModule } from '@angular/material/divider';
import { ClipboardModule } from '@angular/cdk/clipboard';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './web-service-json.dialog.html',
    styleUrls: ['./web-service-json.dialog.scss'],
    standalone: true,
    imports: [MatDividerModule, FlexModule, MatFormFieldModule, ClipboardModule, NgxJsonViewerModule, MatChipsModule, FormsModule, ReactiveFormsModule, CustomAngularEditorComponent, MatCheckboxModule, MatButtonModule, MatDialogModule]
})
export class WebServiceJsonDialog implements OnInit{

  webService!: ApplicationWebService;
  header: string = '';
  json: Object = {};
  jsonString = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WebServiceJsonDialogData,
    private fb: FormBuilder,
    private toastService: PortalHotToastService,
    private matDialogRef: MatDialogRef<WebServiceJsonDialog, WebServiceJsonDialogResult>
  ) {
    this.webService = this.data.webService;
    this.header = this.webService.type;
    this.json = this.webService.json;
    this.jsonString = stringifyJSON(this.json);
  }

  ngOnInit() {
    setupUntilDestroy(this);
  }

  onCancel() {
    this.matDialogRef.close();
  }

  onCopy() {
    this.toastService.snackbar("JSON data has been copied into the clipboard");
  }
}
