<div class="asset-credit-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="Credit Decisoning"><div *ngTemplateOutlet="creditDecisoningTab"></div></mat-tab>
      <mat-tab label="DSCR"><div *ngTemplateOutlet="dscrTab"></div></mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

<ng-template #dscrTab>
  <dscr-calculator
    [application]="application"
    [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
    [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn">
  </dscr-calculator>
</ng-template>

<ng-template #creditDecisoningTab>
  <credit-overview
    [application]="application"
    [runCreditFlowFn]="runCreditFlowFn"
    [getCreditWebServicesFn]="getCreditWebServicesFn"
    [getApplicationCreditFlowResultFn]="getApplicationCreditFlowResultFn"
    [downloadCreditorWatchReportFn]="downloadCreditorWatchReportFn"
    [updateCreditStatusFn]="updateCreditStatusFn"
    [getCreditAuditLogFn]="getCreditAuditLogFn"
    [getApplicationByIdFn]="getApplicationByIdFn"
    [getDuplicatedApplicationsFn]="getDuplicatedApplicationsFn"
    [deleteWebServicesFn]="deleteWebServicesFn"
    [apiUrl]="apiUrl"
    [requestMoreInformationFn]="requestMoreInformationFn"
    [getUserFn]="getUserFn"
    [getApplicationOwnerFn]="getApplicationOwnerFn"
    (viewReport)="onViewReport($event)"
    (clickApplicationEvent)="onClick($event)"
    (refreshCreditTabEvent)="refreshCreditTab($event)"
    ></credit-overview>
</ng-template>
