<div class="insurance-app-component">
  <mat-card appearance="outlined">
    <mat-tab-group [mat-stretch-tabs]="false" mat-align-tabs="start">
      <mat-tab label="APPLICANT"><div *ngTemplateOutlet="applicantTab;"></div></mat-tab>
      <!-- <mat-tab label="DIRECTORS"><div *ngTemplateOutlet="directorsTab"></div></mat-tab> -->
      <mat-tab label={{getOrganisationTypeForTitle(application)}}><div *ngTemplateOutlet="directorsTab"></div></mat-tab>
      <mat-tab label="AUTHORISED SIGNATORY"><div *ngTemplateOutlet="authorisedSignatoryTab"></div></mat-tab>
      <mat-tab label="PRIMARY CONTACT"><div *ngTemplateOutlet="primaryContactTab"></div></mat-tab>
      <mat-tab label="POLICIES"><div *ngTemplateOutlet="policiesTab"></div></mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

<ng-template #applicantTab>
  <div class="row separator header flex space-between align-center"
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="center start">
    @if (applicationStatus && applicationStatus==='Under Review') {
      <div class="col-md-3 under-review">
        <status-box type="under-review"></status-box>
      </div>
    } @else {
      <div></div>
    }
    <div fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="center start">
      @if (applicationStatus !== 'Closed') {
        <button mat-flat-button class="inactive ml-2 w-lt-md-100" (click)="onWithdrawApplication()">
          <span class="mdi mdi-arrow-u-left-bottom" matPrefix></span>
          Withdraw Application
        </button>
      }
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Application ID</div>
        <div body>{{getBrokerApplicationId(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Salesforce ID</div>
        <div body>{{getAppSalesforceId(application)}}</div>
      </cell>
    </div>
    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Broker name</div>
        <div body>{{getAppInfoBrokerEntityName(application)}}</div>
      </cell>
    </div>
  </div>
  @if (isInternalUser()) {
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Submitter's name</div>
          <div body>
            <user-selection
              [getFunc]="getUsersInCompanyFn"
              (events)="emitChangeSubmitterEvent($event)"
              [companySalesforceId]="application.AppInfoBrokerSalesforceID"
              [userId]="application.UserId">
            </user-selection>
          </div>
        </cell>
      </div>
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Sales Contact</div>
          <div body>{{getOpportunityOwnerEmail(application)}}</div>
        </cell>
      </div>
      @if (application.AdditionalBrokerCorrespondent) {
        <div class="col-lg-6 col-md-12">
          <cell>
            <div label>Additional Correspondent's name</div>
            <div body>
              <user-selection
                [getFunc]="getUsersInCompanyFn"
                (events)="emitChangeAdditionalCorrespondentEvent($event)"
                [companySalesforceId]="application.AppInfoBrokerSalesforceID"
                [userId]="application.AdditionalBrokerCorrespondent.UserId">
              </user-selection>
            </div>
          </cell>
        </div>
      }
    </div>
  }
  <mat-divider [inset]="true"></mat-divider>

  <div class="row">
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Premium amount</div>
        <div body><span class="mdi mdi-currency-usd"></span>{{getTotalAmount(application) | looseCurrency:'':''}}</div>
      </cell>
    </div>
    <div class="col-lg-3 col-md-12">
      <cell>
        <div label>Application fee</div>
        @if (data && data.applicationFee) {
          <div body><span class="mdi mdi-currency-usd"></span>{{(data.applicationFee != undefined ? data.applicationFee : getDocFee(application)) | looseCurrency:'':''}}</div>
        }
      </cell>
    </div>

    <div class="col-lg-6 col-md-12">
      <cell>
        <div label>Repayment</div>
        @if (data && data.monthlyInstallment) {
          <div body><span class="mdi mdi-currency-usd"></span>{{(data.monthlyInstallment != undefined ? data.monthlyInstallment : getRepayment(application)) | looseCurrency:'':''}} paid in {{getLoanTerms(application)}} {{getPaymentPeriod(application)}} installments</div>
        }
      </cell>
    </div>
  </div>
  <mat-divider [inset]="true"></mat-divider>


  <div class="section">
    <div class="row">
      <div class="col-12">
        <risk-analysis [application]="application"></risk-analysis>
      </div>
    </div>
  </div>


  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Broker Contact</div>
          <div body>{{getBrokerContact(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Company name</div>
          <div body>{{getCompanyName(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Organisation type</div>
          <div body>{{getOrganisationType(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>ABN</div>
          <div body>{{getAbn(application)}}</div>
        </cell>
      </div>
      @if (getOrganisationType(application)==='Company') {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>ACN</div>
            <div body>{{getAcn(application)}}</div>
          </cell>
        </div>
      }
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>


  <div class="section">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Finance type</div>
          <div body>{{getFinanceType(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>


  <div class="section">
    <div class="row">
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Revenue</div>
          <div body><span class="mdi mdi-currency-usd"></span>{{getRevenue(application) | looseCurrency:'':''}}</div>
        </cell>
      </div>
      @if (getLimitRequest(application)) {
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Limit request</div>
            <div body>{{getLimitRequest(application)}}</div>
          </cell>
        </div>
      }
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Commercial premise</div>
          <div body>{{getOperateInCommercialPremises(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Primary industry</div>
          <div body>{{getApplicationPrimaryIndustry(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Industry sector</div>
          <div body>{{getIndustrySector(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <cell>
          <div label>Primary business address</div>
          <div body>{{getPrimaryBusinessAddress(application)}}</div>
        </cell>
      </div>
      <div class="col-lg-3 col-md-12">
        <cell>
          <div label>Business landline</div>
          <div body><!--span>+61</span-->{{getBusinessLandline(application)}}</div>
        </cell>
      </div>
    </div>
    <mat-divider [inset]="true"></mat-divider>
  </div>

  @if (getApplicationNotes(application)) {
    <div class="section">
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Application notes</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <cell>
            <div body>{{getApplicationNotes(application)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    </div>
  }
</ng-template>


<ng-template #directorsTab>
  <individual-details [application]="application" type="all-non-guarantors"></individual-details>
</ng-template>
<ng-template #authorisedSignatoryTab>
  <authorised-signatories-details [application]="application"></authorised-signatories-details>
</ng-template>
<ng-template #primaryContactTab>
  <primary-contact-details [application]="application"></primary-contact-details>
</ng-template>
<ng-template #policiesTab>
  <policies-details [application]="application"></policies-details>
</ng-template>
