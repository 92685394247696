<div class="overdraft-account-details-component">

  @if (loader.loadingOverdraft.inProgress$ | async) {
    <custom-content-loader type="list"></custom-content-loader>
  }
  @if (!(loader.loadingOverdraft.inProgress$ | async) && pismoAccountAndOpportunityDetails) {
    <div class="row mb-5">
      <div class="col-12">
        <mat-accordion>
          @for (pismoAccountAndOpportunityDetail of pismoAccountAndOpportunityDetails; track pismoAccountAndOpportunityDetail; let isFirstExpansionPanel = $first; let i = $index) {
            <mat-expansion-panel [expanded]="isFirstExpansionPanel">
              <mat-expansion-panel-header>
                <mat-panel-title>Account # {{pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id}}</mat-panel-title>
                <mat-panel-description>Name: {{pismoAccountAndOpportunityDetail.pismoAccountDetails.account_name}} - {{pismoAccountAndOpportunityDetail.pismoAccountDetails.email}}</mat-panel-description>
              </mat-expansion-panel-header>
              @if (canAccess) {
              <div class="row mb-3">
                <div class="col-12">
                  <div fxLayoutGap="5px"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center start">
                    @if (canEdit) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="onCreateNewOverdraftUser(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)">
                        <span class="mdi mdi-plus" matPrefix></span> New user
                      </button>
                    }
                    @if (canEdit) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="postPismoManualTransaction(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)">
                        <span class="mdi mdi-bank" matPrefix></span> Post manual transaction
                      </button>
                    }
                    @if (canEdit) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="editPismoAccount(pismoAccountAndOpportunityDetail)">
                        <span class="mdi mdi-text-account" matPrefix></span> Change account details
                      </button>
                    }
                    @if (canEdit && pismoAccountAndOpportunityDetail?.pismoAccountDetails?.status !== 'CANCELLED') {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="onCancelAccount(pismoAccountAndOpportunityDetail)">
                        <span class="mdi mdi-close" matPrefix></span> Account closure
                      </button>
                    }
                    @if (canEdit) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="generatePayout(pismoAccountAndOpportunityDetail)">
                        <span class="mdi mdi-cash-refund" matPrefix></span> Generate payout
                      </button>
                    }
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <div fxLayoutGap="5px"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayout.lt-md="column"
                    fxLayoutAlign.lt-md="center start">
                    @if (canEdit) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="limitIncrease(pismoAccountAndOpportunityDetail)">
                        <span class="mdi mdi-bank-plus" matPrefix></span> Limit increase
                      </button>
                    }
                    @if (canEdit) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="directDebit(pismoAccountAndOpportunityDetail)">
                        <span class="mdi mdi-bank-transfer-out" matPrefix></span> Direct debit
                      </button>
                    }
                    @if (showDisplayTransactions) {
                      <button mat-stroked-button color="accent" class="w-lt-md-100" (click)="displayPismoAccountTransaction(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)">
                        <span class="mdi mdi-bank" matPrefix></span> Display transactions
                      </button>
                    }
                  </div>
                </div>
              </div>
              }
            @if (!canEdit) {
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <message-box title="Info" type="info">
                    Only Admin, Operations and Analyst can perform edit.
                  </message-box>
                </div>
              </div>
              }
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Pismo Account Number</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Account Name</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.account_name}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Status</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.status}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Program</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.program_name}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Program ID</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.program_id}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Email</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.email}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Creation Date</div>
                <div class="col-lg-6 col-md-12">{{moment(pismoAccountAndOpportunityDetail.pismoAccountDetails.creation_date).format('DD/MM/YYYY')}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Total Limit</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.total_credit_limit | looseCurrency}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Current Balance</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.current_balance | looseCurrency}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Available Limit</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.pismoAccountDetails.available_limit | looseCurrency}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Rate</div>
                @if (pismoAccountWithInterestRate.length) {
                  <div class="col-lg-6 col-md-12">
                    {{pismoAccountWithInterestRate[i].interestRate | number : '1.2-2' | percentage}}
                  </div>
                }
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Term</div>
                @if (pismoAccountAndOpportunityDetail.opportunityDetails?.Term) {
                  <div class="col-lg-6 col-md-12" >{{pismoAccountAndOpportunityDetail.opportunityDetails?.Term}} months</div>
                }
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Doc Fee</div>
                @if (pismoAccountAndOpportunityDetail.opportunityDetails?.DocFee !== undefined && pismoAccountAndOpportunityDetail.opportunityDetails?.DocFee !== null) {
                  <div class="col-lg-6 col-md-12">
                    {{pismoAccountAndOpportunityDetail.opportunityDetails?.DocFee | looseCurrency}}
                  </div>
                }
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Doc Fee Financed</div>
                <div class="col-lg-6 col-md-12">
                  @if (pismoAccountAndOpportunityDetail.opportunityDetails?.DocFeeFinanced) {
                    Yes
                  }
                  @if (!pismoAccountAndOpportunityDetail.opportunityDetails?.DocFeeFinanced) {
                    No
                  }
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Monthly Facility Fee</div>
                <div class="col-lg-6 col-md-12">{{pismoAccountAndOpportunityDetail.monthlyFacilityFee | looseCurrency}}</div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Monthly Facility Fee%</div>
                <div class="col-lg-6 col-md-12">
                  {{(pismoAccountAndOpportunityDetail.pismoAccountDetails.custom_fields.monthlyFacilityFeePercentage ?? 0) | number : '1.2-2' | percentage}}
                  @if (canAccess) {
                    <button mat-flat-button
                      [disabled]="!canEdit"
                      class="inactive retry-button"
                      (click)="updateMonthlyFacilityFeePercentage(pismoAccountAndOpportunityDetail)">
                      <span class="mdi mdi-pencil-outline"></span>
                    </button>
                  }
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Payment Limit</div>
                <div class="col-lg-6 col-md-12">
                  <editable>
                    <ng-template editMode>
                      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px">
                        <currency-input
                          [formControl]="pismoAccountAndOpportunityDetail.formControlPaymentLimit">
                        </currency-input>
                        <button mat-flat-button
                                editableOnSave
                                (click)="saveOverdraftPaymentLimit($event, pismoAccountAndOpportunityDetail)"
                                [disabled]="!canEdit || !canAccess || pismoAccountAndOpportunityDetail.formControlPaymentLimit.invalid">
                          Save
                        </button>
                        @if (loader.paymentLimitUpdated.inProgress$ | async) {
                          <span>Updating ...</span>
                        }
                      </div>
                      @for (errorKey of errorKeys(pismoAccountAndOpportunityDetail.formControlPaymentLimit); track errorKey) {
                        <mat-error>
                          @if (pismoAccountAndOpportunityDetail.formControlPaymentLimit.touched && pismoAccountAndOpportunityDetail.formControlPaymentLimit.hasError(errorKey)) {
                            {{errorMessage(pismoAccountAndOpportunityDetail.formControlPaymentLimit, errorKey)}}
                          }
                        </mat-error>
                      }
                    </ng-template>
                    <ng-template viewMode>
                      {{(pismoAccountAndOpportunityDetail.pismoAccountDetails.custom_fields.paymentLimit ? pismoAccountAndOpportunityDetail.pismoAccountDetails.custom_fields.paymentLimit : 0) | looseCurrency}}
                      @if (canAccess) {
                         <span class="mdi mdi-pencil-outline" matTooltip="Edit Payment Limit"></span>
                      @if (loader.paymentLimitUpdated.inProgress$ | async) {
                        <span>Updating ...</span>
                      }
                    }
                    </ng-template>
                  </editable>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title">Require Fixed Monthly Installment</div>
                <div class="col-lg-6 col-md-12">
                  {{!!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.requireMonthlyFixedInstallment ? 'Yes' : 'No'}}
                  @if (canAccess) {
                     <button mat-flat-button
                        [disabled]="!canEdit"
                        class="inactive retry-button"
                        (click)="updateRequireMonthlyFixedInstallmentFlag(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)">
                        <span class="mdi mdi-pencil-outline"></span>
                      </button>
                    }
                  <!-- <mat-slide-toggle
                  color="primary"
                  [disabled]="true"
                  [checked]="!!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.requireMonthlyFixedInstallment"
                  (click)="onChangeToggle($event, pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)">
                </mat-slide-toggle> -->
                </div>
              </div>
              @if (!!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.requireMonthlyFixedInstallment) {
                <mat-divider></mat-divider>
                <div class="row pismo-account-details">
                  <div class="col-lg-3 col-md-12 title">Monthly Repayment</div>
                  <div class="col-lg-6 col-md-12">
                    {{pismoAccountAndOpportunityDetail.pismoAccountDetails.custom_fields.repayment ?? 0 | looseCurrency}}
                    @if (canAccess) {
                        <button mat-flat-button
                          [disabled]="!canEdit"
                          class="inactive retry-button"
                          (click)="updateAmortisedRepayment(pismoAccountAndOpportunityDetail)">
                          <span class="mdi mdi-pencil-outline"></span>
                        </button>
                      }
                  </div>        
                </div>
              }
            <mat-divider></mat-divider>
            <div class="row pismo-account-details mb-2">
              <div class="col-12 section">Account Setup Fees</div>
            </div>
            <mat-divider></mat-divider>
            <div class="row pismo-account-details">
              <div class="col-lg-3 col-md-12 title">Facility Establishment Fee</div>
              <div class="col-lg-6 col-md-12">
                <!-- Facility Establishment Fee -->
                @if (getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.facilityEstablishmentFeeStatus) {
                  Posted Success
                }
                @if (!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.facilityEstablishmentFeeStatus) {
                  Failed
                  @if (canAccess) {
                    <button mat-flat-button
                    [disabled]="!canEdit"
                    class="retry-button"
                    (click)="retryFacilityEstablishmentFee(pismoAccountAndOpportunityDetail.pismoAccountDetails)">
                    <span class="mdi mdi-refresh"></span>Retry
                  </button>
                }
                }
              </div>
            </div>
            <div class="row pismo-account-details">
              <div class="col-lg-3 col-md-12 title">Brokerage</div>
              <!-- Brokerage -->
              <div class="col-lg-6 col-md-12">
                @if (getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.brokerageStatus) {
                  Posted Success
                }
                @if (!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.brokerageStatus) {
                  Failed
                  @if (canAccess) {  
                  <button mat-flat-button
                    [disabled]="!canEdit"
                    class="inactive retry-button"
                    (click)="retryBrokerage(pismoAccountAndOpportunityDetail.pismoAccountDetails)">
                    <span class="mdi mdi-refresh"></span>Retry
                  </button>
                }
              }
              </div>
            </div>
            <div class="row pismo-account-details">
              <div class="col-lg-3 col-md-12 title">Documentation Fee</div>
              <!-- Documentation Fee -->
              <div class="col-lg-6 col-md-12">
                @if (getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.documentationFeeStatus) {
                  Posted Success
                }
                @if (!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.documentationFeeStatus) {
                  Failed
                  @if (canAccess) {  
                  <button mat-flat-button
                    [disabled]="!canEdit"
                    class="inactive retry-button"
                    (click)="retryDocumentationFee(pismoAccountAndOpportunityDetail.pismoAccountDetails)">
                    <span class="mdi mdi-refresh"></span>Retry
                  </button>
                }
                }
              </div>
            </div>
            <!-- Documentation Fee Reversal -->
            @if (!pismoAccountAndOpportunityDetail.opportunityDetails?.DocFeeFinanced) {
              <div class="row pismo-account-details">
                <div class="col-lg-3 col-md-12 title" >Documentation Fee Reversal</div>
                <div class="col-lg-6 col-md-12">
                  @if (getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.documentationFeeReversalStatus) {
                    Posted Success
                  }
                  @if (!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.documentationFeeReversalStatus) {
                    Failed
                    @if (canAccess) {
                       <button mat-flat-button
                      [disabled]="!canEdit"
                      class="inactive retry-button"
                      (click)="retryDocumentationFeeReversal(pismoAccountAndOpportunityDetail.pismoAccountDetails)">
                      <span class="mdi mdi-refresh"></span>Retry
                    </button>
                  }
                  }
                </div>
              </div>
            }
            <!-- Account Interest Rate -->
            <div class="row pismo-account-details">
              <div class="col-lg-3 col-md-12 title">Account Interest Rate Setup</div>
              <div class="col-lg-6 col-md-12">
                @if (getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.saveAccountInterestRateStatus) {
                  Posted Success
                }
                @if (!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.saveAccountInterestRateStatus) {
                  Failed
                  @if (canAccess) {  
                  <button mat-flat-button
                    class="inactive retry-button"
                    [disabled]="!canEdit"
                    (click)="retryAccountInterestRate(pismoAccountAndOpportunityDetail.pismoAccountDetails)">
                    <span class="mdi mdi-refresh"></span>Retry
                  </button>
                }
              }
              </div>
            </div>
            <!-- Transferred Balance -->
            <div class="row pismo-account-details mb-2">
              <div class="col-lg-3 col-md-12 title">Transferred Balance</div>
              <div class="col-lg-6 col-md-12">
                @if (getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.postBalanceStatus) {
                  Posted Success
                }
                @if (!getPismoAccountMapping(pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id)?.postBalanceStatus) {
                  No Balance or Post Failed
                  @if (canAccess) {  
                  <button mat-flat-button
                    [disabled]="!canEdit"
                    class="inactive retry-button"
                    (click)="retryTransferredBalance(pismoAccountAndOpportunityDetail.pismoAccountDetails)">
                    <span class="mdi mdi-refresh"></span>Retry
                  </button>
                }
                }
              </div>
            </div>
            <mat-divider></mat-divider>
          </mat-expansion-panel>
        }
      </mat-accordion>
    </div>
  </div>
}
</div>
