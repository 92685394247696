<div class="select-credit-rule-item-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Select Credit Rule Item</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Group</mat-label>
          <mat-select [formControl]="formControlCreditRuleItemGroup">
            @for (option of creditRuleItemGroupOptions; track option) {
              <mat-option [value]="option">{{option}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field>
          <mat-label>Credit Rule Item</mat-label>
          <input #input
           type="text"
           matInput
           [formControl]="formControlCreditRuleItem"
           [matAutocomplete]="auto"
           (input)="filter()"
           (focus)="filter()">
          <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayItem">
            @for (option of filteredCreditRuleItemOptions; track option) {
              <mat-option [value]="option">{{option.name}} ({{option.outputType}})</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <br>
      <div class="row">
        <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-flat-button color="accent" class="ml-3" (click)="cancel()">Cancel</button>
          <button mat-flat-button color="primary" class="mr-3" [disabled]="formControlCreditRuleItem.invalid" (click)="submit()">Select</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
