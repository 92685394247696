import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { ApplicationSubmittedDialog } from './application-submitted.dialog';
import { ApplicationConfirmationDialog } from './application-confirmation.dialog';
import { ApplicationDialogService } from './application-dialog.service';
import { TotalPaymentBreakupDialog } from './total-payment-breakup.dialog';
import { ChartModule } from '../chart-component/chart.module';
import { CreditInfoExchangeAlertDialog } from './credit-info-exchange-alert.dialog';
import { MessageBoxModule } from '../message-box/message-box.module';
import { ApplicationErrorDialog } from './application-error.dialog';
import { AlertDialog } from './alert.dialog';
import { IndividualFormDialog } from './individual-form.dialog';
import { TitleSelectionModule } from '../title-selection-component/title-selection.module';
import { AddressModule } from '../address-component/address.module';
import { YesNoModule } from '../yes-no-component/yes-no.module';
import { GenderModule } from '../gender-component/gender.module';
import { InputMaskModule } from '@ngneat/input-mask';
import { CurrencySelectionModule } from '../currency-selection-component/currency-selection.module';
import { RadioOptionsModule } from '../radio-options-component/radio-options.module';
import { UploadFilesDialog } from './upload-files.dialog';
import { UploadFileModule } from '../upload-file-component/upload-file.module';
import { CurrencyInputDialog } from './currency-input.dialog';
import { DatepickerModule } from '../datepicker-component/datepicker.module';
import { ProgressSpinnerDialog } from './progress-spinner.dialog';
import { ConfirmPasswordDialog } from './confirm-password.dialog';
import { ConfirmPasswordAdminDialog } from './confirm-password-admin.dialog';
import { ConfirmPasswordModule } from '../confirm-password-component/confirm-password.module';
import { ConfirmPasswordAdminModule } from '../confirm-password-admin-component/confirm-password-admin.module';
import { OverdraftPaymentBreakupDialog } from './overdraft-payment-breakup.dialog';
import { AddBrokerUserFormDialog } from './add-broker-user-form.dialog';
import { AddCustomerUserFormDialog } from './add-customer-user-form.dialog';
import { AggregatorSearchModule } from '../aggregator-search-component/aggregator-search.module';
import { AccessLevelModule } from '../access-level-component/access-level.module';
import { AngularMaterialLibraryModule } from '../../angular-material/angular-material-library.module';
import { DirectiveLibraryModule } from '../../directives/directive-library.module';
import { PipeLibraryModule } from '../../pipes/pipe-library.module';
import { ExtendTermsDialog } from './extend-terms.dialog';
import { PPSRConfirmationDialog } from './ppsr-confirmation.dialog';
import { MarkAsSoldDialog } from './mark-as-sold.dialog';
import { Enable2FAPasswordDialog } from './enable-2fa-password.dialog';
import { Enable2FADialog } from './enable-2fa.dialog';
import { TFAActivatedDialog } from './2fa-activated.dialog';
import { AssetSupplierSelectModule } from '../asset-supplier-select-component/asset-supplier-select.module';
import { BsbModule } from '../bsb-component/bsb.module';
import {EmailModule} from '../common fields/email.module';
import {MobileModule} from '../mobile-component/mobile.module';
import {AccountNumberModule} from '../account-number-component/account-number.module';
import { NameModule } from '../name-component/name.module';
import { UploadFilesMultiTagsDialog } from './upload-files-multi-tags.dialog';
import { ConfirmationDialog } from './confirmation.dialog';
import { CustomDocumentTagDialog } from './custom-document-tag.dialog';
import { DocumentNotificationDialog } from './document-notification.dialog';
import { BusinessSearchModule } from '../business-search-component/business-search.module';
import { PrivateSupplierFormDialog } from './private-supplier-form.dialog';
import { PPSRRegisterDialog } from './PPSR-register.dialog';
import { SettleLoanDialog } from './settle-loan.dialog';
import { ShowUserLockDialog } from './show-user-lock.dialog';
import { PPSRCheckDialog } from './PPSR-check.dialog';
import { AssetSaveDialog } from './asset-save.dialog';
import { GenerateContractDialog } from './generate-contract.dialog';
import {ApplicationStepperModule} from '../application-stepper-component/application-stepper.module';
import { ContractStatusDialog } from './contract-status.dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InformationDialog } from './information-dialog';
import {DateRangeDialog} from './date-range.dialog';
import { DaterangeModule } from '../date-range-component/date-range.module';
import { AssetDisbursementPayeeDialog } from './asset-disbursement-payee.dialog';
import { AssetDisbursementInvoicesDialog } from './asset-disbursement-invoices.dialog';
import { DisbursementBankDetailsDialog } from './disbursement-bank-details.dialog';
import { DisbursementAmountDialog } from './disbursement-amount.dialog';
import {AssetListingModule} from '../asset-listing-component/asset-listing.module';
import { EditAddressDialog } from './edit-address.dialog';
import {BusinessDisbursementPayeeDialog} from './business-disbursement-payee.dialog';
import {BusinessDisbursementInvoiceDialog} from './business-disbursement-invoice.dialog';
import {BusinessNumberSearchModule} from '../business-number-search-component/business-number-search.module';
import {RouterModule} from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { BpayBankDetailsModule } from '../bpay-bank-details-component/bpay-bank-details.module';
import { DisbursementDialog } from './disbursement.dialog';
import { LocalBankAccountDetailModule } from '../local-bank-account-detail-component/local-bank-account-detail.module';
import { BPayTransactionDialog } from './bpay-transaction.dialog';
import { DirectPaymentTransactionDialog } from './direct-payment-transaction.dialog';
import { DirectPaymentBatchDialog } from './direct-payment-batch.dialog';
import { NewBatchPaymentDialog } from './new-batch-payment.dialog';
import { ApprovalNoticeDialog } from './approval-notice.dialog';
import { CopyApplicationDialog } from './copy-application.dialog';
import {CustomAngularEditorModule} from '../custom-angular-editor-component/custom-angular-editor.module'
// import { PaywayTransactionDialog } from './payway-transaction-confirmation.dialog';
import { CreateOverdraftAccountDialog } from './create-overdraft-account.dialog';
import { FirstOverdraftUserDialog } from './first-overdraft-user.dialog';
import { EditKycStatusDialog } from './edit-kyc-status.dialog';
import { TaxInvoiceDetailsDialog } from './tax-invoice-details.dialog';
import { TransferredBalanceDialog } from './transferred-balance.dialog';
import {AssetPricingComparisonDialog} from "./asset-pricing-comparison.dialog";
import {
  BusinessOverdraftPricingComparisonDialog
} from "./business-overdraft-pricing-comparison.dialog";
import {BusinessPricingComparisonDialog} from "./business-pricing-comparison.dialog";
import { ManageApplicationDialog } from './manage-application.dialog';
import {AllocateUserToPismoAccountDialog} from "./allocate-user-to-pismo-account.dialog";
import {CorporateLoanPaymentBreakupDialog} from "./corporate-loan-payment-breakup.dialog";
import {InterestRateCalculationLogDialog} from "./interest-rate-calculation-log.dialog";
import {CorporateLoanPricingComparisonDialog} from "./corporate-loan-pricing-comparison.dialog";
import { OverdraftAccountLimitIncreaseDialog } from './overdraft-account-limit-increase.dialog';
import { EditBrokerageDialog } from './edit-brokerage.dialog';
import { EditInvoiceAmountDialog } from './edit-invoice-amount.dialog';
import { ApplicantsPrivacyConsentDialog } from './applicants-privacy-consent.dialog';
import { SelectableTagsDialog } from './selectable-tags.dialog';

const dialogs = [
  ApplicationSubmittedDialog,
  ShowUserLockDialog,
  ApplicationConfirmationDialog,
  TotalPaymentBreakupDialog,
  CreditInfoExchangeAlertDialog,
  ApplicationErrorDialog,
  PPSRRegisterDialog,
  SettleLoanDialog,
  PPSRCheckDialog,
  AssetSaveDialog,
  AlertDialog,
  IndividualFormDialog,
  UploadFilesDialog,
  CurrencyInputDialog,
  ProgressSpinnerDialog,
  ConfirmPasswordDialog,
  ConfirmPasswordAdminDialog,
  OverdraftPaymentBreakupDialog,
  AddBrokerUserFormDialog,
  AddCustomerUserFormDialog,
  ExtendTermsDialog,
  PPSRConfirmationDialog,
  MarkAsSoldDialog,
  Enable2FAPasswordDialog,
  Enable2FADialog,
  TFAActivatedDialog,
  PrivateSupplierFormDialog,
  UploadFilesMultiTagsDialog,
  ConfirmationDialog,
  SelectableTagsDialog,
  CustomDocumentTagDialog,
  DocumentNotificationDialog,
  GenerateContractDialog,
  ContractStatusDialog,
  InformationDialog,
  DateRangeDialog,
  AssetDisbursementPayeeDialog,
  AssetDisbursementInvoicesDialog,
  DisbursementBankDetailsDialog,
  DisbursementAmountDialog,
  EditAddressDialog,
  BusinessDisbursementPayeeDialog,
  BusinessDisbursementInvoiceDialog,
  DisbursementDialog,
  ApprovalNoticeDialog,
  CopyApplicationDialog,
  BPayTransactionDialog,
  DirectPaymentTransactionDialog,
  DirectPaymentBatchDialog,
  NewBatchPaymentDialog,
  CreateOverdraftAccountDialog,
  FirstOverdraftUserDialog,
  EditKycStatusDialog,
  TaxInvoiceDetailsDialog,
  TransferredBalanceDialog,
  AssetPricingComparisonDialog,
  BusinessPricingComparisonDialog,
  BusinessOverdraftPricingComparisonDialog,
  ManageApplicationDialog,
  AllocateUserToPismoAccountDialog,
  CorporateLoanPaymentBreakupDialog,
  InterestRateCalculationLogDialog,
  CorporateLoanPricingComparisonDialog,
  OverdraftAccountLimitIncreaseDialog,
  EditBrokerageDialog,
  EditInvoiceAmountDialog,
  ApplicantsPrivacyConsentDialog,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ChartModule,
        MessageBoxModule,
        TitleSelectionModule,
        AddressModule,
        YesNoModule,
        GenderModule,
        InputMaskModule,
        CurrencySelectionModule,
        RadioOptionsModule,
        UploadFileModule,
        DatepickerModule,
        ConfirmPasswordModule,
        ConfirmPasswordAdminModule,
        AggregatorSearchModule,
        AccessLevelModule,
        AngularMaterialLibraryModule,
        DirectiveLibraryModule,
        PipeLibraryModule,
        AssetSupplierSelectModule,
        BsbModule,
        EmailModule,
        MobileModule,
        AccountNumberModule,
        NameModule,
        BusinessSearchModule,
        DaterangeModule,
        ApplicationStepperModule,
        PdfViewerModule,
        AssetListingModule,
        BusinessNumberSearchModule,
        RouterModule,
        QRCodeModule,
        BpayBankDetailsModule,
        LocalBankAccountDetailModule,
        CustomAngularEditorModule,
        ...dialogs,
    ],
    exports: [
        ...dialogs,
    ],
    providers: [
        { provide: ApplicationDialogService, useClass: ApplicationDialogService },
    ],
})
export class ApplicationDialogModule {}
