import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FlexModule } from "@angular/flex-layout/flex";
import { AsyncPipe, CommonModule, JsonPipe, NgTemplateOutlet, Location } from "@angular/common";
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { loadingFor } from "@ngneat/loadoff";
import { OverdraftCustomerService } from "../../service/overdraft-customer.service";
import {
  CreateAccountInterestRateFn,
  CreateOverdraftUserFn,
  CustomerUser,
  DigitalIdGetApplicationIndividualsFn, DisableOverdraftUserFn, GetAccountDetailsFromSfFn, GetAllPismoAccountStatusFn,
  GetCardFn,
  GetCustomerFn,
  GetOpportunitiesForCustomerFn,
  GetPendingPaymentsFn,
  GetPismoAccountAndOpportunityDetailsFn,
  GetPismoAccountDetailsFn,
  GetPismoAccountStatusReasonsFn,
  GetPismoCardsForCustomerFn,
  GetPismoCustomerForAccountFn,
  GetPismoStatementsFn,
  GetPismoTransactionFlowFn,
  GetPismoTransactionsFn,
  GetPismoTransactionTypeByIdFn,
  GetTokenInfoFn,
  GetRateCardDetailsFn,
  GetRbaRateFn,
  GetTransactionDetailsFn,
  GetTransactionFromTimelineFn,
  GetUserByEmailFn,
  ManualDirectDebitFn,
  OverdraftAccountLimitIncreaseFn,
  PismoAccountMappingAndApplication,
  PismoChangeCardStatusFn,
  PismoCheckEmailExistsFn,
  PismoDownloadStatementFn,
  PismoGeneratePayoutFn,
  PismoGetAccountInterestRateFn,
  PismoGetBuiltInTransactionTypesFn,
  PismoGetCardReissueReasonsFn,
  PismoGetCardsForAccountFn,
  PismoGetPaidFacilityFeeFn,
  PismoGetPendingInterestFn,
  PismoGetTransactionTypesFn, PismoReissueCardFn, PismoRollbackAccountStatusFn,
  PismoSendPayoutNotificationFn,
  PismoUpdateAccountLimitFn, PismoUpdateAccountStatusFn,
  PismoUpdateCustomerFn,
  PismoUpdateRequireMonthlyFixedInstallmentFlagFn,
  PostManualTransactionFn,
  PostOverdraftAccountFeeFn,
  SendForgotPasswordFn,
  UpdatePismoAccountPaymentLimitFn,
  UpdateUserKycStatusFn,
  ValidEmailCheckFn,
  EnableOverdraftUserFn,
  CustomerAngularEditorComponentValue,
  User,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  PismoAccountNotes,
  RemovePismoAccountNoteByNoteIdFn,
  UpdateMonthlyFacilityFeeFn,
  GetPismoAccountTimelineFn,
  UpdateAmortisedRepaymentFn,
} from "@portal-workspace/grow-shared-library";
import { RegistrationService } from "../../service/registration.service";
import { OverdraftAccountUsersComponent } from "libs/grow-ui-library/src/lib/components/overdraft-customer-components/overdraft-account-users.component";
import { Subscription, of } from "rxjs";
import { switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import {
  getUser,
  MessageBoxComponent,
  OverdraftAccountDetailsComponentEvent,
  OverdraftAccountStatementsComponentEvent,
  OverdraftModule,
  PortalHotToastService,
  setupUntilDestroy,
} from "@portal-workspace/grow-ui-library";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AuthService } from "../../service/auth.service";
import { DigitalIdService } from "../../service/digital-id.service";
import { navigationUrlForPismoAccounts, navigationUrlForPismoDisplayTransactions, navigationUrlForPismoStatementDetails } from "../../service/navigation-urls";
import { ApplicationService } from "../../service/application.service";
import { environment } from '../../../environments/environment';
import { CustomAngularEditorComponent } from "libs/grow-ui-library/src/lib/components/custom-angular-editor-component/custom-angular-editor.component";
import { MatButtonModule } from "@angular/material/button";
import { GetPismoNotesByAccountNumberFn, PismoNotesComponent, PismoNotesComponentEvent } from "libs/grow-ui-library/src/lib/components/overdraft-customer-components/pismo-notes.component";
import { OverdraftAccountTimelineComponent } from "@portal-workspace/grow-ui-library";

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  templateUrl: './pismo-account.page.html',
  styleUrls: ['./pismo-account.page.scss'],
  standalone: true,
  imports: [
    FlexModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    JsonPipe,
    AsyncPipe,
    MatCardModule,
    MatTabsModule,
    NgTemplateOutlet,
    OverdraftModule,
    MessageBoxComponent,
    MatButtonModule,
    CustomAngularEditorComponent,
    PismoNotesComponent,
    OverdraftAccountTimelineComponent,
  ]
})
export class PismoAccountPage implements OnInit {

  formControlAccountNotes!: FormControl<CustomerAngularEditorComponentValue>;
  stage: 'init' | 'error' = 'init';

  @ViewChild(OverdraftAccountUsersComponent) overdraftAccountUsersComponent!: OverdraftAccountUsersComponent;
  loggedInUser: User | null = getUser();
  loader = loadingFor('loadingPismoAccount');
  subscriptions: Subscription[] = [];
  showButton = false;
  pismoAccountNumber!: number;
  pismoNotes:  PismoAccountNotes[] | [] = [];
  existingPismoAccountMappings: PismoAccountMappingAndApplication[] = [];
  customer: CustomerUser | null = null;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  total!: number;
  // account details
  updatePismoAccountPaymentLimitFn!: UpdatePismoAccountPaymentLimitFn;
  getOpportunitiesForCustomerFn!: GetOpportunitiesForCustomerFn;
  getPismoAccountAndOpportunityDetailsFn!: GetPismoAccountAndOpportunityDetailsFn;
  validEmailCheckFn!: ValidEmailCheckFn;
  getUserByEmailFn!: GetUserByEmailFn;
  getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  createOverdraftUserFn!: CreateOverdraftUserFn;
  createAccountInterestRateFn!: CreateAccountInterestRateFn;
  postOverdraftAccountFeeFn!: PostOverdraftAccountFeeFn;
  getPismoTransactionTypeByIdFn!: GetPismoTransactionTypeByIdFn;
  postManualTransactionFn!: PostManualTransactionFn;
  getPismoTransactionFlowFn!: GetPismoTransactionFlowFn;
  getPismoCardsForAccountFn!: PismoGetCardsForAccountFn;
  getPismoTransactionTypesFn!: PismoGetTransactionTypesFn;
  updatePismoAccountLimitFn!: PismoUpdateAccountLimitFn;
  getAllPismoAccountStatusFn!: GetAllPismoAccountStatusFn;
  getPismoAccountStatusReasonsFn!: GetPismoAccountStatusReasonsFn;
  updatePismoAccountStatusFn!: PismoUpdateAccountStatusFn;
  rollbackPismoAccountStatusFn!: PismoRollbackAccountStatusFn;
  pismoCheckEmailExistsFn!: PismoCheckEmailExistsFn;
  pismoGetBuiltInTransactionTypesFn!: PismoGetBuiltInTransactionTypesFn;
  pismoUpdateRequireMonthlyFixedInstallmentFlagFn!: PismoUpdateRequireMonthlyFixedInstallmentFlagFn;
  pismoGetPaidFacilityFeeFn!: PismoGetPaidFacilityFeeFn;
  pismoGetPendingInterestFn!: PismoGetPendingInterestFn;
  pismoSendPayoutNotificationFn!: PismoSendPayoutNotificationFn;
  pismoGeneratePayoutFn!: PismoGeneratePayoutFn;
  getPendingPaymentsFn!: GetPendingPaymentsFn;
  pismoGetAccountInterestRateFn!: PismoGetAccountInterestRateFn;
  getDefaultRatecardDetailsFn!: GetRateCardDetailsFn;
  getCustomerFn!: GetCustomerFn;
  getRbaRateFn!: GetRbaRateFn;
  getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  overdraftAccountLimitIncreaseFn!: OverdraftAccountLimitIncreaseFn;
  manualDirectDebitFn!: ManualDirectDebitFn;
  updateMonthlyFacilityFeeFn!: UpdateMonthlyFacilityFeeFn;
  getPismoAccountTimelineFn!: GetPismoAccountTimelineFn;
  updateAmortisedRepaymentFn!: UpdateAmortisedRepaymentFn;
  environment = environment;

  // account user
  getPismoCustomerForAccountFn!: GetPismoCustomerForAccountFn;
  disableOverdraftUserFn!: DisableOverdraftUserFn;
  enableOverdraftUserFn!: EnableOverdraftUserFn;
  updateUserKycStatusFn!: UpdateUserKycStatusFn;
  sendForgotPasswordFn!: SendForgotPasswordFn;
  pismoUpdateCustomerFn !: PismoUpdateCustomerFn;
  getPismoCardsForCustomerFn!: GetPismoCardsForCustomerFn;
  pismoChangeCardStatusFn!: PismoChangeCardStatusFn;
  pismoGetCardReissueReasonsFn!: PismoGetCardReissueReasonsFn;
  pismoReissueCardFn!: PismoReissueCardFn;
  getTokenInfoFn!: GetTokenInfoFn;
  removePismoAccountNoteByNoteIdFn!: RemovePismoAccountNoteByNoteIdFn;
  getPismoNotesByAccountNumberFn!: GetPismoNotesByAccountNumberFn;
  
  // account transactions
  getPismoTransactionsFn!: GetPismoTransactionsFn;
  getTransactionDetailsFn!: GetTransactionDetailsFn;
  getPismoAccountDetailsFn!: GetPismoAccountDetailsFn;
  getCardFn!: GetCardFn;
  getTransactionFromTimelineFn!: GetTransactionFromTimelineFn;

  // account statements
  getPismoStatementsFn!: GetPismoStatementsFn;
  downloadStatementInPDF!: PismoDownloadStatementFn;
  downloadStatementInCSV!: PismoDownloadStatementFn;
  downloadStatementInOFX!: PismoDownloadStatementFn;

  private overdraftCustomerService = inject(OverdraftCustomerService);
  private registrationService = inject(RegistrationService);
  private authService: AuthService = inject(AuthService);
  private digitalIdService: DigitalIdService = inject(DigitalIdService);
  private applicationService: ApplicationService = inject(ApplicationService);
  private _location = inject(Location);
  private activatedRoute = inject(ActivatedRoute);
  private router: Router = inject(Router);

  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private toastService: PortalHotToastService) {
    this.formControlAccountNotes = formBuilder.control('', [Validators.required])

    // account details
    this.updatePismoAccountPaymentLimitFn = this.overdraftCustomerService.updatePismoAccountPaymentLimitFn;
    this.getOpportunitiesForCustomerFn = this.overdraftCustomerService.getOpportunitiesForCustomerFn;
    this.getPismoAccountAndOpportunityDetailsFn = this.overdraftCustomerService.getPismoAccountAndOpportunityDetailsFn;
    this.validEmailCheckFn = this.overdraftCustomerService.pismoValidEmailCheckFn;
    this.getUserByEmailFn = this.authService.getUserByEmailFn;
    this.getApplicationIndividualsFn = this.digitalIdService.getApplicationIndividualsFn;
    this.createOverdraftUserFn = this.overdraftCustomerService.createOverdraftUserFn;
    this.createAccountInterestRateFn = this.overdraftCustomerService.createAccountInterestRateFn;
    this.postOverdraftAccountFeeFn = this.overdraftCustomerService.postOverdraftAccountFeeFn;
    this.getPismoTransactionTypeByIdFn = this.overdraftCustomerService.getPismoTransactionTypeByIdFn;
    this.postManualTransactionFn = this.overdraftCustomerService.postManualTransactionFn;
    this.getPismoTransactionFlowFn = this.overdraftCustomerService.getPismoTransactionFlowFn;
    this.getPismoCardsForAccountFn = this.overdraftCustomerService.getPismoCardsForAccountFn;
    this.getPismoTransactionTypesFn = this.overdraftCustomerService.getPismoTransactionTypesFn;
    this.updatePismoAccountLimitFn = this.overdraftCustomerService.updatePismoAccountLimitFn;
    this.getAllPismoAccountStatusFn = this.overdraftCustomerService.getAllPismoAccountStatusFn;
    this.getPismoAccountStatusReasonsFn = this.overdraftCustomerService.getPismoAccountStatusReasonsFn;
    this.updatePismoAccountStatusFn = this.overdraftCustomerService.updatePismoAccountStatusFn;
    this.rollbackPismoAccountStatusFn = this.overdraftCustomerService.rollbackPismoAccountStatusFn;
    this.pismoCheckEmailExistsFn = this.overdraftCustomerService.pismoCheckEmailExists;
    this.pismoGetBuiltInTransactionTypesFn = this.overdraftCustomerService.pismoListBuiltInTransactionTypesFn;
    this.pismoUpdateRequireMonthlyFixedInstallmentFlagFn = this.overdraftCustomerService.pismoUpdateRequireMonthlyFixedInstallmentFlagFn;
    this.pismoGetPaidFacilityFeeFn = this.overdraftCustomerService.pismoGetPaidFacilityFeeFn;
    this.pismoGetPendingInterestFn = this.overdraftCustomerService.pismoGetPendingInterestFn;
    this.pismoSendPayoutNotificationFn = this.overdraftCustomerService.pismoSendPayoutNotificationFn;
    this.pismoGeneratePayoutFn = this.overdraftCustomerService.pismoGeneratePayoutFn;
    this.getPendingPaymentsFn = this.overdraftCustomerService.getPendingPaymentsFn;
    this.pismoGetAccountInterestRateFn = this.overdraftCustomerService.pismoGetAccountInterestRateFn;
    this.getDefaultRatecardDetailsFn = this.applicationService.getDefaultRatecardDetailsFn;
    this.getRbaRateFn = this.applicationService.getRbaRateFn;
    this.getCustomerFn = this.authService.getCustomerFn;
    this.getAccountDetailsFromSfFn = this.overdraftCustomerService.getAccountDetailsFromSfFn;
    this.overdraftAccountLimitIncreaseFn = this.overdraftCustomerService.overdraftAccountLimitIncreaseFn;
    this.manualDirectDebitFn = this.overdraftCustomerService.manualDirectDebitFn;
    this.updateMonthlyFacilityFeeFn = this.overdraftCustomerService.updateMonthlyFacilityFeeFn;
    this.getPismoAccountTimelineFn = this.overdraftCustomerService.getPismoAccountTimelineFn;
    this.updateAmortisedRepaymentFn = this.overdraftCustomerService.updateAmortisedRepaymentFn;

    // account users
    this.disableOverdraftUserFn = this.overdraftCustomerService.disableOverdraftUserFn;
    this.enableOverdraftUserFn = this.overdraftCustomerService.enableOverdraftUserFn;
    this.getPismoCustomerForAccountFn = this.overdraftCustomerService.getPismoCustomerForAccountFn;
    this.updateUserKycStatusFn = this.overdraftCustomerService.updateUserKycStatusFn;
    this.sendForgotPasswordFn = this.registrationService.sendForgotPassword.bind(this.registrationService);
    this.pismoUpdateCustomerFn = this.overdraftCustomerService.updatePismoCustomerFn;
    this.getPismoCardsForCustomerFn = this.overdraftCustomerService.getPismoCardsForCustomerFn;
    this.pismoChangeCardStatusFn = this.overdraftCustomerService.pismoChangeCardStatusFn;
    this.pismoGetCardReissueReasonsFn = this.overdraftCustomerService.pismoGetCardReissueReasonsFn;
    this.pismoReissueCardFn = this.overdraftCustomerService.pismoReissueCardFn;
    this.getTokenInfoFn = this.overdraftCustomerService.getTokenInfoFn;
    this.removePismoAccountNoteByNoteIdFn =this.overdraftCustomerService.removePismoAccountNoteByNoteIdFn
    this.getPismoNotesByAccountNumberFn = this.overdraftCustomerService.getPismoNotesByAccountNumberFn

    // account transactions
    this.getPismoTransactionsFn = this.overdraftCustomerService.getPismoTransactionsFn;
    this.getTransactionDetailsFn = this.overdraftCustomerService.getTransactionDetailsFn;
    this.getPismoAccountDetailsFn = this.overdraftCustomerService.getPismoAccountDetailsFn;
    this.getCardFn = this.overdraftCustomerService.getCardFn;
    this.getTransactionFromTimelineFn = this.overdraftCustomerService.getTransactionFromTimelineFn;

    // account statements
    this.getPismoStatementsFn = this.overdraftCustomerService.getPismoStatementsFn;
    this.downloadStatementInPDF = this.overdraftCustomerService.downloadStatement;
    this.downloadStatementInCSV = this.overdraftCustomerService.downloadStatementInCsv;
    this.downloadStatementInOFX = this.overdraftCustomerService.downloadStatementInOfx;

  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.pismoAccountNumber = this.activatedRoute.snapshot.params.pismoAccountNumber
    this.reload()
  }

  reload() {
    this.subscriptions.push(this.overdraftCustomerService.getPismoAccountMapping(this.pismoAccountNumber).pipe(
      this.loader.loadingPismoAccount.track(),
      switchMap(r => {
        if (r.status) {
          this.existingPismoAccountMappings = [r.payload];
          return this.authService.getCustomer(this.existingPismoAccountMappings[0].customerId);
        } else {
          this.existingPismoAccountMappings = [];
          this.stage = 'error';
          return of(null);
        }
      }),
      tap(r => {
        if (r) {
          this.customer = r.payload;
        }
      })
    ).subscribe());
    this.getPismoNotesByAccountNumberFn(this.limit, this.offset, this.pismoAccountNumber).pipe(
      tap(r => {
        this.total = r.total;
        this.pismoNotes = r.payload;
      })
    ).subscribe();
  }

  async onClickBack() {
    await this.router.navigate(navigationUrlForPismoAccounts())
  }

  async onPismoAccountDetailsEvent($event: OverdraftAccountDetailsComponentEvent) {
    switch ($event.type) {
      case 'display-transaction': {
        await this.router.navigate(navigationUrlForPismoDisplayTransactions($event.customerId, $event.pismoAccountNumber));
        break;
      }
      case 'account-user-created': {
        if (this.overdraftAccountUsersComponent) {
          this.overdraftAccountUsersComponent.reload();
        }
        break;
      }
      case 'retry-account-fees':
      case 'flag-change': {
        this.reload();
        break;
      }
    }
  }

  async onPismoAccountStatementsEvent($event: OverdraftAccountStatementsComponentEvent) {
    switch ($event.type) {
      case 'display-statements': {
        await this.router.navigate(navigationUrlForPismoStatementDetails($event.userId, $event.accountId, $event.statementId));
        break;
      }
    }
  }
  show() {
    this.showButton = true;
  }

  cancel() {
    this.formControlAccountNotes.reset();
    this.showButton = false;
  }


  onTotalNotesChange(event: PismoNotesComponentEvent) {
    if (event.type === 'total-changed') {
    this.total = event.payload;
    }
  }

  saveNotes() {
    // NOTE: formControl values should be valid when this is called
    const accountId = this.route.snapshot.paramMap.get('pismoAccountNumber') || ''
    const data = {
      AccountNumber: parseInt(accountId),
      Note: this.formControlAccountNotes.value ?? '',
      UserId: this.loggedInUser?.UserId!,
    }
    this.overdraftCustomerService.createPismoAccountNote(data).pipe(
      this.toastService.retryableMessage({
        successMessage: 'Notes created',
        errorMessage: 'Failed to retrive the Notes',
        retryFn: () => {
          this.saveNotes();
        }
      }),
      this.toastService.spinnerObservable(),
      tap(r => {
        this.reload();
      })
    ).subscribe();
    this.showButton = false;
    this.formControlAccountNotes.reset();
  }
}
