import {Injectable} from '@angular/core';
import escapeStringRegexp from 'escape-string-regexp';
import {
  ApplicationSort, ApplicationsReport,
  ApplicationTypes,
  DEFAULT_FILTER,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DEFAULT_SORTS,
  DEFAULT_APPLICATION_TYPE_FILTER,
  LastRecentApplicationsBundle,
  PaginablePayloadApiResponse, PayloadApiResponse, SimplifiedApplication, AllApplicationTypes
} from '@portal-workspace/grow-shared-library';
import {Observable, of, Subscription} from 'rxjs';
import loki from 'lokijs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {UntilDestroy} from '@ngneat/until-destroy';
import {map, switchMap, tap} from 'rxjs/operators';
import {httpOptions} from '@portal-workspace/grow-ui-library';
import {ApplicationTypeFilter} from '../page/applications-page/applications.page';
import moment from 'moment';

const URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE = ()=>`${environment.api2Host}/api2/applications-last-recent-bundle`;

@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class LocalApplicationsDbService {

  CACHE_EXPIRY_IN_MINUTES = 60;
  lastUpdate: moment.Moment = moment();  // last time it was updated
  loaded: boolean = false;  // loaded applications for the first time ?

  subscriptions: Subscription[] = [];

  localDb = new loki('local-applications-db');

  allDbCollection: Collection<SimplifiedApplication>;
  inSettlementDbCollection: Collection<SimplifiedApplication>;
  underReviewDbCollection: Collection<SimplifiedApplication>;
  closedWonDbCollection: Collection<SimplifiedApplication>;

  constructor(private httpClient: HttpClient) {
    this.allDbCollection = this.localDb.addCollection('all');
    this.inSettlementDbCollection = this.localDb.addCollection('inSettlement');
    this.underReviewDbCollection = this.localDb.addCollection('underReview');
    this.closedWonDbCollection = this.localDb.addCollection('closedWon');
  }

  reload(opts: {
    forceReload: boolean,
    applicationState: 'all' | 'under-review' | 'in-settlement' | 'closed-won'
    page: {offset: number, limit: number},
    sorts?: ApplicationSort,
    filter: string,
    applicationType: AllApplicationTypes,
    filterOpportunityOwner?:string
  } = {
    forceReload: false,
    applicationState: 'all',
    page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
    sorts: undefined,
    filter: '',
    applicationType: 'All',
    filterOpportunityOwner:''
  }): Observable<{
    totalApplications: number,
    totalApplicationsUnderReview: number,
    totalApplicationsInSettlement: number,
    totalApplicationsClosedWon: number,
    total: number,   // total applications search / sorts / filter
    limit: number,   // limit of applications search / sorts / filter
    offset: number,  // offset of applications search / sorts / filter
    applications: SimplifiedApplication[],
  }> {
    return this.reloadFromCacheOrRemoteSource(opts.forceReload).pipe(
      map(res => {
        let r: {total: number, offset: number, limit: number, applications: SimplifiedApplication[]};
        switch(opts.applicationState) {
          case 'all': {
            r = this.getApplicationsByUser(opts);
            break;
          }
          case 'in-settlement': {
            r = this.getApplicationsByUserInSettlement(opts);
            break;
          }
          case 'under-review': {
            r = this.getApplicationsByUserUnderReview(opts);
            break;
          }
          case 'closed-won': {
            r =  this.getApplicationsByUserClosedWon(opts);
            break;
          }
        }
        return {
          totalApplications: this.allDbCollection.data.length,
          totalApplicationsUnderReview: this.underReviewDbCollection.data.length,
          totalApplicationsInSettlement: this.inSettlementDbCollection.data.length,
          totalApplicationsClosedWon: this.closedWonDbCollection.data.length,
          ...r,
        }
      }),
    );
  }

  private reloadFromCacheOrRemoteSource(forceReload: boolean = false): Observable<{
    all: Collection<SimplifiedApplication>,
    inSettlement: Collection<SimplifiedApplication>,
    underReview: Collection<SimplifiedApplication>,
    closedWon: Collection<SimplifiedApplication>,
  }> {
    const t = moment().diff(this.lastUpdate, 'minutes');
    if (this.useCache(forceReload)) {
      console.log(`local-application-db: init: useCache: ${t} mins`);
      return of ({
        all: this.allDbCollection,
        inSettlement: this.inSettlementDbCollection,
        underReview: this.underReviewDbCollection,
        closedWon: this.closedWonDbCollection,
      });
    } else {
      console.log(`local-application-db: init: reload: ${t} mins`);
      return this.reloadFromRemoteSource();
    }
  }

  private useCache(forceReload: boolean = false): boolean {
    // previously loaded
    const loadedPreviously =  this.loaded;
    // force reload
    const noForceReload = !forceReload;
    // has cache expired?
    const cacheNotExpired = (moment().diff(this.lastUpdate, 'minutes') < this.CACHE_EXPIRY_IN_MINUTES);

    return (loadedPreviously && noForceReload && cacheNotExpired);
  }

  removeApplicationLocally(applicationId: number) {
    this.allDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
    this.inSettlementDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
    this.underReviewDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
    this.closedWonDbCollection.findAndRemove({
      ApplicationId: applicationId,
    });
  }

  private reloadFromRemoteSource(): Observable<{
    all: Collection<SimplifiedApplication>,
    inSettlement: Collection<SimplifiedApplication>,
    underReview: Collection<SimplifiedApplication>,
    closedWon: Collection<SimplifiedApplication>,
  }> {

    const obs = this.httpClient.post<PayloadApiResponse<LastRecentApplicationsBundle>>(URL_GET_LAST_RECENT_APPLICATIONS_BUNDLE(), {}, httpOptions())
      .pipe(
        tap(rs => {
          if (rs.payload) {
            this.loaded = true;
            this.lastUpdate = moment();

            this.allDbCollection.clear({removeIndices: true});
            this.inSettlementDbCollection.clear({removeIndices: true});
            this.underReviewDbCollection.clear({removeIndices: true});
            this.closedWonDbCollection.clear({removeIndices: true});

            const r = rs.payload;
            (r.all.records ?? []).forEach(rec => {
              this.allDbCollection.insert(rec);
            });
            (r.inSettlement.records ?? []).forEach(rec => {
              this.inSettlementDbCollection.insert(rec);
            });
            (r.underReview.records ?? []).forEach(rec => {
              this.underReviewDbCollection.insert(rec);
            });
            (r.closedWon.records ?? []).forEach(rec => {
              this.closedWonDbCollection.insert(rec);
            });
          }
        }),
        map(r => {
          return {
            all: this.allDbCollection,
            underReview: this.underReviewDbCollection,
            inSettlement: this.inSettlementDbCollection,
            closedWon: this.closedWonDbCollection,
          }
        })
      );
    return obs;
  }

  private doFiltering(chain: Resultset<SimplifiedApplication & LokiObj>, filter?: string) {
    if (!!filter) {
      const __filter = (filter ?? '').trim();
      const _filter = escapeStringRegexp(__filter);
      chain = chain.find({
        '$or': [
          {'BrokerAppId': {'$regex': [_filter, 'i']}},
          {'SalesforceId': {'$regex': [_filter, 'i']}},
          {'CompanyName': {'$regex': [_filter, 'i']}},
          {'BrokerName': {'$regex': [_filter, 'i']}},
          {'IndividualGivenName': {'$regex': [_filter, 'i']}},
          {'IndividualSurName': {'$regex': [_filter, 'i']}},
        ]
      });
    }
    return chain;
  }

  private doFilterOpportunityOwner(chain: Resultset<SimplifiedApplication & LokiObj>, filterOpportunityOwner?: string) {
    if (!!filterOpportunityOwner) {
      const __filter = (filterOpportunityOwner ?? '').trim();
      const _filter = escapeStringRegexp(__filter);
      chain = chain.find({
        '$or': [
          {'sfOwnerEmail': {'$regex': [_filter, 'i']}},
           {'sfOwnerFirstName': {'$regex': [_filter, 'i']}},
           {'sfOwnerLastname': {'$regex': [_filter, 'i']}},
        ]
      });
    }
    return chain;
  }

  private doTypeFiltering(chain: Resultset<SimplifiedApplication & LokiObj>, typeFilter?: ApplicationTypes) {
    // console.log(typeFilter)
    if (!!typeFilter) {
      const _filter = (typeFilter ?? '').trim();
      chain = chain.find({
        'ApplicationType': { '$eq': _filter }
      });
    }
    return chain;
  }

  private doSorting(chain: Resultset<SimplifiedApplication & LokiObj>, sorts?: ApplicationSort) {
    const sortCriteria: [(keyof SimplifiedApplication | keyof LokiObj), boolean][] = [];
    if (sorts && sorts.length) {
      for (const sort of sorts) {
        switch(sort.prop) {
          case 'BrokerAppId': {
            sortCriteria.push(['BrokerAppId', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'BrokerName': {
            sortCriteria.push(['BrokerName', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'CompanyName': {
            sortCriteria.push(['CompanyName', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'CreateTime': {
            sortCriteria.push(['CreateTime', (sort.dir == 'DESC' ? true : false)]);
            break;
          }
          case 'Status': {  //
            sortCriteria.push(['Status', (sort.dir == 'DESC' ? true : false)]);
            sortCriteria.push(['CreateTime', true]);
            break;
          }
          case 'AppInfoStageName': { //
            sortCriteria.push(['StageName', (sort.dir == 'DESC' ? true : false)]);
            sortCriteria.push(['CreateTime', true]);
            break;
          }
        }
      }
    }
    if (sortCriteria && sortCriteria.length) {
      chain = chain.compoundsort(sortCriteria);
    }
    return chain;
  }

  private doPagination(chain: Resultset<SimplifiedApplication & LokiObj>, page: {offset: number, limit: number}) {
    chain = chain
      .offset(page.offset * page.limit)
      .limit(page.limit)
    return chain;
  }

  // getApplicationsReport(): Observable<PayloadApiResponse<ApplicationsReport>> {
  //   return this.reload().pipe(
  //     map(v => {
  //       return {
  //         status: true,
  //         message: '',
  //         payload: {
  //           underReview: this.underReviewDbCollection.data.length,
  //           won: this.closedWonDbCollection.data.length,
  //           settlement: this.inSettlementDbCollection.data.length,
  //         }
  //       }
  //     })
  //   );
  // }

  private getApplicationsByUser(
    options: {
      page: {offset: number, limit: number},
      sorts?: ApplicationSort,
      filter: string,
      applicationType: AllApplicationTypes,
      filterOpportunityOwner?:string
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER, applicationType: 'All' }): {
    total: number,
    limit: number,
    offset: number,
    applications: SimplifiedApplication[]
  } {
         
        let chain: Resultset<SimplifiedApplication & LokiObj> = this.allDbCollection.chain();
        chain = this.doFiltering(chain, options.filter);
        
        chain = this.doFilterOpportunityOwner(chain, options.filterOpportunityOwner);

        if (options.applicationType && options.applicationType !== 'All') {
          chain = this.doTypeFiltering(chain, options.applicationType);
        }
        chain = this.doSorting(chain, options.sorts);
        const total = chain.data().length;
        chain = this.doPagination(chain, options.page);

        const result = chain.data();
        return {
          limit: options.page.limit,
          offset: options.page.offset,
          total,
          applications: result,
        };
  }

  private getApplicationsByUserInSettlement(
    options: {
      page: {offset: number, limit: number},
      sorts?: ApplicationSort,
      filter: string,
      applicationType: AllApplicationTypes,
      filterOpportunityOwner?:string
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER, applicationType: 'All' }): {
    offset: number,
    limit: number,
    total: number,
    applications: SimplifiedApplication[]
  } {

        let chain: Resultset<SimplifiedApplication & LokiObj> = this.inSettlementDbCollection .chain();
        chain = this.doFiltering(chain, options.filter);
        chain = this.doFilterOpportunityOwner(chain, options.filterOpportunityOwner);
        if (options.applicationType && options.applicationType !== 'All') {
          chain = this.doTypeFiltering(chain, options.applicationType);
        }
        chain = this.doSorting(chain, options.sorts);
        const total = chain.data().length;
        chain = this.doPagination(chain, options.page);

        const result = chain.data();
        return {
          limit: options.page.limit,
          offset: options.page.offset,
          total,
          applications: result,
        };
  }


  private getApplicationsByUserUnderReview(
    options: {
      page: {offset: number, limit: number},
      sorts?: ApplicationSort,
      filter: string,
      applicationType: AllApplicationTypes,
      filterOpportunityOwner?:string
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER, applicationType: 'All' }): {
    offset: number,
    limit: number,
    total: number,
    applications: SimplifiedApplication[]
  } {

        let chain: Resultset<SimplifiedApplication & LokiObj> = this.underReviewDbCollection.chain();
        chain = this.doFiltering(chain, options.filter);
        chain = this.doFilterOpportunityOwner(chain, options.filterOpportunityOwner);
        if (options.applicationType && options.applicationType !== 'All') {
          chain = this.doTypeFiltering(chain, options.applicationType);
        }
        chain = this.doSorting(chain, options.sorts);
        const total = chain.data().length;
        chain = this.doPagination(chain, options.page);

        const result = chain.data();
        return {
          limit: options.page.limit,
          offset: options.page.offset,
          total,
          applications: result,
        };
  }

  private getApplicationsByUserClosedWon(
    options: {
      page: {offset: number, limit: number},
      sorts?: ApplicationSort,
      filter: string,
      applicationType: AllApplicationTypes,
      filterOpportunityOwner?:string
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER, applicationType: 'All' }): {
    limit: number,
    offset: number,
    total: number,
    applications: SimplifiedApplication[],
  } {

        let chain: Resultset<SimplifiedApplication & LokiObj> = this.closedWonDbCollection.chain();
        chain = this.doFiltering(chain, options.filter);
        chain = this.doFilterOpportunityOwner(chain, options.filterOpportunityOwner);
        if (options.applicationType && options.applicationType !== 'All') {
          chain = this.doTypeFiltering(chain, options.applicationType);
        }
        chain = this.doSorting(chain, options.sorts);
        const total = chain.data().length;
        chain = this.doPagination(chain, options.page);

        const result = chain.data();
        return {
          limit: options.page.limit,
          offset: options.page.offset,
          total,
          applications: result,
        };

  }
}
