import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ApplicationDialogService,
  createAbnInputMask,
  createAcnInputMask,
  createEmailInputMask,
  createPhoneNumberInputMask,
  createTwoDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  noSpaceValidator,
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {of, Subscription} from 'rxjs';
import {
  AbnComponentValue,
  AcnComponentValue, CustomerEntityTypeValueOptions,
  EmailComponentValue,
  MobileValue,
  OriginatorBusiness, OriginatorTypeValueOptions,
  UpdateOriginatorInput
} from '@portal-workspace/grow-shared-library';
import { InputMaskModule } from '@ngneat/input-mask';
import { ActivatedRoute,Router } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { OriginatorTypeValue } from '@portal-workspace/grow-shared-library';
import { OriginatorRelationshipValue } from '@portal-workspace/grow-shared-library';
import { CustomerEntityTypeValue } from '@portal-workspace/grow-shared-library';
import { GetFn as RatecardGetFn, } from '@portal-workspace/grow-ui-library';
import { RateCardListValue } from '@portal-workspace/grow-shared-library';
import { tap } from 'rxjs/operators';
import {createAsyncState, createAsyncStore, loadingFor} from '@ngneat/loadoff';
import { navigationUrlForCompanies } from '../../service/navigation-urls';
import { MatButtonModule } from '@angular/material/button';
import { RateCardListComponent } from '@portal-workspace/grow-ui-library';
import { MobileComponent } from '@portal-workspace/grow-ui-library';
import { AcnComponent } from '@portal-workspace/grow-ui-library';
import { AbnComponent } from '@portal-workspace/grow-ui-library';
import { CustomerEntityTypeComponent } from '@portal-workspace/grow-ui-library';
import { OriginatorRelationshipComponent } from '@portal-workspace/grow-ui-library';
import { DisableControlDirective } from '@portal-workspace/grow-ui-library';
import { OriginatorTypeComponent } from '@portal-workspace/grow-ui-library';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { AsyncPipe } from '@angular/common';
import {FlexModule} from "@angular/flex-layout";


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './company.page.html',
    styleUrls: ['./company.page.scss'],
    standalone: true,
    imports: [
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    OriginatorTypeComponent,
    DisableControlDirective,
    FormsModule,
    ReactiveFormsModule,
    OriginatorRelationshipComponent,
    CustomerEntityTypeComponent,
    AbnComponent,
    AcnComponent,
    MobileComponent,
    RateCardListComponent,
    MatButtonModule,
    AsyncPipe,
    FlexModule,
    InputMaskModule,
]
})
export class CompanyPage implements OnInit {
  loader = loadingFor('update');
  store = createAsyncStore();
  errorTitle =  'Error Occurred!'
  errorMessage = 'Please try again.'
  retry(){
    this.reload();
  }
  errorKeys = formControlErrorKeys;
  errorMessages = formControlErrorMessage;
  createTwoDecimalInputMask = createTwoDecimalInputMask;
  originatorBusiness: OriginatorBusiness | null = null

  subscriptions: Subscription[] = [];

  formGroup: FormGroup<{
    type: FormControl<OriginatorTypeValue>,
    relationship: FormControl<OriginatorRelationshipValue>,
    entityName: FormControl<string | null>,
    entityType: FormControl<CustomerEntityTypeValue>,
    abn: FormControl<AbnComponentValue>,
    acn: FormControl<AcnComponentValue>,
    // aggregator: FormControl<AggregatorListValue>,
    website: FormControl<string | null>,
    email: FormControl<string | null>,
    telephoneNumber: FormControl<MobileValue>,
    ratecard: FormControl<RateCardListValue>,
    percentagePaid: FormControl<number | null>,
  }>;
  formControlType: FormControl<OriginatorTypeValue>;
  formControlRelationship: FormControl<OriginatorRelationshipValue>;
  formControlEntityName: FormControl<string | null>;
  formControlEntityType: FormControl<CustomerEntityTypeValue>;
  formControlAbn: FormControl<AbnComponentValue>;
  formControlAcn: FormControl<AcnComponentValue>;
  // formControlAggregator: FormControl<AggregatorListValue>;
  formControlWebsite: FormControl<string | null>;
  formControlEmail: FormControl<string | null>;
  formControlTelephoneNumber: FormControl<MobileValue>;
  formControlRatecard: FormControl<RateCardListValue>;
  formControlPercentagePaid: FormControl<number | null>;
  // aggregatorGetFn: AggregatorGetFn;
  ratecardGetFn: RatecardGetFn;

  constructor(private activatedRoute: ActivatedRoute,
              private adminService: AdminService,
              private toastService: PortalHotToastService,
              private router: Router,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private dialogService: ApplicationDialogService) {
    this.formControlType = formBuilder.control(null, [Validators.required]);
    this.formControlRelationship = formBuilder.control(null, [Validators.required]);
    this.formControlEntityName = formBuilder.control(null, [Validators.required, noSpaceValidator()]);
    this.formControlEntityType = formBuilder.control(null, [Validators.required]);
    this.formControlAbn = formBuilder.control(null, [Validators.required]);
    this.formControlAcn = formBuilder.control(null, [Validators.required]);
    // this.formControlAggregator = formBuilder.control(null);
    this.formControlWebsite = formBuilder.control(null, [Validators.required,Validators.pattern('((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)')]);
    this.formControlEmail = formBuilder.control(null);
    this.formControlTelephoneNumber = formBuilder.control(null, [Validators.required]);
    this.formControlRatecard = formBuilder.control(null, [Validators.required, this.checkRateCardHasName()]);
    this.formControlPercentagePaid = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      type: this.formControlType,
      relationship: this.formControlRelationship,
      entityName: this.formControlEntityName,
      entityType: this.formControlEntityType,
      abn: this.formControlAbn,
      acn: this.formControlAcn,
      // aggregator: this.formControlAggregator,
      website: this.formControlWebsite,
      email: this.formControlEmail,
      telephoneNumber: this.formControlTelephoneNumber,
      ratecard: this.formControlRatecard,
      percentagePaid: this.formControlPercentagePaid,
    });
    // this.aggregatorGetFn = this.adminService.AggregatorsGetFn;
    this.ratecardGetFn = this.adminService.RatecardsGetFn;
  }


  ngOnInit(): void {
    setupUntilDestroy(this);
    this.originatorBusiness = (this.activatedRoute.snapshot.data as any).originatorBusiness;
    console.log('***** originatorBusiness', this.originatorBusiness, this.formControlType.disabled);
    this.populate();
    this.checkForValidationIssues();
  }

  populate() {
    if (this.originatorBusiness) {
      const a: OriginatorTypeValue = OriginatorTypeValueOptions.find(opt => opt.type == this.originatorBusiness?.Type) ?? null; // { type: this.originatorBusiness.Type, name: '' };
      this.formControlType.setValue(a);

      const b: OriginatorRelationshipValue = this.originatorBusiness.Relationship;
      this.formControlRelationship.setValue(b);

      this.formControlEntityName.setValue(this.originatorBusiness.EntityName);

      const c: CustomerEntityTypeValue = CustomerEntityTypeValueOptions.find(opt => opt?.type == this.originatorBusiness?.EntityType) ?? null; // { type: this.originatorBusiness.EntityType, name: '' };
      this.formControlEntityType.setValue(c);

      this.formControlAbn.setValue(this.originatorBusiness.ABN);
      this.formControlAcn.setValue(this.originatorBusiness.ACN);

      // const d: AggregatorListValue = this.originatorBusiness.Aggregator ? { aggregatorId: this.originatorBusiness.Aggregator, name: '' } : null;
      // const d: AggregatorListValue = this.originatorBusiness.OriginatorBusinessId ? { aggregatorId: this.originatorBusiness.OriginatorBusinessId, name: '' } : null;
      // this.formControlAggregator.setValue(d);

      this.formControlWebsite.setValue(this.originatorBusiness.Website);
      this.formControlEmail.setValue(this.originatorBusiness.Email);
      this.formControlTelephoneNumber.setValue(this.originatorBusiness.TelephoneNumber);

      const e: RateCardListValue = { RatecardId: this.originatorBusiness.RatecardId, Name: '', CreateTime: '' };
      this.formControlRatecard.setValue(e);
      this.formControlPercentagePaid.setValue(this.originatorBusiness.PercentagePaid);
    } else {
      this.store.value$ = of(createAsyncState({
        error: new Error(`Invalid orignator business`)
      }));
      this.toastService.publishErrorNotification({
        type: 'error',
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      });
    }
  }

  checkForValidationIssues() {
    Object.keys(this.formGroup.controls).forEach(field => {
      const control = this.formGroup.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    this.cdr.detectChanges();
  }

  reload() {
    this.store = createAsyncStore();
    if (this.originatorBusiness) {
      this.adminService.getOriginatorById(this.originatorBusiness.OriginatorBusinessId).pipe(
        // this.toastService.toastObserver('loading company'),
        this.toastService.publishErrorNotificationObservable({
          errorTitle: this.errorTitle, errorMessage: this.errorMessage, retryFn: this.retry.bind(this),
        }),
        this.store.track(),
        tap(r => {
          this.originatorBusiness = r.payload
          this.populate();
        })
      ).subscribe();
    }
    else{
      this.store.value$ = of(createAsyncState({
        error: new Error(`Invalid orignator business`)
      }));
      this.toastService.publishErrorNotification({
        type: 'error',
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
        retryFn: this.retry.bind(this),
      });
    }
  }

  update($event: Event) {
    // NOTE: form controls should be valid at this point (pass validators validation)
    if (this.originatorBusiness) {
      // const type: OriginatorTypeValue = this.formControlType.value;
      const relationship: OriginatorRelationshipValue = this.formControlRelationship.value;
      const entityType: CustomerEntityTypeValue = this.formControlEntityType.value;
      if (entityType) {
        // const aggregator: AggregatorListValue = this.formControlAggregator.value;
        const ratecard: RateCardListValue = this.formControlRatecard.value;
        const input: UpdateOriginatorInput = {
          // Type: type!.type,
          Relationship: relationship,
          EntityName: this.formControlEntityName.value ?? '',
          EntityType: entityType.type,
          ABN: this.formControlAbn.value ?? '',
          ACN: this.formControlAcn.value ?? '',
          // Aggregator: aggregator!.aggregatorId,
          Website: this.formControlWebsite.value ?? '',
          Email: this.formControlEmail.value ?? '',
          TelephoneNumber: this.formControlTelephoneNumber.value ?? '',
          RatecardId: ratecard!.RatecardId,
          PercentagePaid: this.formControlPercentagePaid.value ?? 0,
        };
        console.log(`****** update originator`, input);
        this.subscriptions.push(this.adminService.updateOriginatorBusiness(this.originatorBusiness.OriginatorBusinessId, input).pipe(
          this.loader.update.track(),
          this.toastService.retryableMessage({
            successMessage: 'Company details saved',
            errorMessage: 'Failed to save the Company details',
            retryFn: ()=> {
              console.log('**** retry ', this);
              this.update($event);
            }
          }),
          tap(r => {
            this.reload();
          })
        ).subscribe());
      } else {
        // this should not happen as there is a validator for entity type
        this.dialogService.openAlertDialog({message: `Alert`, subMessage: `Entity type is not valid`});
      }
    }
  }
  async onClickBack() {
    await this.router.navigate(navigationUrlForCompanies())
  }

  checkRateCardHasName (): ValidatorFn { // Ratecard only has the name after loading the ratecard list
    return (control: AbstractControl): ValidationErrors | null => {
      const val = control.value;
      return !(val && val.Name && val.Name != '') ? {hasName: true} : null;
    }
  }
}
