import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ApplicationTypes,
  ConsumerFinanceApplication,
  ContractDetails,
  DeclineContractFn,
  DeleteContractFileFn,
  GenerateContractFn,
  GetActiveContractForApplicationFn,
  GetContractStatusFn,
  isInternalUser,
  PayloadApiResponse,
  PpsrAsset,
  PpsrAssetValue,
  RatecardDetails,
  RequestDoc,
  SendContractEmailsFn,
  User,
  VehicleDetails,
  getAppSalesforceId,
  CreateApplicationNoteFn,
  applicationStageAllowGenerateContract,
  CreateNewDisbursementFn,
  UpdateDisbursementFn,
  GetDisbursementByApplicationIdFn,
  SyncDisbursementToSfFn,
  SendAssetToSfRecord,
  getBrokerageAmount,
  SyncBankDetailsToSfFn,
  SyncContractDetailsToSfFn,
  RemoveApplicationNoteByNoteIdFn,
  getAssetGoodRetail,
  GetBillerNameFn,
  ValidateBPAYFn,
  UpdateApplicationStageInSfFn,
  GetApplicationByIdFn,
  formGroupedDocumentData,
  unclassifiedDocuments,
  AzureStorageDocument,
  Application,
  GroupedDocument,
  ApiResponse,
  Disbursement,
  validateDisbursementData,
  SettleLoanFn,
  SyncPrivateSellerBankDetailsToSfFn,
  GetInstitutionNameFn,
  SyncDepositPaidToSfFn,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  GetApplicationOwnerFn,
  groupStandardCondition,
  RefreshBankStatementFn,
  GetUserFn,
  GetAccountDetailsFromSfFn,
  GetBankDetailsFromOpportunitySfFn,
  LocalBankAccountDetailValue,
  ExtractTaxInvoiceFn,
  UpdateApplicationFn,
  constants,
  CreateAssetInspectionFn,
  GetAssetInspectionsForApplicationFn,
  DownloadVerimotoReportFn,
  VerimotoInspectionTableDataWithInspectionDetails,
  VerimotoLenderType,
  GetBankStatementsAnalysisFn,
  VerimotoInspectionTypeSelection,
  DownloadDocumentFromAzureFn,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  getDocFee,
  UpdateApplicationSfFn,
  TotalPaymentBreakupDialogData,
  isAdminOrCreditUserOrSalesAM,
  isAdminOrCreditUser,
  calculateConsumerAssetFinanceEstimation,
  AssetConditionType,
  PaymentFrequencyType,
  getCreditRateAdjustment,
  getAssetSpec,
  getApplicationAssetCategory,
  getAssetCategoryObj,
  getAssetTypeObj,
  LoanTermType,
  FinanceType,
  BalloonPaymentType,
  BrokerageSelectionType,
  getHybrid,
  SendPrivacyConsentEmailFn,
  GetBsaLenderListFn,
  GetBsaExcludedLenderListFn,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  SaveBsaCalculatorFn,
  GetBsaCalculatorFn,
  GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, RefreshBasiqConnectionsFn, GetBankStatementAndBasiqDataStatusFn,
  getRateDiscount, GetOriginatorBusinessByIdFn,
  GetConsumerDscrCalculatorValueFn,
  UpdateConsumerDscrCalculatorValueFn,
  GetDscrCalculatorHistoryFn,
  ConsumerFinanceDsrServiceabliltyCalculationFn,
  AddAuditLogFn,
  RunCreditFlowFn,
  GetCreditWebServicesFn,
  GetApplicationCreditFlowResultFn,
  DownloadCreditorWatchReportFn,
  UpdateCreditStatusFn,
  GetCreditAuditLogFn,
  ApplicationSelectionObject,
  ApplicationWebService,
  GetWebServiceReportFn,
  GetDuplicatedApplicationsFn,
  DuplicatedApplication,
  DeleteWebServicesFn,
  getRepaymentType,
  RequestMoreInformationFn,
  WithdrawApplicationFn,
  PortalLoginUser,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent, BreadcrumbComponent } from '../../breadcrumb-component/breadcrumb.component';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {ApplicationSelectionType} from '@portal-workspace/grow-shared-library';
import {
  ApproveApplicationDocumentFn,
  CompleteDocumentWorklistFn,
  CreateDocumentWorklistFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn,
  DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn,
  DownloadAllApplicationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn,
  GetOriginatorByIdFn,
  getUser,
  ListApplicationDocumentFn,
  SendApplicationDocumentNotificationFn,
  setupUntilDestroy,
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  PortalHotToastService,
  ApplicationDialogService,
  AssetSettlementAddPpsrDetailFn,
  AssetSettlementGetApplicationPpsrInfoFn,
  AssetSettlementGetPpsrDetailsFn,
  AssetSettlementGetPpsrDocumentsFn,
  AssetSettlementRemoveApplicationAssetFn,
  AssetSettlementSavePpsrDocumentFn,
  AssetSettlementSearchAssetsFn,
  AssetSettlementSearchGrantorsFn,
  AssetSettlementSendAssetToSfFn,
  AssetSettlementSubmitApplicationAssetsFn,
  AssetSettlementSubmitPpsrFn,
  AssetSettlementUpdateApplicationAssetsFn,
  AssetSettlementUpdatePpsrDetailFn,
  SearchSupplierFn,
  SaveSupplierFn,
  BusinessSearchFn,
  BusinessNumberSearchFn,
  checkContractIndividuals,
  applicationDefaultDocuments,
  UndoApplicationDocumentFn,
  BusinessOverdraftTab,
  AssetSelectionComponentSearchFn,
  AssetDocumentsComponent,
  applicationToMonthlyAccountKeepingFee,
} from '@portal-workspace/grow-ui-library';
import {yesNoToBoolean} from '@portal-workspace/grow-shared-library';
import {switchMap, tap} from 'rxjs/operators';
import {
  getBalloonPaymentPercentage,
  primaryCommercialEntity,
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake, getAssetNewPrice,
  getAssetType,
  getAssetYear, getBalloonPayment,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName, getBrokerOriginationFee,
  getBrokerSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLoanAmount,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType, getPrimaryBusinessAddress,
  getPrimaryIndustry,
  getPrivateSales,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  getPaymentType,
  DigitalIdGetApplicationIndividualsFn,
  GetRateCardDetailsFn,
  AssetSettlementGetApplicationAssetFn,
  getApplicationStage,
  applicationStageAllowSettleLoan,
  DocumentTag,
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {GetUsersFunc, UserSelectionComponentEvent } from '@portal-workspace/grow-ui-library';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {RequestDocFn} from '../asset/asset-finance-application-details.component'
import {GetApplicationAuditLogsFn} from '../../asset-listing-component/audit-log.component';
import {GetNotesByApplicationIdFn} from '../../asset-listing-component/notes.component';
import { UpdateDocumentMetadataFn, AddAssetComponent } from '../../asset-listing-component/add-asset.component';
import { ConsumerAssetDocumentsComponent } from './consumer-documents.component';
import { KycVerificationComponent } from '../kyc-verification.component';
import { AssetSettlementComponent } from '../asset/asset-settlement.component';
import { ConsumerAppComponent } from './consumer-app.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { ApplicationStageIconComponent } from '../application-stage-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { BankComponent } from '../bank.component';
import { ConsumerPricingComponent, ConsumerPricingComponentEvent } from './consumer-pricing.component';
import {ConsumerCreditComponent} from "./consumer-credit.component";
import {ApplicationTypeIconComponent} from '../../application-type-icon/application-type-icon.component';
import { CreditReportComponent } from '../../credit-component/credit-report.component';

export type ConsumerTab = {selectionTypes: ApplicationSelectionType[], iconClass: string, name: string, index: number};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'consumer-finance-application-details',
  templateUrl: './consumer-finance-application-details.component.html',
  styleUrls: ['./consumer-finance-application-details.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    BreadcrumbComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    ApplicationStageIconComponent,
    MatTabsModule,
    MatMenuModule,
    MatDividerModule,
    MessageBoxComponent,
    ConsumerAppComponent,
    AssetSettlementComponent,
    AddAssetComponent,
    KycVerificationComponent,
    ConsumerAssetDocumentsComponent,
    BankComponent,
    ConsumerPricingComponent,
    AssetDocumentsComponent,
    ConsumerCreditComponent,
    ApplicationTypeIconComponent,
    CreditReportComponent,
  ]
})
export class ConsumerFinanceApplicationDetailsComponent {

  tabs: BusinessOverdraftTab[] = [];

  // possible selections that can be access directly through URL
  possibleSelections: ApplicationSelectionType[] = ['app', 'quote','asset', 'bank-statement', 'documents', 'kyc-aml', 'pricing', 'credit-report'];

  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getBrokerName = getBrokerName;
  getOrganisationType = getOrganisationType;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getAssetCategory = getAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getPrimaryIndustry = getPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  isInternalUser = isInternalUser;
  getAppSalesforceId = getAppSalesforceId;
  getApplicationStage = getApplicationStage;
  applicationStageAllowGenerateContract = applicationStageAllowGenerateContract;
  getPaymentType = getPaymentType;
  applicationStageAllowSettleLoan = applicationStageAllowSettleLoan;
  groupStandardCondition = groupStandardCondition;
  formGroupedDocumentData = formGroupedDocumentData;
  unclassifiedDocuments = unclassifiedDocuments;
  @Input({required: true}) application!: ConsumerFinanceApplication;

  breadcrumbTrails: string[] = ['Applications'];

  @Input({required: true}) getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input({required: true}) addIndividualFn!: DigitalIdAddIndividualFn;
  @Input({required: true}) verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input({required: true}) verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input({required: true}) sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input({required: true}) updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input({required: true}) updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input({required: true}) printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input({required: true}) setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: true}) bankStatementsUrl!: string;
  @Input({required: true}) idVerifyUrl!: string;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  // @Input({required: true}) updateApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;

  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  @Input({required: true}) sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) assetSearchFn!: AssetSelectionComponentSearchFn;
  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();

  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: false}) initialSelection: ApplicationSelectionType | null = null;
  @Output() selectionEvent: EventEmitter<ApplicationSelectionObject> = new EventEmitter<ApplicationSelectionObject>();
  @Input({required: true}) requestDocFn!: RequestDocFn;
  @Input({required: true}) getApplicationPpsrInfoFn!: AssetSettlementGetApplicationPpsrInfoFn;
  @Input({required: true}) removeApplicationAssetFn!: AssetSettlementRemoveApplicationAssetFn;
  @Input({required: true}) searchAssetFn!: AssetSettlementSearchAssetsFn;
  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) saveSupplierFn!: SaveSupplierFn;
  @Input({required: true}) submitPpsrFn!: AssetSettlementSubmitPpsrFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) sendAssetToSfFn!: AssetSettlementSendAssetToSfFn;
  @Input({required: true}) getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  @Input({required: true}) addPpsrDetailFn!: AssetSettlementAddPpsrDetailFn;
  @Input({required: true}) updatePpsrDetailFn!: AssetSettlementUpdatePpsrDetailFn;
  @Input({required: true}) updateApplicationAssetsFn!: AssetSettlementUpdateApplicationAssetsFn;
  @Input({required: true}) submitApplicationAssetsFn!: AssetSettlementSubmitApplicationAssetsFn;
  @Input({required: true}) searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input({required: true}) getPpsrDocumentsFn!: AssetSettlementGetPpsrDocumentsFn;
  @Input({required: false}) savePpsrDocumentFn!: AssetSettlementSavePpsrDocumentFn;
  @Input({required: true}) assetSettlementSearchAssetsFn!: AssetSettlementSearchAssetsFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) updateDocumentMetadataFn!: UpdateDocumentMetadataFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({required: true}) generateContractFn!: GenerateContractFn;
  @Input({required: true}) deleteContractFileFn!: DeleteContractFileFn;
  @Input({required: true}) sendContractEmailsFn!: SendContractEmailsFn;
  @Input({required: true}) getActiveContractForApplicationFn!: GetActiveContractForApplicationFn;
  @Input({required: true}) declineContractFn!: DeclineContractFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input({required: true}) updateDisbursementFn!: UpdateDisbursementFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input({required: true}) syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input({required: true}) syncPrivateSellerBankDetailsToSfFn!: SyncPrivateSellerBankDetailsToSfFn;
  @Input({required: true}) syncDepositPaidToSfFn!: SyncDepositPaidToSfFn;
  @Input({required: true}) syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) getBillerNameFn!: GetBillerNameFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: true}) validateBpayFn!: ValidateBPAYFn;
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;
  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  @Input({required: true}) getBankDetailsFromOpportunitySfFn!: GetBankDetailsFromOpportunitySfFn;
  @Input({required: true}) allowContractGeneration!: boolean;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) extractTaxInvoiceFn!: ExtractTaxInvoiceFn;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) createAssetInspectionFn!: CreateAssetInspectionFn;
  @Input({required: true}) getAssetInspectionsForApplicationFn!: GetAssetInspectionsForApplicationFn;
  @Input({required: true}) downloadVerimotoReportFn!: DownloadVerimotoReportFn;
  @Input({required: true}) verimotoLender!: VerimotoLenderType;
  @Input({required: false}) verimotoAssetInspectionTypes!: VerimotoInspectionTypeSelection[];
  @Input({required: true}) downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input({required: true}) sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input({required: true}) bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input({required: true}) deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input({required: true}) getConsumerDscrCalculatorValueFn!: GetConsumerDscrCalculatorValueFn;
  @Input({required: true}) updateConsumerDscrCaculatorValueFn!: UpdateConsumerDscrCalculatorValueFn;
  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  @Input({required: true}) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({required: true}) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({required: true}) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  @Input({required: true}) getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  @Input({required: true}) consumerFinanceDscrServiceabilityCalculationFn!: ConsumerFinanceDsrServiceabliltyCalculationFn;
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getWebServiceReportFn!: GetWebServiceReportFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;
  @Input({required: true}) withdrawApplicationFn!: WithdrawApplicationFn;
  @Output() consumerPricingEvents = new EventEmitter<ConsumerPricingComponentEvent>();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() navigateToApplications = new EventEmitter();
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;

  activeContract: ContractDetails | null = null;

  repaymentEstimationData: RepaymentEstimationEntry[] = [];
  amortizationScheduleData: AmortisationScheduleEntry[] = [];
  assetInspectionTableData: VerimotoInspectionTableDataWithInspectionDetails[] = [];

  paymentChartData: PaymentChartData = {
    amountFinanced: 0,
    totalInterest: 0 ,
    emiAmt: 0,
    paymentFrequency: 'Monthly',
    principalAmt: 0,
    interestAmt: 0,
    totalAmt: 0,
    loanTerm: 0,
    lvr: 0,
    rv: 0,
    brokerageAmount: 0,
    docFee: 0,
    brokerOriginationFee: 0,
    applicationType:'Consumer',
    monthlyAccountKeepingFee: 0,
  };
  amortizationChartData: AmortizationChartData = {
    estimatedDrawdownDate: moment(),
    annualData: [],
    quarterlyData: [],
  };
  calculationLog?: {log: string, rate?: number}[];

  subscriptions: Subscription[] = [];
  loggedInUser: PortalLoginUser | null = getUser();
  isDealer: boolean = false;


  currentSection: ApplicationSelectionType = 'app';
  currentSectionIndex: number = 0;
  sendToSF !: boolean;

  editAssetValue!: PpsrAssetValue
  registered: boolean = false;
  ppsrChecked!: boolean;
  ppsrRegistered!: boolean;
  assetStatus:any=[];
  isSettledLoan: boolean = false
  allAssetAccepted : boolean = false
  isAssetEmpty: boolean = false;
  missingBankDetails: null | 'customer' | 'broker' = null;
  rst!: TotalPaymentBreakupDialogData;

  constructor(private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              private applicationDialogService: ApplicationDialogService,
  ) {}

  initTabs() {
    this.tabs = [];
    this.tabs.push({selectionTypes: ['app'], iconClass: 'mdi-view-grid-outline', name: 'APPLICATION', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['bank-statement'], iconClass: 'mdi-bank-outline', name: 'BANK STATEMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['kyc-aml'], iconClass: 'mdi-check-decagram-outline', name: 'CUSTOMER DETAILS(KYC/AML)', index:(this.tabs.length)});
    if (isAdminOrCreditUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['credit', 'credit-report'], iconClass: 'mdi-calculator', name: 'CREDIT', index: (this.tabs.length)});
    }
    this.tabs.push({selectionTypes: ['documents'], iconClass: 'mdi-folder-outline', name: 'DOCUMENTS', index: (this.tabs.length)});
    if (isInternalUser(this.loggedInUser) || this.isDealer) {
      this.tabs.push({selectionTypes: ['asset', 'add-asset'], iconClass: 'mdi-handshake-outline', name: 'CONTRACT', index: (this.tabs.length)});
    }
    if (isAdminOrCreditUserOrSalesAM(this.loggedInUser) || isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['pricing'], iconClass: 'mdi-currency-usd', name: 'PRICING', index: (this.tabs.length)});
    }
  }

  // getApplicationTabIndex(tab: ApplicationSelectionType) {
  //   const tabFound = this.tabs.find((t) => t.selectionTypes.includes(tab));
  //   return tabFound ? tabFound.index : 0 /* default tab index if not found */;
  //   // switch (tab) {
  //   //   case 'app':
  //   //     return 0;
  //   //   case 'bank-statement':
  //   //     return 1;
  //   //   case 'kyc-aml':
  //   //     return 2
  //   //   case 'documents':
  //   //     return 3
  //   //   case 'asset':
  //   //   case 'add-asset':
  //   //     return 4
  //   //   // case 'lvr-calculator':
  //   //   //   return 5;
  //   //   default:
  //   //     return 0;
  //   // }
  // }

  async onSelectedTabChange(event: MatTabChangeEvent) {
    // const isInternalUser = this.isInternalUser(this.loggedInUser);
    // const isDealer = this.isDealer(this.loggedInUser);
    // let selection: ApplicationSelectionType = 'app';
    // switch (tabIndex) {
    //   case 0:
    //     selection = 'app';
    //     break;
    //   case 1:
    //     selection = 'bank-statement';
    //     break;
    //   case 2:
    //     selection = 'kyc-aml';
    //     break;
    //   case 3:
    //     selection = 'documents';
    //     break;
    //   case 4:
    //     selection = 'asset';
    //     break;
    //   // case 5:
    //   //   selection = 'add-asset';
    //   //   break;
    // }
    const tabFound = this.tabs.find(t => t.index == event.index);
    const selection = tabFound ? tabFound.selectionTypes[0] : 'app' /* default selection if not found */ ;
    this.currentSection = selection;
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  showAddAsset() {
    this.currentSection = 'add-asset';
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  ngOnInit() {
    this.isDealer = !!this.loggedInUser?.priviledges?.includes('dealer');
    this.initTabs();
    const routeTab = this.initialSelection;
    if (routeTab && this.possibleSelections.includes(routeTab)) {
      const _tab = this.tabs.find(tab => tab.selectionTypes.includes(routeTab));
      if (_tab) {
        this.currentSection = routeTab;
        this.currentSectionIndex = _tab.index;
      }
    }
    setupUntilDestroy(this);
    const user = getUser();
    const commercialEntity = primaryCommercialEntity(this.application);
    if (commercialEntity) {
      this.breadcrumbTrails.push(commercialEntity.LegalName);
    }
    const sub = combineLatest([
      this.getRatecardDetailsFn(this.application.UserId, 'Consumer'),
      this.getOriginatorBusinessByIdFn(this.application.CompanyId),
    ]).pipe(
      tap(([r, o]) => {
        const ratecard = r;
        const originatorBusiness = o;
        const date = moment().add(1, 'day');

        const assetSpec = getAssetSpec(this.application);
        const assetGoodRetail = getAssetGoodRetail(this.application);
        const assetNewPrice = getAssetNewPrice(this.application);
        const assetCategory = getApplicationAssetCategory(this.application); // as string
        const assetType = getAssetType(this.application); // as string
        const ac = getAssetCategoryObj(this.application);
        const at = getAssetTypeObj(this.application);
        const financialType = (getFinanceType(this.application) as string).toLowerCase().split(' ').join('-') as FinanceType;

        const rst = calculateConsumerAssetFinanceEstimation(date, {
          type: 'Consumer',
          paymentFrequencyType: getRepaymentFrequency(this.application) ? getRepaymentFrequency(this.application) as PaymentFrequencyType : 'Monthly',
          assetConditionType: getAssetCondition(this.application) as AssetConditionType ?? null,
          assetSelectionValue: {
            // ...getAssetSpec(this.application) as AssetSpec, //  as { year: string, description: string },
            ...{
              make: assetSpec?.make,
              family: assetSpec?.family,
              year: assetSpec?.year ? String(assetSpec.year) : '',
              vehicle:  {
                goodretail: assetGoodRetail,
                newprice: assetNewPrice,
              },
              LVR: assetSpec?.LVR,
              description: assetSpec?.description ?? '',
              avgRetail: assetSpec?.avgretail,
              newPrice: assetSpec?.newPrice,
              OtherCar: assetSpec?.OtherCar ?? false,
              truckGrossVehicleWeight:  assetSpec?.truckGrossVehicleWeight,
            },
            category: ac ?? {index: assetCategory, value: assetCategory},
            type: at ?? { index: assetType, value: assetType }
          }, // as AssetSelectionComponentValue,
          assetYear: parseFloat(getAssetYear(this.application) != '' ? getAssetYear(this.application) as any :  "0"),
          loanTerm: getLoanTerms(this.application),
          financeType: financialType,
          balloonPayment: getBalloonPaymentPercentage(this.application),
          loanAmount: getLoanAmount(this.application),
          businessSearchValue: null,
          existingApplicationBureauReport: this.application.CompanyDetails,
          propertyOwnership: yesNoToBoolean(getPropertyOwner(this.application)),
          docFeeFinanced: yesNoToBoolean(getDocFeeFinanced(this.application)),
          brokerOriginationFee: getBrokerOriginationFee(this.application),
          brokerage: getBrokerage(this.application),
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          equifaxScoreAbove600: yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          privateSaleOrLeaseback: getPrivateSales(this.application) !== 'No',
          //balloonPayment: getBalloonPaymentPercentage(this.application),
          balloonPaymentAmount: getBalloonPayment(this.application),
          //docFee: getDocFee(this.application) as number,
          rateCard: r,
          invoiceAmount: Number(getInvoiceAmount(this.application)),
          deposit: Number(getDeposit(this.application)),
          brokerageAmount: this.application.PricingDetails?.BrokerageAmount ? getBrokerageAmount(this.application) : undefined,
          paymentType: String(getPaymentType(this.application)),
          creditRateAdjustment: getCreditRateAdjustment(this.application),
          hybrid: yesNoToBoolean(getHybrid(this.application)),
          rateDiscount: getRateDiscount(this.application) ?? 0,
          repaymentType : getRepaymentType(this.application) ?? '',
          percentagePaidToDealerOrBroker: originatorBusiness ? originatorBusiness.PercentagePaid : 100,
          monthlyAccountKeepingFee: applicationToMonthlyAccountKeepingFee(this.application),
          ppsrFee: r.PpsrFee, // has been included in the doc fee
          // transactionType: applicationToTransactionValue(this.application)?.type,
        });

        this.rst = rst.totalPaymentBreakupDialogData
        this.repaymentEstimationData = rst.totalPaymentBreakupDialogData.repaymentEstimationData;
        this.amortizationScheduleData = rst.totalPaymentBreakupDialogData.amortizationScheduleData;
        this.paymentChartData = rst.totalPaymentBreakupDialogData.paymentChartData;
        this.amortizationChartData = rst.totalPaymentBreakupDialogData.amortizationChartData;
        this.calculationLog = rst.totalPaymentBreakupDialogData.calculationLog;

      })).subscribe();


    this.subscriptions.push(this.consumerPricingEvents.subscribe((evt) => {
      switch(evt.type) {
        case "consumer-pricing-saved": {
          this.rst = evt.rst.totalPaymentBreakupDialogData;
          this.repaymentEstimationData = evt.rst.totalPaymentBreakupDialogData.repaymentEstimationData;
          this.amortizationChartData = evt.rst.totalPaymentBreakupDialogData.amortizationChartData;
          this.paymentChartData = evt.rst.totalPaymentBreakupDialogData.paymentChartData;
          this.amortizationChartData = evt.rst.totalPaymentBreakupDialogData.amortizationChartData;
          break;
        }
      }
    }));

    this.subscriptions.push(sub);
    this.getApplicationAsset();
    this.getActiveContract();
    this.getBankDetails();
    this.loadAssetInspections();
  }

  loadAssetInspections() {
    this.subscriptions.push(
      this.getAssetInspectionsForApplicationFn(this.application.ApplicationId).pipe(
      ).subscribe((data: VerimotoInspectionTableDataWithInspectionDetails[]) => {
        console.log('===asset inspections: ', data);
        this.assetInspectionTableData = data;
      })
    )
  }

  onViewReport(element: ApplicationWebService) {
    this.currentSection = 'credit-report';
    console.log(this.currentSection)
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: String(element.id),
    });
  }

  onLeaveReport(event: any) {
    this.currentSection = 'credit';
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  getApplicationAsset() {
    this.subscriptions.push(
      this.getApplicationAssetFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe(res => {
        if (res.length) {
          if (res[0].AssetSFRes !== null && res[0].AssetSFRes !== undefined) {
            this.isSettledLoan = true
          }
          this.assetStatus = res.filter(r => r.status === 'accepted');
          if (res.length !== this.assetStatus.length) {
            this.allAssetAccepted = true
          }
          else {
            this.allAssetAccepted = false
          }
        } else {
          this.isSettledLoan = false;
        }
      })
    )
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  getEvent() {
    this.currentSection = "asset"
    this.editAssetValue = null;
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  getAssetSave() {
    this.currentSection = "asset"
    this.editAssetValue = null;
    this.isAssetEmpty = false;
    this.selectionEvent.emit({
      selection: this.currentSection,
      innerTabParam: undefined,
    });
  }

  onEditAssetEvent($event: PpsrAssetValue) {
    this.editAssetValue = $event;
    this.currentSection = 'add-asset'
  }

  onRequestDoc() {
    let salesforceId = getAppSalesforceId(this.application)
    let data: any = []
    data = { "Data": { "StageName": "Documentation" }, "Object": "Opportunity", "ID": `${salesforceId}` }
    this.requestDocFn(data).pipe(
      this.toastService.spinnerObservable(),
      tap((r: PayloadApiResponse<any>) => {
        if( r && r.status ){
          this.dialogService.openAlertDialog({
            message: `Success`,
            subMessage: r.payload.res
          });
        }
      })
    ).subscribe();
  }

  onPPSRCheckAll(element: any) {
    let ppsrAsset: PpsrAsset[] = [];
    let ppsrError = false;

    this.applicationDialogService.openPPSRCheckDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.getApplicationAssetFn(this.application.ApplicationId)
              .pipe(
                switchMap((res: PpsrAsset[]) => {
                  const subs = [];
                  for (let i = 0; i < res.length; i++) {
                    this.ppsrChecked = false;
                    if (res[i].SettlementAssetDetails?.serialNumber !== null && res[i].SettlementAssetDetails?.serialNumberType !== null && (res[i].PPSRStatus === 'unchecked' || res[i].PPSRStatus === 'issue')) {
                      ppsrAsset.push(res[i]);
                      subs.push(
                        this.assetSettlementSearchAssetsFn(this.application.ApplicationId, {
                          SearchNumber: res[i].SettlementAssetDetails?.serialNumber,
                          NumberType: (res[i].SettlementAssetDetails?.serialNumberType as string).replace(/ +/g, ''),
                        })
                      )
                    }
                  }
                  if (!subs.length) {
                    return of([]);
                  }
                  return combineLatest(subs);
                }),
                this.toastService.spinnerObservable(),
                tap((results: (VehicleDetails | { Error: any } | null)[]) => {
                  if (!results || !results?.length) {
                    this.dialogService.openAlertDialog({
                      message: 'Warn',
                      subMessage: 'PPSR check is already done',
                    });
                  } else {
                    for (let i = 0; i < results.length; i++) {
                      const data = results[i];
                      if (data && (data as { Error: any })?.Error) {
                        let asset: any = {};
                        asset.AssetId = ppsrAsset[i].AssetId;
                        asset.PPSRStatus = 'issue';
                        this.updateAssetsToDatabase(asset, 'save')
                        ppsrError = true;
                      } else if (data && !(data as { Error: any })?.Error) {
                        let asset: any = {};
                        asset.AssetId = ppsrAsset[i].AssetId;
                        asset.SettlementAssetDetails = ({
                          assetCategory: ppsrAsset[i].SettlementAssetDetails?.assetCategory,
                          assetType: ppsrAsset[i].SettlementAssetDetails?.assetType,
                          description: ppsrAsset[i].SettlementAssetDetails?.description,
                          serialNumberType: ppsrAsset[i].SettlementAssetDetails?.serialNumberType,
                          serialNumber: ppsrAsset[i].SettlementAssetDetails?.serialNumber,
                          InvoicePrice: ppsrAsset[i].SettlementAssetDetails?.InvoicePrice,
                          GST: ppsrAsset[i].SettlementAssetDetails?.GST,
                          year: ppsrAsset[i].SettlementAssetDetails?.year,
                          supplier: ppsrAsset[i].SettlementAssetDetails?.supplier,
                          VehicleDetails: data,
                        })
                        asset.PPSRStatus = 'confirmed';
                        this.updateAssetsToDatabase(asset, 'save')
                      }
                    }

                    if (ppsrError) {
                      this.dialogService.openAlertDialog({
                        message: 'Error',
                        subMessage: 'Please try after sometime',
                      });
                    } else {
                      this.dialogService.successDialog({
                        message: 'Success',
                        subMessage: 'PPSR check completed',
                      });
                    }
                  }
                })
              ).subscribe(() => { this.ppsrChecked = true; }) // refresh the table
          }
        })
      ).subscribe();
  }

  async updateAssetsToDatabase(assetUpdateData: PpsrAsset, key: string) {
    this.updateApplicationAssetsFn(assetUpdateData).pipe(
      this.toastService.spinnerObservable(),
      this.toastService.snackBarObservable('Asset Updated'),
      tap(r => {
        console.log("success")
      }
      )).subscribe();
  }

  getBankDetails() {
    this.subscriptions.push(
      combineLatest([
        this.getAccountDetailsFromSfFn(this.application.BrokerSalesforceId),
        this.getAccountDetailsFromSfFn(this.application.AppInfo.CustomerId ?? ""),
      ]).pipe(
        switchMap(([brokerBankDetails, customerBankDetails]: [LocalBankAccountDetailValue, LocalBankAccountDetailValue]) => {
          console.log('brokerBankDetails, customerBankDetails: ', brokerBankDetails, customerBankDetails)
          this.missingBankDetails = null;
          if (!brokerBankDetails?.AccountNumber || !brokerBankDetails?.BSB || !brokerBankDetails.AccountName) {
            this.missingBankDetails = 'broker';
            return of(null);
          } else if (!customerBankDetails?.AccountNumber || !customerBankDetails.BSB || !customerBankDetails.AccountName) {
            return this.getBankDetailsFromOpportunitySfFn(this.application.AppInfoSalesforceID ?? "");
          } else {
            return of(customerBankDetails);
          }
        })
      ).subscribe((result: LocalBankAccountDetailValue) => {
        if ((!result?.AccountNumber || !result?.BSB || !result?.AccountName) && this.missingBankDetails !== 'broker') {
          this.missingBankDetails = 'customer';
        }
        console.log('customer bank details on oppo level: ', result);
      })
    )
  }

  applicationDocumentTypes(application: Application) {
    const tags = application?.DocumentTypes?.length ? application?.DocumentTypes.map(t => {
      return {
        ...t,
        value: t.value.toLowerCase()
      }
    }) : [];
    const documentTypes: DocumentTag[] = [];
    for (const tag of tags) {
      if (!documentTypes.map(t => t.value).includes(tag.value)) {
        documentTypes.push(tag)
      }
    }
    return documentTypes;
  }

  onPPSRRegister() {
    this.applicationDialogService.openPPSRRegisterDialog()
      .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            let asset: any = {}
            this.getApplicationAssetFn(this.application.ApplicationId).pipe(
              this.toastService.spinnerObservable(),
            ).subscribe(res => {
              console.log("res---------------", res)
              let r: any = res;
              for (let i = 0; i < res.length; i++) {
                this.ppsrRegistered = false;
                if (r[i].PPSRStatus === 'confirmed') {
                  asset.AssetId = r[i].AssetId;
                  asset.PPSRStatus = 'registered';
                  this.registered = true;
                  // this.ppsrRegistered = true;
                  this.updateAssetsToDatabase(asset, 'save')
                }
              }
              this.dialogService.openAlertDialog({
                message: 'Success',
                subMessage: 'PPSR Registered',
              });
            });
          }
        })
      ).subscribe(() =>{ this.ppsrRegistered = true;});
  }

  onSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.applicationDialogService.openSettleLoanDialog(true)
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            this.doSendAssetAndDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      this.applicationDialogService.openSettleLoanDialog(false).afterClosed().pipe(
        tap(async (r) => {
          if (r) {
            // check documents first
            this.subscriptions.push(
              combineLatest([
                this.listApplicationDocumentFn(this.application.ApplicationId),
                this.getApplicationByIdFn(this.application.ApplicationId)
              ]).pipe(
                this.toastService.spinnerObservable()
              ).subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
                if (r) {
                  let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
                    app?.DocumentTypes?.length ? app?.DocumentTypes
                    : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
                    '',
                  );
                  docs = this.groupStandardCondition(docs, this.application);
                  docs = this.displayRequiredDocumentsOnly(docs);
                  const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
                  const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
                  const standardConditionDocs = docs.filter(d => d?.standardCondition);
                  const pendingGroups = [
                    ...standardDocs.filter(group => group.status === 'Pending'),
                    ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
                    ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
                  ];
                  console.log(pendingGroups);
                  if (pendingGroups.length) {
                    // do not allow settle loan
                    this.applicationDialogService.openAlertDialog({
                      message: 'Error',
                      subMessage: 'You cannot settle loan as you still have pending documents',
                    }).afterClosed().subscribe();
                  } else {
                    this.doUpdateApplicationStage();
                  }
                }
              })
            )
          }
        })
      ).subscribe()
    }
  }

  doUpdateApplicationStage() {
    this.subscriptions.push(
      this.updateApplicationStageFn({
        salesforceId: this.application.AppInfoSalesforceID ?? "",
        stageName: 'QA'
      }).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response.status) {
          this.applicationDialogService.successDialog({
            message: 'Success',
            subMessage: "Application sent to QA queue",
          }).afterClosed().subscribe();
        } else {
          this.applicationDialogService.openAlertDialog({
            message: 'Error',
            subMessage: response.message,
          }).afterClosed().subscribe();
        }
      })
    )
  }

  doSendAssetAndDisbursementToSf() {
    this.subscriptions.push(
      combineLatest([
        this.getApplicationAssetFn(this.application.ApplicationId),
        this.getDisbursementByApplicationIdFn(this.application.ApplicationId)
      ]).pipe(
        switchMap(([assets, disbursements]: [PpsrAsset[], Disbursement[]]) => {
          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }

          return this.settleLoanFn({
            assets: assets,
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }
        // reload asset table
        this.getApplicationAsset();
      })
    )
  }

  // doSendAssetToSalesforce() {
  //   this.subscriptions.push(
  //     this.getApplicationAssetFn(this.application.ApplicationId).pipe(
  //       this.toastService.spinnerObservable(),
  //       this.toastService.snackBarObservable('Asset Sent to Salesforce'),
  //       switchMap((assets: PpsrAsset[]) => {
  //         const allSfData: SendAssetToSfRecord[] = [];
  //         for (const asset of assets) {
  //           let sfData: SendAssetToSfRecord = {
  //             attributes: {
  //               type: "Asset",
  //               referenceId: String(asset?.AssetId ?? ""),
  //             },
  //             Name: (asset.SettlementAssetDetails?.description ?? "").substring(0, 255),
  //             Asset_Category__c: asset.SettlementAssetDetails?.assetCategory ?? "",
  //             Asset_Class__c: `${asset.SettlementAssetDetails?.assetCategory ?? ""}-${asset.SettlementAssetDetails?.assetType ?? ""}`,
  //             Opportunity__c: this.application.AppInfo.SalesforceId ?? "",
  //             Description: asset.SettlementAssetDetails?.description ?? "",
  //             Invoice_Price_incl_GST__c: asset.SettlementAssetDetails?.InvoicePrice ?? 0,
  //             GST__c: asset.SettlementAssetDetails?.GST ?? 0,
  //             SerialNumber: asset.SettlementAssetDetails?.serialNumber ?? "",
  //             Invoice_Number__c: asset.invoiceNumber ?? "",
  //             Manufacture_Year__c: String(asset.SettlementAssetDetails?.year ?? "").slice(0, 4),
  //             Supplier__c: "",
  //           }
  //           // supplier
  //           if (asset.SettlementAssetDetails?.supplier) {
  //             sfData.Supplier__c = (asset.SettlementAssetDetails?.supplier as any)?.SalesForceId
  //           } else { // private seller
  //           }
  //
  //           // vehicle details
  //           if (asset.SettlementAssetDetails?.VehicleDetails) {
  //             sfData.Make__c = asset.SettlementAssetDetails?.VehicleDetails?.Make ?? "";
  //             sfData.Model__c = asset.SettlementAssetDetails?.VehicleDetails?.Model ?? "";
  //           }
  //
  //           allSfData.push(sfData);
  //         }
  //         console.log("salesforce data: ", allSfData);
  //         return this.sendAssetToSfFn({
  //           records: allSfData
  //         })
  //       }),
  //     ).subscribe()
  //   )
  // }

  emptyAssetFn($event: any) {
    console.log('asset-finance', $event);
    this.isAssetEmpty = $event;
  }

  assetAcceptedFn($event: any) {
    this.isSettledLoan = $event;
  }

  disableSettleLoan($event: any){
    this.isSettledLoan = $event
    this.allAssetAccepted = $event
  }

  disableOnPPSRStatus($event: any){
    this.allAssetAccepted = $event
  }

  getActiveContract() {
    this.subscriptions.push(
      this.getActiveContractForApplicationFn(this.application.ApplicationId)
        .subscribe((r: ContractDetails | null) => {
          this.activeContract = r;
        })
    )
  }

  openGenerateContractDialog() {
    const ok = checkContractIndividuals(this.application, this.dialogService);
    if (ok) {
      this.subscriptions.push(
        this.dialogService.openGenerateContractDialog({
          sendContractEmailsFn: this.sendContractEmailsFn,
          getRatecardDetailsFn: this.getRatecardDetailsFn,
          getApplicationIndividualsFn: this.getApplicationIndividualsFn,
          generateContractFn: this.generateContractFn,
          getApplicationAssetFn: this.getApplicationAssetFn,
          deleteContractFileFn: this.deleteContractFileFn,
          syncContractDetailsToSfFn: this.syncContractDetailsToSfFn,
          getApplicationOwnerFn: this.getApplicationOwnerFn,
          getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
          updateApplicationFn: this.updateApplicationFn,
          getOriginatorBusinessByIdFn: this.getOriginatorBusinessByIdFn,
          application: this.application,
          apiUrl: this.apiUrl,
        }).afterClosed().subscribe(() => {
          this.getActiveContract();
        })
      )
    }
  }

  openContractStatusDialog() {
    this.subscriptions.push(
      this.applicationDialogService.openContractStatusDialog({
        getContractStatusFn: this.getContractStatusFn,
        applicationId: this.application.ApplicationId,
        declineContractFn: this.declineContractFn,
        envelopeId: this.activeContract?.envelopeId ?? ""
      }).afterClosed().subscribe(() => {
        this.getActiveContract();
      })
    )
  }

  private displayRequiredDocumentsOnly(docs: GroupedDocument[]) {
    return docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })
  }

  onAssetPricingEvent($event: ConsumerPricingComponentEvent) {
    this.consumerPricingEvents.emit($event)
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }

  refreshCreditTab(event: Application) {
    this.refreshCreditTabEvent.emit(event);
  }
}
