import {
  NewArrearsReportRecord,
  PismoCardReIssueReason,
  PismoDelinquencyBucket,
  PismoGenericTransaction,
  PismoPendingTransaction,
  PismoPendingTransactionResponse,
  PismoTransaction,
  PismoTransactionCategory,
  PismoTransactionProcessingCode, PismoTransactionsIncludingPending,
  PismoTransactionTypeIds
} from "@portal-workspace/grow-shared-library";
import numeral from "numeral";
import getSymbolFromCurrency from 'currency-symbol-map'

  export const getIsPendingTransaction = (transaction: PismoGenericTransaction): transaction is PismoPendingTransaction => {
    return !!(transaction as PismoPendingTransaction).correlation_id
  }

  export const isPismoTransaction = (transaction: Extract<PismoGenericTransaction, PismoTransaction | PismoPendingTransaction>): transaction is PismoTransaction => {
    return !!!(transaction as any).correlation_id;
  }

  export const getMccCode = (transaction: PismoGenericTransaction): string => {
    return (transaction as PismoTransaction)?.created_at
      ? ((transaction as PismoTransaction)?.merchant?.category?.code ?? '')
      : (transaction as PismoPendingTransaction)?.data?.item?.merchant_category_code ?? '';
  }

  export const getSoftDescriptor = (transaction: PismoGenericTransaction): string => {
    return (transaction as PismoTransaction).created_at
      ? (transaction as PismoTransaction).merchant?.name ? (transaction as PismoTransaction).transaction_type.id === 1000001 ? `${(transaction as PismoTransaction)?.merchant?.name ?? ''} (${(transaction as PismoTransaction).transaction_type.description})` : ((transaction as PismoTransaction)?.merchant?.name ?? '') : (transaction as PismoTransaction).soft_descriptor ?? (transaction as any).descriptor
      : (transaction as PismoPendingTransaction).data.item.soft_descriptor ?? (transaction as any).descriptor;
  }

  export const getCreatedDate = (transaction: PismoGenericTransaction): string => {
    return (transaction as PismoTransaction).created_at
      ? (transaction as PismoTransaction).created_at
      : (transaction as PismoPendingTransaction).timestamp;
  }

  export const getIsCredit = (transaction: PismoGenericTransaction): boolean => {
    return (transaction as PismoTransaction).created_at
      ? (transaction as PismoTransaction).transaction_type?.credit ?? false
      : (transaction as PismoPendingTransaction).isCredit ?? false;
  }

  export const getAmount = (transaction: PismoGenericTransaction): number => {
    return (transaction as PismoTransaction).created_at
      ? (transaction as PismoTransaction).amount[0].value
      : (transaction as PismoPendingTransaction).data.item.principal_amount;
  }

  export const getCardId = (transaction: PismoGenericTransaction): number => {
    return (transaction as PismoTransaction).created_at
      ? (transaction as PismoTransaction).card?.id
      : (transaction as PismoPendingTransaction).data.item.card_id;
  }

  export const getCustomerId = (transaction: PismoGenericTransaction): string => {
    return (transaction as PismoTransaction).created_at
      ? (transaction as PismoTransaction).customer_id
      : (transaction as PismoPendingTransaction).data.item.customer_id.toString();
  }

  export const getPismoTransactionType = (
    transaction: PismoGenericTransaction
  ): PismoTransactionTypeIds => {
    let processingCode = ''
    if ((transaction as PismoTransaction).created_at) {
      processingCode = (transaction as PismoTransaction).processing_code
    } else {
      processingCode = (transaction as PismoPendingTransaction).data.item.org_operation.processing_code
    }
    return processingCode == PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER
      ? 'BankTransfer'
      : processingCode == PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL
        ? 'BankTransferReversal'
        : processingCode == PismoTransactionProcessingCode.BPAY
          ? 'Bpay'
          : processingCode == PismoTransactionProcessingCode.BPAY_REVERSAL
            ? 'BpayReversal'
            : (processingCode == PismoTransactionProcessingCode.DOCUMENTATION_FEE)
              ? 'DocumentationFee'
              : processingCode == PismoTransactionProcessingCode.PAYMENT
                ? 'Payment'
                : processingCode == PismoTransactionProcessingCode.ANNUAL_FEE
                  ? 'CardAnnualFee'
                  : processingCode == PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE
                    ? 'DishonourFee'
                    : processingCode == PismoTransactionProcessingCode.CARD_REISSUE_FEE
                      ? 'CardReissueFee'
                      : processingCode == PismoTransactionProcessingCode.FACILITY_FEE
                        ? 'MonthlyFacilityFee'
                        : processingCode == PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE
                          ? 'FacilityEstablishmentFee'
                          : processingCode == PismoTransactionProcessingCode.MERCHANT || processingCode == PismoTransactionProcessingCode.MERCHANT_REVERSAL
                            ? 'Merchant'
                            : processingCode == PismoTransactionProcessingCode.PAYMENT_REVERSAL
                              ? 'PaymentReversal'
                              : processingCode == PismoTransactionProcessingCode.PURCHASE
                                ? 'Purchase'
                                : processingCode == PismoTransactionProcessingCode.PURCHASE_REVERSAL
                                  ? 'PurchaseReversal'
                                  : 'other';
  };

  export const getTransactionCategory = (transaction: PismoGenericTransaction): PismoTransactionCategory => {
    let processingCode: PismoTransactionProcessingCode;
    if ((transaction as PismoTransaction).created_at) {
      processingCode = (transaction as PismoTransaction).processing_code
      if ((transaction as PismoTransaction).merchant?.category) {
        return 'Merchant'
      }
    } else {
      processingCode = (transaction as PismoPendingTransaction).data.item.org_operation.processing_code
      if ((transaction as PismoPendingTransaction).data?.item?.merchant_category_code) {
        return 'Merchant'
      }
    }
    const mapping: { [key in PismoTransactionProcessingCode]: PismoTransactionCategory } = {
      [PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER]: 'Transfer',
      [PismoTransactionProcessingCode.EXTERNAL_BANK_TRANSFER_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.BPAY]: 'BPay',
      [PismoTransactionProcessingCode.BPAY_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.DOCUMENTATION_FEE]: 'Fees',
      [PismoTransactionProcessingCode.DOCUMENTATION_FEE_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.FACILITY_FEE]: 'Fees',
      [PismoTransactionProcessingCode.FACILITY_FEE_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE]: 'Fees',
      [PismoTransactionProcessingCode.FACILITY_ESTABLISHMENT_FEE_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.BROKERAGE]: 'Fees',
      [PismoTransactionProcessingCode.PAYMENT]: 'Payment',
      [PismoTransactionProcessingCode.PAYMENT_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.MINIMUM_PAYMENT_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.MERCHANT]: 'Merchant',
      [PismoTransactionProcessingCode.MERCHANT_REVERSAL]: 'Merchant',
      [PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE]: 'Fees',
      [PismoTransactionProcessingCode.PAYMENT_DISHONOUR_FEE_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.CARD_REISSUE_FEE]: 'Fees',
      [PismoTransactionProcessingCode.CARD_REISSUE_FEE_REVERSAL]: 'Reversals',
      // [PismoTransactionProcessingCode.PURCHASE]: 'Payment',
      [PismoTransactionProcessingCode.PURCHASE_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.BAD_DEBT_WRITE_OFF]: 'Adjustments',
      [PismoTransactionProcessingCode.BAD_DEBT_WRITE_OFF_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.INSTALLMENT_WITHOUT_INTEREST]: 'Interests',
      // [PismoTransactionProcessingCode.INSTALLMENT_WITHOUT_INTEREST_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.CREDIT_BY_INSTALLMENTS]: 'Interests',
      [PismoTransactionProcessingCode.CREDIT_BY_INSTALLMENTS_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.REFINANCING_AGREEMENT]: 'Payment',
      [PismoTransactionProcessingCode.REFINANCING_AGREEMENT_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.COMPULSORY_INSTALLMENT_CREDIT]: 'Payment',
      [PismoTransactionProcessingCode.COMPULSORY_INSTALLMENT_CREDIT_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.INTEREST_ON_REVOLVING_CREDIT]: 'Interests',
      [PismoTransactionProcessingCode.INTEREST_ON_REVOLVING_CREDIT_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CANC_INTEREST_ON_REVOLVING_CREDIT]: 'Reversals',
      [PismoTransactionProcessingCode.LATE_PAYMENT_INTEREST]: 'Interests',
      [PismoTransactionProcessingCode.CAN_LATE_PAYMENT_INTEREST]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CANC_LATE_PAYMENT_INTEREST]: 'Reversals',
      [PismoTransactionProcessingCode.LATE_FEE]: 'Fees',
      [PismoTransactionProcessingCode.CAN_LATE_FEE]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CANC_LATE_FEE]: 'Reversals',
      [PismoTransactionProcessingCode.OVERLIMIT_FEE]: 'Fees',
      [PismoTransactionProcessingCode.CAN_OVERLIMIT_FEE]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CANC_OVERLIMIT_FEE]: 'Reversals',
      [PismoTransactionProcessingCode.CREDIT_VOUCHER]: 'Voucher',
      [PismoTransactionProcessingCode.CREDIT_ADJUSTMENT]: 'Adjustments',
      [PismoTransactionProcessingCode.DEBIT_ADJUSTMENT]: 'Adjustments',
      [PismoTransactionProcessingCode.INTEREST]: 'Interests',
      [PismoTransactionProcessingCode.INTEREST_REVERSAL]: 'Reversals',
      [PismoTransactionProcessingCode.TAXES]: 'Taxes',
      [PismoTransactionProcessingCode.CAN_TAXES]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CANC_TAXES]: 'Reversals',
      [PismoTransactionProcessingCode.DEBIT_EXCHANGE_DIFFERENCE]: 'Exchange',
      [PismoTransactionProcessingCode.CAN_DEBIT_EXCHANGE_DIFFERENCE]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CANC_DEBIT_EXCHANGE_DIFFERENCE]: 'Reversals',
      [PismoTransactionProcessingCode.CREDIT_EXCHANGE_DIFFERENCE]: 'Exchange',
      [PismoTransactionProcessingCode.CAN_CREDIT_EXCHANGE_DIFFERENCE]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CAN_CREDIT_EXCHANGE_DIFFERENCE]: 'Reversals',
      [PismoTransactionProcessingCode.ANNUITY]: 'Annuity',
      [PismoTransactionProcessingCode.CAN_ANNUITY]: 'Reversals',
      [PismoTransactionProcessingCode.UNDO_CAN_ANNUITY]: 'Reversals',
      [PismoTransactionProcessingCode.TRANSFERRED_BALANCE]: "Payment",
      [PismoTransactionProcessingCode.TRANSFERRED_BALANCE_REVERSAL]: "Payment",
      [PismoTransactionProcessingCode.PAYOUT]: "Fees",
      [PismoTransactionProcessingCode.PAYOUT_REVERSAL]: "Reversals",
      [PismoTransactionProcessingCode.SECURITY_REGISTRATION_AND_LEGAL_FEE]: "Fees",
      [PismoTransactionProcessingCode.SECURITY_REGISTRATION_AND_LEGAL_FEE_REVERSAL]: "Reversals",
    };

    return mapping[processingCode] || 'Unknown';
  }

  export const getTransactionAttributes = (transactionType: PismoTransactionCategory) => {
      switch(transactionType){
        case 'Transfer':
          return 'mdi mdi-bank-outline'
        case 'Reversals':
          return 'mdi mdi-refresh'
        case 'BPay':
          return 'mdi mdi-barcode'
        case 'Fees':
          return 'mdi mdi-file-document-outline'
        case 'Payment':
          return 'mdi mdi-plus-circle-multiple'
        case 'Merchant':
          return 'mdi mdi-cart-outline'
        case 'Adjustments':
          return 'mdi mdi-swap-horizontal'
        case 'Interests':
          return 'mdi mdi-percent'
        case 'Voucher':
          return 'mdi mdi-receipt'
        case 'Taxes':
          return 'mdi mdi-chart-donut'
        case 'Exchange':
          return 'mdi mdi-repeat-variant'
        case 'Annuity':
          return 'mdi mdi-calendar-check'
        default:
          return 'mdi mdi-refresh'
      }
  };

  export const displayTransactionType = (transacionType: PismoTransactionTypeIds): string => {
    switch (transacionType) {
      case 'BankTransfer':
        return 'Bank Transfer';
      case 'BankTransferReversal':
        return 'Bank Transfer Reversal'
      case 'Bpay':
        return 'BPAY';
      case 'BpayReversal':
        return 'BPAY Reversal';
      case 'CardAnnualFee':
        return 'Card Annual Fee';
      case 'DishonourFee':
        return 'Dishonour Fee';
      case 'CardReissueFee':
        return 'Card Reissue Fee';
      case 'DocumentationFee':
        return 'Documentation Fee';
      case 'FacilityEstablishmentFee':
        return 'Facility Establishment Fee';
      case 'Payment':
        return 'Payment';
      case 'PaymentReversal':
        return 'Payment Reversal';
      case 'MonthlyFacilityFee':
        return 'Monthly Facility Fee';
      case 'Purchase':
        return 'Purchase';
      case 'PurchaseReversal':
        return 'Purchase Reversal';
      default:
        return 'Others';
    }
  }

  export const getRelativeTransactionAmount = (
    value: string | number,
    isPositive: boolean,
    currency?: string
  ) => {
    if (typeof value == 'string') {
      value = parseFloat(value);
    }
    // const value = transaction.amount[0].value;
    const formattedValue = numeral(value).format('0,0.00');
    let symbol;
    if (currency) symbol = getSymbolFromCurrency(currency)
    if (isPositive) {
      return `${currency ? currency : ''} ${symbol ?? '$'}${formattedValue}`;
    } else {
      return `-${currency ? currency : ''} ${symbol ?? '$'}${formattedValue}
      `;
    }
  };

  export const getTime = (date: string) => {
    const d = new Date(date);
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const hour = hours % 12 === 0 ? 12 : hours % 12;
    const minute = minutes < 10 ? '0' + minutes : minutes;
    return `${hour}:${minute} ${ampm}`;
  };

  // get dd Month yyyy from date string
export const getDate = (dateString: string) => {
  // console.log('get date', dateString);
  const date = new Date(dateString);
  // console.log('date', date)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const day = date.getDate();

  const dd = `${day} ${months[monthIndex]} ${year}`;
  return dd;
};

export const filterCardReissueReasons = (reasons: PismoCardReIssueReason[]): PismoCardReIssueReason[] => {
  const allowedTypes: PismoCardReIssueReason["type"][] = ["BROKEN", "CLIENT_ORDER", "LOST", "UNRECEIVED", "DAMAGED", "THEFT"];
  return reasons.filter(reason => allowedTypes.includes(reason.type));
}

export const createArrearsReportRecord = (delinquencyBuckets: PismoDelinquencyBucket[], accountId: number): NewArrearsReportRecord | null=> {
  if (delinquencyBuckets.length) {
    // sort the buckets
    delinquencyBuckets.sort((a, b) => b.statement_id - a.statement_id);

    const D0 = delinquencyBuckets[0].bucket_balance;
    let D30 = 0, D60 = 0, D90 = 0, D120 = 0, D150 = 0, D180 = 0, D210Plus = 0;
    let DueDate = '';

    if (delinquencyBuckets.length >= 2) {
      D30 = delinquencyBuckets[1].bucket_balance;
    }
    if (delinquencyBuckets.length >= 3) {
      D60 = delinquencyBuckets[2].bucket_balance;
    }
    if (delinquencyBuckets.length >= 4) {
      D90 = delinquencyBuckets[3].bucket_balance;
    }
    if (delinquencyBuckets.length >= 5) {
      D120 = delinquencyBuckets[4].bucket_balance;
    }
    if (delinquencyBuckets.length >= 6) {
      D150 = delinquencyBuckets[5].bucket_balance;
    }
    if (delinquencyBuckets.length >= 7) {
      D180 = delinquencyBuckets[6].bucket_balance;
    }
    if (delinquencyBuckets.length >= 8) {
      D210Plus = delinquencyBuckets.slice(7).reduce((partialSum, details) => partialSum + details.bucket_balance, 0);
    }

    const AmountDue = Math.round(100 * (D0 + D30 + D60 + D90 + D120 + D150 + D180 + D210Plus)) / 100;

    if (delinquencyBuckets.length === 1) {
      DueDate = delinquencyBuckets[0].due_date;
    } else if (delinquencyBuckets[delinquencyBuckets.length - 1].bucket_balance > 0) {
      DueDate = delinquencyBuckets[delinquencyBuckets.length - 1].due_date;
    } else {
      for (let i = 0; i < delinquencyBuckets.length; i++) {
        if (delinquencyBuckets[i].bucket_balance <= 0 && i >= 1) {
          DueDate = delinquencyBuckets[i - 1].due_date;
          break;
        }
      }
    }

    return {
      AmountDue,
      AccountId: accountId,
      D0,
      D30,
      D60,
      D90,
      D120,
      D150,
      D180,
      D210Plus,
      DueDate,
      IsDeleted: false,
    }

  } else {
    return null;
  }
}
