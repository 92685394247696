<div class="interest-rate-calculation-log-dialog">
  <h3 mat-dialog-title>Rate calculation</h3>
  <mat-dialog-content class="pt-2">
    <message-box type="info">
      Rate information is based on our product guide at the time of the quotation.  
      Note that our rates can change prior to a signed contract.
    </message-box>
    <table mat-table [dataSource]="data.logs">
      <ng-container matColumnDef="log">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let log">{{log.log}}</td>
      </ng-container>
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef>Rate (%)</th>
        <td mat-cell *matCellDef="let log">{{(log.rate ?? '') | percentage: '1.0-2' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let log; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="width-100 pl-lt-md-3 pr-lt-md-3"
         fxLayoutGap="10px"
         fxLayout="row"
         fxLayout.lt-md="column"
         fxLayoutAlign="space-between"
         fxLayoutAlign.lt-md="center start">
      <div></div>
      <button mat-flat-button class="ml-0 w-lt-md-100" color="primary" (click)="onClose()">Close</button>
    </div>
  </mat-dialog-actions>
</div>
