import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output} from '@angular/core';
import {Application, ApplicationCreditFlow, ApplicationWebService, CreditDecisioningReasonDialogResult, CreditOverviewSelection, CreditOfficerStatus, DownloadCreditorWatchReportFn, GetApplicationByIdFn, GetApplicationCreditFlowResultFn, GetCreditAuditLogFn, GetCreditWebServicesFn, RunCreditFlowFn, UpdateCreditStatusFn, getApplicationStage, getCompanyName, getFinanceType, getLoanAmount, GetDuplicatedApplicationsFn, DuplicatedApplication, DeleteWebServicesFn, ConfirmationDialogResult, GetApplicationOwnerFn, GetUserFn, RequestMoreInformationFn, CreditApprovedDialogResult} from '@portal-workspace/grow-shared-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { Subscription, combineLatest, switchMap } from 'rxjs';
import { ApplicationDialogService } from '../application-dialog-component/application-dialog.service';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder,FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { CreditResultAlertsComponent } from './credit-result-alerts.component';
import { CreditResultWebServicesComponent } from './credit-result-web-services.component';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { CreditAuditLogComponent } from './credit-audit-log.component';
import { DatePipe } from '@angular/common';
import { CreditFlowDetailsComponent } from './credit-flow-details.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { DuplicatedApplicationsComponent } from './duplicated-applications.component';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
    selector: 'credit-result',
    templateUrl: './credit-result.component.html',
    styleUrls: ['./credit-result.component.scss'],
    standalone: true,
    imports: [
      MatTableModule, 
      MatTabsModule, 
      NgClass, 
      ExtendedModule, 
      MatCardModule,
      MatDividerModule,
      MatFormFieldModule,
      MatOptionModule,
      MatSelectModule,
      FormsModule, 
      ReactiveFormsModule,
      MatButtonModule,
      FlexModule,
      MatInputModule,
      CreditResultAlertsComponent,
      CreditResultWebServicesComponent,
      TagBoxComponent,
      LooseCurrencyPipe,
      CreditAuditLogComponent,
      DatePipe,
      CreditFlowDetailsComponent,
      MatExpansionModule,
      DuplicatedApplicationsComponent,
      MessageBoxComponent,
    ]
})
export class CreditResultComponent implements OnInit {
  @Input({required: true}) application!: Application;
  @Input({required: true}) runCreditFlowFn!: RunCreditFlowFn;
  @Input({required: true}) getCreditWebServicesFn!: GetCreditWebServicesFn;
  @Input({required: true}) getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  @Input({required: true}) downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  @Input({required: true}) updateCreditStatusFn!: UpdateCreditStatusFn;
  @Input({required: true}) getCreditAuditLogFn!: GetCreditAuditLogFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  @Input({required: true}) deleteWebServicesFn!: DeleteWebServicesFn;
  @Input({required: true}) requestMoreInformationFn!: RequestMoreInformationFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) apiUrl!: string;
  @Output() viewReport: EventEmitter<ApplicationWebService> = new EventEmitter();
  @Output() clickApplicationEvent = new EventEmitter<DuplicatedApplication>;
  @Output() refreshCreditTabEvent = new EventEmitter<Application>;

  formControlReasonSelection!: FormControl<string | null>;
  formControlReason!: FormControl<string | null>;
  subscriptions: Subscription[] = [];
  webServices: ApplicationWebService[] = [];
  creditFlowResult: ApplicationCreditFlow[] = [];
  duplicatedApplications: DuplicatedApplication[] = [];
  getApplicationStage = getApplicationStage;
  getFinanceType = getFinanceType;
  getLoanAmount = getLoanAmount;
  getCompanyName = getCompanyName;

  constructor(
    private applicationDialogService: ApplicationDialogService,
    private toastService: PortalHotToastService,
    private formBuilder: FormBuilder,
  ) {
    this.formControlReasonSelection = formBuilder.control(null);
    this.formControlReason = formBuilder.control(null);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.getCreditWebServicesFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(webServices => {
        this.webServices = webServices;
        console.log('webServices: ', webServices);
      })
    )

    this.subscriptions.push(
      this.getApplicationCreditFlowResultFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(creditFlowResult => {
        this.creditFlowResult = creditFlowResult;
        console.log('creditFlowResult: ', creditFlowResult);
      })
    )

    this.subscriptions.push(
      this.getDuplicatedApplicationsFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(duplicatedApplications => {
        this.duplicatedApplications = duplicatedApplications;
        console.log('duplicatedApplications: ', duplicatedApplications);
      })
    )
  }

  reloadApplication() {
    this.subscriptions.push(
      this.getApplicationByIdFn(this.application.ApplicationId).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(application => {
        this.application = application;
      })
    )
  }

  reload() {
    this.subscriptions.push(
      combineLatest([
        this.getApplicationByIdFn(this.application.ApplicationId),
        this.getCreditWebServicesFn(this.application.ApplicationId),
        this.getApplicationCreditFlowResultFn(this.application.ApplicationId)
      ]).pipe(
        this.toastService.spinnerObservable()
      ).subscribe(([application, webServices, creditFlowResult]) => {
        this.application = application;
        this.webServices = webServices;
        console.log('webServices: ', webServices);
        this.creditFlowResult = creditFlowResult;
        console.log('creditFlowResult: ', creditFlowResult);
      })
    )
  }

  runCreditFlow() {
    if (this.webServices.length) {
      this.subscriptions.push(
        this.applicationDialogService.openSelectWebServicesDialog({
          webServices: this.webServices,
          application: this.application
        }).afterClosed().subscribe(dialogResult => {
          if (dialogResult && dialogResult?.webServiceIds) {
            this.deleteWebServicesFn(dialogResult.webServiceIds).pipe(
              switchMap(response => this.runCreditFlowFn(this.application.ApplicationId, false)),
              this.toastService.spinnerObservable()
            ).subscribe(result => {
              if (result.status) {
                this.applicationDialogService.successDialog({
                  message: 'Success',
                  subMessage: result.message
                }).afterClosed().subscribe(() => {
                  this.reload();
                })
              } else {
                this.applicationDialogService.openAlertDialog({
                  message: 'Alert',
                  subMessage: result.message
                }).afterClosed().subscribe()
              }
            })
          }
        })
      )
    } else {
      this.subscriptions.push(
        this.runCreditFlowFn(this.application.ApplicationId, false).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(result => {
          if (result.status) {
            this.applicationDialogService.successDialog({
              message: 'Success',
              subMessage: result.message
            }).afterClosed().subscribe(() => {
              this.reload();
            })
          } else {
            this.applicationDialogService.openAlertDialog({
              message: 'Alert',
              subMessage: result.message
            }).afterClosed().subscribe()
          }
        })
      )
    }
  }

  resumeCreditFlow() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: "Resume Credit Flow",
        subMessage: "This action will resume the credit flow from the point it failed last time. Are you sure to continue?"
      }).afterClosed().subscribe((result) => {
        if (result?.readyForSubmission) {
          this.runCreditFlowFn(this.application.ApplicationId, true).pipe(
            this.toastService.spinnerObservable()
          ).subscribe(result => {
            if (result.status) {
              this.applicationDialogService.successDialog({
                message: 'Success',
                subMessage: result.message
              }).afterClosed().subscribe(() => {
                this.reload();
              })
            } else {
              this.applicationDialogService.openAlertDialog({
                message: 'Alert',
                subMessage: result.message
              }).afterClosed().subscribe()
            }
          })
        }
      })
    )
  }

  onCreditApprove() {
    this.subscriptions.push(
      this.applicationDialogService.openCreditApprovedDialog({
        application: this.application,
      })
      .afterClosed().subscribe((result: CreditApprovedDialogResult | undefined) => {
          if (result) {
            this.updateCreditStatusFn({
              applicationId: this.application.ApplicationId,
              status: 'Approved',
              approveDetails: result
            }).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Credit Status Updated`),
            ).subscribe(() => {
              // this.reloadApplication();
              this.refreshCreditTabEvent.emit(this.application);
            })
          }
        }
      )
    )
  }

  onDecline() {
    this.subscriptions.push(
      this.applicationDialogService.openCreditDecisioningReasonsDialog({
        status: 'Declined'
      }).afterClosed().subscribe((result: CreditDecisioningReasonDialogResult | undefined) => {
          if (result && result.readyForSubmission) {
            this.updateCreditStatusFn({
              applicationId: this.application.ApplicationId,
              status: 'Declined',
              declineDetails: {
                reasonSelection: result.reasonSelection,
                reason: result.reason
              }
            }).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Credit Status Updated`),
            ).subscribe(() => {
              // this.reloadApplication();
              this.refreshCreditTabEvent.emit(this.application);
            })
          }
        }
      )
    )
  }

  moreInformation() {
    this.subscriptions.push(
      this.applicationDialogService.openMoreInformationDialog({
        application: this.application,
        getApplicationOwnerFn: this.getApplicationOwnerFn,
        getUserFn: this.getUserFn,
      }).afterClosed().subscribe(result => {
        if (result && result.readyForSubmission) {
          console.log('====result: ', result)
          this.requestMoreInformationFn({
            additionalInformation: result.additionalInformation,
            emailList: result.emailList,
            applicationId: this.application.ApplicationId
          }).pipe(
            this.toastService.spinnerObservable(),
            this.toastService.snackBarObservable(`Request More Information Success`),
          ).subscribe(() => {
            // this.reloadApplication();
            this.refreshCreditTabEvent.emit(this.application);
          })
        }
      })
    )
  }

  requestForBankStatements() {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        subMessage: "Are you sure that you want Bank Statements for this application?",
        message: 'Request Bank Statements'
      }).afterClosed().subscribe((result: ConfirmationDialogResult | undefined) => {
          if (result && result.readyForSubmission) {
            this.updateCreditStatusFn({
              applicationId: this.application.ApplicationId,
              status: "Request Bank Statements",
            }).pipe(
              this.toastService.spinnerObservable(),
              this.toastService.snackBarObservable(`Credit Status Updated`),
            ).subscribe(() => {
              // this.reloadApplication();
              this.refreshCreditTabEvent.emit(this.application);
            })
          }
        }
      )
    )
  }

  onViewReport(element: ApplicationWebService) {
    this.viewReport.emit(element)
  }

  onClick(event: DuplicatedApplication) {
    this.clickApplicationEvent.emit(event);
  }
}
