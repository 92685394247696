import {AfterViewChecked, ChangeDetectorRef, Component,  OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApplicationService} from '../../service/application.service';
import {
  Application,
  ApplicationSelectionType,
  AssetSettlementGetApplicationAssetFn,
  CreateApplicationNoteFn,
  DeclineContractFn,
  DeleteContractFileFn,
  DigitalIdGetApplicationIndividualsFn,
  GenerateContractFn,
  GetActiveContractForApplicationFn,
  GetContractStatusFn,
  SendContractEmailsFn,
  GetRateCardDetailsFn,
  CreateNewDisbursementFn,
  UpdateDisbursementFn,
  GetDisbursementByApplicationIdFn,
  SyncDisbursementToSfFn,
  SyncBankDetailsToSfFn,
  SyncContractDetailsToSfFn,
  RemoveApplicationNoteByNoteIdFn,
  GetBillerNameFn,
  ValidateBPAYFn,
  UpdateApplicationStageInSfFn,
  GetApplicationByIdFn,
  SettleLoanFn,
  SyncPrivateSellerBankDetailsToSfFn,
  GetInstitutionNameFn,
  SyncDepositPaidToSfFn,
  GetBankDetailsFromOpportunitySfFn,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  CopyApplicationFn,
  GetApplicationOwnerFn,
  GetGeoLocationFn,
  RefreshBankStatementFn,
  GetUserFn,
  GetAccountDetailsFromSfFn,
  ExtractTaxInvoiceFn,
  GetLvrCalculatorValueFn,
  UpdateLvrCalculatorValueFn,
  UpdateApplicationFn,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  CreateAssetInspectionFn,
  GetAssetInspectionsForApplicationFn,
  DownloadVerimotoReportFn,
  VerimotoLenderType,
  UpdateApplicationSfFn,
  CalculateAssetFinanceEstmationResult,
  GetBankStatementsAnalysisFn,
  VerimotoInspectionTypeSelection,
  DownloadDocumentFromAzureFn,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  GetBsaLenderListFn,
  GetBsaExcludedLenderListFn,
  SaveBsaCalculatorFn,
  GetBsaCalculatorFn,
  RunCreditFlowFn,
  GetBasiqCustomerMappingFn, GetBasiqStatementDataForCompanyFn, RefreshBasiqConnectionsFn, GetBankStatementAndBasiqDataStatusFn,
  GetOriginatorBusinessByIdFn,
  SendPrivacyConsentEmailFn,
  GetConsumerDscrCalculatorValueFn,
  UpdateConsumerDscrCalculatorValueFn,
  GetDscrCalculatorHistoryFn,
  ConsumerFinanceDsrServiceabliltyCalculationFn,
  AddAuditLogFn,
  GetCreditWebServicesFn,
  GetApplicationCreditFlowResultFn,
  DownloadCreditorWatchReportFn,
  UpdateCreditStatusFn,
  GetCreditAuditLogFn,
  ApplicationSelectionObject,
  GetWebServiceReportFn,
  GetDuplicatedApplicationsFn,
  DuplicatedApplication,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DeleteWebServicesFn,
  RequestMoreInformationFn,
  WithdrawApplicationFn,
} from '@portal-workspace/grow-shared-library';
import {
  ApproveApplicationDocumentFn,
  AssetSettlementAddPpsrDetailFn,
  AssetSettlementGetApplicationPpsrInfoFn,
  AssetSettlementGetPpsrDetailsFn,
  AssetSettlementGetPpsrDocumentsFn,
  AssetSettlementRemoveApplicationAssetFn,
  AssetSettlementSearchAssetsFn,
  AssetSettlementSearchGrantorsFn,
  AssetSettlementSendAssetToSfFn,
  AssetSettlementSubmitApplicationAssetsFn,
  AssetSettlementSubmitPpsrFn,
  AssetSettlementUpdateApplicationAssetsFn,
  AssetSettlementUpdatePpsrDetailFn,
  BreadcrumbComponentEvent,
  BusinessSearchFn,
  CompleteDocumentWorklistFn,
  CreateDocumentWorklistFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn,
  DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn,
  DownloadAllApplicationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn,
  GetOriginatorByIdFn,
  RequestDocFn,
  ListApplicationDocumentFn,
  SaveSupplierFn,
  SearchSupplierFn,
  SendApplicationDocumentNotificationFn,
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  BusinessNumberSearchFn,
  displayGenerateContractButton,
  UndoApplicationDocumentFn,
  AssetSelectionComponentSearchFn,
  BusinessPricingComponentEvent,
  BusinessOverdraftPricingComponentEvent,
  ConsumerPricingComponentEvent, InsuranceDocumentsComponent, CorporateLoansPricingComponentEvent, setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import {
  navigationUrlForApplications,
  navigationUrlForAssetFinance,
  navigationUrlForBusinessFinance,
  navigationUrlForCommercialFinance,
  navigationUrlForConsumerAssetFinance,
  navigationUrlForConsumerFinance,
  navigationUrlForInsurancePremium,
  navigationUrlForBusinessOverdraft,
  navigationUrlForApplication,
  navigationUrlForApplicationCreditTab,
  navigationUrlForCorporateLoan, navigationUrlForApplicationsWithQueryParams
} from '../../service/navigation-urls';
import {getUser} from '@portal-workspace/grow-ui-library';
import {UpdateApplicationDocumentFn} from '@portal-workspace/grow-ui-library';
import {GetUsersFunc, UserSelectionComponentEvent} from '@portal-workspace/grow-ui-library';
import {AuthService} from '../../service/auth.service';
import {map, tap} from 'rxjs/operators';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {environment} from '../../../environments/environment';
import {PpsrService} from '../../service/ppsr.service';
import {DigitalIdService} from '../../service/digital-id.service';
import {AdminService} from '../../service/admin.service';
import {
  GetApplicationAuditLogsFn,
  GetNotesByApplicationIdFn,
  UpdateDocumentMetadataFn
} from '@portal-workspace/grow-ui-library';
import {ApplicationAuditService} from '../../service/applicationAudit.service';
import {DocusignService} from '../../service/docusign.service';
import { CorporateLoansApplicationDetailsComponent } from '@portal-workspace/grow-ui-library';

import { InsurancePremiumApplicationDetailsComponent } from '@portal-workspace/grow-ui-library';
import { ConsumerFinanceApplicationDetailsComponent } from '@portal-workspace/grow-ui-library';
import { BusinessOverdraftApplicationDetailsComponent } from '@portal-workspace/grow-ui-library';
import { BusinessFinanceApplicationDetailsComponent } from '@portal-workspace/grow-ui-library';
import { AssetFinanceApplicationDetailsComponent } from '@portal-workspace/grow-ui-library';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';

import {RedbookService} from "../../service/redbook.service";
import { AssetPricingComponentEvent } from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import { BasiqService } from '../../service/basiq.service';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './application.page.html',
    styleUrls: ['./application.page.scss'],
    standalone: true,
    imports: [
    MessageBoxComponent,
    AssetFinanceApplicationDetailsComponent,
    BusinessFinanceApplicationDetailsComponent,
    BusinessOverdraftApplicationDetailsComponent,
    ConsumerFinanceApplicationDetailsComponent,
    InsurancePremiumApplicationDetailsComponent,
    CorporateLoansApplicationDetailsComponent,
    InsuranceDocumentsComponent
]
})
export class ApplicationPage implements OnInit, AfterViewChecked {

  subscriptions: Subscription[] = [];

  apiUrl = `${environment.api2Host}/api2`;
  bankStatementsUrl = `${environment.bankStatements}`;
  idVerifyUrl = `${environment.idVerify}`;
  verimotoLender: VerimotoLenderType = environment.verimoto.lender;
  navigationUrlForApplication = navigationUrlForApplication;

  getClientFn!: DigitalIdGetClientIdFn;
  authenticateFn!: DigitalIdAuthenticateFn;

  getApplicationPpsrInfoFn!: AssetSettlementGetApplicationPpsrInfoFn;
  removeApplicationAssetFn!: AssetSettlementRemoveApplicationAssetFn;
  searchAssetFn!: AssetSettlementSearchAssetsFn;
  submitPpsrFn!: AssetSettlementSubmitPpsrFn;
  getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  sendAssetToSfFn!: AssetSettlementSendAssetToSfFn;
  getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;
  addPpsrDetailFn!: AssetSettlementAddPpsrDetailFn;
  updatePpsrDetailFn!: AssetSettlementUpdatePpsrDetailFn;
  updateApplicationAssetsFn!: AssetSettlementUpdateApplicationAssetsFn;
  submitApplicationAssetsFn!: AssetSettlementSubmitApplicationAssetsFn;
  searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  getPpsrDocumentsFn!: AssetSettlementGetPpsrDocumentsFn;
  // savePpsrDocumentFn!: AssetSettlementSavePpsrDocumentFn;
  listApplicationDocumentFn!: ListApplicationDocumentFn;
  downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  createDocumentWorklistFn!: CreateDocumentWorklistFn;
  completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  getLvrCalculatorValueFn!: GetLvrCalculatorValueFn;
  updateLvrCalculatorValueFn!: UpdateLvrCalculatorValueFn;
  getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  getConsumerDscrCalculatorValueFn!: GetConsumerDscrCalculatorValueFn;
  updateConsumerDscrCalculatorValueFn!: UpdateConsumerDscrCalculatorValueFn;
  consumerFinanceDscrServiceablilityCalculationFn!: ConsumerFinanceDsrServiceabliltyCalculationFn;

  getOriginatorByIdFn!: GetOriginatorByIdFn;
  addIndividualFn!: DigitalIdAddIndividualFn;
  verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  updateIndividualFn!: DigitalIdUpdateIndividualFn;
  updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;

  getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  createApplicationNoteFn!: CreateApplicationNoteFn;
  removeApplicationNoteByNoteIdFn !: RemoveApplicationNoteByNoteIdFn;
  searchSupplierFn!: SearchSupplierFn;
  saveSupplierFn!: SaveSupplierFn;
  businessSearchFn!: BusinessSearchFn;
  onBreadcrumbEventFn = this.onBreadcrumbEvent.bind(this);
  uploadApplicationDocumentFn: UpdateApplicationDocumentFn;
  getRatecardDetailsFn: GetRateCardDetailsFn;
  getUsersInCompanyFn: GetUsersFunc;
  getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  requestDocFn!: RequestDocFn;
  assetSettlementSearchAssetsFn!: AssetSettlementSearchAssetsFn;
  sendContractEmailsFn!: SendContractEmailsFn;
  updateDocumentMetadataFn!: UpdateDocumentMetadataFn;
  getContractStatusFn!: GetContractStatusFn;
  generateContractFn!: GenerateContractFn;
  deleteContractFileFn!: DeleteContractFileFn;
  getActiveContractForApplicationFn!: GetActiveContractForApplicationFn;
  declineContractFn!: DeclineContractFn;
  createNewDisbursementFn!: CreateNewDisbursementFn;
  updateDisbursementFn!: UpdateDisbursementFn;
  getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  syncDepositPaidToSfFn!: SyncDepositPaidToSfFn;
  syncPrivateSellerBankDetailsToSfFn!: SyncPrivateSellerBankDetailsToSfFn;
  syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  businessNumberSearchFn!: BusinessNumberSearchFn
  getBillerNameFn!: GetBillerNameFn;
  validateBpayFn!: ValidateBPAYFn;
  updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  getApplicationByIdFn!: GetApplicationByIdFn;
  getInstitutionNameFn!: GetInstitutionNameFn;
  getBankDetailsFromOpportunitySfFn!: GetBankDetailsFromOpportunitySfFn;
  settleLoanFn!: SettleLoanFn;
  generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  copyApplicationFn!: CopyApplicationFn;
  getApplicationOwnerFn!: GetApplicationOwnerFn;
  getGeoLocationFn!: GetGeoLocationFn;
  refreshBankStatementFn!: RefreshBankStatementFn;
  getUserFn!: GetUserFn;
  getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  extractTaxInvoiceFn!: ExtractTaxInvoiceFn;
  updateApplicationFn!: UpdateApplicationFn;
  updateApplicationSfFn!: UpdateApplicationSfFn;
  createAssetInspectionFn!: CreateAssetInspectionFn;
  getAssetInspectionsForApplicationFn!: GetAssetInspectionsForApplicationFn;
  downloadVerimotoReportFn!: DownloadVerimotoReportFn;
  assetSearchFn!: AssetSelectionComponentSearchFn;
  downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  bypassFaceCompareFn!: BypassFaceCompareFn;
  deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  getBsaLenderListFn!: GetBsaLenderListFn;
  getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  getBsaCalculatorFn!: GetBsaCalculatorFn;
  runCreditFlowFn!: RunCreditFlowFn;
  getCreditWebServicesFn!: GetCreditWebServicesFn;
  getApplicationCreditFlowResultFn!: GetApplicationCreditFlowResultFn;
  downloadCreditorWatchReportFn!: DownloadCreditorWatchReportFn;
  updateCreditStatusFn!: UpdateCreditStatusFn;
  getCreditAuditLogFn!: GetCreditAuditLogFn;
  getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  addAuditLogFn!: AddAuditLogFn;
  getWebServiceReportFn!: GetWebServiceReportFn;
  getDuplicatedApplicationsFn!: GetDuplicatedApplicationsFn;
  deleteWebServicesFn!: DeleteWebServicesFn;
  requestMoreInformationFn!: RequestMoreInformationFn;
  withdrawApplicationFn!: WithdrawApplicationFn;

  application!: Application;
  ip: string = '';
  initialApplicationSelectionType: ApplicationSelectionType = 'app';
  allowContractGeneration: boolean = false;
  loggedInUser  = getUser();
  verimotoAssetInspectionTypes: VerimotoInspectionTypeSelection[] = environment.verimoto.verimotoInspectionTypes;

  queryParams: Params = {};

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private ppsrService: PpsrService,
              private portalHotToastService: PortalHotToastService,
              private applicationService: ApplicationService,
              private digitalIdService: DigitalIdService,
              private changeDetectorRef: ChangeDetectorRef,
              private auditService: ApplicationAuditService,
              private docusignService: DocusignService,
              private redbookService: RedbookService,
    private basiqService: BasiqService,
              private adminService: AdminService) {

    this.getClientFn = this.digitalIdService.clientIdFn;
    this.authenticateFn = this.digitalIdService.authenticateFn;
    this.assetSearchFn = this.redbookService.assetSearchFn;
    this.getApplicationPpsrInfoFn = this.ppsrService.getApplicationPpsrInfoFn;
    this.removeApplicationAssetFn = this.ppsrService.removeApplicationAssetFn;
    this.searchAssetFn = this.ppsrService.searchAssetsFn;
    this.submitPpsrFn = this.ppsrService.submitPpsrFn;
    this.getApplicationAssetFn = this.ppsrService.getApplicationAssetFn;
    this.sendAssetToSfFn = this.ppsrService.sendAssetToSfFn;
    this.getPpsrDetailsFn = this.ppsrService.getPpsrDetailsFn;
    this.addPpsrDetailFn = this.ppsrService.addPpsrDetailFn;
    this.updatePpsrDetailFn = this.ppsrService.updatePpsrDetailFn;
    this.updateApplicationAssetsFn = this.ppsrService.updateApplicationAssetsFn;
    this.submitApplicationAssetsFn = this.ppsrService.submitApplicationAssetsFn;
    this.searchGrantorsFn = this.ppsrService.searchGrantorsFn;
    this.getPpsrDocumentsFn = this.ppsrService.getPpsrDocumentsFn;
    // this.savePpsrDocumentFn = this.ppsrService.savePPSRDocumentFn;

    this.getBankStatementsAnalysisFn = this.applicationService.getBankStatementsAnalysisFn;
    this.getBankStatementAndBasiqDataStatusFn = this.applicationService.getBankStatementAndBasiqDataStatusFn;
    this.getBasiqCustomerMappingByAbnFn = this.applicationService.getBasiqCustomerMappingByAbnFn;
    this.getBasiqStatementDataForCompanyFn = this.applicationService.getBasiqStatementDataForCompanyFn;
    this.refreshBasiqConnectionsFn = this.basiqService.refreshBasiqConnectionsFn;
    this.uploadApplicationDocumentFn = this.applicationService.uploadApplicationDocumentToAzureStorageFn;
    this.getRatecardDetailsFn = this.applicationService.getRatecardDetailsFn;
    this.getUsersInCompanyFn = this.authService.getUsersInCompanyFn;
    this.searchSupplierFn = this.ppsrService.searchSupplierFn;
    this.businessNumberSearchFn = this.applicationService.businessNumberSearchFn;
    this.saveSupplierFn = this.ppsrService.saveSupplierFn;
    this.businessSearchFn = this.applicationService.businessSearchFn;
    this.listApplicationDocumentFn = this.applicationService.listApplicationDocumentFn;
    this.downloadApplicationDocumentUrlFn = this.applicationService.downloadApplicationDocumentUrlFn;
    this.downloadAllApplicationDocumentUrlFn = this.applicationService.downloadAllApplicationDocumentUrlFn;
    this.approveApplicationDocumentFn = this.applicationService.approveApplicationDocumentFn;
    this.declineApplicationDocumentFn = this.applicationService.declineApplicationDocumentFn;
    this.undoApplicationDocumentFn = this.applicationService.undoApplicationDocumentFn;
    this.deleteApplicationDocumentFn = this.applicationService.deleteApplicationDocumentFn;
    this.updateApplicationDocumentTagsFn = this.applicationService.updateDocumentTagsFn;
    this.updateApplicationRequiredDocumentsFn = this.applicationService.updateApplicationRequiredDocumentsFn;
    this.sendApplicationDocumentNotificationFn = this.applicationService.sendApplicationDocumentNotificationFn;
    this.createDocumentWorklistFn = this.applicationService.createDocumentWorklistFn;
    this.completeDocumentWorklistFn = this.applicationService.completeDocumentWorklistFn;
    this.getLvrCalculatorValueFn = this.applicationService.getLvrCalculatorValueFn;
    this.updateLvrCalculatorValueFn = this.applicationService.updateLvrCalculatorValueFn;
    this.getDscrCalculatorValueFn = this.applicationService.getDscrCalculatorValueFn;
    this.updateDscrCalculatorValueFn = this.applicationService.updateDscrCalculatorValueFn;
    this.getConsumerDscrCalculatorValueFn = this.applicationService.getConsumerDscrCalculatorValueFn;
    this.updateConsumerDscrCalculatorValueFn = this.applicationService.updateConsumerDscrCalculatorValueFn;
    this.consumerFinanceDscrServiceablilityCalculationFn = this.applicationService.consumerFinanceDscrServiceabilityCalculationFn;

    this.getOriginatorByIdFn = this.adminService.getOriginatorByIdFn;
    this.addIndividualFn = this.digitalIdService.addIndividualFn;
    this.verifyApplicationIndividualsFn = this.digitalIdService.verifyApplicationIndividualsFn;
    this.verifyOneApplicationIndividualFn = this.digitalIdService.verifyOneApplicationIndividualFn;
    this.sendAskForVerificationInfoEmailFn = this.digitalIdService.sendAskForVerificationInfoEmailFn;
    this.getApplicationIndividualsFn = this.digitalIdService.getApplicationIndividualsFn;
    this.updateIndividualFn = this.digitalIdService.updateIndividualFn;
    this.updateApplicationIndividualDigitalIdMappingFn = this.digitalIdService.updateApplicationIndividualDigitalIdMappingFn;
    this.updateApplicationIndividualInfoFn = this.digitalIdService.updateApplicationIndividualInfoFn;
    this.printDigitalIdResultFn = this.digitalIdService.printDigitalIdResultFn;
    this.setupApplicationIndividualMappingFn = this.digitalIdService.setupApplicationIndividualMappingFn;

    this.createApplicationNoteFn = this.ppsrService.createNoteFn;
    this.removeApplicationNoteByNoteIdFn = this.ppsrService.removeApplicationNoteByNoteIdFn;
    this.getApplicationAuditLogsFn = this.auditService.getApplicationAuditLogsFn;
    this.getNotesByApplicationIdFn = this.ppsrService.getNotesByApplicationIdFn;
    this.requestDocFn = this.ppsrService.requestDocFn;
    // this.assetSettlementSearchAssetsFn = this.ppsrService.searchAssetsFn;
    this.sendContractEmailsFn = this.docusignService.sendContractEmailsFn;
    this.updateDocumentMetadataFn = this.applicationService.updateDocumentMetadataFn;
    this.getContractStatusFn = this.docusignService.getContractStatusFn;
    this.generateContractFn = this.docusignService.generateContractFn;
    this.deleteContractFileFn = this.docusignService.deleteContractFileFn;
    this.getActiveContractForApplicationFn = this.docusignService.getActiveContractForApplicationFn;
    this.declineContractFn = this.docusignService.declineContractFn;
    this.createNewDisbursementFn = this.applicationService.createNewDisbursementFn;
    this.updateDisbursementFn = this.applicationService.updateDisbursementFn;
    this.getDisbursementByApplicationIdFn = this.applicationService.getDisbursementByApplicationIdFn;
    this.syncDisbursementToSfFn = this.applicationService.syncDisbursementToSfFn;
    this.syncBankDetailsToSfFn = this.applicationService.syncBankDetailsToSfFn;
    this.syncPrivateSellerBankDetailsToSfFn = this.applicationService.syncPrivateSellerBankDetailsToSfFn;
    this.syncDepositPaidToSfFn = this.applicationService.syncDepositPaidToSfFn;
    this.syncContractDetailsToSfFn = this.docusignService.syncContractDetailsToSfFn;
    this.getBillerNameFn = this.applicationService.getBillerNameFn;
    this.validateBpayFn = this.applicationService.validateBpayFn;
    this.updateApplicationStageFn = this.applicationService.updateApplicationStageFn;
    this.getApplicationByIdFn = this.applicationService.getApplicationByIdFn;
    this.settleLoanFn = this.applicationService.settleLoanFn;
    this.getInstitutionNameFn = this.applicationService.getInstitutionNameFn;
    this.getBankDetailsFromOpportunitySfFn = this.applicationService.getBankDetailsFromOpportunitySfFn;
    this.generateApprovalNoticeFn = this.applicationService.generateApprovalNoticeFn;
    this.sendApprovalNoticeEmailFn = this.applicationService.sendApprovalNoticeEmailFn;
    this.deleteApprovalNoticeFileFn = this.applicationService.deleteApprovalNoticeFileFn;
    this.copyApplicationFn = this.applicationService.copyApplicationFn;
    this.getApplicationOwnerFn = this.applicationService.getApplicationOwnerFn;
    this.getGeoLocationFn = this.applicationService.getGeoLocationFn;
    this.refreshBankStatementFn = this.applicationService.refreshBankStatementFn;
    this.getUserFn = this.authService.getUserFn;
    this.getAccountDetailsFromSfFn = this.applicationService.getAccountDetailsFromSfFn;
    this.extractTaxInvoiceFn = this.applicationService.extractTaxInvoiceFn;
    this.updateApplicationFn = this.applicationService.updateApplicationFn;
    this.updateApplicationSfFn = this.applicationService.updateApplicationSfFn;
    this.createAssetInspectionFn = this.applicationService.createAssetInspectionFn;
    this.getAssetInspectionsForApplicationFn = this.applicationService.getAssetInspectionsForApplicationFn;
    this.downloadVerimotoReportFn = this.applicationService.downloadVerimotoReportFn;
    this.downloadDocumentFromAzureFn = this.applicationService.downloadDocumentFromAzureFn;
    this.sendIdVerifyLinkFn = this.applicationService.sendIdVerifyLinkFn;
    this.bypassFaceCompareFn = this.applicationService.bypassFaceCompareFn;
    this.deleteIdentityVerificationFn = this.applicationService.deleteIdentityVerificationFn;
    this.sendPrivacyConsentEmailFn = this.applicationService.sendPrivacyConsentEmailFn;
    this.getBsaLenderListFn = this.applicationService.getBsaLenderListFn;
    this.getBsaExcludedLenderListFn = this.applicationService.getBsaExcludedLenderListFn;
    this.saveBsaCalculatorFn = this.applicationService.saveBsaCalculatorFn;
    this.getBsaCalculatorFn = this.applicationService.getBsaCalculatorFn;
    this.runCreditFlowFn = this.applicationService.runCreditFlowFn;
    this.getCreditWebServicesFn = this.applicationService.getApplicationWebServicesFn;
    this.getApplicationCreditFlowResultFn = this.applicationService.getApplicationCreditFlowResultFn;
    this.downloadCreditorWatchReportFn = this.applicationService.downloadCreditorWatchReportFn;
    this.updateCreditStatusFn = this.applicationService.updateCreditStatusFn;
    this.getCreditAuditLogFn = this.applicationService.getCreditAuditLogFn;
    this.getDscrCalculatorHistoryFn = this.applicationService.getDscrCalculatorHistoryFn;
    this.getOriginatorBusinessByIdFn = this.applicationService.getOriginatorBusinessByIdFn;
    this.addAuditLogFn = this.ppsrService.addAuditLogFn;
    this.getWebServiceReportFn = this.applicationService.getWebServiceReportFn;
    this.getDuplicatedApplicationsFn = this.applicationService.getDuplicatedApplicationsFn;
    this.deleteWebServicesFn = this.applicationService.deleteWebServicesFn;
    this.requestMoreInformationFn = this.applicationService.requestMoreInformationFn;
    this.withdrawApplicationFn = this.applicationService.withdrawApplicationFn;
  }

  async ngOnInit() {
    setupUntilDestroy(this);
    this.initialApplicationSelectionType = this.activatedRoute.snapshot.params['innerTabs'];
    this.queryParams = this.activatedRoute.snapshot.queryParams ?? {};
    this.application = (this.activatedRoute.snapshot.data as any).application;
    this.allowContractGeneration = displayGenerateContractButton(this.loggedInUser, environment.production);
    if (this.application && this.application.Status === 'Draft') {
      const applicationId = this.application.ApplicationId;
      switch(this.application.ApplicationType) {
        case 'AssetFinance':
          const urlTree = navigationUrlForAssetFinance(this.router, this.application.AppInfo.productType, this.application.AppInfo.isSwitchToEasyDoc, applicationId);
          await this.router.navigateByUrl(urlTree);
          // await this.router.navigate(navigationUrlForAssetFinance(applicationId));
          break;
        case 'BusinessLoans':
          await this.router.navigate(navigationUrlForBusinessFinance(applicationId));
          break;
        case 'BusinessOverdraft':
          await this.router.navigate(navigationUrlForBusinessOverdraft(applicationId));
          break;
        case 'Commercial':
          await this.router.navigate(navigationUrlForCommercialFinance(applicationId));
          break;
        case 'Consumer':
          if (getUser()!.priviledges.includes('lg' as any)) {
            await this.router.navigate(navigationUrlForConsumerFinance(applicationId));
          } else {
            await this.router.navigate(navigationUrlForConsumerAssetFinance(applicationId));
          }
          break;
        case 'InsurancePremium':
          await this.router.navigate(navigationUrlForInsurancePremium(applicationId));
          break;
        case 'CorporateLoans':
          await this.router.navigate(navigationUrlForCorporateLoan(applicationId));
          break;
      }
    }

    this.subscriptions.push(this.applicationService.getIpAddress().pipe(
      map(r => {
        if (r.status) {
          this.ip = r.payload;
        }
      })
    ).subscribe());
  }

  async onApplicationSelectionType(selectionObject: ApplicationSelectionObject) {
    // WEB-4762: browser hangs
    // await this.router.navigateByUrl(this.navigationUrlForApplication(this.router,String(this.application.ApplicationId), currentSelection), {replaceUrl: true});
    // await this.router.navigate(this.navigationUrlForApplication(String(this.application.ApplicationId), currentSelection), {replaceUrl: true});
    await this.router.navigate(
      this.navigationUrlForApplication(String(this.application.ApplicationId), selectionObject.selection, selectionObject.innerTabParam),
      {replaceUrl: true, onSameUrlNavigation: 'ignore'});
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }


  async onBreadcrumbEvent(evt: BreadcrumbComponentEvent) {
    // NOTE:
    // WEB-5403: You get here from application listing page, so the list cached already has this application, no
    //           need to reload when going back to the listing page
    await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, {
      ...(this.queryParams ?? {}),
      reload: false
    }));
  }

  onApplicationDetailsEvent($event: UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}) {
    if ($event && $event.value) {
      if ($event.type == 'submitter') {
        const submitterUserId = $event.value.UserId;
        this.subscriptions.push(this.applicationService.updateApplicationSubmitter(this.application.ApplicationId, submitterUserId).pipe(
          this.portalHotToastService.snackBarObservable(`Submitter updated`),
          tap(r => { })
        ).subscribe());
      }
      else if ($event.type == 'additional-correspondent') {
        const data = $event.value;
        this.subscriptions.push(this.applicationService.updateApplicationAdditionalCorrespondent(this.application.ApplicationId, data).pipe(
          this.portalHotToastService.snackBarObservable(`Additional correspondent updated`),
          tap(r => { })
        ).subscribe());
      }
    }
  }

  async onNavigateToApplications() {
    // WEB-5403:
    await this.router.navigateByUrl(navigationUrlForApplicationsWithQueryParams(this.router, {
      ...(this.queryParams ?? {}),
      reload: true
    }));

  }

  async onAssetPricingEvents(event: AssetPricingComponentEvent) {
    switch(event.type) {
      case "asset-pricing-saved": {
        this.reloadApplication();
        break;
      }
    }
  }

  async onBusinessPricingEvents(event: BusinessPricingComponentEvent) {
    switch(event.type) {
      case "business-pricing-saved": {
        this.reloadApplication();
        break;
      }
    }
  }

  async onBusinessOverdraftPricingEvents(event: BusinessOverdraftPricingComponentEvent) {
    switch(event.type) {
      case "business-overdraft-pricing-saved": {
        this.reloadApplication();
        break;
      }
    }
  }

  async onConsumerPricingEvents(event: ConsumerPricingComponentEvent) {
    switch(event.type) {
      case "consumer-pricing-saved": {
        this.reloadApplication();
        break;
      }
    }
  }

  async onCorporateLoansPricingEvent(event: CorporateLoansPricingComponentEvent) {
    switch(event.type) {
      case 'corporate-loan-pricing-saved': {
        this.reloadApplication();
        break;
      }
    }
  }

  reloadApplication() {
    this.subscriptions.push(this.applicationService.getApplicationById(this.application.ApplicationId).pipe(
      tap(r => {
        if (r.status) {
          this.application = r.payload;
          console.log('************** reload application', this.application);
        }
      })
    ).subscribe());
  }

  async onClickApplicationEvent(application: DuplicatedApplication) {
    const stage = application.AppInfo.StageName ?? 'Draft';
    const applicationId = application.ApplicationId;
    if (stage === 'Draft') {
      console.log('****** status-draft');
      switch (application.ApplicationType) {
        case 'AssetFinance':
          const urlTree = navigationUrlForAssetFinance(this.router, application.AppInfo.productType, application.AppInfo.isSwitchToEasyDoc, applicationId);
          await this.router.navigateByUrl(urlTree);
          // await this.router.navigate(navigationUrlForAssetFinance(applicationId));
          break;
        case 'BusinessLoans':
          await this.router.navigate(navigationUrlForBusinessFinance(applicationId));
          break;
        case 'BusinessOverdraft':
          await this.router.navigate(navigationUrlForBusinessOverdraft(applicationId));
          break;
        case 'Commercial':
          await this.router.navigate(navigationUrlForCommercialFinance(applicationId));
          break;
        case 'Consumer':
          if (getUser()!.priviledges.includes('lg' as any)) {
            await this.router.navigate(navigationUrlForConsumerFinance(applicationId));
          } else {
            await this.router.navigate(navigationUrlForConsumerAssetFinance(applicationId));
          }
          break;
        case 'InsurancePremium':
          await this.router.navigate(navigationUrlForInsurancePremium(applicationId));
          break;
        case 'CorporateLoans':
          await this.router.navigate(navigationUrlForCorporateLoan(applicationId));
          break;
        
      }
    } else {
      const queryParams = {
        reload: false,
        limit: DEFAULT_LIMIT,
        offset: DEFAULT_OFFSET,
        search: '',
        applicationType: 'All',
        applicationState: 'all',
      };
      this.router.navigate(
        navigationUrlForApplication(
          String(application.ApplicationId), 'app', undefined,
          queryParams as any,
        )
      ).then(() => window.location.reload())
    }
  }

  async refreshCreditTab(application: Application) {
    window.location.reload();
  }
}
